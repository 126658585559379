import { Selector } from '@ngxs/store';
import { WarehousesViewState } from './warehouses_view.state';
import { WarehousesViewStateModel } from './warehouses_view-state.model';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';

export class WarehousesViewSelectors {
  @Selector([WarehousesViewState])
  static warehouse(state: WarehousesViewStateModel): Warehouse {
    return state.warehouse;
  }
}

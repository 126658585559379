import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';

export class ChangeDatePeriodInAttendanceWidget {
  static readonly type = '[Attendance Widget] Change date period';
  constructor(public datePeriod: DatePeriodDayjs) {}
}

export class LoadDataForAttendanceWidget {
  static readonly type = '[Attendance Widget] Load attendance data';
  constructor() {}
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, map } from 'rxjs';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';
import { SaveCompanyPopularRouteFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-popular-route/save-company-popular-route-form-data';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyPopularRouteTypes } from '@app/modules/companies/interfaces/company-popular-route-types';

@Injectable({
  providedIn: 'root',
})
export class CompanyPopularRoutesService {
  fromIcon = {
    url: 'assets/icons/popular-routes/from.png',
    scaledSize: {
      width: 32,
      height: 32,
    },
    anchor: new google.maps.Point(16, 16),
  };

  toIcon = {
    url: 'assets/icons/popular-routes/to.png',
    scaledSize: {
      width: 32,
      height: 32,
    },
    anchor: new google.maps.Point(16, 16),
  };

  constructor(private apiService: ApiService) {}

  // companyPopularRouteList
  list(companyId: number): Observable<CompanyPopularRoute[]> {
    return this.apiService.getAll<CompanyPopularRoute>(
      '/company/' + companyId + '/popular-route'
    );
  }

  // not set
  listAll(type: CompanyPopularRouteTypes): Observable<CompanyPopularRoute[]> {
    return this.apiService.getAll<CompanyPopularRoute>(
      '/company/popular-route',
      { type }
    );
  }

  // companyPopularRouteCreate
  create(
    data: SaveCompanyPopularRouteFormData
  ): Observable<CompanyPopularRoute> {
    const companyId = data.companyId;
    delete data.companyId;
    return this.apiService.post<CompanyPopularRoute>(
      '/company/' + companyId + '/popular-route',
      data
    );
  }

  // companyPopularRouteUpdate
  update(
    data: SaveCompanyPopularRouteFormData
  ): Observable<CompanyPopularRoute> {
    const companyId = data.companyId;
    delete data.companyId;
    const routeId = data.id;
    delete data.id;
    return this.apiService.put<CompanyPopularRoute>(
      '/company/' + companyId + '/popular-route/' + routeId,
      data
    );
  }

  // companyPopularRouteView
  get(companyId: number, routeId: number): Observable<CompanyPopularRoute> {
    return this.apiService.get<CompanyPopularRoute>(
      '/company/' + companyId + '/popular-route/' + routeId
    );
  }

  // companyPopularRouteDelete
  delete(companyId: number, routeId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>(
        '/company/' + companyId + '/popular-route/' + routeId
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }

  getCompanies(routeId: number): Observable<Company[]> {
    return this.apiService.getAll<Company>(
      '/company/popular-route/' + routeId,
      {
        expand: ['details'].join(','),
        'per-page': 100,
      }
    );
  }

  getCargoTenderCompanies(tenderId: number): Observable<Company[]> {
    return this.apiService.getAll<Company>(
      '/tender/' + tenderId + '/popular-route-company',
      {
        expand: ['details'].join(','),
        'per-page': 100,
      }
    );
  }
}

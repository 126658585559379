import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { TransportAddEditFormData } from '@app/pages/transport/add-edit/interfaces/transport_add-edit.form-data';
import { SearchEntityParams } from '@app/modules/form-elements/components/select-entity/interfaces/search-entity-params';
import { IdName } from '@app/interfaces/id-name';
import { TransportMode } from '../interfaces/transport-mode';
import { TransportModeTypes } from '../transport-mode-types';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('MODULES.TRANSPORT.SERVICE.Авто');
marker('MODULES.TRANSPORT.SERVICE.Тягач');
marker('MODULES.TRANSPORT.SERVICE.Прицеп');
marker('MODULES.TRANSPORT.SERVICE.Корабль');

@Injectable({
  providedIn: 'root',
})
export class TransportService {
  constructor(private apiService: ApiService) {}

  list(
    page: number,
    companyId: number = null,
    searchValue: string = null,
    isFavorite: boolean = false
  ): Observable<PaginationResults<Transport>> {
    const data: any = { page };
    if (companyId) {
      data.companyId = companyId;
    }

    if (searchValue) {
      data.search = searchValue;
    }

    if (isFavorite) {
      data.isFavorite = 1;
    }

    data.expand = ['company', 'company.details', 'transportType'].join(',');
    return this.apiService.getPaginatedResults<Transport>('/transport', data);
  }

  getCompanyTransportList(
    companyId: number,
    search?: string
  ): Observable<Transport[]> {
    const data: any = {
      companyId,
    };

    if (search) {
      data.search = search;
    }
    data['expand'] = ['transportType'].join(',');

    return this.apiService
      .getPaginatedResults<Transport>('/transport', data)
      .pipe(
        map((results: PaginationResults<Transport>) => {
          return results.data;
        })
      );
  }

  getTenderTransportList(tenderId: number): Observable<Transport[]> {
    return this.apiService.getAll<Transport>(
      '/transport/for-tender/' + tenderId,
      {
        expand: ['transportType'].join(','),
      }
    );
  }

  listTransport(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName>> {
    const apiPath =
      '/transport/for-tender/' +
      params.tenderId +
      '/company/' +
      params.companyId;

    delete params.tenderId;
    delete params.companyId;

    return this.apiService
      .getPaginatedResults<Transport>(apiPath, {
        fields: ['id', 'model', 'number', 'transportType.mode.code'].join(','),
        expand: ['transportType'].join(','),
        ...params,
      })
      .pipe(
        map((results: PaginationResults<Transport>) => {
          const preparedResults: PaginationResults<IdName> = {
            ...results,
            data: results.data.map((transport: Transport) => {
              return {
                id: transport.id,
                name:
                  (transport.model ? transport.model + ' - ' : '') +
                  transport.number,
                type: transport.transportType.mode.code,
              };
            }),
          };

          return preparedResults;
        })
      );
  }

  get(transportId: number): Observable<Transport> {
    return this.apiService.get<Transport>('/transport/' + transportId, {
      expand: ['transportType'].join(','),
    });
  }

  update(data: TransportAddEditFormData): Observable<Transport> {
    const id: number = data.id;
    delete data.id;
    return this.apiService.put<Transport>('/transport/' + id.toString(), data);
  }

  add(data: TransportAddEditFormData) {
    return this.apiService.post('/transport', data);
  }

  save(data: TransportAddEditFormData) {
    if (data.id) {
      return this.update(data);
    }

    return this.add(data);
  }

  remove(id: number) {
    return this.delete(id);
  }

  delete(id: number): Observable<unknown> {
    return this.apiService.delete('/transport/' + id);
  }

  lockTransport(transportId: number): Observable<Transport> {
    return this.apiService.put<Transport>(
      '/transport/' + transportId.toString() + '/deactivate'
    );
  }

  unlockTransport(transportId: number): Observable<Transport> {
    return this.apiService.put<Transport>(
      '/transport/' + transportId.toString() + '/activate'
    );
  }

  addTransportPhoto(
    transportId: number,
    photoBase64: string
  ): Observable<Transport> {
    return this.apiService.put<Transport>(
      '/transport/' + transportId + '/photos',
      {
        photos: [photoBase64],
      }
    );
  }
  removeTransportPhoto(
    transportId: number,
    photoId: number
  ): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/transport/' + transportId + '/photos/' + photoId
    );
  }

  getTransportModes(): TransportMode[] {
    return [
      {
        id: TransportModeTypes.VAN,
        name: 'MODULES.TRANSPORT.SERVICE.Авто',
      },
      {
        id: TransportModeTypes.TRACTOR,
        name: 'MODULES.TRANSPORT.SERVICE.Тягач',
      },
      {
        id: TransportModeTypes.TRAILER,
        name: 'MODULES.TRANSPORT.SERVICE.Прицеп',
      },
      {
        id: TransportModeTypes.SHIP,
        name: 'MODULES.TRANSPORT.SERVICE.Корабль',
      },
    ];
  }

  getTransportModeName(mode: TransportMode): string {
    const names: any = {
      [TransportModeTypes.VAN]: 'MODULES.TRANSPORT.SERVICE.Авто',
      [TransportModeTypes.TRACTOR]: 'MODULES.TRANSPORT.SERVICE.Тягач',
      [TransportModeTypes.TRAILER]: 'MODULES.TRANSPORT.SERVICE.Прицеп',
      [TransportModeTypes.SHIP]: 'MODULES.TRANSPORT.SERVICE.Корабль',
    };

    return names[mode.toString()];
  }
}

export enum TenderStates {
  ACTIVE = 1,
  CLOSED = 2,
  IN_PROGRESS = 3,
  DONE = 4,
  CANCEL = 5,
  REFUSED = 6,
  ARCHIVE = 7,
  CONFIRMED = 8,
  ACTIVE_WITH_BET = 9,
  CONFIRMED_BY_CARRIER = 10,
  CARGO_DELIVERED = 11,
}

import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { TenderPointStates } from '@app/modules/tenders/tender-point-states';

export class CloseCarCargoTender {
  static readonly type = '[View Car Cargo Tender] Close cargo tender';
  constructor(public id: number) {}
}

export class RefuseCarCargoTender {
  static readonly type = '[View Car Cargo Tender] Refuse cargo tender';
  constructor(public id: number) {}
}

export class CancelCarCargoTender {
  static readonly type = '[View Car Cargo Tender] Cancel cargo tender';
  constructor(public id: number) {}
}

export class CompleteCarCargoTender {
  static readonly type =
    '[View Car Cargo Tender] Mark cargo tender as completed';
  constructor(public id: number) {}
}

export class SetCarCargoTenderPointState {
  static readonly type = '[View Car Cargo Tender] Set tender point state';
  constructor(public pointId: number, public state: TenderPointStates) {}
}

export class ToggleCanChangeTransportInCarCargoTender {
  static readonly type =
    '[View Car Cargo Tender] Toggle checkbox: Allow change transport and driver data for carrier';
  constructor(public tenderId: number) {}
}

export class RepeatCarCargoTender {
  static readonly type =
    '[View Car Cargo Tender] Repeat cargo tender (Create cargo tender with populated form data)';
  constructor(public tender: CarCargoTender) {}
}

export class LoadCarCargoTenderTransportAndDriversList {
  static readonly type =
    '[View Car Cargo Tender / Cargo tenders] Load cargo tender transport list and driver list';
  constructor(public tenderId: number) {}
}

export class NewBetPlacedInCarCargoTender {
  static readonly type =
    '[View Car Cargo Tender / Cargo tender bets] Mercury signal: A new cargo tender bet was placed';
  constructor(public tenderId: number) {}
}

export class SetCarCargoTenderInProgress {
  static readonly type =
    '[View Car Cargo Tender] Set car cargo tender state to in-progress';
  constructor(public tenderId: number) {}
}

export class SetCarCargoTenderStateCargoDelivered {
  static readonly type =
    '[View Car Cargo Tender] Set car cargo tender state to cargo delivered';
  constructor(public tenderId: number) {}
}

export class DeleteCarCargoTender {
  static readonly type = '[View Car Cargo Tender] Delete car cargo tender';
  constructor(public tenderId: number) {}
}

export class UpdateCarCargoPointArrivalTime {
  static readonly type =
    '[View Car Cargo Tender] Update car cargo tender point arrival time';
  constructor(public pointID: number, public arrivalTime: string) {}
}

export class SetCarCargoTenderContainerNumber {
  static readonly type =
    '[View Car Cargo Tender] Set car cargo tender container number';
  constructor(public tenderId: number, public containerNumber: string) {}
}

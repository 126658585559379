import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ProfileStateModel } from './profile-state.model';
import { Injectable } from '@angular/core';
import {
  ChangePasswordFailed,
  ChangePasswordSuccess,
  InitChangePasswordForm,
  LoadProfile,
  TryChangePassword,
  UploadPicture,
  UpdateProfile,
  LoadUserComments,
  SetUserTimeBlocking,
  DeletePersonalAccount,
} from './profile.actions';
import { ApiTokenState } from './api_token';
import { tap, catchError } from 'rxjs/operators';
import { FileService } from '@app/services/file.service';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { UsersService } from '@app/modules/users/services/users.service';
import { User } from '@app/modules/users/interfaces/user';
import {
  AuthSelectors,
  LogoutSuccess,
  SaveAccessAndRefreshTokens,
  UpdateCompanyAvatar,
} from '@app/store';
import { CompanyAvatarUpdateResponse } from '@app/modules/companies/interfaces/company-avatar-update-response';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { UiService } from '@app/services/ui.service';
import { of } from 'rxjs';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { ProfileService } from '@app/modules/profile/services/profile.service';
import { UserComment } from '@app/modules/profile/interfaces/user-comment';
import { UserCommentsService } from '@app/modules/profile/services/user-comments.service';
import { NotificationsState } from './notifications';
import { UploadUserSignature } from './documents/profile_documents.actions';
import { DocumentsService } from '@app/modules/documents/services/documents.service';
import { UserFinancesState } from './user_finances';
import { ProfileCompaniesState } from './companies';
import { UserAnalyticsState } from './analytics';

@Injectable()
@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    profile: null,
    fieldsErrors: null,
    companies: [],
    accessToken: null,
    comments: null,
  },
  children: [
    ApiTokenState,
    NotificationsState,
    UserFinancesState,
    ProfileCompaniesState,
    UserAnalyticsState,
  ],
})
export class ProfileState {
  constructor(
    private store: Store,
    private fileService: FileService,
    private usersService: UsersService,
    private uiService: UiService,
    private profileService: ProfileService,
    private userCommentsService: UserCommentsService,
    private documentsService: DocumentsService
  ) {}

  @Action(LoadProfile)
  @ImmutableContext()
  loadProfile(
    { setState }: StateContext<ProfileStateModel>,
    { userId }: LoadProfile
  ) {
    let profileId: number;
    // my-profile
    if (!userId) {
      const user = this.store.selectSnapshot(AuthSelectors.user);
      profileId = user.id;
      // profile/:id
    } else {
      profileId = userId;
    }

    return this.usersService.getUser(profileId).pipe(
      tap((user: User) => {
        setState((state: ProfileStateModel) => {
          state.profile = user;
          return state;
        });
      })
    );
  }

  @Action(UploadPicture)
  @ImmutableContext()
  uploadPicture(
    {}: StateContext<ProfileStateModel>,
    { file, companyId, userId }: UploadPicture
  ) {
    if (companyId) {
      return this.fileService.uploadPicture(file, companyId).pipe(
        tap((response: CompanyAvatarUpdateResponse) => {
          this.store.dispatch(
            new UpdateCompanyAvatar(companyId, response.avatar)
          );
        }),
        catchError((err: any) => {
          if (err instanceof UnprocessableEntity) {
            this.uiService.errorMessage(
              err.originalError.error.errors.image[0]
            );
          }
          return of(null);
        })
      );
    }

    return this.fileService.uploadPicture(file, null, userId).pipe(
      catchError((err: any) => {
        if (err instanceof UnprocessableEntity) {
          this.uiService.errorMessage(err.originalError.error.errors[0]);
        }
        return of(null);
      }),
      tap((newAccessTokenResponse: NewAccessTokenResponse) => {
        if (!userId) {
          this.store.dispatch(
            new SaveAccessAndRefreshTokens(newAccessTokenResponse)
          );
        }
      })
    );
  }

  @Action(TryChangePassword)
  @ImmutableContext()
  tryChangePassword(
    {}: StateContext<ProfileStateModel>,
    { formData }: TryChangePassword
  ) {
    this.uiService.displayLoading();
    return this.usersService.changePassword(formData).pipe(
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new ChangePasswordFailed(err.originalError.error)
          );
        }
        return of(null);
      }),
      tap((response: ResponseSuccess<unknown>) => {
        this.uiService.dismissLoading();
        if (response) {
          this.store.dispatch(new ChangePasswordSuccess());
        }
      })
    );
  }

  @Action(InitChangePasswordForm)
  @Action(TryChangePassword)
  @ImmutableContext()
  initRegistration({ setState }: StateContext<ProfileStateModel>) {
    setState((state: ProfileStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(ChangePasswordFailed)
  @ImmutableContext()
  changePasswordFailed(
    { setState }: StateContext<ProfileStateModel>,
    { responseError }: ChangePasswordFailed
  ) {
    setState((state: ProfileStateModel) => {
      state.fieldsErrors = responseError.errors;
      return state;
    });
  }

  @Action(ChangePasswordSuccess)
  @ImmutableContext()
  changePasswordSuccess({ setState }: StateContext<ProfileStateModel>) {
    setState((state: ProfileStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(UpdateProfile)
  @ImmutableContext()
  updateProfile(
    {}: StateContext<ProfileStateModel>,
    { data, userId }: UpdateProfile
  ) {
    return this.profileService.updateProfile(data, userId).pipe(
      tap((accessToken: NewAccessTokenResponse) => {
        if (!userId) {
          this.store.dispatch(new SaveAccessAndRefreshTokens(accessToken));
        }
      })
    );
  }

  @Action(LoadUserComments)
  @ImmutableContext()
  loadUserComments(
    { setState }: StateContext<ProfileStateModel>,
    { userId }: LoadUserComments
  ) {
    return this.userCommentsService.list(userId).pipe(
      tap((comments: UserComment[]) => {
        setState((state: ProfileStateModel) => {
          state.comments = comments;
          return state;
        });
      })
    );
  }

  @Action(UploadUserSignature)
  @ImmutableContext()
  uploadSignature(
    { getState, setState }: StateContext<ProfileStateModel>,
    { file }: UploadUserSignature
  ) {
    return this.documentsService
      .uploadUserSignature(getState().profile.id, file)
      .pipe(
        tap((signature: string) => {
          return setState((state: ProfileStateModel) => {
            state.profile.signature = signature;
            return state;
          });
        })
      );
  }

  @Action(SetUserTimeBlocking)
  @ImmutableContext()
  setUserTimeBlocking(
    { getState, setState }: StateContext<ProfileStateModel>,
    { timeBlocking }: SetUserTimeBlocking
  ) {
    return this.profileService
      .setUserTimeBlocking(getState().profile.id, timeBlocking)
      .pipe(
        tap((user: User) => {
          return setState((state: ProfileStateModel) => {
            state.profile.timeBlocking = user.timeBlocking;
            return state;
          });
        })
      );
  }

  @Action(DeletePersonalAccount)
  @ImmutableContext()
  deletePersonalAccount() {
    return this.profileService.deletePersonalAccount().pipe(
      tap(() => {
        this.store.dispatch(new LogoutSuccess());
      })
    );
  }
}

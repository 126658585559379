import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { Currency } from '@app/modules/currencies/interfaces/currency';

@Injectable({
  providedIn: 'root',
})
export class CurrenciesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Currency[]> {
    return this.apiService.getAll('/currency');
  }

  getRates(): Observable<Currency[]> {
    return this.apiService.get<Currency[]>('/exchange-rates');
  }
}

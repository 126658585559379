import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { LoginFormData } from '@app/pages/login/interfaces/login-form-data';
import { RegistrationApplicationFormData } from '@app/pages/registration-application/interface/registration-application.form-data';
import { resetPasswordFormData } from '@app/pages/reset-password/interfaces/reset-password.form-data';

export class InitLoginPage {
  static readonly type = '[Login Page] Initialize';
  constructor() {}
}

export class TryLogin {
  static readonly type = '[Login Page] Try login';
  constructor(public payload: LoginFormData) {}
}
export class LoginFailed {
  static readonly type = '[Login Page] Login failed, wrong credentials?';
  constructor() {}
}
export class LoginSucceess {
  static readonly type =
    '[Login Page] Login succeeded, redirect to dashboard...';
  constructor() {}
}

export class SaveAccessAndRefreshTokens {
  static readonly type = '[Auth] Save access and refresh tokens in store';
  constructor(public payload: NewAccessTokenResponse) {}
}

export class TryLogout {
  static readonly type = '[Auth] Logout.';
}

export class LogoutFailed {
  static readonly type = '[Auth] Logout failed, try to clear cookies.';
}

export class LogoutSuccess {
  static readonly type = '[Auth] Logout success, redirect to login page.';
}

export class ReloadCurrentCompanyPermissions {
  static readonly type =
    '[Auth (Permissions)] Reload current company permissions';
}

export class SubmitRegistrationApplication {
  static readonly type = '[Auth] Submit registration application';
  constructor(public data: RegistrationApplicationFormData) {}
}

export class ForgotPassword {
  static readonly type = '[Auth] Forgot password (reset password request)';
  constructor(public email: string) {}
}

export class ResetPassword {
  static readonly type = '[Auth] Reset password (set the new password)';
  constructor(public token: string, public data: resetPasswordFormData) {}
}

export class SetUserLangauge {
  static readonly type = '[Auth] Set user language';
  constructor(public lang: string) {}
}

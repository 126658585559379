import { Selector } from '@ngxs/store';
import { CompanyFinancesBillsState } from './company-finances_bills.state';
import { CompanyFinancesBillsStateModel } from './company-finances_bills-state.model';
import { CompanyFinancesBillsTabs } from '@app/pages/company-finances/bills/company-finances-bills-tabs';

export class CompanyFinancesBillsSelectors {
  @Selector([CompanyFinancesBillsState])
  static activeTab(
    state: CompanyFinancesBillsStateModel
  ): CompanyFinancesBillsTabs {
    return state.activeTab;
  }
}

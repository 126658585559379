import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { FetchTendersOnTendersPage } from './tenders-page.actions';
import { TendersPageStateModel } from './tenders-page-state.model';
import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { tap } from 'rxjs/operators';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import {
  SetCargoTendersTransportationTypeOnTendersPage,
  TendersPageFiltersState,
} from './filters';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { SetCarTendersOnTendersPage, TendersPageCarState } from './car';
import { SetSeaTendersOnTendersPage, TendersPageSeaState } from './sea';
import {
  SetTransportTendersOnTendersPage,
  TendersPageTransportState,
} from './transport';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { TendersPageFiltersStateModel } from './filters/tenders-page_filters-state.model';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';

@Injectable()
@State<TendersPageStateModel>({
  name: 'tenders_page',
  defaults: {
    page: 1,
    maxPage: null,
  },
  children: [
    TendersPageFiltersState,
    TendersPageCarState,
    TendersPageSeaState,
    TendersPageTransportState,
  ],
})
export class TendersPageState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private transportTendersService: TransportTenderService,
    private store: Store
  ) {}

  @Action([SetCargoTendersTransportationTypeOnTendersPage])
  @ImmutableContext()
  onFilterValueChange({ setState }: StateContext<TendersPageStateModel>) {
    return setState((state: TendersPageStateModel) => {
      state.page = 1;
      return state;
    });
  }

  @Action(FetchTendersOnTendersPage)
  @ImmutableContext()
  fetchTendersOnTendersPage(
    { getState, setState }: StateContext<TendersPageStateModel>,
    { page }: FetchTendersOnTendersPage
  ) {
    const newPage = page || getState().page;

    const filtersModel: TendersPageFiltersStateModel =
      this.store.selectSnapshot(TendersPageFiltersState);

    // todo: define type instead of any
    let filters: any = {
      state: filtersModel.states,
      search: filtersModel.searchValue,
      createdAtFrom: filtersModel.createdAtPeriod?.from,
      createdAtTo: filtersModel.createdAtPeriod?.to,
      companyId: filtersModel.company?.id,
      curatorId: filtersModel.curator?.id,
    };

    // Build filters object
    filters = Object.keys(filters)
      .filter((key) => filters[key] && filters[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = filters[key];
        return newObj;
      }, {});

    // Fetch Car Cargo Tenders
    if (
      filtersModel.cargoTendersTransportationType ===
      CargoTendersTransportationTypes.CAR
    ) {
      return this.cargoTendersCarService
        .listOnTendersPage(page, filters, true)
        .pipe(
          tap(
            (
              result: PaginationResults<
                CarCargoTender,
                FilteredTendersResultsCount
              >
            ) => {
              this.store.dispatch(new SetCarTendersOnTendersPage(result.data));
              return setState((state: TendersPageStateModel) => {
                state.page = newPage;
                state.maxPage = result.maxPage;
                return state;
              });
            }
          )
        );
    }

    // Fetch Sea Cargo Tenders
    if (
      filtersModel.cargoTendersTransportationType ===
      CargoTendersTransportationTypes.SEA
    ) {
      return this.cargoTendersSeaService.list(page, null, filters, true).pipe(
        tap(
          (
            result: PaginationResults<
              SeaCargoTender,
              FilteredTendersResultsCount
            >
          ) => {
            this.store.dispatch(new SetSeaTendersOnTendersPage(result.data));
            setState((state: TendersPageStateModel) => {
              state.page = newPage;
              state.maxPage = result.maxPage;
              return state;
            });
          }
        )
      );
    }

    // Fetch Transport Tenders
    if (
      filtersModel.cargoTendersTransportationType ===
      CargoTendersTransportationTypes.TRANSPORT
    ) {
      return this.transportTendersService
        .listOnTendersPage(page, filters, true)
        .pipe(
          tap(
            (
              result: PaginationResults<
                TransportTender,
                FilteredTendersResultsCount
              >
            ) => {
              this.store.dispatch(
                new SetTransportTendersOnTendersPage(result.data)
              );
              return setState((state: TendersPageStateModel) => {
                state.page = newPage;
                state.maxPage = result.maxPage;
                return state;
              });
            }
          )
        );
    }
  }
}

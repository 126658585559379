import { Injectable, OnDestroy } from '@angular/core';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { ApiService } from '@app/services/api.service';
import { Observable, of, Subject } from 'rxjs';
import {
  map,
  takeUntil,
  catchError,
  tap,
  concatMap,
  scan,
  filter,
} from 'rxjs/operators';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { MultipleResults } from '@app/interfaces/multiple-results';
import { Store } from '@ngxs/store';
import { SaveChatMessageFailed, SaveChatMessageSuccess } from '@app/store';
import { UnsavedMessageAttachment } from '@app/modules/chat/interfaces/unsaved-message-attachment';
import {
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { EntityStatuses } from '@app/modules/entity/entity-statuses';
import { MessageAttachment } from '@app/modules/chat/interfaces/message-attachment';

interface UnsavedMessage {
  chatID: number;
  messageID: number;
  messageContent: string;
  message?: ChatMessage;
  files?: number[];
  answeredMessageId?: number;
  forwardMessageId?: number;
  contactId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChatGroupMessagesService implements OnDestroy {
  private timeout: any;
  private isPlaying: boolean = false;

  private destroy$ = new Subject();

  private chatMessagesToBeSaved$ = new Subject<UnsavedMessage>();

  constructor(private apiService: ApiService, private store: Store) {
    this.chatMessagesToBeSaved$
      .pipe(
        takeUntil(this.destroy$),
        concatMap((unsavedMessage: UnsavedMessage) => {
          return this.saveMessage(
            unsavedMessage.chatID,
            unsavedMessage.messageContent,
            unsavedMessage.files,
            unsavedMessage.answeredMessageId,
            unsavedMessage.forwardMessageId,
            unsavedMessage.contactId
          ).pipe(
            catchError((e: any) => {
              this.store.dispatch(
                new SaveChatMessageFailed(
                  unsavedMessage.chatID,
                  unsavedMessage.messageID
                )
              );

              return of(null);
            }),
            map((chatMessage: ChatMessage) => {
              unsavedMessage.message = chatMessage;
              return unsavedMessage;
            })
          );
        }),
        tap((unsavedMessage: UnsavedMessage) => {
          if (unsavedMessage.message) {
            this.store.dispatch(
              new SaveChatMessageSuccess(
                unsavedMessage.chatID,
                unsavedMessage.messageID,
                unsavedMessage.message
              )
            );
          }
        })
      )
      .subscribe();
  }

  getChatGroupMessages(
    chatGroupId: number,
    count: number = 0
  ): Observable<MultipleResults<ChatMessage>> {
    return this.apiService.getMultiple<ChatMessage>(
      '/chat/' + chatGroupId + '/message',
      {
        fields: [
          'id',
          'date.createdAt',
          'message',
          'user.id',
          'user.first_name',
          'user.middle_name',
          'user.last_name',
          'user.avatar',
          'user.isContact',
          'user.isBlacklist',
          'feedback.id',
          'feedback.feedback',
          'feedback.feedbackId',
          'feedback.userId',
          'feedback.user.id',
          'feedback.user.avatar',
          'feedback.user.first_name',
          'feedback.user.middle_name',
          'feedback.user.last_name',
          'isRead',
          'files.id',
          'files.name',
          'files.url.view',
          'files.mimeType',
          'files.size',
          'answeredMessage.user.first_name',
          'answeredMessage.user.middle_name',
          'answeredMessage.user.last_name',
          'answeredMessage.message',
          'forwardMessage.user.id',
          'forwardMessage.user.first_name',
          'forwardMessage.user.middle_name',
          'forwardMessage.user.last_name',
          'forwardMessage.message',
          'forwardMessage.files.id',
          'forwardMessage.files.name',
          'forwardMessage.files.url.view',
          'forwardMessage.files.mimeType',
          'forwardMessage.files.size',
          'contact.id',
          'contact.first_name',
          'contact.middle_name',
          'contact.last_name',
          'contact.avatar',
          'contact.isContact',
          'contact.isBlacklist',
        ].join(','),
        expand: ['feedback', 'feedback.user'].join(','),
        count,
      }
    );
    // .pipe(
    //   map((result: MultipleResults<ChatMessage>) => {
    //     result.dataModels.map((msg: ChatMessage, index: number) => {
    //       msg.message =
    //         '[count: ' +
    //         count +
    //         ', index: ' +
    //         index +
    //         ', id: ' +
    //         msg.id +
    //         '] ' +
    //         msg.message;
    //     });
    //     return result;
    //   })
    // )
  }

  addMessage(
    chatID: number,
    messageID: number,
    messageContent: string,
    files: number[],
    answeredMessageId?: number,
    forwardMessageId?: number,
    contactId?: number
  ): void {
    this.chatMessagesToBeSaved$.next({
      chatID,
      messageID,
      messageContent,
      files,
      answeredMessageId,
      forwardMessageId,
      contactId,
    });
  }

  private saveMessage(
    chatID: number,
    messageContent: string,
    attachments?: number[],
    answeredMessageId?: number,
    forwardMessageId?: number,
    contactId?: number
  ): Observable<ChatMessage> {
    const data: any = {
      message: messageContent,
    };
    if (attachments) {
      data.files = attachments;
    }

    if (answeredMessageId) {
      data.answeredMessageId = answeredMessageId;
    }

    if (forwardMessageId) {
      data.forwardMessageId = forwardMessageId;
    }

    if (contactId) {
      data.contactId = contactId;
    }

    return this.apiService.create<ChatMessage>(
      '/chat/' + chatID + '/message',
      data
    );
  }

  deleteMessage(chatId: number, messageId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<ChatMessage>>(
        '/chat/' + chatId + '/message/' + messageId
      )
      .pipe(
        map((response: ResponseSuccess<ChatMessage>) => {
          return response.statusCode === 202;
        })
      );
  }

  markMessagesAsRead(chatId: number, messageIds: number[]): Observable<any> {
    return this.apiService.put('/chat/' + chatId + '/message/read', {
      messageIds,
    });
  }

  markAllMessagesAsReadInChat(chatId: number): Observable<any> {
    return this.apiService.put('/chat/' + chatId + '/messages/read');
  }

  markAllMessagesAsRead(): Observable<any> {
    return this.apiService.put('/chat/messages/read/all');
  }

  playSendMessageSound(): void {
    this.playSound('../../../assets/sounds/send message.mp3');
  }

  playReceiveMessageSound(): void {
    this.playSound('../../../assets/sounds/receive message.mp3');
  }

  private playSound(audioFilePath: string): void {
    if (this.isPlaying) {
      return;
    }
    this.isPlaying = true;
    this.timeout = setTimeout(() => {
      this.isPlaying = false;
    }, 500);
    const audio = new Audio();
    audio.src = audioFilePath;
    audio.load();
    audio.play().catch(() => {});
  }

  uploadAttachment(
    attachment: UnsavedMessageAttachment
  ): Observable<UnsavedMessageAttachment> {
    attachment.progress = 0;
    attachment.status.code = EntityStatuses.IS_BEING_CREATED;
    const calculateState = (
      attachment: UnsavedMessageAttachment,
      event: HttpEvent<ResponseSuccess<MessageAttachment>>
    ): UnsavedMessageAttachment => {
      if (this.isUploadEvent(event)) {
        attachment.progress = Math.round((event.loaded / event.total) * 100);
      } else if (this.isHttpResponse(event)) {
        attachment.progress = 100;
        attachment.status.code = EntityStatuses.ACTIVE;
        attachment.id = event.body.data.id;
        attachment.url = event.body.data.url;
      }

      return attachment;
    };

    return this.apiService
      .upload<ResponseSuccess<MessageAttachment>>('/chat/message/file', {
        name: attachment.name,
        file: attachment.base64,
      })
      .pipe(
        filter((event: HttpEvent<ResponseSuccess<MessageAttachment>>) => {
          return (
            !this.isSentEvent<ResponseSuccess<MessageAttachment>>(event) &&
            !this.isDownloadEvent<ResponseSuccess<MessageAttachment>>(event) &&
            !this.isHttpResponseHeader<ResponseSuccess<MessageAttachment>>(
              event
            ) &&
            !this.isHttpUser<ResponseSuccess<MessageAttachment>>(event)
          );
        })
      )
      .pipe(scan(calculateState, attachment));
  }

  isSentEvent<T>(event: HttpEvent<T>): event is HttpProgressEvent {
    return event.type === HttpEventType.Sent;
  }

  isUploadEvent<T>(event: HttpEvent<T>): event is HttpProgressEvent {
    return event.type === HttpEventType.UploadProgress;
  }

  isDownloadEvent<T>(event: HttpEvent<T>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress;
  }

  isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response;
  }

  isHttpResponseHeader<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.ResponseHeader;
  }

  isHttpUser<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.User;
  }

  getAttachment(documentId: number): Observable<MessageAttachment> {
    return this.apiService.get<MessageAttachment>(
      '/chat/message/file/' + documentId
    );
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import { Bet } from '@app/modules/bets/interfaces/bet';
import { Selector } from '@ngxs/store';
import { CargoTendersBetsHistoryStateModel } from './cargo-tenders_bets-history-state.model';
import { CargoTendersBetsHistoryState } from './cargo-tenders_bets-history.state';

export class CargoTendersBetsHistorySelectors {
  @Selector([CargoTendersBetsHistoryState])
  static bets(state: CargoTendersBetsHistoryStateModel): Bet[] {
    return state.bets;
  }

  @Selector([CargoTendersBetsHistoryState])
  static page(state: CargoTendersBetsHistoryStateModel): number {
    return state.page;
  }

  @Selector([CargoTendersBetsHistoryState])
  static maxPage(state: CargoTendersBetsHistoryStateModel): number {
    return state.maxPage;
  }
}

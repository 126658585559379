import { Injectable } from '@angular/core';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { BillsStateModel } from './bills-state.model';
import { PayForBillState } from './pay-for-bill';
import { BillsService } from '@app/modules/bills/services/bills.service';
import { DeactivateBill, UpdateBillData } from './bills.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs';
import { BillEntity } from '@app/modules/bills/interfaces/bill-entity';

@Injectable()
@State<BillsStateModel>({
  name: 'bills',
  children: [PayForBillState],
})
export class BillsState {
  constructor(private billsService: BillsService, private store: Store) {}

  @Action(DeactivateBill)
  @ImmutableContext()
  deactivateBill(
    {}: StateContext<BillsStateModel>,
    { billID }: DeactivateBill
  ) {
    return this.billsService.deactivate(billID).pipe(
      tap((billEntity: BillEntity) => {
        this.store.dispatch(new UpdateBillData(billEntity));
      })
    );
  }
}

import { Selector } from '@ngxs/store';
import { CompanyPopularRoutesStateModel } from './company-popular-routes-state.model';
import { CompanyPopularRoutesState } from './company-popular-routes.state';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';

export class CompanyPopularRoutesSelectors {
  @Selector([CompanyPopularRoutesState])
  static routes(state: CompanyPopularRoutesStateModel): CompanyPopularRoute[] {
    return state.routes;
  }

  @Selector([CompanyPopularRoutesState])
  static fieldErrors(
    state: CompanyPopularRoutesStateModel
  ): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      const errorMessages = [];
      state.fieldsErrors[key].forEach((errorMessage: string) => {
        errorMessages.push(errorMessage);
      });

      errors.push({
        field: key,
        errors: errorMessages,
      });
    });

    return errors;
  }
}

import { Selector } from '@ngxs/store';
import { CompaniesListFiltersStateModel } from './companies_list_filters-state.model';
import { CompaniesListFiltersState } from './companies_list_filters.state';
import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyTypes } from '@app/modules/companies/company-types';
import { DatePeriod } from '@app/interfaces/date-period';

export class CompaniesListFiltersSelectors {
  @Selector([CompaniesListFiltersState])
  static types(state: CompaniesListFiltersStateModel): CompanyTypes[] {
    return state.types;
  }

  @Selector([CompaniesListFiltersState])
  static isAttendanceCompany(state: CompaniesListFiltersStateModel): boolean {
    return state.isAttendanceCompany;
  }

  @Selector([CompaniesListFiltersState])
  static attendanceDatePeriod(
    state: CompaniesListFiltersStateModel
  ): DatePeriod {
    return state.attendanceDatePeriod;
  }
}

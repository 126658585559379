import { Injectable } from '@angular/core';
import { User } from '@app/modules/users/interfaces/user';
import { AuthSelectors, CompaniesViewSelectors } from '@app/store';
import { Select } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { PermissionTypes } from '@app/modules/permissions/permission-types';
import { PermissionsService } from './permissions.service';

@Injectable({
  providedIn: 'root',
})
export class CheckPermissionsService {
  @Select(AuthSelectors.companyPermissions)
  companyPermissions$: Observable<Permission[]>;

  @Select(AuthSelectors.userPermissions)
  userPermissions$: Observable<Permission[]>;

  @Select(CompaniesViewSelectors.permissions)
  permissions$: Observable<Permission[]>;

  @Select(AuthSelectors.user)
  user$: Observable<User>;

  constructor(private permissionsService: PermissionsService) {}

  hasAccess(
    permissionName: string,
    permissionType: PermissionTypes = PermissionTypes.COMPANY
  ): Observable<boolean> {
    return this.user$.pipe(
      mergeMap((user: User) => {
        if (user?.is_super_admin) {
          return of(true);
        } else {
          const permissions$: Observable<Permission[]> =
            permissionType === PermissionTypes.COMPANY
              ? this.companyPermissions$.pipe(filter((v) => !!v))
              : this.userPermissions$.pipe(filter((v) => !!v));

          return this.permissionsService.hasAccess(
            permissions$,
            permissionName
          );
        }
      }),
      distinctUntilChanged()
    );
  }

  hasCompanyAccess(permissionName: string): Observable<boolean> {
    return this.user$.pipe(
      mergeMap((user: User) => {
        if (user?.is_super_admin) {
          return of(true);
        } else {
          return this.permissionsService.hasAccess(
            this.permissions$.pipe(filter((v) => !!v)),
            permissionName
          );
        }
      }),
      distinctUntilChanged()
    );
  }
}

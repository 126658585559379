import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TransportType } from '@app/modules/transport-types/interfaces/transport-type';

@Injectable({
  providedIn: 'root',
})
export class TransportTypesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<TransportType[]> {
    return this.apiService.getAll<TransportType>('/transport-type').pipe(
      map((transportTypes: TransportType[]) => {
        return transportTypes.reverse();
      })
    );
  }
}

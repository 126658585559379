import { Injectable } from '@angular/core';
import { SaveCompanyLimitsFormData } from '@app/pages/companies/view/tab4/components/set-company-limits/save-company-limits-form-data';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyLimit } from '@app/modules/companies/interfaces/company-limit';

@Injectable({
  providedIn: 'root',
})
export class CompanyLimitsService {
  constructor(private apiService: ApiService) {}

  create(data: SaveCompanyLimitsFormData): Observable<CompanyLimit> {
    return this.apiService.create<CompanyLimit>(
      '/company/' + data.companyId + '/limits',
      {
        currencyId: data.currencyId,
        financialLimit: data.financialLimit,
        cargoValueLimit: data.cargoValueLimit,
      }
    );
  }

  update(data: SaveCompanyLimitsFormData): Observable<CompanyLimit> {
    return this.apiService.put<CompanyLimit>(
      '/company/' + data.companyId + '/limits/' + data.id,
      {
        financialLimit: data.financialLimit,
        cargoValueLimit: data.cargoValueLimit,
      }
    );
  }

  delete(companyId: number, companyLimitId: number): Observable<boolean> {
    return this.apiService.delete<boolean>(
      '/company/' + companyId + '/limits/' + companyLimitId
    );
  }
}

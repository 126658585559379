import { CalendarTaskStates } from '@app/pages/calendar/calendar-task-states';
import { CalendarTaskTypes } from '@app/pages/calendar/calendar-task-types';
import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';
import { Selector } from '@ngxs/store';
import { CalendarListState } from './calendar_list.state';
import { CalendarListStateModel } from './calendar_list.state-model';

export class CalendarListSelectors {
  @Selector([CalendarListState])
  static filters(state: CalendarListStateModel): CalendarListFilters {
    return state.filters;
  }

  @Selector([CalendarListState])
  static type(state: CalendarListStateModel): CalendarTaskTypes {
    return state.filters.type;
  }

  @Selector([CalendarListState])
  static state(state: CalendarListStateModel): CalendarTaskStates {
    return state.filters.state;
  }

  @Selector([CalendarListState])
  static displayFilters(state: CalendarListStateModel): boolean {
    return state.displayFilters;
  }
}

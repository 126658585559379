import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CompanyStateModel } from './company-state.model';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import {
  LoadCompanyShippers,
  AddCompanyConsignee,
  LoadCompanyConsignees,
  AddCompanyShipper,
  CheckCurrencyLimit,
  ResetCurrencyLimit,
} from './company.actions';
import { Company } from '@app/modules/companies/interfaces/company';
import { AuthSelectors } from '../auth';
@Injectable()
@State<CompanyStateModel>({
  name: 'company',
  defaults: {
    shippers: null,
    consignees: null,
    validLimit: true,
  },
})
export class CompanyState {
  constructor(
    private companiesService: CompaniesService,
    private store: Store
  ) {}

  @Action(LoadCompanyShippers, { cancelUncompleted: true })
  @ImmutableContext()
  loadCompanyShippers({ getState, setState }: StateContext<CompanyStateModel>) {
    if (getState().shippers === null) {
      return this.companiesService.getShippers().pipe(
        tap((shippers: Company[]) => {
          setState((state: CompanyStateModel) => {
            state.shippers = shippers;
            return state;
          });
        })
      );
    }
  }

  @Action(AddCompanyShipper)
  @ImmutableContext()
  addCompanyShipper(
    { setState }: StateContext<CompanyStateModel>,
    { tin }: AddCompanyShipper
  ) {
    return this.companiesService.addShipper(tin).pipe(
      tap((shipper: Company) => {
        setState((state: CompanyStateModel) => {
          state.shippers = state.shippers.filter((company: Company) => {
            return company.id !== shipper.id;
          });
          state.shippers.unshift(shipper);
          return state;
        });
      })
    );
  }

  @Action(LoadCompanyConsignees, { cancelUncompleted: true })
  @ImmutableContext()
  loadCompanyConsignees({
    getState,
    setState,
  }: StateContext<CompanyStateModel>) {
    if (getState().consignees === null) {
      return this.companiesService.getConsignees().pipe(
        tap((consignees: Company[]) => {
          setState((state: CompanyStateModel) => {
            state.consignees = consignees;
            return state;
          });
        })
      );
    }
  }

  @Action(AddCompanyConsignee)
  @ImmutableContext()
  addCompanyConsignee(
    { setState }: StateContext<CompanyStateModel>,
    { tin }: AddCompanyConsignee
  ) {
    return this.companiesService.addConsignee(tin).pipe(
      tap((consignee: Company) => {
        setState((state: CompanyStateModel) => {
          state.consignees = state.consignees.filter((company: Company) => {
            return company.id !== consignee.id;
          });
          state.consignees.unshift(consignee);
          return state;
        });
      })
    );
  }

  @Action(CheckCurrencyLimit)
  @ImmutableContext()
  checkCurrencyLimit(
    { setState }: StateContext<CompanyStateModel>,
    { currencyId }: CheckCurrencyLimit
  ) {
    const companyId = this.store.selectSnapshot(AuthSelectors.currentCompanyId);
    return this.companiesService.checkCurrencyLimit(companyId, currencyId).pipe(
      tap((validLimit: boolean) => {
        console.log({ validLimit });
        setState((state: CompanyStateModel) => {
          state.validLimit = validLimit;
          return state;
        });
      })
    );
  }

  @Action(ResetCurrencyLimit)
  @ImmutableContext()
  resetCurrencyLimit({ setState }: StateContext<CompanyStateModel>) {
    return setState((state: CompanyStateModel) => {
      state.validLimit = true;
      return state;
    });
  }
}

import { CompaniesListFiltersFormData } from '@app/pages/companies/list/components/filters/companies-list-filters.form-data';

export class DisplayCompaniesListFilters {
  static readonly type =
    '[Companies list filters] Display companies list filters';
  constructor(public mouseEvent: MouseEvent) {}
}

export class SetCompaniesListFilters {
  static readonly type = '[Companies list filters] Set companies list filters';
  constructor(public filters: CompaniesListFiltersFormData) {}
}

import { AdditionalTransportationRequirement } from '@app/modules/additional-transportation-requirements/interfaces/additional-transportation-requirement';
import { CargoType } from '@app/modules/cargo-types/interfaces/cargo-type';
import { ContainerType } from '@app/modules/container-types/interfaces/container-type';
import { Country } from '@app/modules/countries/interfaces/country';
import { Currency } from '@app/modules/currencies/interfaces/currency';
import { DeliveryCondition } from '@app/modules/delivery-conditions/interfaces/delivery-condition';
import { ExchangeCargoType } from '@app/modules/exchange-cargo-types/interfaces/exchange-cargo-type';
import { Incoterm } from '@app/modules/incoterms/interfaces/incoterm';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { RenewalTime } from '@app/modules/renewal-times/interfaces/renewal-time';
import { CountrySquares } from '@app/modules/squares/interfaces/country-squares';
import { TenderUnitedOption } from '@app/modules/tender-united-options/interfaces/tender-united-option';
import { TransportType } from '@app/modules/transport-types/interfaces/transport-type';
import { TransportModeTypes } from '@app/modules/transport/transport-mode-types';
import { Selector } from '@ngxs/store';
import { ListingsStateModel } from './listings-state.model';
import { ListingsState } from './listings.state';
import { Nomenclature } from '@app/modules/nomenclatures/interfaces/nomenclature';
import { LoadingType } from '@app/modules/loading-types/interfaces/loading-type';
import { PackagingType } from '@app/modules/packaging-types/interfaces/packaging-type';

export class ListingsSelectors {
  @Selector([ListingsState])
  static currencies(state: ListingsStateModel): Currency[] {
    return state.currencies;
  }

  @Selector([ListingsState])
  static nomenclatures(state: ListingsStateModel): Nomenclature[] {
    return state.nomenclatures;
  }

  @Selector([ListingsState])
  static getCurrencyNameById(
    state: ListingsStateModel
  ): (id: number) => string {
    return (id: number) => {
      if (!state.currencies) {
        return null;
      }

      return state.currencies.find((c: Currency) => {
        return c.id === id;
      })?.currency;
    };
  }

  @Selector([ListingsState])
  static currencyRates(state: ListingsStateModel): Currency[] {
    return state.currencyRates;
  }

  @Selector([ListingsState])
  static cargoTypes(state: ListingsStateModel): CargoType[] {
    return state.cargoTypes;
  }

  @Selector([ListingsState])
  static exchangeCargoTypes(state: ListingsStateModel): ExchangeCargoType[] {
    return state.exchangeCargoTypes;
  }

  @Selector([ListingsState])
  static countries(state: ListingsStateModel): Country[] {
    return state.countries;
  }

  @Selector([ListingsState])
  static countriesReversed(state: ListingsStateModel): Country[] {
    const countries = [...state.countries];
    countries.reverse();
    return countries;
  }

  @Selector([ListingsState])
  static countrySquares(state: ListingsStateModel): CountrySquares {
    return state.countrySquares;
  }

  @Selector([ListingsState])
  static permissions(state: ListingsStateModel): Permission[] {
    return state.permissions;
  }

  @Selector([ListingsState])
  static companyPermissions(state: ListingsStateModel): Permission[] {
    return state.companyPermissions;
  }

  @Selector([ListingsState])
  static transportTypes(state: ListingsStateModel): TransportType[] {
    return state.transportTypes;
  }

  @Selector([ListingsState])
  static transportTypesExceptTractor(
    state: ListingsStateModel
  ): TransportType[] {
    return state.transportTypes.filter((transportType: TransportType) => {
      return transportType.mode.code !== TransportModeTypes.TRACTOR;
    });
  }

  @Selector([ListingsState])
  static loadingTypes(state: ListingsStateModel): LoadingType[] {
    return state.loadingTypes;
  }

  @Selector([ListingsState])
  static additionalTransportationRequirements(
    state: ListingsStateModel
  ): AdditionalTransportationRequirement[] {
    return state.additionalTransportationRequirements;
  }

  @Selector([ListingsState])
  static renewalTimes(state: ListingsStateModel): RenewalTime[] {
    return state.renewalTimes;
  }

  @Selector([ListingsState])
  static tenderUnitedOptions(state: ListingsStateModel): TenderUnitedOption[] {
    return state.tenderUnitedOptions;
  }

  @Selector([ListingsState])
  static incoterms(state: ListingsStateModel): Incoterm[] {
    return state.incoterms;
  }

  @Selector([ListingsState])
  static deliveryConditions(state: ListingsStateModel): DeliveryCondition[] {
    return state.deliveryConditions;
  }

  @Selector([ListingsState])
  static containerTypes(state: ListingsStateModel): ContainerType[] {
    return state.containerTypes;
  }

  @Selector([ListingsState])
  static packagingTypes(state: ListingsStateModel): PackagingType[] {
    return state.packagingTypes;
  }
}

import { Selector } from '@ngxs/store';
import { CompaniesListStateModel } from './companies_list-state.model';
import { CompaniesListState } from './companies_list.state';
import { Company } from '@app/modules/companies/interfaces/company';

export class CompaniesListSelectors {
  @Selector([CompaniesListState])
  static companies(state: CompaniesListStateModel): Company[] {
    return state.companies;
  }

  @Selector([CompaniesListState])
  static page(state: CompaniesListStateModel): number {
    return state.page;
  }

  @Selector([CompaniesListState])
  static maxPage(state: CompaniesListStateModel): number {
    return state.maxPage;
  }
  @Selector([CompaniesListState])
  static isFavorite(state: CompaniesListStateModel): boolean {
    return state.isFavorite;
  }
}

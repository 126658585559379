import { Transport } from '@app/modules/transport/interfaces/transport';
import { Selector } from '@ngxs/store';
import { TransportViewStateModel } from './transport_view-state.model';
import { TransportViewState } from './transport_view.state';

export class TransportViewSelectors {
  @Selector([TransportViewState])
  static transport(state: TransportViewStateModel): Transport {
    return state.transport;
  }
}

import { BillsListFilters } from '@app/modules/bills/interfaces/bills-list-filters';

export class LoadFinancesAccountsReceivableBillsList {
  static readonly type =
    '[Finances -> Bills -> Accounts Receivable] Load bills list';
  constructor() {}
}

export class SetFinancesAccountsReceivableBillsListFilters {
  static readonly type =
    '[Finances -> Bills -> Accounts Receivable] Set filters';
  constructor(public filters: BillsListFilters) {}
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TasksCalendarListStateModel } from './tasks-calendar_list.state-model';
import {
  ApplyFiltersInTasksCalendarWidget,
  DisplayFiltersInTasksCalendarWidget,
  ListTasksInTasksCalendarWidget,
  SetDateFilterValueInTasksCalendarWidget,
} from './tasks-calendar_list.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';
import * as moment from 'moment';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';

const now: moment.Moment = moment();

@Injectable()
@State<TasksCalendarListStateModel>({
  name: 'widgets__tasks_calendar__list',
  defaults: {
    tasks: null,
    displayFilters: false,
    filters: {
      date: now.format('MM.YYYY'),
      state: null,
      type: null,
    },
  },
})
export class TasksCalendarListState {
  constructor(private calendarService: CalendarService) {}

  @Action(ListTasksInTasksCalendarWidget)
  @ImmutableContext()
  listTasksInTasksCalendarWidget({
    getState,
    setState,
  }: StateContext<TasksCalendarListStateModel>) {
    const filters = getState().filters;
    const dateFilterValue: string = filters.date;
    const dateFilterValueParts = dateFilterValue.split('.');
    return this.calendarService
      .list(filters, [
        'date.startAt',
        'date.endAt',
        'state.code',
        'priority.code',
      ])
      .pipe(
        tap((tasks: CalendarTask[]) => {
          setState((state: TasksCalendarListStateModel) => {
            if (!state.tasks) {
              state.tasks = {};
            }
            if (!state.tasks[+dateFilterValueParts[1]]) {
              state.tasks[+dateFilterValueParts[1]] = {};
            }

            state.tasks[+dateFilterValueParts[1]][+dateFilterValueParts[0]] =
              tasks;
            return state;
          });
        })
      );
  }

  @Action(SetDateFilterValueInTasksCalendarWidget)
  @ImmutableContext()
  setDateFilterValueInTasksCalendarWidget(
    { setState }: StateContext<TasksCalendarListStateModel>,
    { date }: SetDateFilterValueInTasksCalendarWidget
  ) {
    setState((state: TasksCalendarListStateModel) => {
      state.filters.date = date;
      return state;
    });
  }

  @Action(DisplayFiltersInTasksCalendarWidget)
  @ImmutableContext()
  displayFiltersInTasksCalendarWidget({
    setState,
  }: StateContext<TasksCalendarListStateModel>) {
    setState((state: TasksCalendarListStateModel) => {
      state.displayFilters = true;
      return state;
    });
  }

  @Action(ApplyFiltersInTasksCalendarWidget)
  @ImmutableContext()
  applyFiltersInTasksCalendarWidget(
    { setState }: StateContext<TasksCalendarListStateModel>,
    { filters }: ApplyFiltersInTasksCalendarWidget
  ) {
    setState((state: TasksCalendarListStateModel) => {
      state.filters.type = filters.type;
      if (filters.state) {
        state.filters.state = filters.state;
      } else {
        delete state.filters.state;
      }
      state.displayFilters = false;
      return state;
    });
  }
}

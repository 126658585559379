import { Selector } from '@ngxs/store';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { CommentsCreateState } from './comments_create.state';
import { CommentsCreateStateModel } from './comments_create.state-model';
import { Comment } from '@app/modules/comments/interfaces/comment';

export class CommentsCreateSelectors {
  @Selector([CommentsCreateState])
  static fieldErrors(state: CommentsCreateStateModel): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      const errorMessages = [];
      state.fieldsErrors[key].forEach((errorMessage: string) => {
        errorMessages.push(errorMessage);
      });

      errors.push({
        field: key,
        errors: errorMessages,
      });
    });

    return errors;
  }

  @Selector([CommentsCreateState])
  static replyToComment(state: CommentsCreateStateModel): Comment {
    return state.replyTo;
  }
}

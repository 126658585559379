import { Injectable } from '@angular/core';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { TransportService } from '@app/modules/transport/services/transport.service';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { AuthSelectors } from '@app/store/auth/auth.selectors';
import { TransportTendersCreateStateModel } from './transport-tenders_create-state.model';
import {
  InitializeCreateTransportTenderPage,
  SearchCompanyTransport,
  ResetInitialTransportTenderData,
  TryCreateTransportTender,
} from './transport-tenders_create.actions';
import { ExchangeTenderService } from '@app/services/exchange-tender.service';
import { ListTransportTenders } from '@app/store/tabs/tabs.actions';
import { RepeatTransportTender } from '@app/store/transport-tenders/view/transport-tenders_view.actions';
import { PrimaryBlockSetTransportTendersView } from '@app/store';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';

@Injectable()
@State<TransportTendersCreateStateModel>({
  name: 'transport_tenders__create',
  defaults: {
    availableTransportList: null,
    initialTenderData: null,
  },
})
export class TransportTendersCreateState {
  constructor(
    private transportService: TransportService,
    private exchangeTenderService: ExchangeTenderService,
    private store: Store
  ) {}

  @Action(InitializeCreateTransportTenderPage)
  @ImmutableContext()
  initializeCreateTransportPage() {
    this.store.dispatch(new SearchCompanyTransport());
  }

  @Action(SearchCompanyTransport)
  @ImmutableContext()
  searchCompanyTransport(
    { setState }: StateContext<TransportTendersCreateStateModel>,
    { searchValue }: SearchCompanyTransport
  ) {
    return this.transportService
      .getCompanyTransportList(
        this.store.selectSnapshot(AuthSelectors.company).id,
        searchValue
      )
      .pipe(
        tap((transport: Transport[]) => {
          setState((state: TransportTendersCreateStateModel) => {
            state.availableTransportList = transport;
            return state;
          });
        })
      );
  }

  @Action(TryCreateTransportTender)
  @ImmutableContext()
  tryCreateTransportTender(
    {}: StateContext<TransportTendersCreateStateModel>,
    { data }: TryCreateTransportTender
  ) {
    return this.exchangeTenderService.create(data).pipe(
      tap(() => {
        this.store.dispatch(new ListTransportTenders());
      })
    );
  }

  @Action(RepeatTransportTender)
  @ImmutableContext()
  repeatTransportTender(
    { setState }: StateContext<TransportTendersCreateStateModel>,
    { tender }: RepeatTransportTender
  ) {
    this.store.dispatch(
      new PrimaryBlockSetTransportTendersView(
        PrimaryBlockTransportTendersViews.CREATE
      )
    );

    return setState((state: TransportTendersCreateStateModel) => {
      state.initialTenderData = tender;
      return state;
    });
  }

  @Action(ResetInitialTransportTenderData)
  @ImmutableContext()
  resetInitialTransportTenderData({
    setState,
  }: StateContext<TransportTendersCreateStateModel>) {
    return setState((state: TransportTendersCreateStateModel) => {
      state.initialTenderData = null;
      return state;
    });
  }
}

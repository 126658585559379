import { FieldsErrors } from '@app/interfaces/fields-errors';
import { Company } from '@app/modules/companies/interfaces/company';
import { UserComment } from '@app/modules/profile/interfaces/user-comment';
import { User } from '@app/modules/users/interfaces/user';

export class ProfileStateModel {
  profile: User;
  fieldsErrors: FieldsErrors;
  companies: Company[];
  accessToken: string;
  comments: UserComment[];
}

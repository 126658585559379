import { Selector } from '@ngxs/store';
import { ApiTokenState } from './api_token.state';
import { ApiTokenStateModel } from './api_token-state.model';
import { ApiToken } from '@app/modules/api-token/interfaces/api-token';

export class ApiTokenSelectors {
  @Selector([ApiTokenState])
  static apiToken(state: ApiTokenStateModel): ApiToken {
    return state.apiToken;
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Comment } from '@app/modules/comments/interfaces/comment';
import { Observable } from 'rxjs';
import { AddCommentFormData } from '@app/modules/comments/interfaces/add-comment.form-data';

@Injectable({
  providedIn: 'root',
})
export class FileCommentsService {
  constructor(private apiService: ApiService) {}

  list(documentId: number): Observable<Comment[]> {
    return this.apiService.getAll<Comment>(
      '/document/' + documentId + '/comment'
    );
  }

  create(data: AddCommentFormData): Observable<Comment> {
    return this.apiService.post<Comment>(
      '/document/' + data.entityId + '/comment',
      { comment: data.comment }
    );
  }

  delete(documentId: number, commentId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/document/' + documentId + '/comment/' + commentId
    );
  }
}

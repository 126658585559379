import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CompanyFinancesStatisticsStateModel } from './company-finances_statistics-state.model';
import { CompanyFinancesStatisticsCarTendersState } from './car-tenders';
import { CompanyFinancesStatisticsSeaTendersState } from './sea-tenders';
import { CompanyFinancesStatisticsTabs } from '@app/pages/company-finances/statistics/company-finances-statitscs-tabs';
import { SetCompanyFinancesStatisticsActiveTab } from './company-finances_statistics.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';

@Injectable()
@State<CompanyFinancesStatisticsStateModel>({
  name: 'company_finances__statistics',
  defaults: {
    activeTab: CompanyFinancesStatisticsTabs.CAR_TENDERS,
  },
  children: [
    CompanyFinancesStatisticsCarTendersState,
    CompanyFinancesStatisticsSeaTendersState,
  ],
})
export class CompanyFinancesStatisticsState {
  @Action(SetCompanyFinancesStatisticsActiveTab)
  @ImmutableContext()
  setCompanyFinancesStatisticsActiveTab(
    { setState }: StateContext<CompanyFinancesStatisticsStateModel>,
    { tab }: SetCompanyFinancesStatisticsActiveTab
  ) {
    return setState((state: CompanyFinancesStatisticsStateModel) => {
      state.activeTab = tab;
      return state;
    });
  }
}

import { CompaniesAddMemberFormData } from '@app/pages/companies/add-member/interfaces/companies_add-member.form-data';
import { FieldsErrors } from '@app/interfaces/fields-errors';

export class InitAddMemberForm {
  static readonly type =
    '[Company -> Add member] Initialize the add member form';
  constructor() {}
}

export class ResetFieldErrorsInTryAddMemberForm {
  static readonly type = '[Company -> Add member] Reset field errors';
  constructor() {}
}

export class TryAddMember {
  static readonly type = '[Company -> Add member] Try add member';
  constructor(public payload: CompaniesAddMemberFormData) {}
}

export class AddMemberFailed {
  static readonly type =
    '[Company -> Add member] Unable to add a member. Wrong data provided?';
  constructor(public errors: FieldsErrors) {}
}

export class AddMemberSucceess {
  static readonly type =
    '[Company -> Add member] The member was successfully added!';
  constructor(public companyId: number) {}
}

import { Selector } from '@ngxs/store';
import { CustomerSupportState } from './customer_support.state';
import { CustomerSupportStateModel } from './customer_support.model';
import { User } from '@app/modules/users/interfaces/user';

export class CustomerSupportSelectors {
  @Selector([CustomerSupportState])
  static customerSupportUser(state: CustomerSupportStateModel): User {
    return state.customerSupportUser;
  }
}

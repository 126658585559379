import { Selector } from '@ngxs/store';
import { WeatherStateModel } from './weather.model';
import { WeatherData } from '@app/modules/weather/interfaces/weather-data';
import { WeatherState } from './weather.state';
import { UserCity } from '@app/modules/weather/interfaces/user-city';

export class WeatherSelectors {
  @Selector([WeatherState])
  static weather(state: WeatherStateModel): WeatherData {
    return state.weather;
  }

  @Selector([WeatherState])
  static userCities(state: WeatherStateModel): UserCity[] {
    return state.userCities;
  }

  @Selector([WeatherState])
  static selectedCity(state: WeatherStateModel): UserCity {
    return state.selectedCity;
  }
}

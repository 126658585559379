import { Selector } from '@ngxs/store';
import { LayoutState } from './layout.state';
import { LayoutStateModel } from './layout-state.model';
import { WhitelabelConfig } from '@app/modules/layout/interfaces/whitelabel-config';

export class LayoutSelectors {
  @Selector([LayoutState])
  static fileURL(state: LayoutStateModel): string {
    return state.fileURL;
  }

  @Selector([LayoutState])
  static ionicSelectableModalOpened(state: LayoutStateModel): boolean {
    return state.ionicSelectableModalOpened;
  }

  @Selector([LayoutState])
  static isWhitelabel(state: LayoutStateModel): boolean {
    return state.isWhitelabel;
  }

  @Selector([LayoutState])
  static whitelabelConfig(state: LayoutStateModel): WhitelabelConfig {
    return state.whitelabelConfig;
  }

  @Selector([LayoutState])
  static notRussia(state: LayoutStateModel): boolean {
    return state.whitelabelConfig.countryCode !== 'RU';
  }

  @Selector([LayoutState])
  static isFullscreenMap(state: LayoutStateModel): boolean {
    return state.isFullscreenMap;
  }
}

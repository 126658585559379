import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, map } from 'rxjs';
import { WeatherData } from '@app/modules/weather/interfaces/weather-data';
import { UserCity } from '@app/modules/weather/interfaces/user-city';
import { ResponseSuccess } from '@app/interfaces/response-success';

@Injectable({
  providedIn: 'root',
})
export class WeatherService {
  constructor(private apiService: ApiService) {}

  get(city?: any): Observable<WeatherData> {
    return this.apiService.get<WeatherData>(
      '/widget/weather' + (city ? '/' + city : '')
    );
  }

  getUserCities(): Observable<UserCity[]> {
    return this.apiService.getAll<UserCity>('/widget/weather-city');
  }

  addCity(city: string): Observable<UserCity> {
    return this.apiService.create<UserCity>('/widget/weather-city', { city });
  }

  removeCity(city: UserCity): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>('/widget/weather-city/' + city.id)
      .pipe(
        map((resposnse: ResponseSuccess<boolean>) => {
          return resposnse.data;
        })
      );
  }
}

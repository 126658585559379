import { CompanyFinancesTendersStatisticsFilters } from '@app/modules/company-finances/statistics/interfaces/company-finances-tenders-statistics-filters';

export class LoadCompanyFinancesCarTendersStatistics {
  static readonly type =
    '[Company finances -> Statistics -> Car tenders] Load car tenders statistics';
}

export class SetCompanyFinancesCarTendersStatisticsFilters {
  static readonly type =
    '[Company finances -> Statistics -> Car tenders] Set filters';
  constructor(public filters: CompanyFinancesTendersStatisticsFilters) {}
}

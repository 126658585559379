import { Company } from '@app/modules/companies/interfaces/company';
import { PackagingType } from '@app/modules/packaging-types/interfaces/packaging-type';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { Selector } from '@ngxs/store';
import { CargoTendersCreateStateModel } from './cargo-tenders_create-state.model';
import { CargoTendersCreateState } from './cargo-tenders_create.state';

export class CargoTendersCreateSelectors {
  @Selector([CargoTendersCreateState])
  static companies(state: CargoTendersCreateStateModel): Company[] {
    return state.companies;
  }

  @Selector([CargoTendersCreateState])
  static initialTenderData(
    state: CargoTendersCreateStateModel
  ): CarCargoTender | SeaCargoTender {
    return state.initialTenderData;
  }
}

import { Selector } from '@ngxs/store';
import { BirthdayState } from './birthday.state';
import { BirthdayStateModel } from './birthday-state.model';
import { BirthdayWidgetDataRecord } from '@app/modules/widgets/components/birthday-widget/interfaces/birthday-widget-data-record';

export class BirthdaySelectors {
  @Selector([BirthdayState])
  static birthdays(state: BirthdayStateModel): BirthdayWidgetDataRecord[] {
    return state.birthdays;
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatWriteMessageStateModel } from './chat_write-message-state.model';
import { Injectable } from '@angular/core';
import { SetHasAttachments } from './chat_write-message.actions';

@Injectable()
@State<ChatWriteMessageStateModel>({
  name: 'chat__write_message',
  defaults: {
    hasAttachments: false,
  },
})
export class ChatWriteMessageState {
  @Action(SetHasAttachments)
  @ImmutableContext()
  setHasAttachments(
    { setState }: StateContext<ChatWriteMessageStateModel>,
    { hasAttachments }: SetHasAttachments
  ) {
    return setState((state: ChatWriteMessageStateModel) => {
      state.hasAttachments = hasAttachments;
      return state;
    });
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  InitRegistrationPage,
  RegistrationFailed,
  RegistrationSucceess,
  TryRegister,
} from './registration.actions';
import { RegistrationStateModel } from './registration-state.model';
import { Injectable } from '@angular/core';

@Injectable()
@State<RegistrationStateModel>({
  name: 'registration',
  defaults: {
    fieldsErrors: null,
  },
})
export class RegistrationState {
  @Action(InitRegistrationPage)
  @Action(TryRegister)
  @ImmutableContext()
  initRegistration({ setState }: StateContext<RegistrationStateModel>) {
    setState((state: RegistrationStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(RegistrationFailed)
  @ImmutableContext()
  registrationFailed(
    { setState }: StateContext<RegistrationStateModel>,
    { responseError }: RegistrationFailed
  ) {
    setState((state: RegistrationStateModel) => {
      state.fieldsErrors = responseError.errors;
      return state;
    });
  }

  @Action(RegistrationSucceess)
  @ImmutableContext()
  registrationSucceeded({ setState }: StateContext<RegistrationStateModel>) {
    setState((state: RegistrationStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { MapFilters } from '@app/pages/map/interfaces/map-filters.interface';

export class LoadCargoReport {
  static readonly type = '[Map Page] Load cargo report';
  constructor(public filters: MapFilters) {}
}

export class LoadCarCargoTenderPoints {
  static readonly type = '[Map Page] Load car cargo tender points';
  constructor(public filters: MapFilters) {}
}

export class LoadSeaCargoTenderPoints {
  static readonly type = '[Map Page] Load sea cargo tender points';
  constructor(public filters: MapFilters) {}
}

export class LoadTransportPoints {
  static readonly type = '[Map Page] Load transport points';
  constructor(public filters: MapFilters) {}
}

export class LoadWarehousePoints {
  static readonly type = '[Map Page] Load warehouse points';
  constructor(public createdAt: DatePeriod) {}
}

export class LoadStructures {
  static readonly type = '[Map Page] Load structures';
  constructor(public createdAt: DatePeriod) {}
}

export class MapLoadCompanyPopularRoutes {
  static readonly type = '[Map Page] Load company popular routes';
  constructor(public companyId: number) {}
}

export class LoadAllCargoPopularRoutes {
  static readonly type = '[Map Page] Load all cargo popular routes';
  constructor() {}
}

export class LoadAllTransportPopularRoutes {
  static readonly type = '[Map Page] Load all transport popular routes';
  constructor() {}
}

export class MapLoadCompaniesWithPopularRoute {
  static readonly type =
    '[Map Page] Load companies with provided popular route';
  constructor(public routeId: number) {}
}

export class MapLoadCargoTenderCompaniesForTender {
  static readonly type =
    '[Map Page] Load cargo tender companies with provided tender';
  constructor(public tenderId: number) {}
}

export class DisplayMapFilters {
  static readonly type = '[Map Page] Display filters';
  constructor() {}
}

export class ApplyMapFilters {
  static readonly type = '[Map Page] Apply filters';
  constructor(public filters: MapFilters) {}
}

export class ApplyMapDateFilter {
  static readonly type = '[Map Page] Apply date period filter';
  constructor(public datePeriod: DatePeriod) {}
}

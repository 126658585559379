export class DisplayPopup {
  static readonly type = '[Layout] Display popup';
  constructor(
    public component: any,
    public componentProps: any = null,
    public cssClass: string = null
  ) {}
}

export class ClosePopup {
  static readonly type = '[Layout] Close popup';
  constructor() {}
}

export class DisplayFileInFullscreen {
  static readonly type = '[Layout] Display file in fullscreen';
  constructor(public fileURL: string) {}
}

export class HideFullscreenFile {
  static readonly type = '[Layout] Hide the display of a file in fullscreen';
  constructor() {}
}

export class SetIonicSelectableModalState {
  static readonly type =
    '[Layout] Set ionic selectable model to open (true) or closed (false) state';
  constructor(public openedState: boolean) {}
}

export class DetectWhitelabel {
  static readonly type = '[Layout] Detect whitelabel';
}

export class LoadWhitelabelConfig {
  static readonly type = '[Layout] Load whitelabel config';
}

export class MapEnterFullscreen {
  static readonly type = '[Layout -> Map] Enter fullscreens';
}

export class MapExitFullscreen {
  static readonly type = '[Layout -> Map] Exit fullscreen';
}

export class DeleteTransport {
  static readonly type = '[Transport] Delete transport';
  constructor(public transportId: number) {}
}

export class SetSearchValue {
  static readonly type = '[Transport List Page] Set search value';
  constructor(public searchValue: string) {}
}

export class SetTransportIsFavoriteFilterValue {
  static readonly type = '[Transport List Page] Set isFavorite filter value';
  constructor(public isFavorite: boolean) {}
}

export class LoadTransportList {
  static readonly type = '[Transport List Page] Load Transport List';
  constructor(public page?: number, public companyId?: number) {}
}

export class LockTransport {
  static readonly type = '[Transport List Page] Lock a transport';
  constructor(public transportId: number) {}
}

export class UnlockTransport {
  static readonly type = '[Transport List Page] Unlock a transport';
  constructor(public transportId: number) {}
}

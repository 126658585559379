// Inside the root 'index.ts' file of our store, eg - store/index.ts
import { AuthState } from './auth';
import { BetsState } from './bets';
import { MapState } from './map';
import {
  ChatState,
  ChatContactsState,
  ChatMyChatsState,
  ChatSelectedChatGroupState,
  ChatTendersChatsState,
  ChatWriteMessageState,
} from './chat';
import {
  CompaniesAddMemberState,
  CompaniesCreateState,
  CompaniesListState,
  CompaniesListFiltersState,
  CompaniesViewState,
  CompaniesViewCuratorsState,
  CompaniesViewTendersCreatedCarState,
  CompaniesViewTendersCreatedSeaState,
  CompaniesViewTendersCreatedTransportState,
  CompaniesViewTendersParticipatedCarState,
  CompaniesViewTendersParticipatedSeaState,
  CompaniesViewTendersParticipatedTransportState,
} from './companies';
import { CompanyState } from './company';
import { CompanyBanksState } from './company-banks';
import { CompanyStructuresState } from './company-structures';
import { CompanyPopularRoutesState } from './company-popular-routes';
// import { CompaniesState } from './chat';
import { LayoutState } from './layout';
import { ListingsState } from './listings';
import { PermissionsState } from './permissions';
import { PrimaryBlockState } from './primary_block';
import {
  ProfileState,
  ApiTokenState,
  ProfileDocumentsState,
  UserFinancesState,
  UserAnalyticsState,
} from './profile';
import {
  TransportAddEditState,
  TransportListState,
  TransportViewState,
} from './transport';
import {
  TransportTendersState,
  TransportTendersBetsHistoryState,
  TransportTendersCreateState,
  TransportTendersListState,
  TransportTendersListFiltersState,
  TransportTendersViewState,
} from './transport-tenders';
import { UsersState } from './users';
import {
  WarehousesState,
  WarehousesCreateState,
  WarehousesListState,
  WarehousesViewState,
} from './warehouses';
import {
  WidgetsState,
  BirthdayState,
  CompanyTendersDistributionState,
  CompanyTendersOverallStatisticsState,
  OverallStatisticsState,
  WeatherState,
  CustomerSupportState,
  SeaShipmentsState,
  TasksCalendarListState,
} from './widgets';
import { CommentsState, CommentsCreateState } from './comments';
import { NotificationsState } from './profile/notifications';
import {
  CargoTendersState,
  CargoTendersCreateState,
  CargoTendersListState,
  CargoTendersListFiltersState,
  CargoTendersListCarState,
  CargoTendersListSeaState,
  CargoTendersViewState,
  CargoTendersViewCarState,
  CargoTendersViewSeaState,
  CargoTendersBetsHistoryState,
} from './cargo-tenders';
import { RegistrationState } from './registration';
import { TabsState } from './tabs';
import {
  CompanyFinancesState,
  CompanyFinancesTendersReportState,
  CompanyFinancesTendersStatementState,
  CompanyFinancesBillsState,
  CompanyFinancesBillsAccountsPayableState,
  CompanyFinancesBillsAccountsReceivableState,
  CompanyFinancesStatisticsState,
  CompanyFinancesStatisticsCarTendersState,
  CompanyFinancesStatisticsSeaTendersState,
  CompanyFinancesTransportTendersStatementState,
} from './company-finances';
import {
  FinancesState,
  FinancesBillsState,
  FinancesBillsAccountsPayableState,
  FinancesBillsAccountsReceivableState,
} from './finances';
import { PushNotificationsState } from './push_notifications/push_notifications.state';
import { AttendanceState } from './widgets/attendance';
import { StickyNotesState } from './sticky-notes';
import { FavoritesState } from './favorites/favorites.state';
import { TasksCalendarState } from './widgets/tasks-calendar/tasks-calendar.state';
import { BillsState } from './bills/bills.state';
import { PayForBillState } from './bills/pay-for-bill';
import { RegisterUserWithCompanyState } from './register-user-with-company';
import { ProfileCompaniesState } from './profile/companies';
import {
  CalendarAddEditState,
  CalendarListState,
  CalendarState,
} from './calendar';
import { DocumentsTreeState } from './documents-tree';
import { StatisticsState } from './statistics';
import { MainPageState } from './main-page';
import {
  TendersPageCarState,
  TendersPageFiltersState,
  TendersPageSeaState,
  TendersPageState,
} from './tenders-page';
import { TendersPageTransportState } from './tenders-page/transport';

// Still allow other modules to take what they need, eg action & selectors
export * from './auth';
export * from './bets';
export * from './bills';
export * from './calendar';
export * from './chat';
export * from './company';
export * from './company-finances';
export * from './comments';
export * from './companies';
export * from './company-banks';
export * from './company-structures';
export * from './company-popular-routes';
export * from './documents-tree';
export * from './favorites';
export * from './finances';
export * from './layout';
export * from './listings';
export * from './main-page';
export * from './map';
export * from './permissions';
export * from './primary_block';
export * from './profile';
export * from './push_notifications';
export * from './registration';
export * from './router';
export * from './cargo-tenders';
export * from './sticky-notes';
export * from './tabs';
export * from './tenders-page';
export * from './transport';
export * from './transport-tenders';
export * from './transport_bets';
export * from './users';
export * from './warehouses';
export * from './widgets';

// rolls up our states into one const
export const AppState = [
  AuthState,
  CompanyState,
  RegistrationState,
  RegisterUserWithCompanyState,
  TabsState,

  BetsState,
  MapState,

  // Bills
  BillsState,
  PayForBillState,

  // Statistics (for company finances, like bills above)
  StatisticsState,

  // Chat
  ChatState,
  ChatContactsState,
  ChatMyChatsState,
  ChatSelectedChatGroupState,
  ChatTendersChatsState,
  ChatWriteMessageState,

  // Calendar
  CalendarState,
  CalendarListState,
  CalendarAddEditState,

  // Cargo Tenders
  CargoTendersState,
  CargoTendersCreateState,
  CargoTendersListState,
  CargoTendersListFiltersState,
  CargoTendersListCarState,
  CargoTendersListSeaState,
  CargoTendersViewState,
  CargoTendersViewCarState,
  CargoTendersViewSeaState,
  CargoTendersBetsHistoryState,

  // Comments
  CommentsState,
  CommentsCreateState,

  // Companies
  CompaniesAddMemberState,
  CompaniesCreateState,
  CompaniesListState,
  CompaniesListFiltersState,
  CompaniesViewState,
  CompaniesViewTendersCreatedCarState,
  CompaniesViewTendersCreatedSeaState,
  CompaniesViewTendersCreatedTransportState,
  CompaniesViewTendersParticipatedCarState,
  CompaniesViewTendersParticipatedSeaState,
  CompaniesViewTendersParticipatedTransportState,
  CompaniesViewCuratorsState,

  // CompaniesState,
  CompanyBanksState,
  CompanyStructuresState,
  CompanyPopularRoutesState,
  LayoutState,
  ListingsState,
  PermissionsState,
  // TransportState,
  UsersState,

  // Company Finances
  CompanyFinancesState,
  CompanyFinancesTendersReportState,
  CompanyFinancesTendersStatementState,
  CompanyFinancesBillsState,
  CompanyFinancesBillsAccountsPayableState,
  CompanyFinancesBillsAccountsReceivableState,
  CompanyFinancesStatisticsState,
  CompanyFinancesStatisticsCarTendersState,
  CompanyFinancesStatisticsSeaTendersState,
  CompanyFinancesTransportTendersStatementState,

  DocumentsTreeState,

  // Favorites
  FavoritesState,

  // Finances
  FinancesState,
  FinancesBillsState,
  FinancesBillsAccountsPayableState,
  FinancesBillsAccountsReceivableState,

  PushNotificationsState,

  // Main Page
  MainPageState,

  // Widgets
  WidgetsState,
  BirthdayState,
  CompanyTendersDistributionState,
  CompanyTendersOverallStatisticsState,
  CustomerSupportState,
  OverallStatisticsState,
  SeaShipmentsState,
  WeatherState,
  AttendanceState,
  TasksCalendarState,
  TasksCalendarListState,

  PrimaryBlockState,

  // Profile
  ProfileState,
  ProfileCompaniesState,
  ApiTokenState,
  ProfileDocumentsState,
  NotificationsState,
  UserFinancesState,
  UserAnalyticsState,

  // Sticky notes
  StickyNotesState,

  // Tenders page
  TendersPageState,
  TendersPageFiltersState,
  TendersPageCarState,
  TendersPageSeaState,
  TendersPageTransportState,

  // Transport
  TransportAddEditState,
  TransportListState,
  TransportViewState,

  // Transport tender
  TransportTendersState,
  TransportTendersBetsHistoryState,
  TransportTendersCreateState,
  TransportTendersListState,
  TransportTendersListFiltersState,
  TransportTendersViewState,

  // Warehouses
  WarehousesState,
  WarehousesCreateState,
  WarehousesListState,
  WarehousesViewState,
];

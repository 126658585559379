import { TransportAddBetFormData } from '@app/modules/transport-bets/interfaces/transport-add-bet.form-data';

export class TryAddTransportBet {
  static readonly type = '[Transport Bets] Try add transport bet';
  constructor(public data: TransportAddBetFormData) {}
}

export class AddTransportBetSuccess {
  static readonly type =
    '[Transport Bets] The transport bet was successfully added';
  constructor() {}
}

export class AddTransportBetFailed {
  static readonly type = '[Transport Bets] The transport bet has failed';
  constructor(public errors: string[]) {}
}

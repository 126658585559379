import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';

export class TasksCalendarListStateModel {
  tasks: {
    [year: number]: {
      [month: number]: CalendarTask[];
    };
  };
  displayFilters: boolean;
  filters: CalendarListFilters;
}

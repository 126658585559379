import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { PrimaryBlockCompaniesViews } from '@app/pages/tabs/components/primary-block-companies/primary-block-companies-views';
import { PrimaryBlockTabs } from '@app/pages/tabs/components/primary-block-tabs/primary-block-tabs';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';

import { PrimaryBlockWarehousesViews } from '@app/pages/tabs/components/primary-block-warehouses/primary-block-warehouses-views';
import { PrimaryBlockViews } from '@app/pages/tabs/components/primary-block/primary-block-views';

export class PrimaryBlockSwitchTab {
  static readonly type = '[Primary block] Switch tab';
  constructor(public tab: PrimaryBlockTabs) {}
}

export class PrimaryBlockToggleCollapseExpand {
  static readonly type = '[Primary block] Toggle collapse/expand';
  constructor() {}
}

export class PrimaryBlockCollapse {
  static readonly type = '[Primary block] Collapse';
  constructor() {}
}

export class PrimaryBlockExpand {
  static readonly type = '[Primary block] Expand';
  constructor() {}
}

export class PrimaryBlockSetCargoTendersView {
  static readonly type = '[Primary block] Set cargo tenders view';
  constructor(public view: PrimaryBlockCargoTendersViews) {}
}

export class PrimaryBlockSetTransportTendersView {
  static readonly type = '[Primary block] Set transport tenders view';
  constructor(public view: PrimaryBlockTransportTendersViews) {}
}

export class PrimaryBlockSetWarehousesView {
  static readonly type = '[Primary block] Set warehouses view';
  constructor(public view: PrimaryBlockWarehousesViews) {}
}

export class PrimaryBlockSetCompaniesView {
  static readonly type = '[Primary block] Set companies view';
  constructor(public view: PrimaryBlockCompaniesViews) {}
}

export class PrimaryBlockSetView {
  static readonly type = '[Primary block] Set view';
  constructor(public view: PrimaryBlockViews) {}
}

export class PrimaryBlockSetListView {
  static readonly type = '[Primary block] Set  listview';
}

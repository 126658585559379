import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesAddMemberStateModel } from './companies_add-member-state.model';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import {
  InitAddMemberForm,
  ResetFieldErrorsInTryAddMemberForm,
  TryAddMember,
  AddMemberFailed,
  AddMemberSucceess,
} from './companies_add-member.actions';
import { UiService } from '@app/services/ui.service';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { ResponseError } from '@app/interfaces/response-error';
import { CompanyMemberPosition } from '@app/modules/companies/interfaces/company-member-position';
import { CompanyMemberPositionsService } from '@app/modules/companies/services/company-member-positions.service';
import { CompanyMembersService } from '@app/modules/companies/services/company-members.service';

@Injectable()
@State<CompaniesAddMemberStateModel>({
  name: 'companies__add_member',
  defaults: {
    positions: [],
    fieldsErrors: null,
  },
})
export class CompaniesAddMemberState {
  constructor(
    private store: Store,
    private companyMemberPositionsService: CompanyMemberPositionsService,
    private companyMembersService: CompanyMembersService,
    private uiService: UiService
  ) {}

  @Action(InitAddMemberForm)
  @ImmutableContext()
  initPage({ setState }: StateContext<CompaniesAddMemberStateModel>) {
    return this.companyMemberPositionsService.getAll().pipe(
      tap((positions: CompanyMemberPosition[]) => {
        setState((state: CompaniesAddMemberStateModel) => {
          state.positions = positions;
          state.fieldsErrors = null;
          return state;
        });
      })
    );
  }

  @Action(TryAddMember)
  @ImmutableContext()
  tryAddMember(
    { setState }: StateContext<CompaniesAddMemberStateModel>,
    { payload }: TryAddMember
  ) {
    setState((state: CompaniesAddMemberStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    this.uiService.displayLoading();
    return this.companyMembersService.addMember(payload).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new AddMemberSucceess(payload.companyId));
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new AddMemberFailed(
              (err.originalError.error as ResponseError).errors
            )
          );
          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(ResetFieldErrorsInTryAddMemberForm)
  @ImmutableContext()
  resetFieldErrorsInTryAddMemberForm(
    { setState }: StateContext<CompaniesAddMemberStateModel>,
    {}: ResetFieldErrorsInTryAddMemberForm
  ) {
    return setState((state: CompaniesAddMemberStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(AddMemberFailed)
  @ImmutableContext()
  addMemberFailed(
    { setState }: StateContext<CompaniesAddMemberStateModel>,
    { errors }: AddMemberFailed
  ) {
    setState((state: CompaniesAddMemberStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }
}

import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext } from '@ngxs/store';
import {
  DisplayPopup,
  ClosePopup,
  DisplayFileInFullscreen,
  HideFullscreenFile,
  DetectWhitelabel,
  LoadWhitelabelConfig,
  MapExitFullscreen,
  MapEnterFullscreen,
} from './layout.actions';
import { LayoutStateModel } from './layout-state.model';
import { LayoutService } from '@app/modules/layout/services/layout.service';
import { RegistrationSucceess } from '@app/store/registration/registration.actions';
import { AddMemberSucceess } from '@app/store/companies';
import { SaveTransportSucceess } from '@app/store/transport';
import { CreateCompanySucceess } from '@app/store/companies';
import { tap } from 'rxjs';
import { WhitelabelConfig } from '@app/modules/layout/interfaces/whitelabel-config';
import { RegisterUserWithCompanySucceess } from '../register-user-with-company';
import { WhitelabelService } from '@app/services/whitelabel.service';

@Injectable()
@State<LayoutStateModel>({
  name: 'layout',
  defaults: {
    fileURL: null,
    ionicSelectableModalOpened: false,
    isWhitelabel: false,
    whitelabelConfig: null,
    isFullscreenMap: false,
  },
})
export class LayoutState {
  constructor(
    private modalController: ModalController,
    private layoutService: LayoutService,
    private whitelabelService: WhitelabelService
  ) {}

  @Action(DisplayPopup)
  @ImmutableContext()
  displayPopup(
    {}: StateContext<LayoutStateModel>,
    { component, componentProps, cssClass }: DisplayPopup
  ) {
    let modalData: any = {
      component,
      componentProps,
    };
    if (cssClass) {
      modalData.cssClass = cssClass;
    }

    this.modalController
      .create(modalData)
      .then((modal: HTMLIonModalElement) => {
        modal.present().then(() => {
          this.layoutService.paused = true;
        });
        modal.onDidDismiss().then(() => {
          this.layoutService.paused = false;
        });
      });
  }

  @Action(CreateCompanySucceess)
  @Action(AddMemberSucceess)
  @Action(SaveTransportSucceess)
  @Action(RegistrationSucceess)
  @Action(RegisterUserWithCompanySucceess)
  @Action(ClosePopup)
  @ImmutableContext()
  closePopups() {
    this.modalController.dismiss();
  }

  @Action(DisplayFileInFullscreen)
  @ImmutableContext()
  displayFileInFullscreen(
    { setState }: StateContext<LayoutStateModel>,
    { fileURL }: DisplayFileInFullscreen
  ) {
    return setState((state: LayoutStateModel) => {
      state.fileURL = fileURL;
      return state;
    });
  }

  @Action(HideFullscreenFile)
  @ImmutableContext()
  hideFullscreenFile({ setState }: StateContext<LayoutStateModel>) {
    return setState((state: LayoutStateModel) => {
      state.fileURL = null;
      return state;
    });
  }

  @Action(DetectWhitelabel)
  @ImmutableContext()
  detectWhitelabel({ setState }: StateContext<LayoutStateModel>) {
    return setState((state: LayoutStateModel) => {
      state.isWhitelabel = this.whitelabelService.isWhitelabel();
      return state;
    });
  }

  @Action(LoadWhitelabelConfig)
  @ImmutableContext()
  loadWhitelabelConfig({ setState }: StateContext<LayoutStateModel>) {
    return this.layoutService.getLayoutConfig().pipe(
      tap((whitelabelConfig: WhitelabelConfig) => {
        setState((state: LayoutStateModel) => {
          state.whitelabelConfig = whitelabelConfig;
          return state;
        });
      })
    );
  }

  @Action(MapEnterFullscreen)
  @ImmutableContext()
  mapEnterFullscreen({ setState }: StateContext<LayoutStateModel>) {
    return setState((state: LayoutStateModel) => {
      state.isFullscreenMap = true;
      return state;
    });
  }

  @Action(MapExitFullscreen)
  @ImmutableContext()
  mapExitFullscreen({ setState }: StateContext<LayoutStateModel>) {
    return setState((state: LayoutStateModel) => {
      state.isFullscreenMap = false;
      return state;
    });
  }
}

import { DriverInterface } from '@app/modules/drivers/interfaces/driver.interface';
import { Selector } from '@ngxs/store';
import { ProfileDocumentsStateModel } from './profile_documents-state.model';
import { ProfileDocumentsState } from './profile_documents.state';

export class ProfileDocumentsSelectors {
  @Selector([ProfileDocumentsState])
  static driver(state: ProfileDocumentsStateModel): DriverInterface {
    return state.driver;
  }
}

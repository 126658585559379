import { createSelector, Selector } from '@ngxs/store';
import { CalendarState } from './calendar.state';
import { CalendarStateModel } from './calendar.state-model';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';
import moment from 'moment';
import { CalendarListState } from './list';
import { CalendarListStateModel } from './list/calendar_list.state-model';
import { CalendarView } from 'angular-calendar';

export class CalendarSelectors {
  // @Selector([CalendarState])
  // static viewMonth(state: CalendarStateModel): number {
  //   return state.viewMonth;
  // }

  // @Selector([CalendarState])
  // static viewYear(state: CalendarStateModel): number {
  //   return state.viewYear;
  // }

  // @Selector([CalendarState])
  // static currentDay(state: CalendarStateModel): number {
  //   return +state.currentDate.format('D');
  // }

  // @Selector([CalendarState])
  // static currentMonth(state: CalendarStateModel): number {
  //   return +state.currentDate.format('M');
  // }

  // @Selector([CalendarState])
  // static currentYear(state: CalendarStateModel): number {
  //   return +state.currentDate.format('Y');
  // }

  // @Selector([CalendarState])
  // static selectedDay(state: CalendarStateModel): number {
  //   return +state.selectedDate.format('D');
  // }

  // @Selector([CalendarState])
  // static selectedMonth(state: CalendarStateModel): number {
  //   return +state.selectedDate.format('M');
  // }

  // @Selector([CalendarState])
  // static selectedYear(state: CalendarStateModel): number {
  //   return +state.selectedDate.format('Y');
  // }

  // @Selector([CalendarState])
  // static selectedDayName(state: CalendarStateModel): string {
  //   return state.selectedDate.format('dddd');
  // }

  // @Selector([CalendarState])
  // static selectedMonthName(state: CalendarStateModel): string {
  //   return state.selectedDate.format('MMMM');
  // }

  @Selector([CalendarState])
  static viewDate(state: CalendarStateModel): Date {
    return state.viewDate;
  }

  @Selector([CalendarState])
  static view(state: CalendarStateModel): CalendarView {
    return state.view;
  }

  @Selector([CalendarState])
  static activeDayIsOpen(state: CalendarStateModel): boolean {
    return state.activeDayIsOpen;
  }

  @Selector([CalendarState, CalendarListState])
  static tasks(
    calendarState: CalendarStateModel,
    tasksCalendarListState: CalendarListStateModel
  ): CalendarTask[] {
    const selectedDate: moment.Moment = moment(calendarState.viewDate);
    const selectedYear = selectedDate.format('YYYY');
    const selectedMonth = selectedDate.format('MM');

    const yearKey = +selectedYear;
    const monthKey = +selectedMonth;

    return tasksCalendarListState.tasks[yearKey][monthKey]
      ? tasksCalendarListState.tasks[yearKey][monthKey]
      : [];
  }
}

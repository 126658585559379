import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatTendersChatsStateModel } from './chat_tenders-chats-state.model';
import { Injectable } from '@angular/core';
import {
  ListTendersChats,
  SearchTendersChats,
  FetchTendersChats,
  AddChatGroupToTendersChats,
} from './chat_tenders-chats.actions';
import { tap } from 'rxjs/operators';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { ChatGroupsService } from '@app/modules/chat/services/chat-groups.service';
import { CacheChatGroups } from '@app/store/chat/chat.actions';
import { MultipleResults } from '@app/interfaces/multiple-results';
import { RTS_ChatGroupRemoved } from '@app/store/chat/selected-chat-group/chat_selected-chat-group.actions';

@Injectable()
@State<ChatTendersChatsStateModel>({
  name: 'chat__tenders_chats',
  defaults: {
    tendersChats: new Set(),
    forceLoad: false,
    tendersChatsSearchValue: null,
    tendersChatsTotal: null,
    hasBeenLoadedAtLeastOnce: false,
  },
})
export class ChatTendersChatsState {
  constructor(
    private chatGroupsService: ChatGroupsService,
    private store: Store
  ) {}

  @Action(ListTendersChats, { cancelUncompleted: true })
  @ImmutableContext()
  listTendersChats({
    getState,
    setState,
  }: StateContext<ChatTendersChatsStateModel>) {
    if (getState().hasBeenLoadedAtLeastOnce && !getState().forceLoad) {
      return;
    }

    setState((state: ChatTendersChatsStateModel) => {
      state.hasBeenLoadedAtLeastOnce = true;
      state.forceLoad = false;
      return state;
    });

    return this.chatGroupsService
      .getTendersChats(0, getState().tendersChatsSearchValue)
      .pipe(
        tap((result: MultipleResults<ChatGroup>) => {
          if (result.dataModels.length > 0) {
            this.store.dispatch(new CacheChatGroups(result.dataModels));
          }
          setState((state: ChatTendersChatsStateModel) => {
            state.tendersChats.clear();
            result.dataModels.map((chatGroup: ChatGroup) => {
              state.tendersChats.add(chatGroup.id);
            });
            state.tendersChatsTotal = result.totalCount;
            return state;
          });
        })
      );
  }

  @Action(FetchTendersChats, { cancelUncompleted: true })
  @ImmutableContext()
  fetchTendersChats({
    getState,
    setState,
  }: StateContext<ChatTendersChatsStateModel>) {
    return this.chatGroupsService
      .getTendersChats(
        getState().tendersChats.size,
        getState().tendersChatsSearchValue
      )
      .pipe(
        tap((result: MultipleResults<ChatGroup>) => {
          setState((state: ChatTendersChatsStateModel) => {
            this.store.dispatch(new CacheChatGroups(result.dataModels));
            result.dataModels.forEach((chatGroup: ChatGroup) => {
              state.tendersChats.add(chatGroup.id);
            });
            state.tendersChatsTotal = result.totalCount;
            return state;
          });
        })
      );
  }

  @Action(SearchTendersChats)
  @ImmutableContext()
  searchTendersChats(
    { setState }: StateContext<ChatTendersChatsStateModel>,
    { searchValue }: SearchTendersChats
  ) {
    return setState((state: ChatTendersChatsStateModel) => {
      state.tendersChatsSearchValue = searchValue;
      state.forceLoad = true;

      return state;
    });
  }

  @Action(AddChatGroupToTendersChats)
  @ImmutableContext()
  addChatGroupToTendersChats(
    { setState }: StateContext<ChatTendersChatsStateModel>,
    { chatGroupID, chatGroupName }: AddChatGroupToTendersChats
  ) {
    return setState((state: ChatTendersChatsStateModel) => {
      const shouldAdd: boolean =
        !state.tendersChatsSearchValue ||
        state.tendersChatsSearchValue.length === 0 ||
        chatGroupName
          .toLowerCase()
          .includes(state.tendersChatsSearchValue.toLowerCase());

      shouldAdd
        ? state.tendersChats.add(chatGroupID)
        : state.tendersChats.delete(chatGroupID);

      return state;
    });
  }

  @Action(RTS_ChatGroupRemoved)
  @ImmutableContext()
  rtsChatGroupRemoved(
    { setState }: StateContext<ChatTendersChatsStateModel>,
    { chatGroupID }: RTS_ChatGroupRemoved
  ) {
    return setState((state: ChatTendersChatsStateModel) => {
      state.tendersChats.delete(chatGroupID);
      return state;
    });
  }
}

import { Selector } from '@ngxs/store';
import { WarehousesListState } from './warehouses_list.state';
import { WarehousesListStateModel } from './warehouses_list-state.model';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';

export class WarehousesListSelectors {
  @Selector([WarehousesListState])
  static warehouses(state: WarehousesListStateModel): Warehouse[] {
    return state.warehouses;
  }

  @Selector([WarehousesListState])
  static page(state: WarehousesListStateModel): number {
    return state.page;
  }

  @Selector([WarehousesListState])
  static maxPage(state: WarehousesListStateModel): number {
    return state.maxPage;
  }
}

export class LoadCompaniesList {
  static readonly type = '[Companies List Page] Load Companies List';
  constructor(public page?: number) {}
}

export class SetCompaniesListSearchValue {
  static readonly type = '[Companies List Page] Set search value';
  constructor(public searchValue: string) {}
}

export class SetCompaniesListIsFavoriteFilterValue {
  static readonly type = '[Companies List Page] Set isFavorite filter value';
  constructor(public isFavorite: boolean) {}
}

export class DeactivateCompany {
  static readonly type = '[Companies List Page] Deactivate a company';
  constructor(public companyId: number) {}
}

export class ActivateCompany {
  static readonly type = '[Companies List Page] Activate a company';
  constructor(public companyId: number) {}
}

export class DeleteCompany {
  static readonly type = '[Companies] Delete company';
  constructor(public companyId: number) {}
}

import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';

export class LoadCargoTenderBetsHistory {
  static readonly type = '[Tender Bets History Page] Load Bets History';
  constructor(
    public tenderId: number,
    public type: CargoTendersTransportationTypes,
    public page?: number
  ) {}
}

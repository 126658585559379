import { createSelector } from '@ngxs/store';
import { BillsStateModel } from './bills-state.model';

export class BillsSelectors {
  static bills(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.bills.data;
    });
  }

  static summary(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.bills.summary;
    });
  }

  static filters(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.filters;
    });
  }

  static page(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.filters.page;
    });
  }

  static maxPage(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.bills.maxPage;
    });
  }

  static searchValue(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return state.filters.search;
    });
  }

  static datePeriod(stateClass) {
    return createSelector([stateClass], (state: BillsStateModel) => {
      return {
        from: state.filters.createdAtFrom,
        to: state.filters.createdAtTo,
      };
    });
  }
}

import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { MapFilters } from '@app/pages/map/interfaces/map-filters.interface';

export class MapStateModel {
  displayFilters: boolean;
  filters: MapFilters;
  carCargoTenderPoints: CarCargoTender[];
  seaCargoTenderPoints: SeaCargoTender[];
  transportPoints: TransportTender[];
  warehousePoints: any[];
  structures: CompanyStructure[];
  popularRoutes: CompanyPopularRoute[];
  allCargoPopularRoutes: CompanyPopularRoute[];
  allTransportPopularRoutes: CompanyPopularRoute[];
  companies: Company[];
}

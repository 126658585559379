export enum EntityStatuses {
  UNLOADED = -50, // Не загружен
  IS_BEING_LOADED = -40, // В процессе загрузки
  UNKNOWN = -30, // Не известно
  UNCREATED = -20, // Не создан
  IS_BEING_CREATED = -10, // В процессе создания
  UNCREATED_ERROR = -5, // Не создан, ошибка
  DELETE = 0, // Удален
  BLOCK = 1, // Заблокирован
  DRAFT = 8, // Черновик
  INACTIVE = 9, // Неактивный
  ACTIVE = 10, // Активный
  IS_BEING_DELETED = 100010, // В процессе удаления
}

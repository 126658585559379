import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CustomerSupportStateModel } from './customer_support.model';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs/operators';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import { LoadCustomerSupportUser } from './customer_support.actions';
import { User } from '@app/modules/users/interfaces/user';

@Injectable()
@State<CustomerSupportStateModel>({
  name: 'widgets__customer_support',
  defaults: {
    customerSupportUser: null,
  },
})
export class CustomerSupportState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(LoadCustomerSupportUser)
  @ImmutableContext()
  loadCustomerSupportUser({
    setState,
  }: StateContext<CustomerSupportStateModel>) {
    return this.widgetsService.getCustomerSupportUser().pipe(
      tap((user: User) => {
        return setState((state: CustomerSupportStateModel) => {
          state.customerSupportUser = user;
          return state;
        });
      })
    );
  }
}

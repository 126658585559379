import { Selector } from '@ngxs/store';
import { UserAnalyticsState } from './analytics.state';
import { UserAnalyticsStateModel } from './analytics-state.model';
import { UserAnalytics } from '@app/modules/users/interfaces/user-analytics';

export class UserAnalyticsSelectors {
  @Selector([UserAnalyticsState])
  static analytics(state: UserAnalyticsStateModel): UserAnalytics {
    return state.analytics;
  }
}

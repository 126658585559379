import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { LoadingType } from '../interfaces/loading-type';

@Injectable({
  providedIn: 'root',
})
export class LoadingTypesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<LoadingType[]> {
    return this.apiService.getAll<LoadingType>('/loading-type');
  }
}

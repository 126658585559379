import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { WidgetsStateModel } from './widgets-state.model';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import {
  ClearWidgetsList,
  LoadWidgets,
  LoadWidgetsConfigs,
  MoveWidget,
  ToggleWidget,
} from './widgets.actions';
import { WidgetConfig } from '@app/modules/widgets/interfaces/widget-config';
import { CompanyTendersOverallStatisticsState } from './company_tenders_overall_statistics';
import { CompanyTendersDistributionState } from './company_tenders_distribution';
import { WeatherState } from './weather';
import { WidgetsGroups } from '@app/modules/widgets/interfaces/widgets-groups';
import { WidgetTypes } from '@app/modules/widgets/types/widget-types';
import { ArrayHelpers } from '@app/utils/array-helpers';
import { EntityStatuses } from '@app/modules/entity/entity-statuses';
import { BirthdayState } from './birthday';
import { CustomerSupportState } from './customer_support';
import { OverallStatisticsState } from './overall_statistics';
import { SeaShipmentsState } from './sea_shipments';
import { AttendanceState } from './attendance';
import { TasksCalendarState } from './tasks-calendar/tasks-calendar.state';

@Injectable()
@State<WidgetsStateModel>({
  name: 'widgets',
  defaults: {
    widgets: {
      left: null,
      right: null,
    },
    widgetsConfigs: null,
  },
  children: [
    BirthdayState,
    CompanyTendersOverallStatisticsState,
    CompanyTendersDistributionState,
    CustomerSupportState,
    OverallStatisticsState,
    SeaShipmentsState,
    WeatherState,
    AttendanceState,
    TasksCalendarState,
  ],
})
export class WidgetsState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(LoadWidgets)
  @ImmutableContext()
  loadWidgets(
    { getState, setState }: StateContext<WidgetsStateModel>,
    { forceLoad }: LoadWidgets
  ) {
    const widgets = getState().widgets;
    if (!forceLoad && widgets.left && widgets.right) {
      return;
    }

    return this.widgetsService.list().pipe(
      tap((widgetGroups: WidgetsGroups<WidgetTypes[]>) => {
        setState((state: WidgetsStateModel) => {
          state.widgets = widgetGroups;
          return state;
        });
      })
    );
  }

  @Action(ClearWidgetsList)
  @ImmutableContext()
  clearWidgetsList({ setState }: StateContext<WidgetsStateModel>) {
    setState((state: WidgetsStateModel) => {
      state.widgets.left = null;
      state.widgets.right = null;
      return state;
    });
  }

  @Action(LoadWidgetsConfigs)
  @ImmutableContext()
  loadWidgetsConfigs({ getState, setState }: StateContext<WidgetsStateModel>) {
    if (getState().widgetsConfigs) {
      return;
    }

    return this.widgetsService.getConfigs().pipe(
      tap((widgetsGroups: WidgetsGroups<WidgetConfig[]>) => {
        setState((state: WidgetsStateModel) => {
          state.widgetsConfigs = widgetsGroups;
          return state;
        });
      })
    );
  }

  @Action(ToggleWidget)
  @ImmutableContext()
  toggleWidget(
    { setState }: StateContext<WidgetsStateModel>,
    { column, widgetId, isEnabled }: ToggleWidget
  ) {
    return this.widgetsService.toggle(widgetId, isEnabled).pipe(
      tap(() => {
        setState((state: WidgetsStateModel) => {
          state.widgetsConfigs[column] = state.widgetsConfigs[column].map(
            (widgetConfig: WidgetConfig) => {
              if (widgetConfig.id === widgetId) {
                widgetConfig.enabled = isEnabled;
              }
              return widgetConfig;
            }
          );

          state.widgets[column] = state.widgetsConfigs[column]
            .filter((widgetConfig: WidgetConfig) => {
              return (
                widgetConfig.enabled &&
                widgetConfig.status.code === EntityStatuses.ACTIVE
              );
            })
            .map((widgetConfig: WidgetConfig) => {
              return widgetConfig.component;
            });

          return state;
        });
      })
    );
  }

  @Action(MoveWidget)
  @ImmutableContext()
  moveWidget(
    { getState, setState }: StateContext<WidgetsStateModel>,
    { column, from, to }: MoveWidget
  ) {
    const workingWidgetsConfigs = getState().widgetsConfigs[column];
    const widgetConfig = workingWidgetsConfigs[from];
    const newIndex = Math.max(
      Math.min(to, workingWidgetsConfigs.length - 1),
      0
    );

    return this.widgetsService.reorder(widgetConfig.id, newIndex + 1).pipe(
      tap(() => {
        setState((state: WidgetsStateModel) => {
          const workingWidgetsConfigs = state.widgetsConfigs[column];
          ArrayHelpers.move(workingWidgetsConfigs, from, newIndex);
          state.widgetsConfigs[column] = workingWidgetsConfigs;

          state.widgets[column] = state.widgetsConfigs[column]
            .filter((widgetConfig: WidgetConfig) => {
              return (
                widgetConfig.enabled &&
                widgetConfig.status.code === EntityStatuses.ACTIVE
              );
            })
            .map((widgetConfig: WidgetConfig) => {
              return widgetConfig.component;
            });

          return state;
        });
      })
    );
  }
}

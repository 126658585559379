import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  InitRegisterUserWithCompanyPage,
  RegisterUserWithCompanyFailed,
  RegisterUserWithCompanySucceess,
  TryRegisterUserWithCompany,
} from './register-user-with-company.actions';
import { RegisterUserWithCompanyStateModel } from './register-user-with-company-state.model';
import { Injectable } from '@angular/core';

@Injectable()
@State<RegisterUserWithCompanyStateModel>({
  name: 'register_user_with_company',
  defaults: {
    fieldsErrors: null,
  },
})
export class RegisterUserWithCompanyState {
  @Action(InitRegisterUserWithCompanyPage)
  @Action(TryRegisterUserWithCompany)
  @ImmutableContext()
  initRegistration({
    setState,
  }: StateContext<RegisterUserWithCompanyStateModel>) {
    setState((state: RegisterUserWithCompanyStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(RegisterUserWithCompanyFailed)
  @ImmutableContext()
  registrationFailed(
    { setState }: StateContext<RegisterUserWithCompanyStateModel>,
    { responseError }: RegisterUserWithCompanyFailed
  ) {
    setState((state: RegisterUserWithCompanyStateModel) => {
      state.fieldsErrors = responseError.errors;
      return state;
    });
  }

  @Action(RegisterUserWithCompanySucceess)
  @ImmutableContext()
  registrationSucceeded({
    setState,
  }: StateContext<RegisterUserWithCompanyStateModel>) {
    setState((state: RegisterUserWithCompanyStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }
}

import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';

export class RouterSelectors {
  @Selector([RouterState])
  static url(router: RouterStateModel): string {
    return router.state.url;
  }

  @Selector([RouterState])
  static isDashboardPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/dashboard');
  }

  @Selector([RouterState])
  static isMapPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/map');
  }

  @Selector([RouterState])
  static isTendersStatementPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith(
      '/tabs/company-finances/tenders-statement'
    );
  }

  @Selector([RouterState])
  static isTransportTendersStatementPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith(
      '/tabs/company-finances/transport-tenders-statement'
    );
  }

  @Selector([RouterState])
  static isTendersReportPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/company-finances/tenders-report');
  }

  @Selector([RouterState])
  static isViewFilePage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/view-file');
  }

  @Selector([RouterState])
  static isCalendarPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/calendar');
  }

  @Selector([RouterState])
  static isMyProfilePage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/my-profile');
  }

  @Selector([RouterState])
  static isTendersCarListPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/tenders/car');
  }

  @Selector([RouterState])
  static isTendersSeaListPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/tenders/sea');
  }

  @Selector([RouterState])
  static isTendersTransportListPage(router: RouterStateModel): boolean {
    return router.state.url.startsWith('/tabs/tenders/transport');
  }

  @Selector([RouterState])
  static comapnyId(router: RouterStateModel): number {
    return router.state.root?.children[0]?.children[0]?.children[0]?.params?.id
      ? +router.state.root?.children[0]?.children[0]?.children[0]?.params?.id
      : null;
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesViewCuratorsStateModel } from './companies_view_curators-state.model';
import { Injectable } from '@angular/core';
import {
  LoadCompanyCurators,
  AddCompanyCurator,
  UpdateCompanyCurator,
  DeleteCompanyCurator,
} from './companies_view_curators.actions';
import { tap } from 'rxjs/operators';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyCuratorsService } from '@app/modules/companies/services/company-curators.service';

@Injectable()
@State<CompaniesViewCuratorsStateModel>({
  name: 'companies__view__curators',
  defaults: {
    curators: null,
  },
})
export class CompaniesViewCuratorsState {
  constructor(private companyCuratorsService: CompanyCuratorsService) {}

  @Action(LoadCompanyCurators)
  @ImmutableContext()
  loadCompanyCurators(
    { setState }: StateContext<CompaniesViewCuratorsStateModel>,
    { companyId }: LoadCompanyCurators
  ) {
    return this.companyCuratorsService.list(companyId).pipe(
      tap((curators: CompanyMember[]) => {
        setState((state: CompaniesViewCuratorsStateModel) => {
          state.curators = curators;
          return state;
        });
      })
    );
  }

  @Action(AddCompanyCurator)
  @ImmutableContext()
  addCompanyCurator(
    {}: StateContext<CompaniesViewCuratorsStateModel>,
    { data }: AddCompanyCurator
  ) {
    return this.companyCuratorsService.create(data);
  }

  @Action(UpdateCompanyCurator)
  @ImmutableContext()
  updateCompanyCurator(
    {}: StateContext<CompaniesViewCuratorsStateModel>,
    { data }: UpdateCompanyCurator
  ) {
    return this.companyCuratorsService.update(data);
  }

  @Action(DeleteCompanyCurator)
  @ImmutableContext()
  deleteCompanyCurator(
    {}: StateContext<CompaniesViewCuratorsStateModel>,
    { companyId, userId }: DeleteCompanyCurator
  ) {
    return this.companyCuratorsService.delete(companyId, userId);
  }
}

import { Selector } from '@ngxs/store';
import { CompanyFinancesTendersStatementState } from './company-finances_tenders-statement.state';
import { CompanyFinancesTendersStatementStateModel } from './company-finances_tenders-statement-state.model';
import { TendersStatementRecord } from '@app/modules/company-finances/interfaces/tenders-statement-record';
import { TendersStatementSummary } from '@app/modules/company-finances/interfaces/tenders-statement-summary';
import { DatePeriod } from '@app/interfaces/date-period';

export class CompanyFinancesTendersStatementSelectors {
  @Selector([CompanyFinancesTendersStatementState])
  static records(
    state: CompanyFinancesTendersStatementStateModel
  ): TendersStatementRecord[] {
    return state.records;
  }

  @Selector([CompanyFinancesTendersStatementState])
  static summary(
    state: CompanyFinancesTendersStatementStateModel
  ): TendersStatementSummary {
    return state.summary;
  }

  @Selector([CompanyFinancesTendersStatementState])
  static fileContent(state: CompanyFinancesTendersStatementStateModel): string {
    return state.fileContent;
  }

  @Selector([CompanyFinancesTendersStatementState])
  static datePeriod(
    state: CompanyFinancesTendersStatementStateModel
  ): DatePeriod {
    return state.datePeriod;
  }
}

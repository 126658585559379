import { TransportBet } from '@app/modules/transport-bets/interfaces/transport-bet';
import { Selector } from '@ngxs/store';
import { TransportTendersBetsHistoryStateModel } from './transport-tenders_bets-history-state.model';
import { TransportTendersBetsHistoryState } from './transport-tenders_bets-history.state';

export class TransportTendersBetsHistorySelectors {
  @Selector([TransportTendersBetsHistoryState])
  static bets(state: TransportTendersBetsHistoryStateModel): TransportBet[] {
    return state.bets;
  }

  @Selector([TransportTendersBetsHistoryState])
  static page(state: TransportTendersBetsHistoryStateModel): number {
    return state.page;
  }

  @Selector([TransportTendersBetsHistoryState])
  static maxPage(state: TransportTendersBetsHistoryStateModel): number {
    return state.maxPage;
  }
}

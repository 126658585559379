import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { SetTransportTendersOnTendersPage } from './tenders-page_transport.actions';
import { TendersPageTransportStateModel } from './tenders-page_transport-state.model';
import { Injectable } from '@angular/core';

@Injectable()
@State<TendersPageTransportStateModel>({
  name: 'tenders_page__transport',
  defaults: {
    tenders: null,
  },
})
export class TendersPageTransportState {
  @Action(SetTransportTendersOnTendersPage)
  @ImmutableContext()
  setTransportTendersOnTendersPage(
    { setState }: StateContext<TendersPageTransportStateModel>,
    { tenders }: SetTransportTendersOnTendersPage
  ) {
    return setState((state: TendersPageTransportStateModel) => {
      state.tenders = tenders;
      return state;
    });
  }
}

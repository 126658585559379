import { Injectable } from '@angular/core';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { TransportBet } from '@app/modules/transport-bets/interfaces/transport-bet';
import { TransportAddBetFormData } from '@app/modules/transport-bets/interfaces/transport-add-bet.form-data';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';

@Injectable({
  providedIn: 'root',
})
export class TransportBetsService {
  constructor(private apiService: ApiService) {}

  add(
    data: TransportAddBetFormData
  ): Observable<ResponseSuccess<TransportBet>> {
    return this.apiService.post<ResponseSuccess<TransportBet>>(
      '/exchange/bet',
      data
    );
  }

  selectBet(bet: TransportBet): Observable<TransportTender> {
    return this.apiService.put<TransportTender>(
      '/exchange/tender/' + bet.tenderId + '/set-winner',
      {
        winnerBetId: bet.id,
      }
    );
  }

  refuseBet(betId: number, reason: string): Observable<unknown> {
    return this.apiService.put<unknown>('/exchange/bet/' + betId + '/decline', {
      reason,
    });
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersParticipatedTransport {
  static readonly type =
    '[Company Tenders Participated Transport] Load company tenders ';
}

export class SetCompanyTendersParticipatedTransportPage {
  static readonly type =
    '[Company Tenders Participated Transport] Set company tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersParticipatedTransportDatePeriod {
  static readonly type =
    '[Company Tenders Participated Transport] Set company tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersParticipatedTransportState {
  static readonly type =
    '[Company Tenders Participated Transport] Set company tenders state';
  constructor(public states: TenderStates[]) {}
}

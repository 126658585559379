import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permission } from '@app/modules/permissions/interfaces/permission';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private apiService: ApiService) {}

  getPermissions(isCompany: boolean = false): Observable<Permission[]> {
    const data: any = {
      is_hidden: false,
    };
    if (isCompany) {
      data.is_company = true;
    }
    return this.apiService.getAll<Permission>(
      '/rbac/permission?is_company=false',
      data
    );
  }

  getUserPermissions(
    userId: number,
    companyId?: number
  ): Observable<Permission[]> {
    companyId = companyId ?? 0;

    return this.apiService.getAll(
      '/rbac/permission/user/' + userId + '/company/' + companyId,
      {
        is_hidden: false,
      }
    );
  }

  assignPermission(
    permissionName: string,
    userId: number,
    companyId: number
  ): Observable<unknown> {
    return this.apiService.post('/rbac/assign', {
      name: permissionName,
      user_id: userId,
      company_id: companyId,
    });
  }

  revokePermission(
    permissionName: string,
    userId: number,
    companyId: number
  ): Observable<unknown> {
    return this.apiService.post('/rbac/revoke', {
      name: permissionName,
      user_id: userId,
      company_id: companyId,
    });
  }

  hasAccess(
    permissions$: Observable<Permission[]>,
    permissionName: string
  ): Observable<boolean> {
    return permissions$.pipe(
      map((permissions: Permission[]) => {
        const result = permissions?.filter((permission: Permission) => {
          return permission.name === permissionName;
        });

        return result?.length > 0;
      })
    );
  }
}

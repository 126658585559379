import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { State, Action, StateContext } from '@ngxs/store';
import {
  LoadTendersStatement,
  DownloadTendersStatement,
  SetDatePeriodInCompanyTendersStatement,
  SetTypeInCompanyTendersStatement,
} from './company-finances_tenders-statement.actions';
import { CompanyFinancesTendersStatementStateModel } from './company-finances_tenders-statement-state.model';
import { CompanyFinancesService } from '@app/modules/company-finances/services/company-finances.service';
import { tap } from 'rxjs';
import { ResultsWithSummary } from '@app/interfaces/results-with-summary';
import { TendersStatementRecord } from '@app/modules/company-finances/interfaces/tenders-statement-record';
import { TendersStatementSummary } from '@app/modules/company-finances/interfaces/tenders-statement-summary';
import { TendersStatementFileData } from '@app/modules/company-finances/interfaces/tenders-statement-file-data';

@Injectable()
@State<CompanyFinancesTendersStatementStateModel>({
  name: 'company_finances__tenders_statement',
  defaults: {
    records: null,
    summary: null,
    fileContent: null,
    datePeriod: null,
    type: null,
  },
})
export class CompanyFinancesTendersStatementState {
  constructor(private companyFinancesService: CompanyFinancesService) {}

  @Action(LoadTendersStatement)
  @ImmutableContext()
  loadTendersStatement({
    getState,
    setState,
  }: StateContext<CompanyFinancesTendersStatementStateModel>) {
    const from = getState().datePeriod?.from || null;
    const to = getState().datePeriod?.to || null;
    return this.companyFinancesService
      .getTendersStatement(from, to, getState().type)
      .pipe(
        tap(
          (
            result: ResultsWithSummary<
              TendersStatementRecord,
              TendersStatementSummary
            >
          ) => {
            return setState(
              (state: CompanyFinancesTendersStatementStateModel) => {
                state.records = result.dataModels;
                state.summary = result.summary;
                return state;
              }
            );
          }
        )
      );
  }

  @Action(DownloadTendersStatement)
  @ImmutableContext()
  downloadTendersStatement({
    getState,
    setState,
  }: StateContext<CompanyFinancesTendersStatementStateModel>) {
    const from = getState().datePeriod?.from || null;
    const to = getState().datePeriod?.to || null;
    return this.companyFinancesService.downloadTendersStatement(from, to).pipe(
      tap((fileData: TendersStatementFileData) => {
        return setState((state: CompanyFinancesTendersStatementStateModel) => {
          state.fileContent = fileData.file;
          return state;
        });
      })
    );
  }

  @Action(SetDatePeriodInCompanyTendersStatement)
  @ImmutableContext()
  setDatePeriodInCompanyTendersStatement(
    { setState }: StateContext<CompanyFinancesTendersStatementStateModel>,
    { datePeriod }: SetDatePeriodInCompanyTendersStatement
  ) {
    return setState((state: CompanyFinancesTendersStatementStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }

  @Action(SetTypeInCompanyTendersStatement)
  @ImmutableContext()
  setTypeInCompanyTendersStatement(
    { setState }: StateContext<CompanyFinancesTendersStatementStateModel>,
    { type }: SetTypeInCompanyTendersStatement
  ) {
    return setState((state: CompanyFinancesTendersStatementStateModel) => {
      state.type = type;
      return state;
    });
  }
}

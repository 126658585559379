import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PackagingType } from '@app/modules/packaging-types/interfaces/packaging-type';

@Injectable({
  providedIn: 'root',
})
export class PackagingTypesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<PackagingType[]> {
    return this.apiService.getAll('/packaging-type').pipe(
      map((response: PackagingType[]) => {
        return response.reverse();
      })
    );
  }
}

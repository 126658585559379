import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CargoTendersViewStateModel } from './cargo-tenders_view-state.model';
import { Injectable } from '@angular/core';
import { CargoTendersViewCarState } from './car';
import { CargoTendersViewSeaState } from './sea';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { ViewSeaCargoTender, ViewCarCargoTender } from '@app/store/tabs';
import {
  UploadDocument,
  TryDeleteDocument,
  DeleteDocumentSuccess,
  DeleteDocumentFailed,
  CreateDirectory,
  RenameDocument,
  TryCreateAdditionalInvoice,
  CreateAdditionalInvoiceSuccess,
  CreateAdditionalInvoiceFailed,
  LinkCargoTenders,
  UnlinkCargoTenders,
} from './cargo-tenders_view.actions';
import { DocumentsService } from '@app/modules/documents/services/documents.service';
import { catchError, of, tap } from 'rxjs';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { HttpErrorResponse } from '@angular/common/http';
import { UiService } from '@app/services/ui.service';
import { BillsService } from '@app/modules/bills/services/bills.service';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';

@Injectable()
@State<CargoTendersViewStateModel>({
  name: 'cargo_tenders__view',
  defaults: {
    cargoTendersTransportationType: null,
  },

  children: [CargoTendersViewCarState, CargoTendersViewSeaState],
})
export class CargoTendersViewState {
  constructor(
    private documentsService: DocumentsService,
    private store: Store,
    private billsService: BillsService,
    private uiService: UiService,
    private cargoTendersCarService: CargoTendersCarService
  ) {}

  @Action(ViewCarCargoTender)
  @ImmutableContext()
  viewCarCargoTender({ setState }: StateContext<CargoTendersViewStateModel>) {
    setState((state: CargoTendersViewStateModel) => {
      state.cargoTendersTransportationType =
        CargoTendersTransportationTypes.CAR;
      return state;
    });
  }

  @Action(ViewSeaCargoTender)
  @ImmutableContext()
  viewSeaCargoTender({ setState }: StateContext<CargoTendersViewStateModel>) {
    setState((state: CargoTendersViewStateModel) => {
      state.cargoTendersTransportationType =
        CargoTendersTransportationTypes.SEA;
      return state;
    });
  }

  @Action(UploadDocument)
  @ImmutableContext()
  uploadDocument(
    {}: StateContext<CargoTendersViewStateModel>,
    { file, directoryId }: UploadDocument
  ) {
    return this.documentsService.uploadDocument(file, directoryId);
  }

  @Action(TryDeleteDocument)
  @ImmutableContext()
  tryDeleteDocument(
    {}: StateContext<CargoTendersViewStateModel>,
    { documentId }: TryDeleteDocument
  ) {
    return this.documentsService.deleteDocument(documentId).pipe(
      tap(() => {
        this.store.dispatch(new DeleteDocumentSuccess());
      }),
      catchError((err: any) => {
        this.store.dispatch(new DeleteDocumentFailed());
        return of(err);
      })
    );
  }

  @Action(CreateDirectory)
  @ImmutableContext()
  createDirectory(
    {}: StateContext<CargoTendersViewStateModel>,
    { directoryName, parentId }: CreateDirectory
  ) {
    return this.documentsService.createDirectory(directoryName, parentId);
  }

  @Action(RenameDocument)
  @ImmutableContext()
  renameDocument(
    {}: StateContext<CargoTendersViewStateModel>,
    { documentId, name }: RenameDocument
  ) {
    return this.documentsService.renameDocument(documentId, name);
  }

  @Action(TryCreateAdditionalInvoice)
  @ImmutableContext()
  tryCreateAdditionalInvoice(
    {}: StateContext<CargoTendersViewStateModel>,
    { data }: TryCreateAdditionalInvoice
  ) {
    return this.billsService.createAdditionalInvoice(data).pipe(
      tap(() => {
        this.store.dispatch(new CreateAdditionalInvoiceSuccess());
      }),
      catchError((error: any) => {
        if (error instanceof UnprocessableEntity) {
          this.uiService.displayErrors({ result: { error } });
          const errorMessages = (error.originalError as HttpErrorResponse).error
            .errors;
          this.store.dispatch(new CreateAdditionalInvoiceFailed(errorMessages));
          return of(null);
        }
      })
    );
  }

  @Action(LinkCargoTenders)
  @ImmutableContext()
  linkCargoTenders(
    {}: StateContext<CargoTendersViewStateModel>,
    { tenderID, tenderType, data }: LinkCargoTenders
  ) {
    return this.cargoTendersCarService.linkTenders(tenderID, tenderType, data);
  }

  @Action(UnlinkCargoTenders)
  @ImmutableContext()
  unlinkCargoTenders(
    {}: StateContext<CargoTendersViewStateModel>,
    { connectionID }: UnlinkCargoTenders
  ) {
    return this.cargoTendersCarService.unlinkTenders(connectionID);
  }
}

import { createSelector, Selector } from '@ngxs/store';
import { TasksCalendarState } from './tasks-calendar.state';
import { TasksCalendarStateModel } from './tasks-calendar.state-model';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';
import moment from 'moment';
import { TasksCalendarListState } from './list';
import { TasksCalendarListStateModel } from './list/tasks-calendar_list.state-model';
import { CalendarTaskStates } from '@app/pages/calendar/calendar-task-states';
import { TasksCalendarDayTasksPriorities } from './tasks-calendar-day-task-priorities';

export class TasksCalendarSelectors {
  @Selector([TasksCalendarState])
  static viewMonth(state: TasksCalendarStateModel): number {
    return state.viewMonth;
  }

  @Selector([TasksCalendarState])
  static viewYear(state: TasksCalendarStateModel): number {
    return state.viewYear;
  }

  @Selector([TasksCalendarState])
  static currentDay(state: TasksCalendarStateModel): number {
    return +state.currentDate.format('D');
  }

  @Selector([TasksCalendarState])
  static currentMonth(state: TasksCalendarStateModel): number {
    return +state.currentDate.format('M');
  }

  @Selector([TasksCalendarState])
  static currentYear(state: TasksCalendarStateModel): number {
    return +state.currentDate.format('Y');
  }

  @Selector([TasksCalendarState])
  static selectedDay(state: TasksCalendarStateModel): number {
    return +state.selectedDate.format('D');
  }

  @Selector([TasksCalendarState])
  static selectedMonth(state: TasksCalendarStateModel): number {
    return +state.selectedDate.format('M');
  }

  @Selector([TasksCalendarState])
  static selectedYear(state: TasksCalendarStateModel): number {
    return +state.selectedDate.format('Y');
  }

  @Selector([TasksCalendarState])
  static selectedDayName(state: TasksCalendarStateModel): string {
    return state.selectedDate.format('dddd');
  }

  @Selector([TasksCalendarState])
  static selectedMonthName(state: TasksCalendarStateModel): string {
    return state.selectedDate.format('MMMM');
  }

  @Selector([TasksCalendarState, TasksCalendarListState])
  static tasks(
    tasksCalendarState: TasksCalendarStateModel,
    tasksCalendarListState: TasksCalendarListStateModel
  ): CalendarTask[] {
    const selectedDate: moment.Moment = tasksCalendarState.selectedDate;
    const selectedYear = selectedDate.format('YYYY');
    const selectedMonth = selectedDate.format('MM');
    const selectedDay = selectedDate.format('DD');

    const yearKey = +selectedYear;
    const monthKey = +selectedMonth;

    return tasksCalendarListState.tasks[yearKey][monthKey]
      ? tasksCalendarListState.tasks[yearKey][monthKey].filter(
          (task: CalendarTask) => {
            return task.date.startAt.startsWith(
              selectedYear + '-' + selectedMonth + '-' + selectedDay
            );
          }
        )
      : [];
  }

  static getDayPriority(day: number, month: number, year: number) {
    return createSelector(
      [TasksCalendarListState],
      (tasksCalendarListState: TasksCalendarListStateModel) => {
        if (!tasksCalendarListState.tasks[year][month]) {
          return TasksCalendarDayTasksPriorities.NONE;
        }

        const dayTasks: CalendarTask[] = tasksCalendarListState.tasks[year][
          month
        ].filter((task: CalendarTask) => {
          const startYear = +moment(task.date.startAt).format('YYYY');
          const startMonth = +moment(task.date.startAt).format('MM') - 1;
          const startDay = +moment(task.date.startAt).format('DD');
          const startAt = new Date(startYear, startMonth, startDay);

          const endYear = +moment(task.date.endAt).format('YYYY');
          const endMonth = +moment(task.date.endAt).format('MM') - 1;
          const endDay = +moment(task.date.endAt).format('DD');
          let endAt = new Date(endYear, endMonth, endDay);

          if (startAt > endAt) {
            endAt = startAt;
          }

          return TasksCalendarSelectors.isDateInRange(
            new Date(year, month - 1, day),
            startAt,
            endAt
          );
        });

        if (dayTasks.length === 0) {
          return TasksCalendarDayTasksPriorities.NONE;
        }

        const priorities: TasksCalendarDayTasksPriorities[] = [];
        dayTasks.forEach((task: CalendarTask) => {
          if (task.state.code === CalendarTaskStates.DONE) {
            priorities.push(TasksCalendarDayTasksPriorities.COMPLETED);
          } else if (!task.priority) {
            priorities.push(TasksCalendarDayTasksPriorities.UNKNOWN);
          } else {
            priorities.push(
              task.priority.code as unknown as TasksCalendarDayTasksPriorities
            );
          }
        });

        return Math.max(...priorities);
      }
    );
  }

  createDatesRange(startDate: Date, stopDate: Date): Date[] {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(...[currentDate]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
  }

  static isDateInRange(
    checkDate: Date,
    startDate: Date,
    endDate: Date
  ): boolean {
    const checkDateTimestamp = checkDate.getTime();
    const startDateTimestamp = startDate.getTime();
    const endDateTimestamp = endDate.getTime();

    return (
      checkDateTimestamp >= startDateTimestamp &&
      checkDateTimestamp <= endDateTimestamp
    );
  }

  static hasUncompletedTasks(day: number, month: number, year: number) {
    return createSelector(
      [TasksCalendarListState],
      (tasksCalendarListState: TasksCalendarListStateModel) => {
        return tasksCalendarListState.tasks[year][month]
          ? tasksCalendarListState.tasks[year][month].filter(
              (task: CalendarTask) => {
                return (
                  task.date.startAt.startsWith(
                    year +
                      '-' +
                      month.toString().padStart(2, '0') +
                      '-' +
                      day.toString().padStart(2, '0')
                  ) && task.state.code === CalendarTaskStates.ACTIVE
                );
              }
            ).length > 0
          : false;
      }
    );
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { PushNotificationsStateModel } from './push_notifications-state.model';
import {
  RemovePushNotificationsDevice,
  RemovePushNotificationsToken,
  SavePushNotificationsToken,
} from './push_notifications.actions';
import { PushNotificationsService } from '@app/modules/push-notifications/services/push-notifications.service';

@Injectable()
@State<PushNotificationsStateModel>({
  name: 'push_notifications',
})
export class PushNotificationsState {
  constructor(private pushNotificationsService: PushNotificationsService) {}

  @Action(SavePushNotificationsToken)
  @ImmutableContext()
  savePushNotificationsToken(
    {}: StateContext<PushNotificationsStateModel>,
    { token, deviceId, deviceName }: SavePushNotificationsToken
  ) {
    return this.pushNotificationsService.saveToken(token, deviceId, deviceName);
  }

  @Action(RemovePushNotificationsToken)
  @ImmutableContext()
  removePushNotificationsToken(
    {}: StateContext<PushNotificationsStateModel>,
    { token }: RemovePushNotificationsToken
  ) {
    return this.pushNotificationsService.removeToken(token);
  }

  @Action(RemovePushNotificationsDevice)
  @ImmutableContext()
  removePushNotificationsDevice(
    {}: StateContext<PushNotificationsStateModel>,
    { deviceId }: RemovePushNotificationsDevice
  ) {
    return this.pushNotificationsService.removeDevice(deviceId);
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersParticipatedSea {
  static readonly type =
    '[Company Tenders Participated Sea] Load company sea tenders';
}

export class SetCompanyTendersParticipatedSeaPage {
  static readonly type =
    '[Company Tenders Participated Sea] Set company sea tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersParticipatedSeaDatePeriod {
  static readonly type =
    '[Company Tenders Participated Sea] Set company sea tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersParticipatedSeaState {
  static readonly type =
    '[Company Tenders Participated Sea] Set company sea tenders state';
  constructor(public states: TenderStates[]) {}
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CommentsStateModel } from './comments.state-model';
import { CommentsCreateState } from './create';
import { DeleteFileComment, DeleteTaskComment } from './comments.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { FileCommentsService } from '@app/modules/documents/services/file-comments.service';
import { TaskCommentsService } from '@app/pages/calendar/services/task-comments.service';

@Injectable()
@State<CommentsStateModel>({
  name: 'comments',
  children: [CommentsCreateState],
})
export class CommentsState {
  constructor(
    private fileCommentsService: FileCommentsService,
    private taskCommentsService: TaskCommentsService
  ) {}

  @Action(DeleteFileComment)
  @ImmutableContext()
  deleteFileComment(
    {}: StateContext<CommentsStateModel>,
    { documentId, commentId }: DeleteFileComment
  ) {
    return this.fileCommentsService.delete(documentId, commentId);
  }

  @Action(DeleteTaskComment)
  @ImmutableContext()
  deleteTaskComment(
    {}: StateContext<CommentsStateModel>,
    { taskId, commentId }: DeleteTaskComment
  ) {
    return this.taskCommentsService.delete(taskId, commentId);
  }
}

import { TenderStates } from '@app/modules/tenders/tender-states';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { DatePeriod } from '@app/interfaces/date-period';
import { IdName } from '@app/interfaces/id-name';

export class TendersPageFiltersStateModel {
  cargoTendersTransportationType: CargoTendersTransportationTypes;
  states?: TenderStates[];
  searchValue?: string;
  displayFilters: boolean;
  createdAtPeriod?: DatePeriod;
  curator?: IdName;
  company?: IdName;
}

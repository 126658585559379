import { AdditionalTransportationRequirement } from '@app/modules/additional-transportation-requirements/interfaces/additional-transportation-requirement';
import { CargoType } from '@app/modules/cargo-types/interfaces/cargo-type';
import { ContainerType } from '@app/modules/container-types/interfaces/container-type';
import { Country } from '@app/modules/countries/interfaces/country';
import { Currency } from '@app/modules/currencies/interfaces/currency';
import { DeliveryCondition } from '@app/modules/delivery-conditions/interfaces/delivery-condition';
import { ExchangeCargoType } from '@app/modules/exchange-cargo-types/interfaces/exchange-cargo-type';
import { Incoterm } from '@app/modules/incoterms/interfaces/incoterm';
import { LoadingType } from '@app/modules/loading-types/interfaces/loading-type';
import { Nomenclature } from '@app/modules/nomenclatures/interfaces/nomenclature';
import { PackagingType } from '@app/modules/packaging-types/interfaces/packaging-type';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { RenewalTime } from '@app/modules/renewal-times/interfaces/renewal-time';
import { CountrySquares } from '@app/modules/squares/interfaces/country-squares';
import { TenderUnitedOption } from '@app/modules/tender-united-options/interfaces/tender-united-option';
import { TransportType } from '@app/modules/transport-types/interfaces/transport-type';

export class ListingsStateModel {
  currencies: Currency[];
  nomenclatures: Nomenclature[];
  currencyRates: Currency[];
  cargoTypes: CargoType[];
  exchangeCargoTypes: ExchangeCargoType[];
  countries: Country[];
  countrySquares: CountrySquares;
  permissions: Permission[];
  companyPermissions: Permission[];
  transportTypes: TransportType[];
  additionalTransportationRequirements: AdditionalTransportationRequirement[];
  renewalTimes: RenewalTime[];
  tenderUnitedOptions: TenderUnitedOption[];
  incoterms: Incoterm[];
  deliveryConditions: DeliveryCondition[];
  containerTypes: ContainerType[];
  loadingTypes: LoadingType[];
  packagingTypes: PackagingType[];
}

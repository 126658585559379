import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';

export class ChangeTendersScopeInCompanyTendersDistribution {
  static readonly type = '[Company Tenders Distribution] Change Tenders Scope';
  constructor(public tendersScope: TendersScopes) {}
}

export class ChangeCompanyInCompanyTendersDistribution {
  static readonly type = '[Company Tenders Distribution] Change Company';
  constructor(public companyId: number) {}
}

export class ChangeDatePeriodInCompanyTendersDistribution {
  static readonly type = '[Company Tenders Distribution] Change Date Period';
  constructor(public datePeriod: DatePeriodDayjs) {}
}

export class LoadCompanyTendersDistributionData {
  static readonly type =
    '[Company Tenders Distribution] Load distribution data';
  constructor() {}
}

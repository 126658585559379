import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { TransportTendersBetsHistoryState } from './bets-history';
import { TransportTendersCreateState } from './create';
import { TransportTendersListState } from './list';
import { TransportTendersViewState } from './view';
import { TransportTendersStateModel } from './transport-tenders-state.model';

@Injectable()
@State<TransportTendersStateModel>({
  name: 'transport_tenders',
  children: [
    TransportTendersBetsHistoryState,
    TransportTendersCreateState,
    TransportTendersListState,
    TransportTendersViewState,
  ],
})
export class TransportTendersState {}

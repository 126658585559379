import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { CargoTendersStateModel } from './cargo-tenders-state.model';
import {
  ConfirmCargoTender,
  ConfirmSeaCargoTender,
  ExtendCargoTender,
  SignDocuments,
} from './cargo-tenders.actions';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { DocumentsService } from '@app/modules/documents/services/documents.service';
import { combineLatest, from, Observable, mergeMap } from 'rxjs';
import { createHash, createDetachedSignature } from 'crypto-pro';
import { SignedDocument } from '@app/modules/documents/interfaces/signed-document';
import { Buffer } from 'buffer';
import {
  SetCargoTenderTransportAndDriver,
  ToggleShareDocument,
} from './cargo-tenders.actions';
import { CargoTendersBetsHistoryState } from './bets-history';
import { CargoTendersCreateState } from './create';
import { CargoTendersListState } from './list';
import { CargoTendersViewState } from './view';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';

@Injectable()
@State<CargoTendersStateModel>({
  name: 'cargo_tenders',
  defaults: {
    overallStatistics: null,
  },
  children: [
    CargoTendersBetsHistoryState,
    CargoTendersCreateState,
    CargoTendersListState,
    CargoTendersViewState,
  ],
})
export class CargoTendersState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private documentsService: DocumentsService
  ) {}

  @Action(ConfirmCargoTender)
  @ImmutableContext()
  confirmCargoTender(
    {}: StateContext<CargoTendersStateModel>,
    { tenderId, offer }: ConfirmCargoTender
  ) {
    return this.cargoTendersCarService.confirmTender(tenderId, offer);
  }

  @Action(ConfirmSeaCargoTender)
  @ImmutableContext()
  confirmSeaCargoTender(
    {}: StateContext<CargoTendersStateModel>,
    { tenderId }: ConfirmSeaCargoTender
  ) {
    return this.cargoTendersSeaService.confirmTender(tenderId);
  }

  @Action(SetCargoTenderTransportAndDriver)
  @ImmutableContext()
  setCargoTenderTransportAndDriver(
    {}: StateContext<CargoTendersStateModel>,
    { tenderId, data }: SetCargoTenderTransportAndDriver
  ) {
    return this.cargoTendersCarService.setTenderTransportAndDriver(
      tenderId,
      data
    );
  }

  @Action(SignDocuments)
  @ImmutableContext()
  signDocuments(
    {}: StateContext<CargoTendersStateModel>,
    { thumbPrint, documentIDs, tenderId, tenderType }: SignDocuments
  ) {
    const contents$ = documentIDs.map((documentID) => {
      return this.documentsService.getDocumentContent(documentID);
    });

    return combineLatest(contents$).pipe(
      mergeMap((contents: string[]) => {
        const observables$: Observable<string>[] = [];
        contents.forEach((content: string) => {
          observables$.push(from(createHash(Buffer.from(content, 'base64'))));
        });

        return combineLatest(observables$);
      }),
      mergeMap((hashes: string[]) => {
        const observables$: Observable<string>[] = [];
        hashes.map((hash: string) => {
          observables$.push(from(createDetachedSignature(thumbPrint, hash)));
        });

        return combineLatest(observables$);
      }),
      mergeMap((signatures: string[]) => {
        const signedDocuments: SignedDocument[] = signatures.map(
          (signature: string, index: number) => {
            return {
              id: documentIDs[index],
              sign: signature,
            };
          }
        );

        return this.documentsService.signDocuments(
          thumbPrint,
          signedDocuments,
          tenderId,
          tenderType
        );
      })
    );
  }

  @Action(ToggleShareDocument)
  @ImmutableContext()
  toggleShareDocument(
    {}: StateContext<CargoTendersStateModel>,
    { documentId, companyId }: ToggleShareDocument
  ) {
    return this.documentsService.toggleShareCompany(documentId, companyId);
  }

  @Action(ExtendCargoTender)
  @ImmutableContext()
  extendCargoTender(
    {}: StateContext<CargoTendersStateModel>,
    { id, type, validUntil }: ExtendCargoTender
  ) {
    const service =
      type === CargoTendersTransportationTypes.SEA
        ? this.cargoTendersSeaService
        : this.cargoTendersCarService;

    return service.extendTender(id, validUntil);
  }
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { CompanyFinancesBillsStateModel } from './company-finances_bills-state.model';
import { CompanyFinancesBillsAccountsPayableState } from './accounts-payable';
import { CompanyFinancesBillsAccountsReceivableState } from './accounts-receivable';
import { CompanyFinancesBillsTabs } from '@app/pages/company-finances/bills/company-finances-bills-tabs';
import { SetCompanyFinancesBillsActiveTab } from './company-finances_bills.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';

@Injectable()
@State<CompanyFinancesBillsStateModel>({
  name: 'company_finances__bills',
  defaults: {
    activeTab: CompanyFinancesBillsTabs.ACCOUNTS_PAYABLE,
  },
  children: [
    CompanyFinancesBillsAccountsPayableState,
    CompanyFinancesBillsAccountsReceivableState,
  ],
})
export class CompanyFinancesBillsState {
  @Action(SetCompanyFinancesBillsActiveTab)
  @ImmutableContext()
  setCompanyFinancesBillsActiveTab(
    { setState }: StateContext<CompanyFinancesBillsStateModel>,
    { tab }: SetCompanyFinancesBillsActiveTab
  ) {
    return setState((state: CompanyFinancesBillsStateModel) => {
      state.activeTab = tab;
      return state;
    });
  }
}

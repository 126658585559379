import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { TransportTenderCreateFormData } from '@app/pages/transport-tender/create/interfaces/transport-tender_create.form-data';

@Injectable({
  providedIn: 'root',
})
export class ExchangeTenderService {
  constructor(private apiService: ApiService) {}

  create(data: TransportTenderCreateFormData): Observable<unknown> {
    return this.apiService.post<Observable<unknown>>('/exchange/tender', data);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { Incoterm } from '@app/modules/incoterms/interfaces/incoterm';

@Injectable({
  providedIn: 'root',
})
export class IncotermsService {
  constructor(private apiService: ApiService) {}

  list(): Observable<Incoterm[]> {
    return this.apiService.getAll<Incoterm>('/incoterms');
  }
}

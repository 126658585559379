import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { LoadDirectoryContents } from './documents-tree.actions';
import { DocumentsTreeStateModel } from './documents-tree-state.model';
import { Injectable } from '@angular/core';
import { DocumentsService } from '@app/modules/documents/services/documents.service';
import { CTDocument } from '@app/modules/documents/interfaces/ct-document';
import { tap } from 'rxjs';

@Injectable()
@State<DocumentsTreeStateModel>({
  name: 'document_tree',
  defaults: {
    documentTree: {},
  },
})
export class DocumentsTreeState {
  constructor(private documentsService: DocumentsService) {}

  @Action(LoadDirectoryContents)
  @ImmutableContext()
  loadDirectoryContents(
    { getState, setState }: StateContext<DocumentsTreeStateModel>,
    { directoryId, forceReload }: LoadDirectoryContents
  ) {
    if (!forceReload && getState().documentTree[directoryId]) {
      return;
    }

    return this.documentsService.loadDirectoryContents(directoryId).pipe(
      tap((contents: CTDocument[]) => {
        setState((state: DocumentsTreeStateModel) => {
          state.documentTree[directoryId] = contents;

          return state;
        });
      })
    );
  }
}

import { Company } from '@app/modules/companies/interfaces/company';
import { Selector } from '@ngxs/store';
import { CompanyStateModel } from './company-state.model';
import { CompanyState } from './company.state';

export class CompanySelectors {
  @Selector([CompanyState])
  static shippers(state: CompanyStateModel): Company[] {
    return state.shippers;
  }

  @Selector([CompanyState])
  static consignees(state: CompanyStateModel): Company[] {
    return state.consignees;
  }

  @Selector([CompanyState])
  static validLimit(state: CompanyStateModel): boolean {
    return state.validLimit;
  }
}

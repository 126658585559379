import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckPermissionsService } from '@app/modules/permissions/services/check-permissions.service';

@Injectable()
export class HasCompanyPermissionGuard implements CanActivate {
  constructor(private checkPermissionsService: CheckPermissionsService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const permission = route.data.permission;

    return this.checkPermissionsService.hasCompanyAccess(permission.name);
  }
}

import { Bet } from '@app/modules/bets/interfaces/bet';
import { ConfirmedBetUpdateData } from '@app/modules/bets/interfaces/confirmed-bet-update-data';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';

export class TryAddBet {
  static readonly type = '[Bets] Try add bet';
  constructor(
    public cargoTenderTransportationType: CargoTendersTransportationTypes,
    public tenderId: number,
    public price: number,
    public deliveryDate?: string,
    public minPrice?: number,
    public comment?: string,
    public duration?: number,
    public markup: number = null,
    public markupPercent: number = null,
    public clientSum: number = null,
    public carrierVat: boolean = false,
    public clientVat: boolean = false
  ) {}
}

export class AddBetSuccess {
  static readonly type = '[Bets] The bet was successfully added';
  constructor() {}
}

export class AddBetFailed {
  static readonly type = '[Bets] Failed to add a bet';
  constructor(public errors: string[]) {}
}

export class TryUpdateBet {
  static readonly type = '[Bets] Try update bet';
  constructor(
    public cargoTenderTransportationType: CargoTendersTransportationTypes,
    public tenderId: number,
    public price: number
  ) {}
}

export class UpdateBetSuccess {
  static readonly type = '[Bets] The bet was successfully updated';
}

export class UpdateBetFailed {
  static readonly type = '[Bets] Failed to update the bet';
  constructor(public errors: string[]) {}
}

export class UpdateConfirmedBet {
  static readonly type = '[Bets] Update confirmed bet';
  constructor(public betId: number, public data: ConfirmedBetUpdateData) {}
}

export class TrySaveSeaTenderMarkup {
  static readonly type = '[Bets] Try to save bet markup for sea tender';
  constructor(public betId: number, public markup: number) {}
}

export class SaveSeaTenderMarkupSuccess {
  static readonly type =
    '[Bets] The bet markup for sea tender was successfully saved';
  constructor() {}
}

export class SaveSeaTenderMarkupFailed {
  static readonly type = '[Bets] Failed to save the bet markup for sea tender';
  constructor(public errors: string[]) {}
}

export class NewTransportBetPlaced {
  static readonly type =
    '[Bets] Mercury signal: A new transport bet was placed';
  constructor(public tender_id: number) {}
}

export class DeleteBet {
  static readonly type = '[Bets] Delete bet';
  constructor(public bet: Bet) {}
}

import { CalendarTaskStates } from '@app/pages/calendar/calendar-task-states';
import { CalendarTaskTypes } from '@app/pages/calendar/calendar-task-types';
import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';

export class SetDateFilterValueInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Set date filter value in the tasks calendar';
  constructor(public date: string) {}
}

export class SetTypeFilterValueInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Set type filter value in the tasks calendar';
  constructor(public type: CalendarTaskTypes) {}
}

export class SetStateFilterValueInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Set state filter value in the tasks calendar';
  constructor(public state: CalendarTaskStates) {}
}

export class DisplayFiltersInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Display filters in the tasks calendar';
}

export class ApplyFiltersInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Apply filters in the tasks calendar';
  constructor(public filters: CalendarListFilters) {}
}

export class ListTasksInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Load tasks in the tasks calendar for provided day';
  constructor() {}
}

import { FieldsErrors } from '@app/interfaces/fields-errors';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';
import { SaveCompanyPopularRouteFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-popular-route/save-company-popular-route-form-data';

export class LoadCompanyPopularRoutes {
  static readonly type = '[View Company Page] Load company popular routes';
  constructor(public companyId: number) {}
}

export class InitAddEditCompanyPopularRouteForm {
  static readonly type =
    '[View Company Page] Initialize the add/edit company popular route form';
  constructor() {}
}

export class EditCompanyPopularRoute {
  static readonly type =
    '[Company Page] Trigger the popup to display company popular route edit form.';
  constructor(public routeId: number) {}
}

export class TrySaveCompanyPopularRoute {
  static readonly type =
    '[Company Page TAB1 Add/Edit Popular Route Popup] Try to save the company popular route';
  constructor(public data: SaveCompanyPopularRouteFormData) {}
}

export class SaveCompanyPopularRouteSuccess {
  static readonly type =
    '[Company Page TAB1 Add/Edit Structure Popup] The company popular route has been successfully saved!';
  constructor() {}
}

export class SaveCompanyPopularRouteFailed {
  static readonly type =
    '[Company Page TAB1 Add/Edit Structure Popup] Failed to save the company popular route!';
  constructor(public errors: FieldsErrors) {}
}

export class DeleteCompanyPopularRoute {
  static readonly type =
    '[Company Page] Display the confirmation box for deletion of company popular route';
  constructor(public route: CompanyPopularRoute) {}
}

export class TryDeleteCompanyPopularRoute {
  static readonly type = '[Company Page] Try to delete company popular route';
  constructor(public companyId: number, public routeId: number) {}
}

export class DeleteCompanyPopularRouteSuccess {
  static readonly type =
    '[Company Page] The company popular route has been successfully deleted!';
  constructor() {}
}

export class DeleteCompanyPopularRouteFailed {
  static readonly type =
    '[Company Page] Failed to delete the company popular route!';
  constructor() {}
}

import { CalendarTaskStates } from '@app/pages/calendar/calendar-task-states';
import { CalendarTaskTypes } from '@app/pages/calendar/calendar-task-types';
import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';

export class SetDateFilterValueInCalendar {
  static readonly type = '[Calendar] Set date filter value in the calendar';
  constructor(public date: string) {}
}

export class SetTypeFilterValueInCalendar {
  static readonly type = '[Calendar] Set type filter value in the calendar';
  constructor(public type: CalendarTaskTypes) {}
}

export class SetStateFilterValueInCalendar {
  static readonly type = '[Calendar] Set state filter value in the calendar';
  constructor(public state: CalendarTaskStates) {}
}

export class DisplayFiltersInCalendar {
  static readonly type = '[Calendar] Display filters in the calendar';
}

export class ApplyFiltersInCalendar {
  static readonly type = '[Calendar] Apply filters in the calendar';
  constructor(public filters: CalendarListFilters) {}
}

export class ListTasksInCalendar {
  static readonly type = '[Calendar] Load tasks in the calendar';
}

export class LoadCompanyShippers {
  static readonly type = '[Company] Load shippers';
  constructor() {}
}

export class AddCompanyShipper {
  static readonly type = '[Company] Add shipper';
  constructor(public tin: number) {}
}

export class LoadCompanyConsignees {
  static readonly type = '[Company] Load consignees';
  constructor() {}
}

export class AddCompanyConsignee {
  static readonly type = '[Company] Add consignee';
  constructor(public tin: number) {}
}

export class CheckCurrencyLimit {
  static readonly type = '[Company] Check currency limit';
  constructor(public currencyId: number) {}
}

export class ResetCurrencyLimit {
  static readonly type = '[Company] Reset currency limit';
}

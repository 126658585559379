import { AccessTokenPayload } from '@app/modules/auth/interfaces/access-token-payload';
import { Permission } from '@app/modules/permissions/interfaces/permission';

export class AuthStateModel {
  public accessTokenPayload: AccessTokenPayload;
  public refreshToken: string;
  public token: string;
  public wrongCredentials: boolean;
  public userPermissions: Permission[];
  public companyPermissions: Permission[];
}

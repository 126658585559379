import { Selector } from '@ngxs/store';
import { CompaniesCreateStateModel } from './companies_create-state.model';
import { CompaniesCreateState } from './companies_create.state';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { Country } from '@app/modules/countries/interfaces/country';

export class CompaniesCreateSelectors {
  @Selector([CompaniesCreateState])
  static fieldErrors(state: CompaniesCreateStateModel): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      const errorMessages = [];
      state.fieldsErrors[key].forEach((errorMessage: string) => {
        errorMessages.push(errorMessage);
      });

      errors.push({
        field: key,
        errors: errorMessages,
      });
    });

    return errors;
  }
}

import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { CompanyTypes } from '@app/modules/companies/company-types';
import { CompanyContact } from '@app/modules/companies/interfaces/company-contact';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyMembersFilters } from '@app/modules/companies/interfaces/company-members-filters';
import { SaveCompanyContactFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-contact/save-company-contact.form-data';
import { SaveCompanyLimitsFormData } from '@app/pages/companies/view/tab4/components/set-company-limits/save-company-limits-form-data';
import { SaveCompanyMarkupFormData } from '@app/pages/companies/view/tab4/components/set-company-markup/save-company-markup-form-data';

export class InitViewCompanyPage {
  static readonly type = '[View Company Page] Initialize view company page';
  constructor() {}
}

export class LoadCompany {
  static readonly type = '[View Company Page] Load company';
  constructor(public companyId: number) {}
}

export class LoadCompanyMembers {
  static readonly type = '[View Company Page] Load company members';
  constructor(public companyId: number) {}
}

export class LoadCompanyComments {
  static readonly type = '[View Company Page] Load company comments';
  constructor(public companyId: number) {}
}

export class LoadDocumentComments {
  static readonly type = '[View Company Page] Load document comments';
  constructor(public companyId: number) {}
}

export class ToggleCompanyMembersFilters {
  static readonly type = '[View Company Page] Toggle company members filters';
  constructor() {}
}

export class HideCompanyMembersFilters {
  static readonly type = '[View Company Page] Hide company members filters';
  constructor() {}
}

export class TrySaveCompanyLimit {
  static readonly type =
    '[Company Page TAB2 Popup] Try to save the company limit';
  constructor(public data: SaveCompanyLimitsFormData) {}
}

export class TryDeleteCompanyLimit {
  static readonly type =
    '[Company Page TAB2 Popup] Try to delete the company limit';
  constructor(public companyId: number, public companyLimitId: number) {}
}

export class TrySaveCompanyMarkupSettings {
  static readonly type =
    '[Company Page TAB2 Popup] Try to save the company markup settings';
  constructor(public data: SaveCompanyMarkupFormData) {}
}

export class UpdateCompanyMembersFilters {
  static readonly type =
    '[View Company Page Tab 3] Update company members filters';
  constructor(public filters: CompanyMembersFilters) {}
}

export class LoadCompanyPartnerships {
  static readonly type = '[View Company -> Partners Tab] Load company partners';
  constructor(
    public companyId: number,
    public search?: string,
    public page?: number
  ) {}
}

export class AddCompanyPartner {
  static readonly type =
    '[View Company -> Partners Tab] Add company partner (display the form)';
  constructor() {}
}

export class TryAddCompanyPartner {
  static readonly type =
    '[View Company -> Partners Tab] Try to add company partner by INN number';
  constructor(public companyId: number, public inn: string) {}
}

export class AddCompanyPartnerSuccess {
  static readonly type =
    '[View Company -> Partners Tab] The company partner has been successfully added!';
  constructor() {}
}

export class AddCompanyPartnerFailed {
  static readonly type =
    '[View Company -> Partners Tab] Failed to add the company partner! Wrong INN number?';
  constructor() {}
}

export class RemoveCompanyPartner {
  static readonly type =
    '[View Company -> Partners Tab] Remove company partner (display the confirmation box)';
  constructor(public partnerId: number) {}
}

export class TryRemoveCompanyPartner {
  static readonly type =
    '[View Company -> Partners Tab] Try to remove company partner';
  constructor(public companyId: number, public partnerId: number) {}
}

export class RemoveCompanyPartnerSuccess {
  static readonly type =
    '[View Company -> Partners Tab] The company partner has been successfully removed!';
  constructor() {}
}

export class RemoveCompanyPartnerFailed {
  static readonly type =
    '[View Company -> Partners Tab] Failed to remove the company partner!';
  constructor() {}
}

export class TryConfirmCompanyPartner {
  static readonly type =
    '[View Company -> Partners Tab] Try to confirm a partnership with a company.';
  constructor(public companyId: number, public partnerId: number) {}
}

export class ConfirmCompanyPartnerSuccess {
  static readonly type =
    '[View Company -> Partners Tab] The partnership with the company has been successfully confirmed!';
  constructor() {}
}

export class ConfirmCompanyPartnerFailed {
  static readonly type =
    '[View Company -> Partners Tab] Failed to confirm the partnership wtih the company!';
  constructor() {}
}

export class UpdateCompanyAvatar {
  static readonly type = '[Companies] Update company avatar';
  constructor(public companyId: number, public avatar: string) {}
}

export class TryDeleteCompanyContact {
  static readonly type = '[Companies] Try Delete company contact';
  constructor(public contact: CompanyContact) {}
}

export class TryCreateCompanyContact {
  static readonly type = '[Companies] Try create company contact';
  constructor(public contactData: SaveCompanyContactFormData) {}
}

export class TryEditCompanyContact {
  static readonly type = '[Companies] Try edit company contact';
  constructor(public contactData: SaveCompanyContactFormData) {}
}

export class SaveCompanyContactFailed {
  static readonly type = '[Companies] Failed to save company contact';
  constructor(public err: UnprocessableEntity) {}
}

export class SaveCompanyContactSuccess {
  static readonly type = '[Companies] Company contact was successfully saved';
  constructor() {}
}

export class DeleteCompanyContact {
  static readonly type =
    '[Companies] Delete company contact (the action is dispatched)';
  constructor(public contact: CompanyContact) {}
}

export class CompanyRemoveMember {
  static readonly type = '[Company -> Members] Remove member from a company';
  constructor(public member: CompanyMember) {}
}

export class RefreshRequisites {
  static readonly type = '[Companies] Refresh company requisites';
  constructor() {}
}

export class SearchCompanyMember {
  static readonly type = '[View Company Page] Search company member';
  constructor(public searchValue: string) {}
}

export class SetCompanyOwner {
  static readonly type = '[View Company Page] Set company owner';
  constructor(public member: CompanyMember) {}
}

export class SetAutoSignature {
  static readonly type = '[View Company Page] Set auto signature';
  constructor(public autoSignature: boolean) {}
}

export class SetUseUserSignature {
  static readonly type = '[View Company Page] Set use user signature';
  constructor(public useUserSignature: boolean) {}
}

export class UploadStamp {
  static readonly type = '[Profile Page] Upload company stamp';
  constructor(public file: File) {}
}

export class UploadSignature {
  static readonly type = '[Profile Page] Upload company signature';
  constructor(public file: File) {}
}

export class SaveCompanyType {
  static readonly type = '[Companies] Save company type';
  constructor(public companyType: CompanyTypes) {}
}

export class SetCompanyTimeBlocking {
  static readonly type = '[Companies] Set company time blocking';
  constructor(public timeBlocking: string) {}
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { tap } from 'rxjs/operators';
import { WarehousesListStateModel } from './warehouses_list-state.model';
import { FetchWarehouses } from './warehouses_list.actions';
import { WarehousesService } from '@app/modules/warehouses/services/warehouses.service';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';

@Injectable()
@State<WarehousesListStateModel>({
  name: 'list',
  defaults: {
    warehouses: null,
    page: 1,
    maxPage: null,
  },
})
export class WarehousesListState {
  constructor(private warehousesService: WarehousesService) {}

  @Action(FetchWarehouses)
  @ImmutableContext()
  fetchWarehouses(
    { getState, setState }: StateContext<WarehousesListStateModel>,
    { page }: FetchWarehouses
  ) {
    const newPage = page || getState().page;

    return this.warehousesService.list(newPage).pipe(
      tap((result: PaginationResults<Warehouse>) => {
        setState((state: WarehousesListStateModel) => {
          state.warehouses = result.data;
          state.page = newPage;
          state.maxPage = result.maxPage;
          return state;
        });
      })
    );
  }
}

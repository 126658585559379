import { BillsListFilters } from '@app/modules/bills/interfaces/bills-list-filters';

export class LoadFinancesAccountsPayableBillsList {
  static readonly type =
    '[Finances -> Bills -> Accounts Payable] Load bills list';
  constructor() {}
}

export class SetFinancesAccountsPayableBillsListFilters {
  static readonly type = '[Finances -> Bills -> Accounts Payable] Set filters';
  constructor(public filters: BillsListFilters) {}
}

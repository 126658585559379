import { StickyNote } from '@app/modules/sticky-notes/interfaces/sticky-note';
import { StickyNoteColor } from '@app/modules/sticky-notes/interfaces/sticky-note-color';
import { UpdateStickyNoteFormData } from '@app/modules/sticky-notes/interfaces/update-sticky-notes.form-dats';

export class LoadStickyNoteColors {
  static readonly type = '[Sticky notes] Load colors list';
}

export class LoadStickyNotesList {
  static readonly type = '[Sticky notes] Load sticky notes';
}

export class AddStickyNote {
  static readonly type = '[Sticky notes] Add note';
}

export class UpdateStickyNote {
  static readonly type = '[Sticky notes] Update sticky note';
  constructor(public noteID: number, public data: UpdateStickyNoteFormData) {}
}

export class UpdateStickyNoteText {
  static readonly type = '[Sticky notes] Update sticky note text';
  constructor(public note: StickyNote, public newText: string) {}
}

export class UpdateStickyNotePosition {
  static readonly type = '[Sticky notes] Update sticky note position';
  constructor(
    public note: StickyNote,
    public newTop: number,
    public newLeft: number
  ) {}
}

export class UpdateStickyNoteColor {
  static readonly type = '[Sticky notes] Update sticky note color';
  constructor(public note: StickyNote, public newColor: StickyNoteColor) {}
}

export class DeleteStickyNote {
  static readonly type = '[Sticky notes] Delete note';
  constructor(public note: StickyNote) {}
}

export class LoadStickyNotesCount {
  static readonly type = '[Sticky notes] Load sticky notes count';
}

// User
export class AddUserToFavorites {
  static readonly type = '[Favorites] Add user to favorites';
  constructor(public entityId: number) {}
}

export class RemoveUserFromFavorites {
  static readonly type = '[Favorites] Remove user to favorites';
  constructor(public entityId: number) {}
}

// Company
export class AddCompanyToFavorites {
  static readonly type = '[Favorites] Add company to favorites';
  constructor(public entityId: number) {}
}

export class RemoveCompanyFromFavorites {
  static readonly type = '[Favorites] Remove company to favorites';
  constructor(public entityId: number) {}
}

// Transport
export class AddTransportToFavorites {
  static readonly type = '[Favorites] Add transport to favorites';
  constructor(public entityId: number) {}
}

export class RemoveTransportFromFavorites {
  static readonly type = '[Favorites] Remove transport to favorites';
  constructor(public entityId: number) {}
}

// Car Cargo Tender
export class AddCarCargoTenderToFavorites {
  static readonly type = '[Favorites] Add car cargo tender to favorites';
  constructor(public entityId: number) {}
}

export class RemoveCarCargoTenderFromFavorites {
  static readonly type = '[Favorites] Remove car cargo tender to favorites';
  constructor(public entityId: number) {}
}

// Transport Tender
export class AddTransportTenderToFavorites {
  static readonly type = '[Favorites] Add transport tender to favorites';
  constructor(public entityId: number) {}
}

export class RemoveTransportTenderFromFavorites {
  static readonly type = '[Favorites] Remove transport tender to favorites';
  constructor(public entityId: number) {}
}

// Sea Cargo Tender
export class AddSeaCargoTenderToFavorites {
  static readonly type = '[Favorites] Add sea cargo tender to favorites';
  constructor(public entityId: number) {}
}

export class RemoveSeaCargoTenderFromFavorites {
  static readonly type = '[Favorites] Remove sea cargo tender to favorites';
  constructor(public entityId: number) {}
}

import { IcebergPopupData } from '@app/pages/main-page/components/iceberg-popup/interfaces/iceberg-popup.data';

export class MainPageStateModel {
  isMouseOverIceberg: boolean;
  intervalRotationEnabled: boolean;
  hoveredFragmentName: number;
  activeFragmentName: number;
  popupData: IcebergPopupData;
  videoUrl: string;
}

export class SavePushNotificationsToken {
  static readonly type = '[Push Notifications] Save token';
  constructor(
    public token: string,
    public deviceId: string,
    public deviceName?: string
  ) {}
}

export class RemovePushNotificationsToken {
  static readonly type = '[Push Notifications] Remove token';
  constructor(public token: string) {}
}

export class RemovePushNotificationsDevice {
  static readonly type = '[Push Notifications] Remove device';
  constructor(public deviceId: string) {}
}

import { Selector } from '@ngxs/store';
import { ChatTendersChatsStateModel } from './chat_tenders-chats-state.model';
import { ChatTendersChatsState } from './chat_tenders-chats.state';

export class ChatTendersChatsSelectors {
  @Selector([ChatTendersChatsState])
  static tendersChatsSearchValue(state: ChatTendersChatsStateModel): string {
    return state.tendersChatsSearchValue;
  }

  @Selector([ChatTendersChatsState])
  static hasMoreResults(state: ChatTendersChatsStateModel): boolean {
    return state.tendersChatsTotal > state.tendersChats?.size;
  }
}

import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';

@Injectable({
  providedIn: 'root',
})
export class CompanyPartnershipsService {
  constructor(private apiService: ApiService) {}

  list(
    companyId: number,
    search: string = null,
    page: number = null,
    confirmed?: boolean
  ): Observable<PaginationResults<CompanyPartnership>> {
    const data: any = {
      fields: ['id', 'partner.details.companyName.short', 'date.confirmAt'],
      expand: ['partner', 'partner.details'].join(','),
    };
    if (search) {
      data.search = search;
    }

    if (page && page > 1) {
      data.page = page;
    }

    if (confirmed) {
      data.confirmed = true;
    }

    return this.apiService.getPaginatedResults<CompanyPartnership>(
      '/company/' + companyId + '/partner',
      data
    );
  }

  listConfirmed(companyId: number): Observable<CompanyPartnership[]> {
    const data: any = {
      fields: ['partner.id', 'partner.details.companyName.short'],
      expand: ['partner', 'partner.details'].join(','),
      confirmed: true,
    };

    return this.apiService.getAll<CompanyPartnership>(
      '/company/' + companyId + '/partner',
      data
    );
  }

  sendPartnershipRequest(
    companyId: number,
    partnerTin: string
  ): Observable<unknown> {
    return this.apiService.post<unknown>('/company/' + companyId + '/partner', {
      partnerTin,
    });
  }

  confirmPartnership(
    companyId: number,
    partnerId: number
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/company/' + companyId + '/partner/' + partnerId + '/confirm'
    );
  }

  cancelPartnership(companyId: number, partnerId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/company/' + companyId + '/partner/' + partnerId
    );
  }
}

import { CTDocument } from '@app/modules/documents/interfaces/ct-document';
import { createSelector } from '@ngxs/store';
import { DocumentsTreeStateModel } from './documents-tree-state.model';
import { DocumentsTreeState } from './documents-tree.state';

export class DocumentsTreeSelectors {
  static documentTree(
    directoryID: number
  ): (state: DocumentsTreeStateModel) => CTDocument[] {
    return createSelector(
      [DocumentsTreeState],
      (state: DocumentsTreeStateModel) => {
        return state.documentTree[directoryID];
      }
    );
  }
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatStateModel } from './chat-state.model';
import { Injectable } from '@angular/core';
import {
  SwitchTab,
  SwitchTabKeepCollapsed,
  SwitchTabAndCollapse,
  ExpandChats,
  CacheChatGroups,
  OpenChatGroup,
  InsertChatMessage,
  UpdateChatMessage,
  OpenPrivateChat,
  LoadPrivateChat,
  RemoveChatMessage,
  CreatePrivateChat,
  SaveChatMessage,
  SaveChatMessageSuccess,
  SaveChatMessageFailed,
  DeleteChatGroupSuccess,
  RemoveFromCache,
  LoadNonPrivateChat,
  CreateTransportTenderChatGroup,
  OpenTransportTenderChat,
  SetUnreadMessagesCountInTenderChat,
  InsertMessageFeedback,
  CreateConsultantChatGroup,
  LoadConsultantChatGroup,
  OpenExistingChatGroup,
  MarkAsRead,
  ReopenChats,
} from './chat.actions';
import { tap, catchError } from 'rxjs/operators';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { ChatTabs } from '@app/modules/chat/chat-tabs';
import { User } from '@app/modules/users/interfaces/user';
import { GroupChatTypes } from '@app/modules/chat/group-chat-types';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { MessageFeedback } from '@app/modules/chat/interfaces/meesage-feedback';
import { AuthSelectors } from '@app/store/auth';
import { ChatGroupsService } from '@app/modules/chat/services/chat-groups.service';
import {
  ChatContactsState,
  UpdatedUserContactRelation,
  UpdatedUserBlockRelation,
} from './contacts';
import {
  ChatMyChatsState,
  PrivateChatAddedToBlackList,
  AddChatGroupToMyChats,
  TryCreateChatGroup,
  CreateChatGroupSuccess,
  TryEditChatGroup,
  EditChatGroupSuccess,
  TryDeleteChatGroup,
} from './my-chats';
import {
  ChatAddMessage,
  ChatSelectedChatGroupSelectors,
  ChatSelectedChatGroupState,
  DeleteChatMessageFailed,
  DeleteChatMessageSuccess,
  FetchChatGroupMessages,
  FetchMoreChatGroupMessages,
  LikeMessage,
  LikeMessageFailed,
  LikeMessageSuccess,
  ReadChatGroupLoadedMessages,
  RemoveMessageFeedback,
  RemoveMessageFeedbackFailed,
  RemoveMessageFeedbackSuccess,
  RTS_ChatGroupCreated,
  RTS_ChatGroupEdited,
  RTS_ChatMessagesWereRead,
  RTS_ChatMessageWasDeleted,
  RTS_MessageFeedbackAdded,
  RTS_MessageFeedbackRemoved,
  RTS_NewChatMessageReceived,
  SelectChatGroup,
  TryDeleteChatMessage,
} from './selected-chat-group';
import {
  ChatTendersChatsState,
  AddChatGroupToTendersChats,
  UpdateTransportTenderChatGroupID,
} from './tenders-chats';
import { of, throwError } from 'rxjs';
import { MultipleResults } from '@app/interfaces/multiple-results';
import * as moment from 'moment';
import { ChatFeedbackService } from '@app/modules/chat/services/chat-feedback.service';
import { EntityStatuses } from '@app/modules/entity/entity-statuses';
import { Company } from '@app/modules/companies/interfaces/company';
import { ChatWriteMessageState } from './write-message';
import { MessageAttachment } from '@app/modules/chat/interfaces/message-attachment';
import { ChatGroupMessagesService } from '@app/modules/chat/services/chat-group-messages.service';
import { NotFound } from '@app/errors/response-errors/not-found';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import {
  ViewCarCargoTender,
  ViewSeaCargoTender,
  ViewTransportTender,
} from '@app/store/tabs/tabs.actions';
import { Platform } from '@ionic/angular';

marker('STATES.CHAT.Консультант');

@Injectable()
@State<ChatStateModel>({
  name: 'chat',
  defaults: {
    activeTab: ChatTabs.MY_CHATS,
    isChatExpanded: false,
    consultantChatGroupID: 0,
    chatGroups: {
      0: {
        id: 0,
        unreadMessageCount: 0,
        name: 'STATES.CHAT.Консультант',
        avatar: 'assets/icons/consultant.png',
        type: {
          code: GroupChatTypes.SPECIAL,
        },
        status: {
          code: EntityStatuses.UNKNOWN,
        },
      },
    },
    reopenLater: false,
  },
  children: [
    ChatContactsState,
    ChatMyChatsState,
    ChatSelectedChatGroupState,
    ChatTendersChatsState,
    ChatWriteMessageState,
  ],
})
export class ChatState {
  constructor(
    private store: Store,
    private chatGroupsService: ChatGroupsService,
    private chatGroupMessagesService: ChatGroupMessagesService,
    private chatFeedbackService: ChatFeedbackService,
    private platform: Platform
  ) {}

  @Action(ExpandChats)
  @ImmutableContext()
  expandChats({ setState }: StateContext<ChatStateModel>) {
    return setState((state: ChatStateModel) => {
      state.isChatExpanded = true;
      return state;
    });
  }

  @Action(ReopenChats)
  @ImmutableContext()
  reopenChats({ setState }: StateContext<ChatStateModel>) {
    return setState((state: ChatStateModel) => {
      if (state.reopenLater) {
        state.isChatExpanded = true;
        state.reopenLater = false;
      }
      return state;
    });
  }

  @Action(SwitchTab)
  @ImmutableContext()
  switchTab({ setState }: StateContext<ChatStateModel>, { tab }: SwitchTab) {
    return setState((state: ChatStateModel) => {
      state.isChatExpanded = true;
      state.activeTab = tab;
      return state;
    });
  }

  @Action(SwitchTabKeepCollapsed)
  @ImmutableContext()
  switchTabKeepCollapsed(
    { setState }: StateContext<ChatStateModel>,
    { tab }: SwitchTabKeepCollapsed
  ) {
    return setState((state: ChatStateModel) => {
      state.activeTab = tab;
      return state;
    });
  }

  @Action(SwitchTabAndCollapse)
  @ImmutableContext()
  switchTabAndCollapse(
    { setState }: StateContext<ChatStateModel>,
    { tab, reopenLater }: SwitchTabAndCollapse
  ) {
    return setState((state: ChatStateModel) => {
      if (state.isChatExpanded) {
        state.isChatExpanded = false;
        state.activeTab = tab;
        state.reopenLater = reopenLater === true;
      }
      return state;
    });
  }

  @Action(ViewCarCargoTender)
  @Action(ViewSeaCargoTender)
  @Action(ViewTransportTender)
  @ImmutableContext()
  closeChat({ setState }: StateContext<ChatStateModel>) {
    return setState((state: ChatStateModel) => {
      if (this.platform.width() <= 1280) {
        state.isChatExpanded = false;
        state.activeTab = ChatTabs.MY_CHATS;
      }
      return state;
    });
  }

  @Action(CacheChatGroups)
  @ImmutableContext()
  cacheChatGroups(
    { setState }: StateContext<ChatStateModel>,
    { chatGroups }: CacheChatGroups
  ) {
    return setState((state: ChatStateModel) => {
      chatGroups.forEach((chatGroup: ChatGroup) => {
        if (!state.chatGroups[chatGroup.id.toString()]) {
          state.chatGroups[chatGroup.id.toString()] = chatGroup;
        }
      });
      return state;
    });
  }

  @Action(OpenTransportTenderChat)
  @ImmutableContext()
  openTransportTenderChat(
    {}: StateContext<ChatStateModel>,
    { transportTender }: OpenTransportTenderChat
  ) {
    if (transportTender.chatId) {
      this.store.dispatch(
        new OpenChatGroup(transportTender.chatId, transportTender.number)
      );
    } else {
      const company: Company = this.store.selectSnapshot(AuthSelectors.company);
      const postfix: string = company ? `-${company.id}` : '';

      this.store.dispatch(
        new OpenChatGroup(
          this.chatGroupsService.getUncreatedID(
            transportTender.id,
            GroupChatTypes.TENDER
          ),
          transportTender.number + postfix,
          true
        )
      );
    }
  }

  @Action(OpenChatGroup)
  @ImmutableContext()
  openChatGroup(
    { getState, setState }: StateContext<ChatStateModel>,
    {
      chatGroupId,
      chatGroupName,
      isUncreatedTransportTenderChat,
    }: OpenChatGroup
  ) {
    setState((state: ChatStateModel) => {
      state.isChatExpanded = true;
      return state;
    });

    if (getState().chatGroups[chatGroupId]) {
      return of(getState().chatGroups[chatGroupId]);
    } else {
      // If not - open a group chat instantly and fetch info about the chat
      const chatGroup: ChatGroup = {
        id: chatGroupId,
        name: chatGroupName,
        type: {
          code: GroupChatTypes.TENDER,
        },
        status: {
          code: EntityStatuses.UNLOADED,
        },
      };

      if (isUncreatedTransportTenderChat) {
        chatGroup.status.code = EntityStatuses.UNCREATED;
      }

      setState((state: ChatStateModel) => {
        state.chatGroups[chatGroup.id] = chatGroup;
        return state;
      });

      if (!isUncreatedTransportTenderChat) {
        this.store.dispatch(new LoadNonPrivateChat(chatGroup.id));
      }

      return of(chatGroup);
    }
  }

  @Action(LoadConsultantChatGroup)
  @ImmutableContext()
  loadConsultantChatGroup({ setState }: StateContext<ChatStateModel>) {
    return this.chatGroupsService.getSpecialChatGroup().pipe(
      tap((chatGroup: ChatGroup) => {
        setState((state: ChatStateModel) => {
          state.chatGroups[chatGroup.id] =
            state.chatGroups[state.consultantChatGroupID];

          if (chatGroup.id !== state.consultantChatGroupID) {
            delete state.chatGroups[state.consultantChatGroupID];
          }
          if (!state.chatGroups[chatGroup.id]) {
            state.chatGroups[chatGroup.id] = {};
          }
          state.chatGroups[chatGroup.id].id = chatGroup.id;
          state.chatGroups[chatGroup.id].lastMessage = chatGroup.lastMessage;
          state.chatGroups[chatGroup.id].status = chatGroup.status;
          state.chatGroups[chatGroup.id].date = chatGroup.date;
          state.consultantChatGroupID = chatGroup.id;
          return state;
        });
      }),
      catchError((error: any) => {
        if (error instanceof NotFound) {
          setState((state: ChatStateModel) => {
            state.chatGroups[state.consultantChatGroupID].status.code =
              EntityStatuses.UNCREATED;
            return state;
          });
          return of(null);
        }
        return throwError(() => error);
      })
    );
  }

  @Action(OpenExistingChatGroup)
  @ImmutableContext()
  OpenExistingChatGroup(
    { getState, setState }: StateContext<ChatStateModel>,
    { chatGroupId }: OpenChatGroup
  ) {
    setState((state: ChatStateModel) => {
      state.isChatExpanded = true;
      return state;
    });

    if (getState().chatGroups[chatGroupId]) {
      return of(getState().chatGroups[chatGroupId]);
    } else {
      return this.chatGroupsService.getChatGroup(chatGroupId).pipe(
        tap((chatGroup: ChatGroup) => {
          setState((state: ChatStateModel) => {
            state.chatGroups[chatGroup.id] = chatGroup;
            return state;
          });
        })
      );
    }
  }

  @Action(UpdatedUserContactRelation)
  @ImmutableContext()
  updatedUserContactRelation(
    { setState }: StateContext<ChatStateModel>,
    { contact }: UpdatedUserContactRelation
  ) {
    return setState((state: ChatStateModel) => {
      // Try to find private chat with the selected person
      const key: string = Object.keys(state.chatGroups).find((key: string) => {
        return (
          state.chatGroups[key].type.code === GroupChatTypes.PRIVATE &&
          state.chatGroups[key].member.id === contact.id
        );
      });

      // If private chat is not found - stop processing
      if (!key) {
        return state;
      }

      // Update private chat
      state.chatGroups[key].member.isContact = contact.isContact;

      return state;
    });
  }

  @Action(UpdatedUserBlockRelation)
  @ImmutableContext()
  updatedUserBlockRelation(
    { setState }: StateContext<ChatStateModel>,
    { user }: UpdatedUserBlockRelation
  ) {
    setState((state: ChatStateModel) => {
      // Try to find private chat with the selected person
      const key: string = Object.keys(state.chatGroups).find((key: string) => {
        return (
          state.chatGroups[key].type.code === GroupChatTypes.PRIVATE &&
          state.chatGroups[key].member.id === user.id
        );
      });

      // If private chat is not found - stop processing
      if (!key) {
        return state;
      }

      // Update private chat
      state.chatGroups[key].member.isContact = user.isContact;
      state.chatGroups[key].member.isBlacklist = user.isBlacklist;

      // Close current private chat with this person, if it is open
      if (user.isBlacklist) {
        this.store.dispatch(
          new PrivateChatAddedToBlackList(state.chatGroups[key].id)
        );
      }

      return state;
    });
  }

  @Action([FetchChatGroupMessages, FetchMoreChatGroupMessages], {
    cancelUncompleted: true,
  })
  @ImmutableContext()
  fetchChatGroupMessages(
    { getState, setState }: StateContext<ChatStateModel>,
    action: FetchChatGroupMessages | FetchMoreChatGroupMessages
  ) {
    const currentUserID: number = this.store.selectSnapshot(
      AuthSelectors.user_id
    );

    const chatGroupId: number = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    const chatGroup: ChatGroup = getState().chatGroups[chatGroupId];

    if (action instanceof FetchChatGroupMessages && chatGroup.loaded) {
      return;
    }

    return this.chatGroupMessagesService
      .getChatGroupMessages(
        chatGroupId,
        chatGroup.chatMessages?.dataModels.length
      )
      .pipe(
        tap((result: MultipleResults<ChatMessage>) => {
          setState((state: ChatStateModel) => {
            const currentChatGroup: ChatGroup = state.chatGroups[chatGroupId];
            let loadedUnreadMessagesCount: number = 0;
            if (currentChatGroup.chatMessages) {
              // Prevent from duplicates
              currentChatGroup.chatMessages.dataModels =
                currentChatGroup.chatMessages.dataModels.concat(
                  // new models equals to
                  // current models
                  result.dataModels
                    .filter((chatMessageToAdd: ChatMessage) => {
                      // plus filtered new models
                      return !~currentChatGroup.chatMessages.dataModels?.findIndex(
                        // filtering: new chat message
                        (currentChatMesage: ChatMessage) => {
                          // in the existing chat messages
                          return currentChatMesage.id === chatMessageToAdd.id;
                        }
                      );
                    })
                    .map((chatMessageToAdd: ChatMessage) => {
                      if (
                        chatMessageToAdd.isRead === false &&
                        chatMessageToAdd.user.id !== currentUserID
                      ) {
                        loadedUnreadMessagesCount++;
                        chatMessageToAdd.isRead = true;
                      }
                      return chatMessageToAdd;
                    })
                );

              currentChatGroup.chatMessages.count += result.count;
              currentChatGroup.chatMessages.totalCount = result.totalCount;
            } else {
              result.dataModels.forEach((chatMessage: ChatMessage) => {
                if (
                  chatMessage.isRead === false &&
                  chatMessage.user.id !== currentUserID
                ) {
                  loadedUnreadMessagesCount++;
                }
              });
              currentChatGroup.chatMessages = result;
            }

            currentChatGroup.unreadMessageCount -= loadedUnreadMessagesCount;

            this.store.dispatch(
              new SetUnreadMessagesCountInTenderChat(
                currentChatGroup.id,
                currentChatGroup.unreadMessageCount
              )
            );

            currentChatGroup.loaded = true;
            return state;
          });
        })
      );
  }

  // @Action(InsertChatGroup)
  // @ImmutableContext()
  // insertChatGroup(
  //   { setState }: StateContext<ChatStateModel>,
  //   { chatGroup }: InsertChatGroup
  // ) {
  //   return setState((state: ChatStateModel) => {
  //     if (!state.chatGroups[chatId].chatMessages) {
  //       state.chatGroups[chatId].chatMessages = {
  //         dataModels: [],
  //         count: 0,
  //         totalCount: 0,
  //       };
  //     }
  //     state.chatGroups[chatGroup.id] = chatGroup;
  //     return state;
  //   });
  // }

  @Action(InsertChatMessage)
  @ImmutableContext()
  insertChatMessage(
    { getState, setState }: StateContext<ChatStateModel>,
    { chatId, message, isOutgoing, increaseUnreadCount }: InsertChatMessage
  ) {
    const currentUserId = this.store.selectSnapshot(AuthSelectors.user_id);
    setState((state: ChatStateModel) => {
      if (!state.chatGroups[chatId].chatMessages) {
        state.chatGroups[chatId].chatMessages = {
          dataModels: [],
          count: 0,
          totalCount: 0,
        };
      }
      state.chatGroups[chatId].chatMessages.dataModels.unshift(message);
      if (!message.status || message.status.code === EntityStatuses.ACTIVE) {
        state.chatGroups[message.chatId].lastMessage = message;
      }

      if (isOutgoing) {
        this.chatGroupMessagesService.playSendMessageSound();
      } else {
        if (message.user.id !== currentUserId) {
          this.chatGroupMessagesService.playReceiveMessageSound();
        }
      }

      return state;
    });

    // Only for received chat messages
    if (isOutgoing) {
      return;
    }

    const selectedChatGroupId: number = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    if (getState().isChatExpanded && selectedChatGroupId === chatId) {
      return this.chatGroupMessagesService.markMessagesAsRead(chatId, [
        message.id,
      ]);
    } else {
      return setState((state: ChatStateModel) => {
        if (increaseUnreadCount) {
          if (state.chatGroups[chatId].unreadMessageCount) {
            state.chatGroups[chatId].unreadMessageCount++;
          } else {
            state.chatGroups[chatId].unreadMessageCount = 1;
          }
        }

        if (state.chatGroups[chatId].type.code === GroupChatTypes.TENDER) {
          this.store.dispatch(
            new SetUnreadMessagesCountInTenderChat(
              chatId,
              state.chatGroups[chatId].unreadMessageCount
            )
          );
        }

        return state;
      });
    }
  }

  @Action(RemoveChatMessage)
  @ImmutableContext()
  removeChatMessage(
    { setState }: StateContext<ChatStateModel>,
    { chatId, messageId }: RemoveChatMessage
  ) {
    return setState((state: ChatStateModel) => {
      // Find the message that must be removed
      const chatMessage: ChatMessage = state.chatGroups[
        chatId
      ].chatMessages.dataModels?.find((chatMessage: ChatMessage) => {
        return chatMessage.id !== messageId;
      });

      if (!chatMessage) {
        console.warn(`
          Message that must be removed - was not found
          Chat ID: ${chatId}, Message ID: ${messageId}
        `);
        return state;
      }

      // Unsaved chat messages that are being removed - should not decrease the count of total messages
      if (
        !chatMessage.status ||
        chatMessage.status.code === EntityStatuses.ACTIVE
      ) {
        state.chatGroups[chatId].chatMessages.count--;
        state.chatGroups[chatId].chatMessages.totalCount--;
      }

      // Remove the mssage
      state.chatGroups[chatId].chatMessages.dataModels = state.chatGroups[
        chatId
      ].chatMessages.dataModels.filter((chatMessage: ChatMessage) => {
        return chatMessage.id !== messageId;
      });

      return state;
    });
  }

  @Action(UpdateChatMessage)
  @ImmutableContext()
  updateChatMessage(
    { setState }: StateContext<ChatStateModel>,
    { chatId, messageId, message }: UpdateChatMessage
  ) {
    const currentUserID: number = this.store.selectSnapshot(
      AuthSelectors.user_id
    );

    return setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatId];
      const currentMessage: ChatMessage =
        chatGroup.chatMessages.dataModels?.find((chatMessage: ChatMessage) => {
          return chatMessage.id === messageId;
        });

      currentMessage.id = message.id;
      currentMessage.date.createdAt = message.date.createdAt;
      delete currentMessage.status;

      chatGroup.chatMessages.count++;
      chatGroup.chatMessages.totalCount++;

      if (chatGroup.type.code !== GroupChatTypes.SPECIAL) {
        chatGroup.date.updatedAt = message.date.createdAt;
      }
      chatGroup.lastMessage = {
        id: currentMessage.id,
        message: currentMessage.message,
        date: { createdAt: message.date.createdAt },
        isRead: currentMessage.isRead,
        user: {
          id: currentUserID,
        },
      };

      if (
        chatGroup.type.code !== GroupChatTypes.SPECIAL &&
        chatGroup.type.code !== GroupChatTypes.TENDER
      ) {
        this.store.dispatch(
          new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
        );
      }

      if (chatGroup.type.code === GroupChatTypes.TENDER) {
        this.store.dispatch(
          new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
        );
      }

      return state;
    });
  }

  @Action(OpenPrivateChat)
  @ImmutableContext()
  openPrivateChat(
    { getState, setState }: StateContext<ChatStateModel>,
    { user }: OpenPrivateChat
  ) {
    const currentUserId = this.store.selectSnapshot(AuthSelectors.user_id);

    // If the private chat is already loaded
    let chatGroup: ChatGroup = Object.values(getState().chatGroups).find(
      (chatGroup: ChatGroup) => {
        return (
          chatGroup.type.code === GroupChatTypes.PRIVATE &&
          chatGroup.member.id === user.id
        );
      }
    );

    // If not - open a private chat instantly and fetch info about private chat
    if (!chatGroup) {
      const uncreatedPrivateChatID: number =
        this.chatGroupsService.getUncreatedID(user.id, GroupChatTypes.PRIVATE);

      chatGroup = {
        id: uncreatedPrivateChatID,
        userId: currentUserId,
        name: (
          user.first_name +
          ' ' +
          user.middle_name +
          ' ' +
          user.last_name
        ).trim(),
        avatar: user.avatar,
        type: {
          code: GroupChatTypes.PRIVATE,
        },
        unreadMessageCount: 0,
        date: {
          updatedAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        },
        member: {
          id: user.id,
          avatar: user.avatar,
          isContact: user.isContact,
          isBlacklist: user.isBlacklist,
        },
        status: {
          code: EntityStatuses.UNKNOWN,
        },
      };

      setState((state: ChatStateModel) => {
        state.chatGroups[chatGroup.id] = chatGroup;
        return state;
      });

      this.store.dispatch(new LoadPrivateChat(user.id));
    }

    this.store.dispatch(new OpenChatGroup(chatGroup.id));
  }

  @Action(CreateTransportTenderChatGroup)
  @ImmutableContext()
  createTransportTenderChatGroup(
    { setState }: StateContext<ChatStateModel>,
    { transportTenderId }: CreateTransportTenderChatGroup
  ) {
    const uncreatedTransportTenderChatID: number =
      this.chatGroupsService.getUncreatedID(
        transportTenderId,
        GroupChatTypes.TENDER
      );

    setState((state: ChatStateModel) => {
      state.chatGroups[uncreatedTransportTenderChatID].status.code =
        EntityStatuses.IS_BEING_CREATED;
      return state;
    });

    return this.chatGroupsService
      .createTransportTenderChat(transportTenderId)
      .pipe(
        catchError((error: any) => {
          setState((state: ChatStateModel) => {
            state.chatGroups[uncreatedTransportTenderChatID].status.code =
              EntityStatuses.UNCREATED;
            return state;
          });
          return throwError(() => error);
        }),
        tap((chatGroup: ChatGroup) => {
          setState((state: ChatStateModel) => {
            chatGroup.loaded = true;
            state.chatGroups[chatGroup.id] = chatGroup;
            state.chatGroups[chatGroup.id].chatMessages =
              state.chatGroups[uncreatedTransportTenderChatID].chatMessages;

            [...state.chatGroups[chatGroup.id].chatMessages.dataModels]
              .reverse()
              .forEach((chatMessage: ChatMessage) => {
                this.store.dispatch(
                  new SaveChatMessage(
                    chatGroup.id,
                    chatMessage.id,
                    chatMessage.message,
                    chatMessage?.files.map((attachment: MessageAttachment) => {
                      return attachment.id;
                    })
                  )
                );
              });

            return state;
          });

          const currentChatGroupId = this.store.selectSnapshot(
            ChatSelectedChatGroupSelectors.selectedChatGroupId
          );

          if (currentChatGroupId === uncreatedTransportTenderChatID) {
            this.store.dispatch(new SelectChatGroup(chatGroup.id));
          }

          this.store.dispatch(
            new UpdateTransportTenderChatGroupID(
              transportTenderId,
              chatGroup.id
            )
          );

          setState((state: ChatStateModel) => {
            delete state.chatGroups[uncreatedTransportTenderChatID];
            return state;
          });
        })
      );
  }

  @Action(CreateConsultantChatGroup)
  @ImmutableContext()
  createConsultantChatGroup(
    { setState }: StateContext<ChatStateModel>,
    { uncreatedConsultantChatGroupID }: CreateConsultantChatGroup
  ) {
    setState((state: ChatStateModel) => {
      state.chatGroups[uncreatedConsultantChatGroupID].status.code =
        EntityStatuses.IS_BEING_CREATED;
      return state;
    });

    return this.chatGroupsService.createSpecialChatGroup().pipe(
      catchError((error: any) => {
        setState((state: ChatStateModel) => {
          state.chatGroups[uncreatedConsultantChatGroupID].status.code =
            EntityStatuses.UNCREATED;
          return state;
        });
        return throwError(() => error);
      }),
      tap((chatGroup: ChatGroup) => {
        setState((state: ChatStateModel) => {
          chatGroup.loaded = true;
          state.chatGroups[chatGroup.id] = chatGroup;
          state.chatGroups[chatGroup.id].chatMessages =
            state.chatGroups[uncreatedConsultantChatGroupID].chatMessages;

          state.consultantChatGroupID = chatGroup.id;
          [...state.chatGroups[chatGroup.id].chatMessages.dataModels]
            .reverse()
            .forEach((chatMessage: ChatMessage) => {
              this.store.dispatch(
                new SaveChatMessage(
                  chatGroup.id,
                  chatMessage.id,
                  chatMessage.message,
                  chatMessage?.files.map((attachment: MessageAttachment) => {
                    return attachment.id;
                  })
                )
              );
            });

          return state;
        });

        const currentChatGroupId = this.store.selectSnapshot(
          ChatSelectedChatGroupSelectors.selectedChatGroupId
        );

        if (currentChatGroupId === uncreatedConsultantChatGroupID) {
          this.store.dispatch(new SelectChatGroup(chatGroup.id));
        }

        setState((state: ChatStateModel) => {
          delete state.chatGroups[uncreatedConsultantChatGroupID];
          return state;
        });
      })
    );
  }

  @Action(LoadNonPrivateChat)
  @ImmutableContext()
  loadNonPrivateChat(
    { setState }: StateContext<ChatStateModel>,
    { chatGroupId }: LoadNonPrivateChat
  ) {
    // Is used, for exmaple, when a tender chat needs to be opened,
    // but it does not present in the tender chats/my chats lists (is not loaded into memory)
    return this.chatGroupsService.getChatGroup(chatGroupId).pipe(
      tap((chatGroup: ChatGroup) => {
        // Group chat is loaded
        setState((state: ChatStateModel) => {
          const chatMessages: MultipleResults<ChatMessage> = state.chatGroups[
            chatGroup.id
          ].chatMessages
            ? {
                ...state.chatGroups[chatGroup.id].chatMessages,
              }
            : null;

          state.chatGroups[chatGroup.id] = chatGroup;

          if (!chatMessages) {
            return state;
          }

          // Add messages that could be added to the chat, while it was loading
          // Note: the messages from this chat are not loaded, yet! The messages will be loaded, when the Fetch action will be performed
          // Avoid duplicates!!!
          // (Visally add)
          state.chatGroups[chatGroup.id].chatMessages = chatMessages;

          // Save the messages
          [...state.chatGroups[chatGroup.id].chatMessages.dataModels]
            .reverse()
            .forEach((chatMessage: ChatMessage) => {
              this.store.dispatch(
                new SaveChatMessage(
                  chatGroup.id,
                  chatMessage.id,
                  chatMessage.message,
                  chatMessage?.files.map((attachment: MessageAttachment) => {
                    return attachment.id;
                  })
                )
              );
            });

          return state;
        });
      })
    );
  }

  @Action(LoadPrivateChat)
  @ImmutableContext()
  loadPrivateChat(
    { setState }: StateContext<ChatStateModel>,
    { userID }: LoadPrivateChat
  ) {
    return this.chatGroupsService.getPrivateChat(userID).pipe(
      tap((chatGroup: ChatGroup) => {
        const uncreatedPrivateChatID: number =
          this.chatGroupsService.getUncreatedID(userID, GroupChatTypes.PRIVATE);

        // Private chat is not loaded, bacause it is not created, yet
        if (!chatGroup) {
          setState((state: ChatStateModel) => {
            if (state.chatGroups[uncreatedPrivateChatID]) {
              state.chatGroups[uncreatedPrivateChatID].status.code =
                EntityStatuses.UNCREATED;
            } else {
              console.warn(`
                Chat that should exist does not exist
                Chat ID: ${uncreatedPrivateChatID}
              `);
            }

            return state;
          });
          return;
        }

        // Private chat is loaded
        setState((state: ChatStateModel) => {
          state.chatGroups[chatGroup.id] = chatGroup;

          if (!state.chatGroups[uncreatedPrivateChatID].chatMessages) {
            return state;
          }

          // Add messages that could be added to the chat, while it was in UNKNOWN state (during loading the private chat group)
          // Note: the messages from this chat are not loaded, yet! The messages will be loaded, when the Fetch action will be performed
          // Avoid duplicates!!!
          // (Visally add)
          state.chatGroups[chatGroup.id].chatMessages =
            state.chatGroups[uncreatedPrivateChatID].chatMessages;

          // Save the messages
          [...state.chatGroups[chatGroup.id].chatMessages.dataModels]
            .reverse()
            .forEach((chatMessage: ChatMessage) => {
              this.store.dispatch(
                new SaveChatMessage(
                  chatGroup.id,
                  chatMessage.id,
                  chatMessage.message,
                  chatMessage?.files.map((attachment: MessageAttachment) => {
                    return attachment.id;
                  })
                )
              );
            });

          return state;
        });

        // Open the loaded private chat group
        this.store.dispatch(new OpenChatGroup(chatGroup.id));

        // Remove chat group with uncreatedPrivateChatID
        setState((state: ChatStateModel) => {
          delete state.chatGroups[uncreatedPrivateChatID];
          return state;
        });
      })
    );
  }

  @Action(LikeMessage)
  @ImmutableContext()
  likeMessage(
    { setState }: StateContext<ChatStateModel>,
    { messageID, chatFeedback }: LikeMessage
  ) {
    const chatID = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    const userID = this.store.selectSnapshot(AuthSelectors.user_id);

    const fakeID: number = +`-${chatID}.${userID}`;

    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];
      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to insert message feedback
        `);
        return state;
      }

      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );
      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to insert message feedback
        `);
        return state;
      }

      // Create new message feedback
      const messageFeedback: MessageFeedback = {
        id: fakeID,
        userId: userID,
        feedbackId: chatFeedback.id,
        feedback: chatFeedback.smiley,
        status: {
          code: EntityStatuses.UNCREATED,
        },
      };

      // Insert new message feedback
      if (!chatMessage.feedback) {
        chatMessage.feedback = [];
      }

      chatMessage.feedback.push(messageFeedback);

      return state;
    });

    return this.chatFeedbackService
      .likeMessage(chatID, messageID, chatFeedback.id)
      .pipe(
        tap((messageFeedback: MessageFeedback) => {
          this.store.dispatch(
            new LikeMessageSuccess(chatID, messageID, fakeID, messageFeedback)
          );
        }),
        catchError((error) => {
          this.store.dispatch(new LikeMessageFailed(chatID, messageID, fakeID));
          return throwError(() => error);
        })
      );
  }

  @Action(LikeMessageSuccess)
  @ImmutableContext()
  likeMessageSuccess(
    { setState }: StateContext<ChatStateModel>,
    {
      chatID,
      messageID,
      messageFeedbackID,
      messageFeedback,
    }: LikeMessageSuccess
  ) {
    setState((state: ChatStateModel) => {
      // Get chat group
      const chatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to update message feedback ID and status, and chat group updatedAt
        `);
        return state;
      }

      chatGroup.date.updatedAt = messageFeedback.date.createdAt;

      // Get chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );

      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to update message feedback ID and status
        `);
        return state;
      }

      // Get chat message feedback
      const currentMessageFeedback: MessageFeedback = chatMessage.feedback.find(
        (feedback: MessageFeedback) => {
          return feedback.id === messageFeedbackID;
        }
      );

      if (!currentMessageFeedback) {
        console.warn(`
          Unable to find a chat group message feedback
          Chat ID: ${chatID}, Message ID: ${messageID}, Feedback ID: ${messageFeedbackID}
          -> Unable to update message feedback ID and status
        `);
        return state;
      }

      currentMessageFeedback.id = messageFeedback.id;
      delete currentMessageFeedback.status;

      if (
        chatGroup.type.code !== GroupChatTypes.SPECIAL &&
        chatGroup.type.code !== GroupChatTypes.TENDER
      ) {
        this.store.dispatch(
          new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
        );
      }

      if (chatGroup.type.code === GroupChatTypes.TENDER) {
        this.store.dispatch(
          new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
        );
      }

      return state;
    });
  }

  @Action(LikeMessageFailed)
  @ImmutableContext()
  likeMessageFailed(
    { setState }: StateContext<ChatStateModel>,
    { chatID, messageID, messageFeedbackID }: LikeMessageFailed
  ) {
    setState((state: ChatStateModel) => {
      // Get chat group
      const chatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to remove message feedback
        `);
        return state;
      }

      // Get chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );

      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to remove message feedback
        `);
        return state;
      }

      // Remove chat message feedback
      chatMessage.feedback = chatMessage.feedback.filter(
        (feedback: MessageFeedback) => {
          return feedback.id !== messageFeedbackID;
        }
      );

      return state;
    });
  }
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  @Action(RemoveMessageFeedback)
  @ImmutableContext()
  removeMessageFeedback(
    { setState }: StateContext<ChatStateModel>,
    { messageID, messageFeedbackID }: RemoveMessageFeedback
  ) {
    const chatID = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];
      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to mark message feedback as it is being deleted
        `);
        return state;
      }

      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );
      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to mark message feedback as it is being deleted
        `);
        return state;
      }

      const currentMessageFeedback: MessageFeedback = chatMessage.feedback.find(
        (existingMessageFeedback: MessageFeedback) => {
          return existingMessageFeedback.id === messageFeedbackID;
        }
      );

      if (!currentMessageFeedback) {
        console.warn(`
          Unable to find a chat group message feedback
          Chat ID: ${chatID}, Message ID: ${messageID}, Feedback ID: ${messageFeedbackID}
          -> Unable to restore message feedback
        `);
        return state;
      }

      if (!currentMessageFeedback.status) {
        currentMessageFeedback.status = {};
      }
      currentMessageFeedback.status.code = EntityStatuses.IS_BEING_DELETED;

      return state;
    });

    return this.chatFeedbackService
      .removeMessageFeedback(chatID, messageID, messageFeedbackID)
      .pipe(
        tap((isDeleted: boolean) => {
          if (isDeleted) {
            this.store.dispatch(
              new RemoveMessageFeedbackSuccess(
                chatID,
                messageID,
                messageFeedbackID
              )
            );
          } else {
            this.store.dispatch(
              new RemoveMessageFeedbackFailed(
                chatID,
                messageID,
                messageFeedbackID
              )
            );
          }
        }),
        catchError((error) => {
          this.store.dispatch(
            new RemoveMessageFeedbackFailed(
              chatID,
              messageID,
              messageFeedbackID
            )
          );
          return throwError(() => error);
        })
      );
  }
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  @Action(RemoveMessageFeedbackSuccess)
  @ImmutableContext()
  removeMessageFeedbackSuccess(
    { setState }: StateContext<ChatStateModel>,
    { chatID, messageID, messageFeedbackID }: RemoveMessageFeedbackSuccess
  ) {
    setState((state: ChatStateModel) => {
      // Get chat group
      const chatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to remove message feedback
        `);
        return state;
      }

      // Get chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );

      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to remove message feedback
        `);
        return state;
      }

      // Remove chat message feedback
      chatMessage.feedback = chatMessage.feedback.filter(
        (feedback: MessageFeedback) => {
          return feedback.id !== messageFeedbackID;
        }
      );

      return state;
    });
  }

  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  @Action(RemoveMessageFeedbackFailed)
  @ImmutableContext()
  removeMessageFeedbackFailed(
    { setState }: StateContext<ChatStateModel>,
    { chatID, messageID, messageFeedbackID }: RemoveMessageFeedbackFailed
  ) {
    setState((state: ChatStateModel) => {
      // Get chat group
      const chatGroup = state.chatGroups[chatID];
      if (!chatGroup) {
        console.warn(`
          Unable to find a chat group
          Chat ID: ${chatID}
          -> Unable to restore message feedback
        `);
        return state;
      }

      // Get chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );

      if (!chatMessage) {
        console.warn(`
          Unable to find a chat group message
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to restore message feedback
        `);
        return state;
      }

      // Get chat message feedback
      const currentMessageFeedback: MessageFeedback = chatMessage.feedback.find(
        (feedback: MessageFeedback) => {
          return feedback.id === messageFeedbackID;
        }
      );

      if (!currentMessageFeedback) {
        console.warn(`
          Unable to find a chat group message feedback
          Chat ID: ${chatID}, Message ID: ${messageID}, Feedback ID: ${messageFeedbackID}
          -> Unable to restore message feedback
        `);
        return state;
      }

      delete currentMessageFeedback.status;

      return state;
    });
  }
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////

  @Action(TryDeleteChatMessage)
  @ImmutableContext()
  tryDeleteChatMessage(
    { setState }: StateContext<ChatStateModel>,
    { messageID }: TryDeleteChatMessage
  ) {
    const chatID = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    if (!chatID) {
      console.warn(`
        Chat group of a messages that must be deleted was not found
        Message ID: ${messageID}
        -> Unable to delete the message
      `);
      return;
    }

    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Chat group with the selected chat group id was not found
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to mark that the message is being deleted
        `);
        return state;
      }

      const chatMessage: ChatMessage = chatGroup.chatMessages.dataModels?.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageID;
        }
      );

      if (!chatMessage) {
        console.warn(`
          Chat message not found
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to mark that the message is being deleted
        `);
        return state;
      }

      if (!chatMessage.status) {
        chatMessage.status = {};
      }

      chatMessage.status.code = EntityStatuses.IS_BEING_DELETED;
      return state;
    });

    return this.chatGroupMessagesService.deleteMessage(chatID, messageID).pipe(
      tap((result: boolean) => {
        if (result) {
          this.store.dispatch(new DeleteChatMessageSuccess(chatID, messageID));
        } else {
          this.store.dispatch(new DeleteChatMessageFailed(chatID, messageID));
        }
      }),
      catchError((error) => {
        this.store.dispatch(new DeleteChatMessageFailed(chatID, messageID));
        return throwError(() => error);
      })
    );
  }

  @Action(DeleteChatMessageSuccess)
  @ImmutableContext()
  deleteChatMessageSuccess(
    { setState }: StateContext<ChatStateModel>,
    { chatID, messageID }: DeleteChatMessageSuccess
  ) {
    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Chat group is not found
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to remove the message from the chat group
        `);
        return state;
      }

      const lengthBefore = chatGroup.chatMessages.dataModels.length;
      chatGroup.chatMessages.dataModels =
        chatGroup.chatMessages.dataModels.filter((chatMessage: ChatMessage) => {
          return chatMessage.id !== messageID;
        });
      const diff = lengthBefore - chatGroup.chatMessages.dataModels.length;
      chatGroup.chatMessages.count -= diff;
      chatGroup.chatMessages.totalCount -= diff;
      if (chatGroup.chatMessages.count === 0) {
        delete chatGroup.lastMessage;
      } else {
        chatGroup.lastMessage = chatGroup.chatMessages.dataModels?.find(
          (chatMessage: ChatMessage) => {
            return (
              !chatMessage.status ||
              chatMessage.status.code === EntityStatuses.ACTIVE
            );
          }
        );
      }

      return state;
    });
  }

  @Action(DeleteChatMessageFailed)
  @ImmutableContext()
  deleteChatMessageFailed(
    { setState }: StateContext<ChatStateModel>,
    { chatID, messageID }: DeleteChatMessageFailed
  ) {
    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Chat group is not found
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to restore the message in the chat group
        `);
        return state;
      }

      const chatMessage: ChatMessage = state.chatGroups[
        chatID
      ].chatMessages.dataModels?.find((chatMessage: ChatMessage) => {
        return chatMessage.id === messageID;
      });

      if (!chatMessage) {
        console.warn(`
          Chat message not found
          Chat ID: ${chatID}, Message ID: ${messageID}
          -> Unable to restore the message in the chat group
        `);
        return state;
      }

      delete chatMessage.status;

      return state;
    });
  }

  @Action(ChatAddMessage)
  @ImmutableContext()
  addMessage(
    { getState }: StateContext<ChatStateModel>,
    { messageContent, attachments, contact }: ChatAddMessage
  ) {
    const user: User = this.store.selectSnapshot(AuthSelectors.user);
    const chatGroup: ChatGroup =
      getState().chatGroups[
        this.store.selectSnapshot(
          ChatSelectedChatGroupSelectors.selectedChatGroupId
        )
      ];

    const newMessage: ChatMessage = {
      id: -Date.now(),
      date: {
        createdAt: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      },

      message: messageContent,
      contact,
      files: attachments,
      user: {
        id: user.id,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
        avatar: user.avatar,
      },
      feedback: [],
      status: {
        code: EntityStatuses.UNCREATED,
      },
      isRead: false,
    };

    const replyMessage: ChatMessage = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.replyChatMessage
    );

    if (replyMessage) {
      newMessage.answeredMessage = replyMessage;
    }

    const forwardChatMessage: ChatMessage = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.forwardChatMessage
    );

    if (forwardChatMessage) {
      newMessage.forwardMessage = forwardChatMessage;
    }

    this.store.dispatch(new InsertChatMessage(chatGroup.id, newMessage, true));

    if (chatGroup.status?.code === EntityStatuses.UNCREATED) {
      if (chatGroup.id === getState().consultantChatGroupID) {
        this.store.dispatch(new CreateConsultantChatGroup(chatGroup.id));
      } else {
        const entityId: number =
          this.chatGroupsService.extractEntityIDfromUncreatedChatID(
            chatGroup.id
          );

        if (chatGroup.type.code === GroupChatTypes.PRIVATE) {
          this.store.dispatch(new CreatePrivateChat(entityId));
        } else {
          this.store.dispatch(new CreateTransportTenderChatGroup(entityId));
        }
      }
    } else if (
      !chatGroup.status ||
      chatGroup.status.code === EntityStatuses.ACTIVE
    ) {
      this.store.dispatch(
        new SaveChatMessage(
          chatGroup.id,
          newMessage.id,
          messageContent,
          attachments.map((attachment: MessageAttachment) => {
            return attachment.id;
          }),
          replyMessage?.id,
          forwardChatMessage?.id,
          contact?.id
        )
      );
    }
  }

  @Action(CreatePrivateChat)
  @ImmutableContext()
  createPrivateChat(
    { setState }: StateContext<ChatStateModel>,
    { userID }: CreatePrivateChat
  ) {
    const uncreatedPrivateChatID: number =
      this.chatGroupsService.getUncreatedID(userID, GroupChatTypes.PRIVATE);

    setState((state: ChatStateModel) => {
      state.chatGroups[uncreatedPrivateChatID].status.code =
        EntityStatuses.IS_BEING_CREATED;
      return state;
    });

    return this.chatGroupsService.createPrivateChat(userID).pipe(
      catchError((error: any) => {
        setState((state: ChatStateModel) => {
          state.chatGroups[uncreatedPrivateChatID].status.code =
            EntityStatuses.UNCREATED;
          return state;
        });
        return throwError(() => error);
      }),
      tap((chatGroup: ChatGroup) => {
        setState((state: ChatStateModel) => {
          chatGroup.loaded = true;
          state.chatGroups[chatGroup.id] = chatGroup;
          state.chatGroups[chatGroup.id].chatMessages =
            state.chatGroups[uncreatedPrivateChatID].chatMessages;

          [...state.chatGroups[chatGroup.id].chatMessages.dataModels]
            .reverse()
            .forEach((chatMessage: ChatMessage) => {
              this.store.dispatch(
                new SaveChatMessage(
                  chatGroup.id,
                  chatMessage.id,
                  chatMessage.message,
                  chatMessage?.files.map((attachment: MessageAttachment) => {
                    return attachment.id;
                  })
                )
              );
            });

          return state;
        });

        const currentChatGroupId = this.store.selectSnapshot(
          ChatSelectedChatGroupSelectors.selectedChatGroupId
        );

        if (currentChatGroupId === uncreatedPrivateChatID) {
          this.store.dispatch(new SelectChatGroup(chatGroup.id));
        }

        setState((state: ChatStateModel) => {
          delete state.chatGroups[uncreatedPrivateChatID];
          return state;
        });
      })
    );
  }

  @Action(SaveChatMessage)
  @ImmutableContext()
  saveChatMessage(
    {}: StateContext<ChatStateModel>,
    {
      chatID,
      messageID,
      messageContent,
      files,
      answeredMessageId,
      forwardMessageId,
      contactId,
    }: SaveChatMessage
  ) {
    return this.chatGroupMessagesService.addMessage(
      chatID,
      messageID,
      messageContent,
      files,
      answeredMessageId,
      forwardMessageId,
      contactId
    );
  }

  @Action(SaveChatMessageSuccess)
  @ImmutableContext()
  saveChatMessageSuccess(
    { setState }: StateContext<ChatStateModel>,
    { chatID, replaceMessageID, message }: SaveChatMessageSuccess
  ) {
    const currentUserID: number = this.store.selectSnapshot(
      AuthSelectors.user_id
    );

    setState((state: ChatStateModel) => {
      const chatGroup: ChatGroup = state.chatGroups[chatID];

      if (!chatGroup) {
        console.warn(`
          Chat group was not found
          Chat ID: ${chatID}
          -> Unable to set unreadMessagesCount (to 0)
        `);
        return state;
      }

      if (chatGroup.unreadMessageCount > 0) {
        // Mark all chat messages as read
        if (chatGroup.chatMessages) {
          chatGroup.chatMessages.dataModels.forEach(
            (chatMessage: ChatMessage) => {
              if (chatMessage.user.id !== currentUserID) {
                chatMessage.isRead = true;
              }
            }
          );
        }

        // Mark last message as read
        if (chatGroup.lastMessage?.user.id !== currentUserID) {
          chatGroup.lastMessage.isRead = true;
        }

        chatGroup.unreadMessageCount = 0;
        this.store.dispatch(new SetUnreadMessagesCountInTenderChat(chatID, 0));
      }

      return state;
    });

    this.store.dispatch(
      new UpdateChatMessage(chatID, replaceMessageID, message)
    );
  }

  @Action(SaveChatMessageFailed)
  @ImmutableContext()
  saveChatMessageFailed(
    {}: StateContext<ChatStateModel>,
    { chatID, messageID }: SaveChatMessageFailed
  ) {
    this.store.dispatch(new RemoveChatMessage(chatID, messageID));
  }

  // Chat groups CRUD
  @Action(TryCreateChatGroup)
  @ImmutableContext()
  tryCreateChatGroup(
    { setState }: StateContext<ChatStateModel>,
    { groupData }: TryCreateChatGroup
  ) {
    return this.chatGroupsService.createGroupChat(groupData).pipe(
      tap((chatGroup: ChatGroup) => {
        setState((state: ChatStateModel) => {
          chatGroup.loaded = true;
          state.chatGroups[chatGroup.id] = chatGroup;
          return state;
        });

        // I have added this check to be sure it is not a tender chat, but AFAIK it should not be
        if (chatGroup.type?.code !== GroupChatTypes.TENDER) {
          this.store.dispatch(
            new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
          );
        }

        this.store.dispatch(new CreateChatGroupSuccess(chatGroup));
      })
    );
  }

  @Action(TryEditChatGroup)
  @ImmutableContext()
  tryEditChatGroup(
    { setState }: StateContext<ChatStateModel>,
    { groupData }: TryEditChatGroup
  ) {
    return this.chatGroupsService.updateGroupChat(groupData).pipe(
      tap((chatGroup: ChatGroup) => {
        setState((state: ChatStateModel) => {
          state.chatGroups[groupData.id].name = chatGroup.name;
          state.chatGroups[groupData.id].avatar = chatGroup.avatar;
          state.chatGroups[groupData.id].date.updatedAt =
            chatGroup.date.updatedAt;
          return state;
        });

        // I have added this check to be sure it is not a tender chat, but AFAIK it should not be
        if (chatGroup.type?.code !== GroupChatTypes.TENDER) {
          this.store.dispatch(
            new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
          );
        }

        this.store.dispatch(new EditChatGroupSuccess(chatGroup));
      })
    );
  }

  @Action(TryDeleteChatGroup)
  @ImmutableContext()
  tryDeleteChatGroup(
    { setState }: StateContext<ChatStateModel>,
    { group }: TryDeleteChatGroup
  ) {
    return this.chatGroupsService.deleteGroupChat(group.id).pipe(
      tap((deleted: boolean) => {
        if (deleted) {
          this.store.dispatch(new DeleteChatGroupSuccess(group.id));
          setState((state: ChatStateModel) => {
            delete state.chatGroups[group.id];
            return state;
          });
        }
      })
    );
  }

  @Action(RemoveFromCache)
  @ImmutableContext()
  deleteChatGroupSuccess(
    { setState }: StateContext<ChatStateModel>,
    { chatGroupID }: RemoveFromCache
  ) {
    return setState((state: ChatStateModel) => {
      delete state.chatGroups[chatGroupID];
      return state;
    });
  }

  @Action([ReadChatGroupLoadedMessages])
  @ImmutableContext()
  readChatGroupLoadedMessages({
    getState,
    setState,
  }: StateContext<ChatStateModel>) {
    const chatGroupId: number = this.store.selectSnapshot(
      ChatSelectedChatGroupSelectors.selectedChatGroupId
    );

    const chatGroup: ChatGroup = getState().chatGroups[chatGroupId];

    if (
      !chatGroup.chatMessages ||
      chatGroup.chatMessages.dataModels.length === 0
    ) {
      return;
    }

    const currentUserID: number = this.store.selectSnapshot(
      AuthSelectors.user_id
    );

    const loadedUnreadMessages: ChatMessage[] =
      chatGroup.chatMessages.dataModels.filter((chatMessage: ChatMessage) => {
        return chatMessage.user.id !== currentUserID && !chatMessage.isRead;
      });

    if (loadedUnreadMessages.length === 0) {
      return;
    }

    setState((state: ChatStateModel) => {
      state.chatGroups[chatGroupId].chatMessages.dataModels.forEach(
        (chatMessage: ChatMessage) => {
          if (chatMessage.user.id !== currentUserID && !chatMessage.isRead) {
            chatMessage.isRead = true;
          }
          return chatMessage;
        }
      );
      state.chatGroups[chatGroupId].unreadMessageCount -=
        loadedUnreadMessages.length;

      this.store.dispatch(
        new SetUnreadMessagesCountInTenderChat(
          state.chatGroups[chatGroupId].id,
          state.chatGroups[chatGroupId].unreadMessageCount
        )
      );

      return state;
    });

    return this.chatGroupMessagesService
      .markMessagesAsRead(
        chatGroupId,
        loadedUnreadMessages.map((chatMessage: ChatMessage) => {
          return chatMessage.id;
        })
      )
      .pipe(
        catchError((error: any) => {
          setState((state: ChatStateModel) => {
            let restoreUnreadCount: number = 0;
            state.chatGroups[chatGroupId].chatMessages.dataModels
              .filter((chatMessage: ChatMessage) => {
                return loadedUnreadMessages
                  .map((loadedChatMessage: ChatMessage) => {
                    return loadedChatMessage.id;
                  })
                  .includes(chatMessage.id);
              })
              .map((chatMessage: ChatMessage) => {
                chatMessage.isRead = false;
                restoreUnreadCount++;
                return chatMessage;
              });

            if (restoreUnreadCount > 0) {
              state.chatGroups[chatGroupId].unreadMessageCount +=
                loadedUnreadMessages.length;

              this.store.dispatch(
                new SetUnreadMessagesCountInTenderChat(
                  state.chatGroups[chatGroupId].id,
                  state.chatGroups[chatGroupId].unreadMessageCount
                )
              );
            }

            return state;
          });

          return throwError(() => error);
        })
      );
  }

  // Mercure
  @Action(RTS_NewChatMessageReceived)
  @ImmutableContext()
  rtsNewChatMessageReceived(
    { getState, setState }: StateContext<ChatStateModel>,
    { message }: RTS_NewChatMessageReceived
  ) {
    const mySelf = this.store.selectSnapshot(AuthSelectors.user_id);

    let alreadyAdded = false;

    let increaseUnreadCount = true;

    // Load chat group, if it missing in the cache
    const cachedChatGroup: ChatGroup = getState().chatGroups[message.chatId];
    if (!cachedChatGroup) {
      return this.chatGroupsService.getChatGroupInMyChats(message.chatId).pipe(
        tap((chatGroup: ChatGroup) => {
          setState((state: ChatStateModel) => {
            // Better to re-check after the async query is completed
            if (!state.chatGroups[chatGroup.id]) {
              // Cache loaded chat group
              state.chatGroups[chatGroup.id] = chatGroup;
              increaseUnreadCount = false;
            } else {
              // Just update the updatedAt
              state.chatGroups[chatGroup.id].date.updatedAt =
                message.date.createdAt;
            }

            return state;
          });

          // Add to my chats (in case if missing)
          if (
            chatGroup.type.code !== GroupChatTypes.SPECIAL &&
            chatGroup.type.code !== GroupChatTypes.TENDER
          ) {
            this.store.dispatch(
              new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
            );
          }

          // Add to tenders chats (in case if missing)
          if (chatGroup.type.code === GroupChatTypes.TENDER) {
            this.store.dispatch(
              new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
            );
          }

          if (message.user.id === mySelf) {
            increaseUnreadCount = false;
          }

          // Proceed to Step 2 (add the message to the chat group)
          this.store.dispatch(
            new InsertChatMessage(
              message.chatId,
              message,
              false,
              increaseUnreadCount
            )
          );
        })
      );
    } else {
      if (
        cachedChatGroup.chatMessages?.dataModels.find(
          (chatMessage: ChatMessage) => {
            return chatMessage.id === message.id;
          }
        )
      ) {
        alreadyAdded = true;
      }
    }

    if (!alreadyAdded) {
      // Just update the updatedAt
      setState((state: ChatStateModel) => {
        state.chatGroups[message.chatId].date.updatedAt =
          message.date.createdAt;
        return state;
      });

      // Add to my chats (in case if missing)
      if (
        cachedChatGroup.type.code !== GroupChatTypes.SPECIAL &&
        cachedChatGroup.type.code !== GroupChatTypes.TENDER
      ) {
        this.store.dispatch(
          new AddChatGroupToMyChats(cachedChatGroup.id, cachedChatGroup.name)
        );
      }

      // Add to tenders chats (in case if missing)
      if (cachedChatGroup.type.code === GroupChatTypes.TENDER) {
        this.store.dispatch(
          new AddChatGroupToTendersChats(
            cachedChatGroup.id,
            cachedChatGroup.name
          )
        );
      }

      if (message.user.id === mySelf) {
        increaseUnreadCount = false;
      }

      // Proceed to Step 2 (add the message to the chat group)
      this.store.dispatch(
        new InsertChatMessage(
          message.chatId,
          message,
          false,
          increaseUnreadCount
        )
      );
    }
  }

  // Mercure
  @Action(RTS_ChatMessagesWereRead)
  @ImmutableContext()
  rtsChatMessagesWereRead(
    { setState }: StateContext<ChatStateModel>,
    { readMessages }: RTS_ChatMessagesWereRead
  ) {
    return setState((state: ChatStateModel) => {
      // Try to find chat group
      const chatGroup: ChatGroup = state.chatGroups[readMessages.chatId];
      if (!chatGroup) {
        return state;
      }

      // Mark last message as read, if needed
      if (
        chatGroup.lastMessage &&
        !!~readMessages.readMessageIds.indexOf(chatGroup.lastMessage.id)
      ) {
        chatGroup.lastMessage.isRead = true;
      }

      // Mark chat messages as read
      if (chatGroup.chatMessages) {
        chatGroup.chatMessages.dataModels
          .filter((chatMessage: ChatMessage) => {
            return !!~readMessages.readMessageIds.indexOf(chatMessage.id);
          })
          .map((chatMesssage: ChatMessage) => {
            chatMesssage.isRead = true;
          });
      }

      return state;
    });
  }

  // Mercure
  @Action(RTS_ChatMessageWasDeleted)
  @ImmutableContext()
  rtsChatMessageWasDeleted(
    { setState }: StateContext<ChatStateModel>,
    { deletedMessageData }: RTS_ChatMessageWasDeleted
  ) {
    /* @todo: shouldn't we just calc delta? async results may cause incorrect count (then we should)
       know, if a deleted message was unread
       actually, the logic of read/unread mesasges should be globally reworked, based on the last read message date,
       so, lets keep this todo for a better times.
    */
    this.store.dispatch(
      new SetUnreadMessagesCountInTenderChat(
        deletedMessageData.chatId,
        deletedMessageData.unreadMessageCount
      )
    );

    return setState((state: ChatStateModel) => {
      // Find chat group in memory
      const chatGroup = state.chatGroups[deletedMessageData.chatId];
      if (!chatGroup) {
        return;
      }
      // Is required to track if a chat message will be deleted (for updating count/totalCount properties)
      const initialCountOfMessages =
        chatGroup.chatMessages?.dataModels.length || 0;

      // Delete chat message
      if (initialCountOfMessages > 0) {
        chatGroup.chatMessages.dataModels =
          chatGroup.chatMessages.dataModels.filter(
            (chatMessage: ChatMessage) => {
              return chatMessage.id !== deletedMessageData.messageId;
            }
          );

        // Decrease count and total count
        chatGroup.chatMessages.count = -(
          initialCountOfMessages - chatGroup.chatMessages.dataModels.length
        );
        chatGroup.chatMessages.totalCount = -(
          initialCountOfMessages - chatGroup.chatMessages.dataModels.length
        );
      }

      if (chatGroup.lastMessage.id === deletedMessageData.messageId) {
        // Update last message and unread messages count
        chatGroup.lastMessage = deletedMessageData.lastMessage;
      }

      /* @todo: shouldn't we just calc delta? async results may cause incorrect count (then we should)
        know, if a deleted message was unread
        actually, the logic of read/unread mesasges should be globally reworked, based on the last read message date,
        so, lets keep this todo for a better times.
      */
      chatGroup.unreadMessageCount = deletedMessageData.unreadMessageCount;

      return state;
    });
  }

  // Mercure
  @Action(RTS_MessageFeedbackAdded)
  @ImmutableContext()
  rtsMessageFeedbackAdded(
    { getState, setState }: StateContext<ChatStateModel>,
    { messageFeedback }: RTS_MessageFeedbackAdded
  ) {
    let alreadyAdded = false;

    // Load chat group, if it missing in the cache
    const cachedChatGroup: ChatGroup =
      getState().chatGroups[messageFeedback.chatId];
    if (!cachedChatGroup) {
      return this.chatGroupsService
        .getChatGroupInMyChats(messageFeedback.chatId)
        .pipe(
          tap((chatGroup: ChatGroup) => {
            setState((state: ChatStateModel) => {
              // Better to re-check after the async query is completed
              if (!state.chatGroups[chatGroup.id]) {
                // Cache loaded chat group
                state.chatGroups[chatGroup.id] = chatGroup;
              } else {
                // Just update the updatedAt
                state.chatGroups[chatGroup.id].date.updatedAt =
                  messageFeedback.date.createdAt;
              }

              return state;
            });

            // Add to my chats (in case if missing)
            if (
              chatGroup.type.code !== GroupChatTypes.SPECIAL &&
              chatGroup.type.code !== GroupChatTypes.TENDER
            ) {
              this.store.dispatch(
                new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
              );
            }

            // Add to tenders chats (in case if missing)
            if (chatGroup.type.code === GroupChatTypes.TENDER) {
              this.store.dispatch(
                new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
              );
            }

            // Proceed to Step 2 (add the message to the chat group)
            this.store.dispatch(new InsertMessageFeedback(messageFeedback));
          })
        );
    } else {
      const chatMessage: ChatMessage =
        cachedChatGroup.chatMessages.dataModels?.find(
          (chatMessage: ChatMessage) => {
            return chatMessage.id === messageFeedback.messageId;
          }
        );

      if (chatMessage) {
        if (
          chatMessage.feedback.find((chatMessageFeedback: MessageFeedback) => {
            return chatMessageFeedback.id === messageFeedback.id;
          })
        ) {
          alreadyAdded = true;
        }
      }
    }

    if (!alreadyAdded) {
      // Just update the updatedAt
      setState((state: ChatStateModel) => {
        state.chatGroups[messageFeedback.chatId].date.updatedAt =
          messageFeedback.date.createdAt;
        return state;
      });

      // Add to my chats (in case if missing)
      if (
        cachedChatGroup.type.code !== GroupChatTypes.SPECIAL &&
        cachedChatGroup.type.code !== GroupChatTypes.TENDER
      ) {
        this.store.dispatch(
          new AddChatGroupToMyChats(cachedChatGroup.id, cachedChatGroup.name)
        );
      }

      // Add to tenders chats (in case if missing)
      if (cachedChatGroup.type.code === GroupChatTypes.TENDER) {
        this.store.dispatch(
          new AddChatGroupToTendersChats(
            cachedChatGroup.id,
            cachedChatGroup.name
          )
        );

        // Proceed to Step 2 (add the message to the chat group)
        this.store.dispatch(new InsertMessageFeedback(messageFeedback));
      }
    }
  }

  // Mercure
  @Action(InsertMessageFeedback)
  @ImmutableContext()
  insertMessageFeedback(
    { setState }: StateContext<ChatStateModel>,
    { messageFeedback }: InsertMessageFeedback
  ) {
    return setState((state: ChatStateModel) => {
      // Try to find chat group
      const chatGroup = state.chatGroups[messageFeedback.chatId];
      if (!chatGroup) {
        console.warn(`
          Following the business logic, a hat group should exist here, but it is missing
          Chat ID: ${messageFeedback.chatId}
          -> Unable to add message feedback
        `);
        return state;
      }

      delete messageFeedback.date;

      // Try to find chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages?.dataModels.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageFeedback.messageId;
        }
      );
      if (!chatMessage) {
        // ok, because the mssage may not be loaded yet, and shouldn't be force loaded for such situations
        return state;
      }

      // Add feedback
      if (!chatMessage.feedback) {
        chatMessage.feedback = [];
      }
      chatMessage.feedback.push(messageFeedback);

      return state;
    });
  }

  // Mercure
  @Action(RTS_MessageFeedbackRemoved)
  @ImmutableContext()
  rtsMessageFeedbackRemoved(
    { setState }: StateContext<ChatStateModel>,
    { messageFeedback }: RTS_MessageFeedbackRemoved
  ) {
    return setState((state: ChatStateModel) => {
      // Try to find chat group
      const chatGroup = state.chatGroups[messageFeedback.chatId];
      if (!chatGroup) {
        return state;
      }

      // Try to find chat message
      const chatMessage: ChatMessage = chatGroup.chatMessages?.dataModels.find(
        (chatMessage: ChatMessage) => {
          return chatMessage.id === messageFeedback.messageId;
        }
      );
      if (!chatMessage) {
        return state;
      }

      // Remove message feedback
      chatMessage.feedback = chatMessage.feedback.filter(
        (currentMessageFeedback: MessageFeedback) => {
          return currentMessageFeedback.id !== messageFeedback.id;
        }
      );

      return state;
    });
  }

  @Action(RTS_ChatGroupCreated)
  @ImmutableContext()
  rtsChatGroupCreated(
    {}: StateContext<ChatStateModel>,
    { chatGroup }: RTS_ChatGroupCreated
  ) {
    this.store.dispatch(new CacheChatGroups([chatGroup]));

    // Is current(logeed in) user a consultant
    const isConsultant: boolean = this.store.selectSnapshot(
      AuthSelectors.isConsultant
    );

    // Add to my chats
    // a consultant cannot chat with a consultant
    // so there can be only situations where current user is a consultant (isConsultant = true)
    // or the interlocutor can be a consultant: chatGroup.type.code === GroupChatTypes.SPECIAL
    if (
      // if current user is a consultant
      isConsultant ||
      // or the chat is not is not with a consultant
      // and is not a tender chat
      (chatGroup.type.code !== GroupChatTypes.SPECIAL &&
        chatGroup.type.code !== GroupChatTypes.TENDER)
    ) {
      this.store.dispatch(
        new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
      );
    }

    // Add to tenders chats
    if (chatGroup.type.code === GroupChatTypes.TENDER) {
      this.store.dispatch(
        new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
      );
    }
  }

  @Action(RTS_ChatGroupEdited)
  @ImmutableContext()
  rtsChatGroupEdited(
    { getState, setState }: StateContext<ChatStateModel>,
    { chatGroup }: RTS_ChatGroupEdited
  ) {
    const currentChatGroup: ChatGroup = getState().chatGroups[chatGroup.id];
    if (currentChatGroup) {
      return setState((state: ChatStateModel) => {
        const currentChatGroup: ChatGroup = state.chatGroups[chatGroup.id];
        currentChatGroup.name = chatGroup.name;
        currentChatGroup.avatar = chatGroup.avatar;
        currentChatGroup.date.updatedAt = chatGroup.date.updatedAt;

        // Add to my chats if:
        // - the chat is not with a consultant
        // - and the chat is not a tender chat
        if (
          currentChatGroup.type.code !== GroupChatTypes.SPECIAL &&
          currentChatGroup.type.code !== GroupChatTypes.TENDER
        ) {
          this.store.dispatch(
            new AddChatGroupToMyChats(
              currentChatGroup.id,
              currentChatGroup.name
            )
          );
        }

        // Add to tenders chats
        if (currentChatGroup.type.code === GroupChatTypes.TENDER) {
          this.store.dispatch(
            new AddChatGroupToTendersChats(
              currentChatGroup.id,
              currentChatGroup.name
            )
          );
        }
        return state;
      });
    } else {
      return this.chatGroupsService.getChatGroup(chatGroup.id).pipe(
        tap((chatGroup: ChatGroup) => {
          setState((state: ChatStateModel) => {
            state.chatGroups[chatGroup.id] = chatGroup;
            return state;
          });
        }),
        tap((chatGroup: ChatGroup) => {
          // Add to my chats
          if (
            chatGroup.type.code !== GroupChatTypes.SPECIAL &&
            chatGroup.type.code !== GroupChatTypes.TENDER
          ) {
            this.store.dispatch(
              new AddChatGroupToMyChats(chatGroup.id, chatGroup.name)
            );
          }

          // Add to tenders chats
          if (chatGroup.type.code === GroupChatTypes.TENDER) {
            this.store.dispatch(
              new AddChatGroupToTendersChats(chatGroup.id, chatGroup.name)
            );
          }
        })
      );
    }
  }

  @Action(MarkAsRead)
  @ImmutableContext()
  markAsRead(
    { getState, setState }: StateContext<ChatStateModel>,
    { chatGroupID }: MarkAsRead
  ) {
    const unreadMessagesCountBeforeMarkingAsRead =
      getState().chatGroups[chatGroupID]?.unreadMessageCount || 0;

    if (unreadMessagesCountBeforeMarkingAsRead > 0) {
      setState((state: ChatStateModel) => {
        const chatGroup: ChatGroup = state.chatGroups[chatGroupID];
        chatGroup.unreadMessageCount = 0;

        return state;
      });

      this.store.dispatch(
        new SetUnreadMessagesCountInTenderChat(chatGroupID, 0)
      );

      return this.chatGroupsService.markAsRead(chatGroupID).pipe(
        catchError(() => {
          this.store.dispatch(
            new SetUnreadMessagesCountInTenderChat(
              chatGroupID,
              unreadMessagesCountBeforeMarkingAsRead
            )
          );

          setState((state: ChatStateModel) => {
            const chatGroup: ChatGroup = state.chatGroups[chatGroupID];
            chatGroup.unreadMessageCount =
              unreadMessagesCountBeforeMarkingAsRead;

            return state;
          });

          return of(null);
        })
      );
    }
  }
}

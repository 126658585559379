import {
  State,
  Action,
  StateContext,
  Actions,
  ofActionDispatched,
} from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap, takeUntil } from 'rxjs/operators';
import { WarehousesViewStateModel } from './warehouses_view-state.model';
import { LoadWarehouse } from './warehouses_view.actions';
import { ChangeCompany, ViewWarehouse } from '@app/store/tabs/tabs.actions';
import { PrimaryBlockSetView, PrimaryBlockSetWarehousesView } from '@app/store';
import { WarehousesService } from '@app/modules/warehouses/services/warehouses.service';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';

@Injectable()
@State<WarehousesViewStateModel>({
  name: 'view',
  defaults: {
    warehouse: null,
  },
})
export class WarehousesViewState {
  constructor(
    private warehousesService: WarehousesService,
    private actions$: Actions
  ) {}

  @Action(LoadWarehouse, { cancelUncompleted: true })
  @ImmutableContext()
  loadWarehouse(
    { setState }: StateContext<WarehousesViewStateModel>,
    { id }: LoadWarehouse
  ) {
    return this.warehousesService.get(id).pipe(
      takeUntil(
        this.actions$.pipe(
          ofActionDispatched(
            ViewWarehouse,
            PrimaryBlockSetWarehousesView,
            PrimaryBlockSetView,
            ChangeCompany
          )
        )
      ),
      tap((warehouse: Warehouse) => {
        setState((state: WarehousesViewStateModel) => {
          state.warehouse = warehouse;
          return state;
        });
      })
    );
  }
}

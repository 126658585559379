import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { CompanyTendersDistributionData } from './interface/company-tenders-distribution-data';

export class CompanyTendersDistributionStateModel {
  datePeriod: DatePeriodDayjs;
  companyId: number;
  tendersScope: TendersScopes;
  distribution: CompanyTendersDistributionData;
}

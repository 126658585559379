import { NgModule } from '@angular/core';
import {
  PreloadAllModules,
  ROUTES,
  RouterModule,
  Routes,
} from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth';
import { IsNotWhitelabelGuard } from './modules/auth/guards/is-not-whitelabel';
import { GuestGuard } from './modules/auth/guards/guest';
import { IsWhitelabelGuard } from './modules/auth/guards/is-whitelabel';
import { WhitelabelService } from './services/whitelabel.service';
import { Platform } from '@ionic/angular';

@NgModule({
  imports: [
    RouterModule.forRoot([], {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  providers: [
    {
      provide: ROUTES,
      useFactory: homepageRoutes,
      deps: [WhitelabelService, Platform],
      multi: true,
    },
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

function homepageRoutes(
  whitelabelService: WhitelabelService,
  platform: Platform
) {
  const routes: Routes = [
    {
      path: 'tabs',
      loadChildren: () =>
        import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
      canActivate: [AuthGuard],
    },
    {
      path: 'privacy-policy',
      loadChildren: () =>
        import('./pages/privacy-policy/privacy-policy.module').then(
          (m) => m.PrivacyPolicyPageModule
        ),
    },
  ];

  if (false && !platform.is('hybrid') && !whitelabelService.isWhitelabel()) {
    routes.push({
      path: '',
      loadChildren: () =>
        import('./pages/iceberg-layout/iceberg-layout-routing.module').then(
          (m) => m.IcebergLayoutRoutingModule
        ),
    });
  } else {
    routes.push({
      path: '',
      loadChildren: () =>
        import('./pages/home/home.module').then((m) => m.HomePageModule),
      canActivate: [GuestGuard],
    });
  }

  routes.push({
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  });

  return routes;
}

import { Selector } from '@ngxs/store';
import { Company } from '@app/modules/companies/interfaces/company';
import { ProfileCompaniesStateModel } from './profile-companies-state.model';
import { UserProfileCompaniesFilters } from '@app/pages/profile/tab4/interfaces/user-profile-companies-filters';
import { ProfileCompaniesState } from './profile-companies.state';

export class ProfileCompaniesSelectors {
  @Selector([ProfileCompaniesState])
  static companies(state: ProfileCompaniesStateModel): Company[] {
    return state.companies.data;
  }
  @Selector([ProfileCompaniesState])
  static page(state: ProfileCompaniesStateModel): number {
    return state.filters.page;
  }
  @Selector([ProfileCompaniesState])
  static maxPage(state: ProfileCompaniesStateModel): number {
    return state.companies.maxPage;
  }
  @Selector([ProfileCompaniesState])
  static filters(
    state: ProfileCompaniesStateModel
  ): UserProfileCompaniesFilters {
    return state.filters;
  }
}

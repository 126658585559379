import { SeaShipmentCreateFormData } from '@app/modules/sea-shipments/interfaces/sea-shipment-create.from-data';

export class LoadSeaShipments {
  static readonly type = '[Widgets -> Sea shipments] Load list';
}

export class AddSeaShipment {
  static readonly type = '[Widgets -> Sea shipments] Add a sea shipment';
  constructor(public data: SeaShipmentCreateFormData) {}
}

export class RemoveSeaShipment {
  static readonly type = '[Widgets -> Sea shipments] Remove a sea shipment';
  constructor(public seaShipmentID: number) {}
}

import { ChatTabs } from '@app/modules/chat/chat-tabs';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { createSelector, Selector } from '@ngxs/store';
import { ChatStateModel } from './chat-state.model';
import { ChatState } from './chat.state';
import { ChatMyChatsState } from './my-chats';
import { ChatMyChatsStateModel } from './my-chats/chat_my-chats-state.model';
import { ChatTendersChatsState } from './tenders-chats';
import { ChatTendersChatsStateModel } from './tenders-chats/chat_tenders-chats-state.model';
import * as moment from 'moment';
import { ChatSelectedChatGroupState } from './selected-chat-group';
import { ChatSelectedChatGroupStateModel } from './selected-chat-group/chat_selected-chat-group-state.model';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { GroupChatTypes } from '@app/modules/chat/group-chat-types';

export class ChatSelectors {
  @Selector([ChatState])
  static activeTab(state: ChatStateModel): ChatTabs {
    return state.activeTab;
  }

  @Selector([ChatState])
  static consultantChatGroup(state: ChatStateModel): ChatGroup {
    return state.chatGroups[state.consultantChatGroupID];
  }

  @Selector([ChatState])
  static isChatExpanded(state: ChatStateModel): boolean {
    return state.isChatExpanded;
  }

  @Selector([ChatState])
  static chatGroups(state: ChatStateModel): { [key: number]: ChatGroup } {
    return state.chatGroups;
  }

  static chatGroup(chatGroupID: number): (state: ChatStateModel) => ChatGroup {
    return createSelector([ChatState], (state: ChatStateModel) => {
      return state.chatGroups[chatGroupID];
    });
  }

  @Selector([ChatState, ChatMyChatsState])
  static myChats(
    chatState: ChatStateModel,
    chatMyChatsSstate: ChatMyChatsStateModel
  ): ChatGroup[] {
    const result = [];
    chatMyChatsSstate.myChats.forEach((id: number) => {
      if (chatState.chatGroups[id]) {
        result.push(chatState.chatGroups[id]);
      } else {
        console.warn(`
          My chats group is not found in the cache
          Chat Group ID: ${id}
          -> Unbale to display the chat group in the my chats list
        `);
      }
    });

    result.sort(ChatSelectors.compare);

    return result;
  }

  @Selector([ChatState, ChatMyChatsState])
  static myChatsNotificationsCount(
    chatState: ChatStateModel,
    chatMyChatsSstate: ChatMyChatsStateModel
  ): number {
    let count = 0;
    chatMyChatsSstate.myChats.forEach((id: number) => {
      if (chatState.chatGroups[id]?.unreadMessageCount > 0) {
        count++;
      }
    });

    return count;
  }

  @Selector([ChatState, ChatTendersChatsState])
  static tendersChatsNotificationsCount(
    chatState: ChatStateModel,
    chatTendersChatsSstate: ChatTendersChatsStateModel
  ): number {
    let count = 0;
    chatTendersChatsSstate.tendersChats.forEach((id: number) => {
      if (chatState.chatGroups[id]?.unreadMessageCount > 0) {
        count++;
      }
    });

    return count;
  }

  @Selector([ChatState, ChatMyChatsState, ChatTendersChatsState])
  static totalChatsNotificationsCount(
    chatState: ChatStateModel,
    chatMyChatsSstate: ChatMyChatsStateModel,
    chatTendersChatsSstate: ChatTendersChatsStateModel
  ): number {
    let notificationsSet = new Set();
    chatMyChatsSstate.myChats.forEach((id: number) => {
      if (chatState.chatGroups[id]?.unreadMessageCount > 0) {
        notificationsSet.add(id);
      }
    });
    chatTendersChatsSstate.tendersChats.forEach((id: number) => {
      if (chatState.chatGroups[id]?.unreadMessageCount > 0) {
        notificationsSet.add(id);
      }
    });

    return notificationsSet.size;
  }

  @Selector([ChatState, ChatTendersChatsState])
  static tendersChats(
    chatState: ChatStateModel,
    chatTendersChatsState: ChatTendersChatsStateModel
  ): ChatGroup[] {
    const result = [];
    chatTendersChatsState.tendersChats.forEach((id: number) => {
      if (chatState.chatGroups[id]) {
        result.push(chatState.chatGroups[id]);
      } else {
        console.warn(`
          The tender chat group is not found in the cache
          Chat Group ID: ${id}
          -> Unbale to display the chat group in the tenders chats list
        `);
      }
    });

    result.sort(ChatSelectors.compare);

    return result;
  }

  static compare(a: ChatGroup, b: ChatGroup): number {
    const aMoment = moment(a.date.updatedAt);
    if (aMoment.isBefore(b.date.updatedAt)) {
      return 1;
    }
    if (aMoment.isAfter(b.date.updatedAt)) {
      return -1;
    }

    if (a.id < b.id) {
      return 1;
    }
    if (a.id > b.id) {
      return -1;
    }

    return 0;
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static selectedChatGroup(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): ChatGroup {
    return chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId];
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static selectedChatGroupInfo(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): ChatGroup {
    const selectedChatGroup: ChatGroup =
      chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId];

    const resultChatGroup: ChatGroup = {
      id: selectedChatGroup.id,
      loaded: selectedChatGroup.loaded,
    };

    if (selectedChatGroup.status) {
      resultChatGroup.status = selectedChatGroup.status;
    }

    return resultChatGroup;
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static isSelectedChatGroupLoaded(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): boolean {
    return chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId]
      .loaded;
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static isPrivateChat(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): boolean {
    return (
      chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId].type
        .code === GroupChatTypes.PRIVATE
    );
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static selectedChatGroupMessages(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): ChatMessage[] {
    return chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId]
      .chatMessages.dataModels;
  }

  @Selector([ChatState, ChatSelectedChatGroupState])
  static hasMoreMessages(
    chatState: ChatStateModel,
    chatSelectedChatGroupState: ChatSelectedChatGroupStateModel
  ): boolean {
    if (
      !chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId]
        ?.chatMessages
    ) {
      return false;
    }

    return (
      chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId]
        .chatMessages.totalCount >
      chatState.chatGroups[chatSelectedChatGroupState.selectedChatGroupId]
        .chatMessages.count
    );
  }
}

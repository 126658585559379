import { ChangePasswordFormData } from '@app/pages/profile/tab1/interfaces/change-password.form-data';
import { ResponseError } from '@app/interfaces/response-error';
import { UpdateProfileFormData } from '@app/pages/profile/interfaces/update-profile.form-data';

export class LoadProfile {
  static readonly type = '[Profile Page] Load Profile';
  constructor(public userId?: number) {}
}

export class UploadPicture {
  static readonly type = '[Profile Page] Upload profile picture';
  constructor(
    public file: File,
    public companyId?: number,
    public userId?: number
  ) {}
}

export class InitChangePasswordForm {
  static readonly type = '[Profile Page] Init change the user password form';
  constructor() {}
}

export class TryChangePassword {
  static readonly type = '[Profile Page] Try to change the user password';
  constructor(public formData: ChangePasswordFormData) {}
}

export class ChangePasswordSuccess {
  static readonly type =
    '[Profile Page] The user password has been successfully changed';
  constructor() {}
}

export class ChangePasswordFailed {
  static readonly type = '[Profile Page] Failed to change the user password';
  constructor(public responseError: ResponseError) {}
}

export class UpdateProfile {
  static readonly type = '[Profile Page] Update profile data';
  constructor(public data: UpdateProfileFormData, public userId?: number) {}
}

export class LoadUserComments {
  static readonly type =
    '[Profile Page -> Documents Tab -> Comments block] Load user comments';
  constructor(public userId: number) {}
}

export class SetUserTimeBlocking {
  static readonly type =
    '[Profile Page -> Common Information] Set user time blocking';
  constructor(public timeBlocking: string) {}
}

export class DeletePersonalAccount {
  static readonly type =
    '[Profile Page -> Common Information] Delete personal account';
}

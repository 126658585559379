import { TransportTenderCreateFormData } from '@app/pages/transport-tender/create/interfaces/transport-tender_create.form-data';

// export class LoadCompanyAvailableTransportList {
//   static readonly type =
//     '[Create Transport Advertisement Page] Load company available transport list';
//   constructor(public companyId: number) {}
// }

export class InitializeCreateTransportTenderPage {
  static readonly type =
    '[Create Transport Tender Page] Initialize Create Transport Tender Page';
  constructor() {}
}

export class SearchCompanyTransport {
  static readonly type =
    '[Create Transport Tender Page] Search company transport';
  constructor(public searchValue?: string) {}
}

export class ResetInitialTransportTenderData {
  static readonly type =
    '[Create Transport Tender Page] Reset initial transport tender data';
  constructor() {}
}

export class TryCreateTransportTender {
  static readonly type =
    '[Create Transport Page] Try create transport (Transport form submitted)';
  constructor(public data: TransportTenderCreateFormData) {}
}

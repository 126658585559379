import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { CompanyFinancesTendersStatisticsRecord } from '../interfaces/company-finances-tenders-statistics-record';
import { CompanyFinancesTendersStatisticsSummary } from '../interfaces/company-finances-tenders-statistics-summary';
import { CompanyFinancesTendersStatisticsFilters } from '../interfaces/company-finances-tenders-statistics-filters';
import { PaginationResults } from '@app/interfaces/pagination-results';

@Injectable({
  providedIn: 'root',
})
export class CompanyFinancesStatisticsService {
  constructor(private apiService: ApiService) {}

  getCarTendersStatistics(
    filters: CompanyFinancesTendersStatisticsFilters
  ): Observable<
    PaginationResults<
      CompanyFinancesTendersStatisticsRecord,
      void,
      CompanyFinancesTendersStatisticsSummary
    >
  > {
    return this.getTendersStatistics(
      CargoTendersTransportationTypes.CAR,
      filters
    );
  }

  getSeaTendersStatistics(
    filters: CompanyFinancesTendersStatisticsFilters
  ): Observable<
    PaginationResults<
      CompanyFinancesTendersStatisticsRecord,
      void,
      CompanyFinancesTendersStatisticsSummary
    >
  > {
    return this.getTendersStatistics(
      CargoTendersTransportationTypes.SEA,
      filters
    );
  }

  private getTendersStatistics(
    type: CargoTendersTransportationTypes,
    filters: CompanyFinancesTendersStatisticsFilters
  ): Observable<
    PaginationResults<
      CompanyFinancesTendersStatisticsRecord,
      void,
      CompanyFinancesTendersStatisticsSummary
    >
  > {
    const endpoint =
      type === CargoTendersTransportationTypes.CAR
        ? '/tender/statistics'
        : '/tender/sea/statistics';

    return this.apiService.getPaginatedResults<
      CompanyFinancesTendersStatisticsRecord,
      CompanyFinancesTendersStatisticsSummary
    >(endpoint, { ...filters });
  }
}

import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { CompanyTendersOverallStatisticsData } from './interface/company-tenders-overall-statistics-data';

export class CompanyTendersOverallStatisticsStateModel {
  datePeriod: DatePeriodDayjs;
  companyId: number;
  tendersScope: TendersScopes;
  statistics: CompanyTendersOverallStatisticsData;
}

import { FieldsErrors } from '@app/interfaces/fields-errors';
import { CompanyBank } from '@app/modules/companies/interfaces/company-bank';
import { SaveCompanyBankFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-bank/save-company-bank-form-data';

export class LoadCompanyBanks {
  static readonly type = '[View Company Page] Load company banks';
  constructor(public companyId: number) {}
}

///

export class InitAddEditCompanyBankForm {
  static readonly type =
    '[View Company Page] Initialize the add/edit company bank form';
  constructor() {}
}

///

export class EditCompanyBank {
  static readonly type =
    '[Company Page] Trigger the popup to display company bank edit form.';
  constructor(public bankId: number) {}
}

export class TrySaveCompanyBank {
  static readonly type =
    '[Company Page TAB1 Add/Edit Bank Popup] Try to save the company bank';
  constructor(public data: SaveCompanyBankFormData) {}
}

export class SaveCompanyBankSuccess {
  static readonly type =
    '[Company Page TAB1 Add/Edit Bank Popup] The company bank has been successfully saved!';
  constructor() {}
}

export class SaveCompanyBankFailed {
  static readonly type =
    '[Company Page TAB1 Add/Edit Bank Popup] Failed to save the company bank!';
  constructor(public errors: FieldsErrors) {}
}

export class MarkCompanyBankAsMain {
  static readonly type = '[Company Page] Mark company bank as main.';
  constructor(public bank: CompanyBank) {}
}

export class DeleteCompanyBank {
  static readonly type =
    '[Company Page] Display the confirmation box for deletion of company bank';
  constructor(public bank: CompanyBank) {}
}

export class TryDeleteCompanyBank {
  static readonly type = '[Company Page] Try to delete company bank';
  constructor(public companyId: number, public bankId: number) {}
}

export class DeleteCompanyBankSuccess {
  static readonly type =
    '[Company Page] The company bank has been successfully deleted!';
  constructor() {}
}

export class DeleteCompanyBankFailed {
  static readonly type = '[Company Page] Failed to delete the company bank!';
  constructor() {}
}

import { Selector } from '@ngxs/store';
import { OverallStatisticsStateModel } from './overall_statistics-state.model';
import { OverallStatistics } from '@app/modules/widgets/components/overall-statistics-widget/interfaces/overall-statistics';
import { OverallStatisticsState } from './overall_statistics.state';

export class OverallStatisticsSelectors {
  @Selector([OverallStatisticsState])
  static overallStatistics(
    state: OverallStatisticsStateModel
  ): OverallStatistics {
    return state.overallStatistics;
  }
}

import { Injectable } from '@angular/core';
import { NotFound } from '@app/errors/response-errors/not-found';
import { ApiService } from '@app/services/api.service';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ApiToken } from '@app/modules/api-token/interfaces/api-token';

@Injectable({
  providedIn: 'root',
})
export class ApiTokenService {
  constructor(private apiService: ApiService) {}

  get(): Observable<ApiToken> {
    return this.apiService.get<ApiToken>('/api-token').pipe(
      catchError((error: any) => {
        if (error instanceof NotFound) {
          return of(null);
        }

        return throwError(() => error);
      })
    );
  }

  create(ip: string): Observable<ApiToken> {
    return this.apiService.create<ApiToken>('/api-token', { ip });
  }

  delete(): Observable<boolean> {
    return this.apiService.delete<boolean>('/api-token');
  }
}

import { createSelector, Selector } from '@ngxs/store';
import { WidgetConfig } from '@app/modules/widgets/interfaces/widget-config';
import { WidgetsState } from './widgets.state';
import { WidgetsStateModel } from './widgets-state.model';
import { WidgetTypes } from '@app/modules/widgets/types/widget-types';
import { WidgetColumns } from '@app/modules/widgets/interfaces/widget-columns';

export class WidgetsSelectors {
  @Selector([WidgetsState])
  static leftColumnWidgets(state: WidgetsStateModel): WidgetTypes[] {
    return state.widgets.left;
  }

  @Selector([WidgetsState])
  static rightColumnWidgets(state: WidgetsStateModel): WidgetTypes[] {
    return state.widgets.right;
  }

  @Selector([WidgetsState])
  static leftColumnWidgetsConfigs(state: WidgetsStateModel): WidgetConfig[] {
    return state.widgetsConfigs.left;
  }

  @Selector([WidgetsState])
  static rightColumnWidgetsConfigs(state: WidgetsStateModel): WidgetConfig[] {
    return state.widgetsConfigs.right;
  }

  static isEnabled(widget: WidgetTypes, column = WidgetColumns.RIGHT) {
    return createSelector([WidgetsState], (state: WidgetsStateModel) => {
      const config = state.widgetsConfigs[column].find(
        (config: WidgetConfig) => {
          return config.component === widget;
        }
      );

      return config?.enabled;
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyMemberPosition } from '@app/modules/companies/interfaces/company-member-position';

@Injectable({
  providedIn: 'root',
})
export class CompanyMemberPositionsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<CompanyMemberPosition[]> {
    return this.apiService.getAll<CompanyMemberPosition>('/company-position');
  }
}

import { FieldsErrors } from '@app/interfaces/fields-errors';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';
import { SaveCompanyStructureFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-structure/save-company-structure-form-data';

export class LoadCompanyStructures {
  static readonly type = '[View Company Page] Load company structures';
  constructor(public companyId: number) {}
}

export class InitAddEditCompanyStructureForm {
  static readonly type =
    '[View Company Page] Initialize the add/edit company structure form';
  constructor() {}
}

export class EditCompanyStructure {
  static readonly type =
    '[Company Page] Trigger the popup to display company structure edit form.';
  constructor(public structureId: number) {}
}

export class TrySaveCompanyStructure {
  static readonly type =
    '[Company Page TAB1 Add/Edit Structure Popup] Try to save the company structure';
  constructor(public data: SaveCompanyStructureFormData) {}
}

export class SaveCompanyStructureSuccess {
  static readonly type =
    '[Company Page TAB1 Add/Edit Structure Popup] The company structure has been successfully saved!';
  constructor() {}
}

export class SaveCompanyStructureFailed {
  static readonly type =
    '[Company Page TAB1 Add/Edit Structure Popup] Failed to save the company structure!';
  constructor(public errors: FieldsErrors) {}
}

export class DeleteCompanyStructure {
  static readonly type =
    '[Company Page] Display the confirmation box for deletion of company structure';
  constructor(public structure: CompanyStructure) {}
}

export class TryDeleteCompanyStructure {
  static readonly type = '[Company Page] Try to delete company structure';
  constructor(public companyId: number, public structureId: number) {}
}

export class DeleteCompanyStructureSuccess {
  static readonly type =
    '[Company Page] The company structure has been successfully deleted!';
  constructor() {}
}

export class DeleteCompanyStructureFailed {
  static readonly type =
    '[Company Page] Failed to delete the company structure!';
  constructor() {}
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthSelectors } from '@app/store';
import { User } from '@app/modules/users/interfaces/user';
import { CheckPermissionsService } from '@app/modules/permissions/services/check-permissions.service';
import { PermissionTypes } from '@app/modules/permissions/permission-types';

@Injectable()
export class CompanyDocumentsGuard implements CanActivate {
  constructor(
    private store: Store,
    private checkPermissionsService: CheckPermissionsService
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AuthSelectors.user).pipe(
      mergeMap((user: User) => {
        if (user?.is_super_admin) {
          return of(true);
        }
        if (user?.isCT) {
          return this.isCtEmployee();
        }
        return of(false);
      })
    );
  }

  isCtEmployee(): Observable<boolean> {
    return this.checkPermissionsService.hasAccess(
      'ctEmployee',
      PermissionTypes.USER
    );
  }
}

import { Selector } from '@ngxs/store';
import { PermissionsStateModel } from './permissions-state.model';
import { PermissionsState } from './permissions.state';
import { Permission } from '@app/modules/permissions/interfaces/permission';

export class PermissionsSelectors {
  @Selector([PermissionsState])
  static userPermissions(state: PermissionsStateModel): Permission[] {
    return state.userPermissions;
  }
}

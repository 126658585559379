import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { OverallStatisticsStateModel } from './overall_statistics-state.model';
import { LoadOverallStatistics } from './overall_statistics.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs/operators';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { OverallStatistics } from '@app/modules/widgets/components/overall-statistics-widget/interfaces/overall-statistics';

@Injectable()
@State<OverallStatisticsStateModel>({
  name: 'widgets__overall_statistics',
  defaults: {
    overallStatistics: null,
  },
})
export class OverallStatisticsState {
  constructor(private cargoTendersCarService: CargoTendersCarService) {}

  @Action(LoadOverallStatistics, { cancelUncompleted: true })
  @ImmutableContext()
  loadOverallStatistics({
    setState,
  }: StateContext<OverallStatisticsStateModel>) {
    return this.cargoTendersCarService.loadOverallStatistics().pipe(
      tap((overallStatistics: OverallStatistics) => {
        setState((state: OverallStatisticsStateModel) => {
          state.overallStatistics = overallStatistics;
          return state;
        });
      })
    );
  }
}

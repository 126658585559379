import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyContact } from '@app/modules/companies/interfaces/company-contact';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { map } from 'rxjs/operators';
import { SaveCompanyContactFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-contact/save-company-contact.form-data';

@Injectable({
  providedIn: 'root',
})
export class CompanyContactsService {
  constructor(private apiService: ApiService) {}

  create(data: SaveCompanyContactFormData): Observable<CompanyContact> {
    const companyId = data.companyId;
    delete data.companyId;
    return this.apiService
      .post<ResponseSuccess<CompanyContact>>(
        '/company/' + companyId + '/contact',
        data
      )
      .pipe(
        map((response: ResponseSuccess<CompanyContact>) => {
          return response.data;
        })
      );
  }

  update(data: SaveCompanyContactFormData): Observable<CompanyContact> {
    const id = data.id;
    delete data.id;
    const companyId = data.companyId;
    delete data.companyId;

    return this.apiService.put<CompanyContact>(
      '/company/' + companyId + '/contact/' + id,
      data
    );
  }

  delete(contact: CompanyContact): Observable<any> {
    return this.apiService.delete(
      '/company/' + contact.companyId + '/contact/' + contact.id
    );
  }
}

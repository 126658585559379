import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './guards/auth';
import { GuestGuard } from './guards/guest';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@envs/environment';
import { IsSuperAdminGuard } from '@app/guards/is-super-admin';
import { NoOneGuard } from '@app/guards/no-one';
import { HasPermissionGuard } from '@app/guards/has-permission';
import { HasCompanyPermissionGuard } from '@app/guards/has-company-permission';
import { IsCompanyGuard } from '@app/guards/is-company';
import { CompanyDocumentsGuard } from '@app/guards/company-documents';
import { ViewCompanyMatchesAuthCompanyGuard } from '@app/guards/view-company-matches-auth-company';
import { IsWhitelabelGuard } from './guards/is-whitelabel';
import { IsNotWhitelabelGuard } from './guards/is-not-whitelabel';

export function tokenGetter(): string {
  const auth: string = localStorage.getItem('auth');
  if (!auth) {
    return null;
  }

  const json: { token: string } = JSON.parse(auth);
  if (!auth) {
    return null;
  }

  return json.token;
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.api_domain],
      },
    }),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    AuthGuard,
    GuestGuard,
    IsWhitelabelGuard,
    IsNotWhitelabelGuard,
    IsSuperAdminGuard,
    NoOneGuard,
    HasPermissionGuard,
    HasCompanyPermissionGuard,
    IsCompanyGuard,
    CompanyDocumentsGuard,
    ViewCompanyMatchesAuthCompanyGuard,
  ],
})
export class AuthModule {}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { TransportTendersBetsHistoryStateModel } from './transport-tenders_bets-history-state.model';
import { LoadTransportTenderBetsHistory } from './transport-tenders_bets-history.actions';
import { TransportBet } from '@app/modules/transport-bets/interfaces/transport-bet';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';

@Injectable()
@State<TransportTendersBetsHistoryStateModel>({
  name: 'transport_tenders__bets_history',
  defaults: {
    bets: [],
    page: 1,
    maxPage: 1,
  },
})
export class TransportTendersBetsHistoryState {
  constructor(private transportTenderService: TransportTenderService) {}

  @Action(LoadTransportTenderBetsHistory)
  @ImmutableContext()
  loadTransportTenderBetsHistory(
    { getState, setState }: StateContext<TransportTendersBetsHistoryStateModel>,
    { targetPointId, page }: LoadTransportTenderBetsHistory
  ) {
    const newPage = page || getState().page;

    return this.transportTenderService
      .getBetsHistory(targetPointId, newPage)
      .pipe(
        tap((result: PaginationResults<TransportBet>) => {
          setState((state: TransportTendersBetsHistoryStateModel) => {
            state.bets = result.data;
            state.page = newPage;
            state.maxPage = result.maxPage;
            return state;
          });
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap, mergeMap } from 'rxjs/operators';
import { MapStateModel } from './map-state.model';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import {
  ApplyMapFilters,
  DisplayMapFilters,
  LoadCarCargoTenderPoints,
  LoadSeaCargoTenderPoints,
  LoadTransportPoints,
  LoadWarehousePoints,
  MapLoadCompanyPopularRoutes,
  MapLoadCompaniesWithPopularRoute,
  MapLoadCargoTenderCompaniesForTender,
  LoadStructures,
  ApplyMapDateFilter,
  LoadCargoReport,
} from './map.actions';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';
import { TenderStates } from '@app/modules/tenders/tender-states';
import {
  HighlightCarCargoTenderOnMap,
  HighlightSeaCargoTenderOnMap,
} from '@app/store/cargo-tenders';
import { HighlightTransportTenderOnMap } from '@app/store/transport-tenders/list';
import { WarehousesService } from '@app/modules/warehouses/services/warehouses.service';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';
import { AuthSelectors } from '@app/store/auth/auth.selectors';
import { MapFilterObjectsTypes } from '@app/pages/map/components/map-filters/map-filter-objects-types';
import { Router } from '@angular/router';
import { CompanyStructuresService } from '@app/modules/companies/services/company-structures.service';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';
import { CompanyPopularRoutesService } from '@app/modules/companies/services/company-popular-routes.service';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';
import { LoadAllCargoPopularRoutes, LoadAllTransportPopularRoutes } from '.';
import { CompanyPopularRouteTypes } from '@app/modules/companies/interfaces/company-popular-route-types';
import { Company } from '@app/modules/companies/interfaces/company';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';

@Injectable()
@State<MapStateModel>({
  name: 'map',
  defaults: {
    displayFilters: false,
    filters: {
      objects: [MapFilterObjectsTypes.CARGO_CARS],
      state: TenderStates.ACTIVE,
      createdAt: null,
    },
    carCargoTenderPoints: [],
    seaCargoTenderPoints: [],
    transportPoints: [],
    warehousePoints: [],
    structures: [],
    popularRoutes: [],
    allCargoPopularRoutes: [],
    allTransportPopularRoutes: [],
    companies: [],
  },
})
export class MapState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private transportTenderService: TransportTenderService,
    private warehousesService: WarehousesService,
    private store: Store,
    private router: Router,
    private companyStructuresService: CompanyStructuresService,
    private companyPopularRoutesService: CompanyPopularRoutesService
  ) {}

  @Action(LoadCarCargoTenderPoints)
  @ImmutableContext()
  loadCarCargoTenderPoints(
    { setState }: StateContext<MapStateModel>,
    { filters }: LoadCarCargoTenderPoints
  ) {
    return this.cargoTendersCarService
      .loadCarCargoTenderPoints(null, null, filters.state, filters.createdAt)
      .pipe(
        tap((tenders: CarCargoTender[]) => {
          setState((state: MapStateModel) => {
            state.carCargoTenderPoints = tenders;
            return state;
          });
        })
      );
  }

  @Action(LoadCargoReport)
  @ImmutableContext()
  loadCargoReport(
    {}: StateContext<MapStateModel>,
    { filters }: LoadCargoReport
  ) {
    return this.cargoTendersCarService.loadCargoReport(
      filters.state,
      filters.createdAt
    );
  }

  @Action(LoadSeaCargoTenderPoints)
  @ImmutableContext()
  loadSeaCargoTenderPoints(
    { setState }: StateContext<MapStateModel>,
    { filters }: LoadSeaCargoTenderPoints
  ) {
    return this.cargoTendersSeaService
      .loadSeaCargoTenderPoints(null, null, filters.state, filters.createdAt)
      .pipe(
        tap((tenders: CarCargoTender[]) => {
          setState((state: MapStateModel) => {
            state.seaCargoTenderPoints = tenders;
            return state;
          });
        })
      );
  }

  @Action(HighlightCarCargoTenderOnMap)
  @ImmutableContext()
  highlightCarCargoTenderOnMap(
    { getState, setState }: StateContext<MapStateModel>,
    { tenderState }: HighlightCarCargoTenderOnMap
  ) {
    this.router.navigate(['/tabs/map']);

    setState((state: MapStateModel) => {
      state.displayFilters = false;
      return state;
    });

    const filters = getState().filters;

    if (
      filters.state !== tenderState ||
      (!~filters.objects.indexOf(MapFilterObjectsTypes.CARGO_CARS) &&
        !~filters.objects.indexOf(MapFilterObjectsTypes.EVERYTHING))
    ) {
      setState((state: MapStateModel) => {
        state.filters.objects = [MapFilterObjectsTypes.CARGO_CARS];
        state.filters.state = tenderState;
        return state;
      });

      return this.cargoTendersCarService
        .loadCarCargoTenderPoints(null, null, tenderState)
        .pipe(
          tap((tenders: CarCargoTender[]) => {
            setState((state: MapStateModel) => {
              state.carCargoTenderPoints = tenders;
              return state;
            });
          })
        );
    }
  }

  @Action(HighlightSeaCargoTenderOnMap)
  @ImmutableContext()
  highlightSeaCargoTenderOnMap(
    { getState, setState }: StateContext<MapStateModel>,
    { tenderState }: HighlightSeaCargoTenderOnMap
  ) {
    this.router.navigate(['/tabs/map']);

    setState((state: MapStateModel) => {
      state.displayFilters = false;
      return state;
    });

    const filters = getState().filters;

    if (
      filters.state !== tenderState ||
      (!~filters.objects.indexOf(MapFilterObjectsTypes.CARGO_SEA) &&
        !~filters.objects.indexOf(MapFilterObjectsTypes.EVERYTHING))
    ) {
      setState((state: MapStateModel) => {
        state.filters.objects = [MapFilterObjectsTypes.CARGO_SEA];
        state.filters.state = tenderState;
        return state;
      });

      return this.cargoTendersSeaService
        .loadSeaCargoTenderPoints(null, null, tenderState)
        .pipe(
          tap((tenders: SeaCargoTender[]) => {
            setState((state: MapStateModel) => {
              state.seaCargoTenderPoints = tenders;
              return state;
            });
          })
        );
    }
  }

  @Action(LoadTransportPoints)
  @ImmutableContext()
  loadTransportPoints(
    { setState }: StateContext<MapStateModel>,
    { filters }: LoadTransportPoints
  ) {
    return this.transportTenderService
      .loadTransportPoints(filters.state, filters.createdAt)
      .pipe(
        tap((transport: TransportTender[]) => {
          setState((state: MapStateModel) => {
            state.transportPoints = transport;
            return state;
          });
        })
      );
  }

  @Action(HighlightTransportTenderOnMap)
  @ImmutableContext()
  highlightTransportTenderOnMap(
    { getState, setState }: StateContext<MapStateModel>,
    { tenderState }: HighlightTransportTenderOnMap
  ) {
    this.router.navigate(['/tabs/map']);

    setState((state: MapStateModel) => {
      state.displayFilters = false;
      return state;
    });

    const filters = getState().filters;

    if (
      filters.state !== tenderState ||
      (!~filters.objects.indexOf(MapFilterObjectsTypes.TRANSPORT) &&
        !~filters.objects.indexOf(MapFilterObjectsTypes.EVERYTHING))
    ) {
      setState((state: MapStateModel) => {
        state.filters.objects = [MapFilterObjectsTypes.TRANSPORT];
        state.filters.state = tenderState;
        return state;
      });

      return this.transportTenderService.loadTransportPoints(tenderState).pipe(
        tap((transport: TransportTender[]) => {
          setState((state: MapStateModel) => {
            state.transportPoints = transport;
            return state;
          });
        })
      );
    }
  }

  @Action(LoadWarehousePoints)
  @ImmutableContext()
  loadWarehousePoints(
    { setState }: StateContext<MapStateModel>,
    { createdAt }: LoadWarehousePoints
  ) {
    return this.store.selectOnce(AuthSelectors.is_super_admin).pipe(
      mergeMap((isSuperAdmin: boolean) => {
        return this.warehousesService.listAll(isSuperAdmin, createdAt).pipe(
          tap((warehouses: Warehouse[]) => {
            setState((state: MapStateModel) => {
              state.warehousePoints = warehouses;
              return state;
            });
          })
        );
      })
    );
  }

  @Action(LoadStructures)
  @ImmutableContext()
  loadStructures(
    { setState }: StateContext<MapStateModel>,
    { createdAt }: LoadStructures
  ) {
    return this.companyStructuresService.listAll(createdAt).pipe(
      tap((structures: CompanyStructure[]) => {
        setState((state: MapStateModel) => {
          state.structures = structures;
          return state;
        });
      })
    );
  }

  @Action(DisplayMapFilters)
  @ImmutableContext()
  displayMapFilters({ setState }: StateContext<MapStateModel>) {
    return setState((state: MapStateModel) => {
      state.displayFilters = true;
      return state;
    });
  }

  @Action(ApplyMapFilters)
  @ImmutableContext()
  applyMapFilters(
    { setState }: StateContext<MapStateModel>,
    { filters }: ApplyMapFilters
  ) {
    return setState((state: MapStateModel) => {
      state.filters.objects = filters.objects;
      state.filters.state = filters.state;
      state.displayFilters = false;
      return state;
    });
  }

  @Action(ApplyMapDateFilter)
  @ImmutableContext()
  applyMapDateFilter(
    { setState }: StateContext<MapStateModel>,
    { datePeriod }: ApplyMapDateFilter
  ) {
    return setState((state: MapStateModel) => {
      state.filters.createdAt = datePeriod;
      return state;
    });
  }

  @Action(MapLoadCompanyPopularRoutes, { cancelUncompleted: true })
  @ImmutableContext()
  mapLoadCompanyPopularRoutes(
    { setState }: StateContext<MapStateModel>,
    { companyId }: MapLoadCompanyPopularRoutes
  ) {
    setState((state: MapStateModel) => {
      state.popularRoutes = [];
      return state;
    });

    return this.companyPopularRoutesService.list(companyId).pipe(
      tap((popularRoutes: CompanyPopularRoute[]) => {
        setState((state: MapStateModel) => {
          state.popularRoutes = popularRoutes;
          return state;
        });
      })
    );
  }

  @Action(LoadAllCargoPopularRoutes, { cancelUncompleted: true })
  @ImmutableContext()
  loadAllCargoPopularRoutes({ setState }: StateContext<MapStateModel>) {
    return this.companyPopularRoutesService
      .listAll(CompanyPopularRouteTypes.SENDING)
      .pipe(
        tap((popularRoutes: CompanyPopularRoute[]) => {
          setState((state: MapStateModel) => {
            state.allCargoPopularRoutes = popularRoutes;
            return state;
          });
        })
      );
  }

  @Action(LoadAllTransportPopularRoutes, { cancelUncompleted: true })
  @ImmutableContext()
  loadAllTransportPopularRoutes({ setState }: StateContext<MapStateModel>) {
    return this.companyPopularRoutesService
      .listAll(CompanyPopularRouteTypes.TRANSPORT)
      .pipe(
        tap((popularRoutes: CompanyPopularRoute[]) => {
          setState((state: MapStateModel) => {
            state.allTransportPopularRoutes = popularRoutes;
            return state;
          });
        })
      );
  }

  @Action(MapLoadCompaniesWithPopularRoute, { cancelUncompleted: true })
  @ImmutableContext()
  mapLoadCompaniesWithPopularRoute(
    { setState }: StateContext<MapStateModel>,
    { routeId }: MapLoadCompaniesWithPopularRoute
  ) {
    setState((state: MapStateModel) => {
      state.companies = [];
      return state;
    });

    return this.companyPopularRoutesService.getCompanies(routeId).pipe(
      tap((companies: Company[]) => {
        setState((state: MapStateModel) => {
          state.companies = companies;
          return state;
        });
      })
    );
  }

  @Action(MapLoadCargoTenderCompaniesForTender, { cancelUncompleted: true })
  @ImmutableContext()
  mapLoadCargoTenderCompaniesForTender(
    { setState }: StateContext<MapStateModel>,
    { tenderId }: MapLoadCargoTenderCompaniesForTender
  ) {
    setState((state: MapStateModel) => {
      state.companies = [];
      return state;
    });

    return this.companyPopularRoutesService
      .getCargoTenderCompanies(tenderId)
      .pipe(
        tap((companies: Company[]) => {
          setState((state: MapStateModel) => {
            state.companies = companies;
            return state;
          });
        })
      );
  }
}

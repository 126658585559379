import { DatePeriod } from '@app/interfaces/date-period';
import { TendersStatementRecord } from '@app/modules/company-finances/interfaces/tenders-statement-record';
import { TendersStatementSummary } from '@app/modules/company-finances/interfaces/tenders-statement-summary';
import { TenderStatementTypes } from '@app/pages/company-finances/tender-statement-types';

export class CompanyFinancesTendersStatementStateModel {
  records: TendersStatementRecord[];
  summary: TendersStatementSummary;
  fileContent: string;
  datePeriod: DatePeriod;
  type: TenderStatementTypes;
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BirthdayStateModel } from './birthday-state.model';
import { LoadBirthdaysData } from './birthday.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs/operators';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import { BirthdayWidgetDataRecord } from '@app/modules/widgets/components/birthday-widget/interfaces/birthday-widget-data-record';

@Injectable()
@State<BirthdayStateModel>({
  name: 'widgets__birthday',
  defaults: {
    birthdays: null,
  },
})
export class BirthdayState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(LoadBirthdaysData)
  @ImmutableContext()
  loadBirthdaysData({ setState }: StateContext<BirthdayStateModel>) {
    return this.widgetsService.getBirthdayWidgetComponentData().pipe(
      tap((birthdays: BirthdayWidgetDataRecord[]) => {
        return setState((state: BirthdayStateModel) => {
          state.birthdays = birthdays;
          return state;
        });
      })
    );
  }
}

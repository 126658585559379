import { Selector } from '@ngxs/store';
import { RegisterUserWithCompanyState } from './register-user-with-company.state';
import { RegisterUserWithCompanyStateModel } from './register-user-with-company-state.model';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';

export class RegisterUserWithCompanySelectors {
  @Selector([RegisterUserWithCompanyState])
  static fieldErrors(
    state: RegisterUserWithCompanyStateModel
  ): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      errors.push({
        field: key,
        errors: state.fieldsErrors[key],
      });
    });

    return errors;
  }
}

import { createSelector, Selector } from '@ngxs/store';
import { UsersState } from './users.state';
import { UsersStateModel } from './users-state.model';
import { User } from '@app/modules/users/interfaces/user';
import { UsersListFilters } from '@app/pages/users/list/interfaces/users-list-filters';

export class UsersSelectors {
  @Selector([UsersState])
  static users(state: UsersStateModel): User[] {
    return state.users;
  }

  @Selector([UsersState])
  static page(state: UsersStateModel): number {
    return state.page;
  }

  @Selector([UsersState])
  static maxPage(state: UsersStateModel): number {
    return state.maxPage;
  }

  @Selector([UsersState])
  static filters(state: UsersStateModel): UsersListFilters {
    return state.filters;
  }

  @Selector([UsersState])
  static search(state: UsersStateModel): string {
    return state.filters.search;
  }

  @Selector([UsersState])
  static isFavorite(state: UsersStateModel): boolean {
    return state.filters.isFavorite;
  }

  static isOnline(userID: number): (state: UsersStateModel) => boolean {
    return createSelector([UsersState], (state: UsersStateModel) => {
      return state.online.users[userID]?.length > 0;
    });
  }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthSelectors, CompaniesViewSelectors } from '@app/store';
import { Company } from '@app/modules/companies/interfaces/company';

@Injectable()
export class ViewCompanyMatchesAuthCompanyGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.store.selectOnce(AuthSelectors.is_super_admin),
      this.store.selectOnce(CompaniesViewSelectors.company),
      this.store.selectOnce(AuthSelectors.company),
    ]).pipe(
      map((result: [boolean, Company, Company]) => {
        return result[0] || result[1]?.id === result[2]?.id;
      })
    );
  }
}

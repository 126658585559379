import { DatePeriod } from '@app/interfaces/date-period';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { CompanyTendersMarkup } from '@app/modules/companies/interfaces/company-tenders-markup';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { Selector } from '@ngxs/store';
import { CompaniesViewTendersStateModel } from '../../companies_view_tenders-state.model';
import { CompaniesViewTendersCreatedTransportState } from './companies_view_tenders-created-transport.state';

export class CompaniesViewTendersCreatedTransportSelectors {
  @Selector([CompaniesViewTendersCreatedTransportState])
  static datePeriod(state: CompaniesViewTendersStateModel): DatePeriod {
    return state.datePeriod;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static state(state: CompaniesViewTendersStateModel): TenderStates[] {
    return state.state;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static tenders(state: CompaniesViewTendersStateModel): CompanyTender[] {
    return state.tenders;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static markups(
    state: CompaniesViewTendersStateModel
  ): CompanyTendersMarkup[] {
    return state.markups;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static page(state: CompaniesViewTendersStateModel): number {
    return state.page;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static maxPage(state: CompaniesViewTendersStateModel): number {
    return state.maxPage;
  }

  @Selector([CompaniesViewTendersCreatedTransportState])
  static filteredResultsCount(
    state: CompaniesViewTendersStateModel
  ): FilteredTendersResultsCount {
    return state.filteredResultsCount;
  }
}

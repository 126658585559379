import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  FetchCargoTenders,
  ReadCargoTender,
  LoadNewCargoTendersCount,
  ReloadCargoTender,
  ToggleCargoTendersListView,
  SetCargoTendersSearchValue,
} from './cargo-tenders_list.actions';
import { CargoTendersListStateModel } from './cargo-tenders_list-state.model';
import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { tap } from 'rxjs/operators';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { DeleteBet } from '@app/store/bets/bets.actions';
import { BetsService } from '@app/modules/bets/bets.service';
import { UiService } from '@app/services/ui.service';

import { CargoTendersListViews } from '@app/pages/cargo-tenders/list/cargo-tenders-list-views';
import {
  CargoTendersListFiltersState,
  ResetCargoTendersListFilters,
  SetCargoTendersListFilteredResultsCount,
  SetCargoTendersListFilters,
} from './filters';
import { CargoTendersListFiltersStateModel } from './filters/cargo-tenders_list_filters-state.model';
import { CargoTendersListFiltersFormData } from '@app/pages/cargo-tenders/list/components/filters-form/cargo-tenders-list-filters.form-data';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CargoTendersListCarState, SetCarCargoTenders } from './car';
import { CargoTendersListSeaState, SetSeaCargoTenders } from './sea';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { User } from '@ionic/cli';
import { Company } from '@app/modules/companies/interfaces/company';

@Injectable()
@State<CargoTendersListStateModel>({
  name: 'cargo_tenders__list',
  defaults: {
    view: CargoTendersListViews.COMPACT,
    searchValue: null,
    page: 1,
    maxPage: null,
    newTendersCount: 0,
  },
  children: [
    CargoTendersListFiltersState,
    CargoTendersListCarState,
    CargoTendersListSeaState,
  ],
})
export class CargoTendersListState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private betsService: BetsService,
    private store: Store,
    private uiService: UiService
  ) {}

  @Action([ResetCargoTendersListFilters, SetCargoTendersListFilters])
  @ImmutableContext()
  onFilterValueChange({ setState }: StateContext<CargoTendersListStateModel>) {
    return setState((state: CargoTendersListStateModel) => {
      state.page = 1;
      return state;
    });
  }

  @Action(SetCargoTendersSearchValue)
  @ImmutableContext()
  setCargoTendersSearchValue(
    { setState }: StateContext<CargoTendersListStateModel>,
    { searchValue }: SetCargoTendersSearchValue
  ) {
    return setState((state: CargoTendersListStateModel) => {
      state.searchValue = searchValue;
      state.page = 1;
      return state;
    });
  }

  @Action(FetchCargoTenders)
  @ImmutableContext()
  fetchCargoTenders(
    { getState, setState }: StateContext<CargoTendersListStateModel>,
    { page }: FetchCargoTenders
  ) {
    const newPage = page || getState().page;

    const filtersModel: CargoTendersListFiltersStateModel =
      this.store.selectSnapshot(CargoTendersListFiltersState);

    let filters: CargoTendersListFiltersFormData = {
      state: filtersModel.states,
      createdAtFrom: filtersModel.createdAtFrom,
      createdAtTo: filtersModel.createdAtTo,
      countryFrom: filtersModel.countryFrom,
      countryTo: filtersModel.countryTo,
      isMyTenders: +filtersModel.isMyTenders,
      isInternational: +filtersModel.isInternational,
      userId: (filtersModel.userId as unknown as User)?.id,
      partnerId: (filtersModel.partnerId as unknown as Company)?.id,
      isFavorite: filtersModel.isFavorite ? 1 : 0,
    };

    filters = Object.keys(filters)
      .filter((key) => filters[key] && filters[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = filters[key];
        return newObj;
      }, {});

    // Fetch Car Cargo Tenders
    if (
      filtersModel.cargoTendersTransportationType ===
      CargoTendersTransportationTypes.CAR
    ) {
      return this.cargoTendersCarService
        .list(page, getState().searchValue, filters)
        .pipe(
          tap(
            (
              result: PaginationResults<
                CarCargoTender,
                FilteredTendersResultsCount
              >
            ) => {
              this.store.dispatch(new SetCarCargoTenders(result.data));
              this.store.dispatch(
                new SetCargoTendersListFilteredResultsCount(result.filter)
              );
              return setState((state: CargoTendersListStateModel) => {
                state.page = newPage;
                state.maxPage = result.maxPage;
                return state;
              });
            }
          )
        );
    }

    // Fetch Sea Cargo Tenders
    if (
      filtersModel.cargoTendersTransportationType ===
      CargoTendersTransportationTypes.SEA
    ) {
      return this.cargoTendersSeaService
        .list(page, getState().searchValue, filters)
        .pipe(
          tap(
            (
              result: PaginationResults<
                SeaCargoTender,
                FilteredTendersResultsCount
              >
            ) => {
              this.store.dispatch(new SetSeaCargoTenders(result.data));
              this.store.dispatch(
                new SetCargoTendersListFilteredResultsCount(result.filter)
              );
              setState((state: CargoTendersListStateModel) => {
                state.page = newPage;
                state.maxPage = result.maxPage;
                return state;
              });
            }
          )
        );
    }
  }

  @Action(ReloadCargoTender)
  @ImmutableContext()
  reloadCargoTender(
    { getState, setState }: StateContext<CargoTendersListStateModel>,
    { tenderId }: ReloadCargoTender
  ) {}

  @Action(ReadCargoTender)
  @ImmutableContext()
  readCargoTender(
    { setState }: StateContext<CargoTendersListStateModel>,
    { tenderId }: ReadCargoTender
  ) {
    // todo: this should run under proper condition: state.newTendersCount--;
    // return this.cargoTendersCarService.markTenderAsRead(tenderId).pipe(
    //   tap(() => {
    //     setState((state: CargoTendersListStateModel) => {
    //       const tenderIndex = state.tenders.findIndex(
    //         (tender: CarCargoTender) => {
    //           return tender.id === tenderId;
    //         }
    //       );
    //       if (tenderIndex !== -1) {
    //         if (state.tenders[tenderIndex].isNew) {
    //           state.newTendersCount--;
    //         }
    //         state.tenders[tenderIndex].isNew = false;
    //         state.tenders[tenderIndex].isRead = true;
    //       }
    //       return state;
    //     });
    //   })
    // );
  }

  @Action(LoadNewCargoTendersCount)
  @ImmutableContext()
  loadNewTendersCount({ setState }: StateContext<CargoTendersListStateModel>) {
    return this.cargoTendersCarService.getNewTendersCount().pipe(
      tap((newTendersCount: number) => {
        setState((state: CargoTendersListStateModel) => {
          state.newTendersCount = newTendersCount;
          return state;
        });
      })
    );
  }

  @Action(DeleteBet)
  @ImmutableContext()
  deleteBet({}: StateContext<CargoTendersListStateModel>, { bet }: DeleteBet) {
    this.uiService.displayLoading();
    return this.betsService.remove(bet).pipe(
      tap(() => {
        this.uiService.dismissLoading();
      })
    );
  }

  @Action(ToggleCargoTendersListView)
  @ImmutableContext()
  toggleCargoTendersListView({
    setState,
  }: StateContext<CargoTendersListStateModel>) {
    return setState((state: CargoTendersListStateModel) => {
      state.view =
        state.view === CargoTendersListViews.NORMAL
          ? CargoTendersListViews.COMPACT
          : CargoTendersListViews.NORMAL;
      return state;
    });
  }
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { NotifificationsStateModel } from './notifications-state.model';
import { Injectable } from '@angular/core';
import {
  LoadUserNotifications,
  UpdateUserNotificationState,
} from './notifications.actions';
import { tap } from 'rxjs/operators';
import { NotificationsService } from '@app/modules/notifications/services/notifications.service';
import { UserNotification } from '@app/modules/notifications/interfaces/user-notification';

@Injectable()
@State<NotifificationsStateModel>({
  name: 'notifications',
  defaults: {
    notifications: null,
  },
})
export class NotificationsState {
  constructor(private notificationsService: NotificationsService) {}

  @Action(LoadUserNotifications, { cancelUncompleted: true })
  @ImmutableContext()
  loadUserNotifications({
    getState,
    setState,
  }: StateContext<NotifificationsStateModel>) {
    if (getState().notifications) {
      return;
    }

    return this.notificationsService.list().pipe(
      tap((notifications: UserNotification[]) => {
        setState((state: NotifificationsStateModel) => {
          state.notifications = notifications;
          return state;
        });
      })
    );
  }

  @Action(UpdateUserNotificationState)
  @ImmutableContext()
  updateUserNotificationState(
    { setState }: StateContext<NotifificationsStateModel>,
    { notificationId, notificationType, state }: UpdateUserNotificationState
  ) {
    return this.notificationsService
      .update(notificationId, notificationType, state)
      .pipe(
        tap((userNotifications: UserNotification[]) => {
          const updatedUserNotification = userNotifications[0];
          setState((state: NotifificationsStateModel) => {
            const index = state.notifications.findIndex(
              (currentNotification: UserNotification) => {
                return (
                  currentNotification.notification.id ===
                  updatedUserNotification.notification.id
                );
              }
            );
            state.notifications[index] = updatedUserNotification;
            return state;
          });
        })
      );
  }
}

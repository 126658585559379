import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { catchError, Observable, of, throwError } from 'rxjs';
import { DriverPassportDataFormData } from '@app/modules/drivers/interfaces/driver-passport-data.form-data';
import { DriverInterface } from '@app/modules/drivers/interfaces/driver.interface';
import { NotFound } from '@app/errors/response-errors/not-found';

@Injectable({
  providedIn: 'root',
})
export class DriversService {
  constructor(private apiService: ApiService) {}

  get(id: number): Observable<DriverInterface> {
    return this.apiService.get<DriverInterface>('/driver/' + id).pipe(
      catchError((error: any) => {
        if (error instanceof NotFound) {
          return of(null);
        }

        return throwError(() => error);
      })
    );
  }

  updatePassportData(
    id: number,
    data: DriverPassportDataFormData
  ): Observable<DriverInterface> {
    return this.apiService.put<DriverInterface>('/driver/' + id, data);
  }
}

import { UserCity } from '@app/modules/weather/interfaces/user-city';

export class SelectCity {
  static readonly type = '[Widgets -> Weather] Select city';
  constructor(public city?: UserCity) {}
}

export class LoadWeatherData {
  static readonly type = '[Widgets -> Weather] Load weather data';
  constructor() {}
}

export class LoadUserCities {
  static readonly type = '[Widgets -> Weather] Load user cities list';
}

export class WeatherWidgetAddCity {
  static readonly type = '[Widgets -> Weather] Add city';
  constructor(public city?: string) {}
}

export class WeatherWidgetRemoveCity {
  static readonly type = '[Widgets -> Weather] Remove city';
  constructor(public city?: UserCity) {}
}

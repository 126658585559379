import { DriverPassportDataFormData } from '@app/modules/drivers/interfaces/driver-passport-data.form-data';

export class LoadDriver {
  static readonly type = '[Profile -> Documents] Load driver';
  constructor(public id: number) {}
}

export class UpdateDriverPassportData {
  static readonly type = '[Profile -> documents] Update driver passport data';
  constructor(public id: number, public data: DriverPassportDataFormData) {}
}

export class UploadUserSignature {
  static readonly type = '[Profile -> documents] Upload user signature';
  constructor(public file: File) {}
}

import { NotificationTypes } from '@app/modules/notifications/notification-types';
import { UserNotificationStates } from '@app/modules/notifications/user-notification-states';

export class LoadUserNotifications {
  static readonly type =
    '[Profile Page -> Notifications] Load user notifications';
  constructor() {}
}

export class UpdateUserNotificationState {
  static readonly type =
    '[Profile Page -> Notifications] Update user notification state';
  constructor(
    public notificationId: number,
    public notificationType: NotificationTypes,
    public state: UserNotificationStates
  ) {}
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { PushNotificationsDeviceToken } from '@app/modules/push-notifications/interfaces/push-notifications-device-token';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  constructor(private apiService: ApiService) {}

  saveToken(
    token: string,
    deviceId: string,
    deviceName?: string
  ): Observable<PushNotificationsDeviceToken> {
    return this.apiService.create<PushNotificationsDeviceToken>(
      '/device-token',
      {
        deviceId,
        deviceToken: token,
        deviceName,
      }
    );
  }

  removeToken(token: string): Observable<unknown> {
    return this.apiService.delete('/device-token/by-token/' + token);
  }
  removeDevice(deviceId: string): Observable<unknown> {
    return this.apiService.delete('/device-token/by-device-id/' + deviceId);
  }
}

import { BillsListFilters } from '@app/modules/bills/interfaces/bills-list-filters';

export class LoadCompanyFinancesAccountsReceivableBillsList {
  static readonly type =
    '[Company finances -> Bills -> Accounts Receivable] Load bills list';
  constructor() {}
}

export class SetCompanyFinancesAccountsReceivableBillsListFilters {
  static readonly type =
    '[Company finances -> Bills -> Accounts Receivable] Set filters';
  constructor(public filters: BillsListFilters) {}
}

import { FieldsErrors } from '@app/interfaces/fields-errors';
import { Comment } from '@app/modules/comments/interfaces/comment';
import { Observable } from 'rxjs';

export class InitAddCommentForm {
  static readonly type = '[Add comment] Initialize the add comment form';
  constructor() {}
}

export class TryAddComment {
  static readonly type = '[Add comment] Try to add a comment.';
  constructor(public request$: Observable<unknown>) {}
}

export class AddCommentFailed {
  static readonly type =
    '[Add comment] Unable to add a comment. Wrong data provided?';
  constructor(public errors: FieldsErrors) {}
}

export class AddCommentSuccess {
  static readonly type = '[Add comment] The comment was successfully added!';
  constructor() {}
}

export class SetReplyToComment {
  static readonly type = '[Add comment] Set "reply to" comment.';
  constructor(public comment: Comment) {}
}

import { Injectable } from '@angular/core';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { Observable, Subscriber } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { CompanyAvatarUpdateResponse } from '@app/modules/companies/interfaces/company-avatar-update-response';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private apiService: ApiService) {}

  public uploadPicture(
    file: File,
    companyId?: number,
    userId?: number
  ): Observable<NewAccessTokenResponse | CompanyAvatarUpdateResponse> {
    return this.fileAsBase64(file).pipe(
      mergeMap((imageAsBase64: string) => {
        const data = {
          image: imageAsBase64,
        };

        if (companyId) {
          return this.apiService.put<CompanyAvatarUpdateResponse>(
            '/company/' + companyId + '/details/avatar',
            {
              avatar: imageAsBase64,
            }
          );
        }

        if (userId) {
          return this.apiService.post<NewAccessTokenResponse>(
            '/user/' + userId + '/avatar',
            {
              image: imageAsBase64,
            }
          );
        }

        return this.apiService.post('/profile/avatar', data).pipe(
          map((response: ResponseSuccess<NewAccessTokenResponse>) => {
            return response.data;
          })
        );
      })
    );
  }

  fileAsBase64(file: File): Observable<string> {
    return new Observable<string>((subscriber: Subscriber<string>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };

      reader.onerror = () => {
        subscriber.error();
        subscriber.complete();
      };
    });
  }

  fileHash(file: File): number {
    return this.stringHash(`${file.name}_${file.size}`);
  }

  private stringHash(str: string): number {
    let hash = 0;
    let charCode: number;

    if (str.length === 0) return hash;
    for (let i = 0; i < str.length; i++) {
      charCode = str.charCodeAt(i);
      hash = (hash << 5) - hash + charCode;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
}

import { Selector } from '@ngxs/store';
import { StickyNotesState } from './sticky-notes.state';
import { StickyNotesStateModel } from './sticky-notes.model';
import { StickyNote } from '@app/modules/sticky-notes/interfaces/sticky-note';
import { StickyNoteColor } from '@app/modules/sticky-notes/interfaces/sticky-note-color';

export class StickyNotesSelectors {
  @Selector([StickyNotesState])
  static colors(state: StickyNotesStateModel): StickyNoteColor[] {
    return state.colors;
  }

  @Selector([StickyNotesState])
  static notes(state: StickyNotesStateModel): StickyNote[] {
    return state.notes;
  }

  @Selector([StickyNotesState])
  static count(state: StickyNotesStateModel): number {
    return state.notes ? state.notes.length : state.count;
  }
}

import { Observable } from 'rxjs';

export class ListTransportTenders {
  static readonly type = '[Tabs Page] List Transport Tenders';
  constructor() {}
}

export class ListWarehouses {
  static readonly type = '[Tabs Page] List Warehouses';
  constructor() {}
}

export class CreateCargoTender {
  static readonly type = '[Tabs Page] Create Cargo Tender';
  constructor() {}
}

export class CreateTransportTender {
  static readonly type = '[Tabs Page] Create Transport Tender';
  constructor() {}
}

export class CreateWarehouse {
  static readonly type = '[Tabs Page] Create Warehouse';
  constructor() {}
}

export class ViewCarCargoTender {
  static readonly type = '[Tabs Page] View Car Cargo Tender';
  constructor(public tenderId?: number, public tenderNumber?: string) {}
}

export class ViewSeaCargoTender {
  static readonly type = '[Tabs Page] View Sea Cargo Tender';
  constructor(public tenderId?: number, public tenderNumber?: string) {}
}

export class ViewTransportTender {
  static readonly type = '[Tabs Page] View Transport tender';
  constructor(public tenderId?: number, public tenderNumber?: string) {}
}

export class ViewWarehouse {
  static readonly type = '[Tabs Page] View Warehouse';
  constructor(public id: number) {}
}

export class InitDashboard {
  static readonly type = '[Tabs Page] Init dashboard';
  constructor() {}
}

export class LoadCompanies {
  static readonly type = '[Tabs Page] Load companies';
  constructor(public destroy$: Observable<any>) {}
}

export class ChangeCompany {
  static readonly type = '[Tabs Page] Change company';
  constructor(public companyId?: number) {}
}

export class ChangeCompanyFailed {
  static readonly type = '[Tabs Page] Failed to change the company';
  constructor() {}
}

export class ChangeCompanySuccess {
  static readonly type =
    '[Tabs Page] The company has been changed successfully';
  constructor(public prevCompanyID: number, public newCompanyID: number) {}
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesCreateStateModel } from './companies_create-state.model';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import {
  InitCreateCompanyForm,
  TryCreateCompany,
  CreateCompanyFailed,
  CreateCompanySucceess,
} from './companies_create.actions';
import { CountriesService } from '@app/modules/countries/countries.service';
import { UiService } from '@app/services/ui.service';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { ResponseError } from '@app/interfaces/response-error';

@Injectable()
@State<CompaniesCreateStateModel>({
  name: 'companies__create',
  defaults: {
    fieldsErrors: null,
  },
})
export class CompaniesCreateState {
  constructor(
    private store: Store,
    private companiesService: CompaniesService,
    private countriesService: CountriesService,
    private uiService: UiService
  ) {}

  @Action(InitCreateCompanyForm)
  @ImmutableContext()
  initPage({ setState }: StateContext<CompaniesCreateStateModel>) {
    return setState((state: CompaniesCreateStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(TryCreateCompany)
  @ImmutableContext()
  createCompany(
    { setState }: StateContext<CompaniesCreateStateModel>,
    { payload }: TryCreateCompany
  ) {
    setState((state: CompaniesCreateStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    this.uiService.displayLoading();
    return this.companiesService.createCompany(payload).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new CreateCompanySucceess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new CreateCompanyFailed(
              (err.originalError.error as ResponseError).errors
            )
          );
          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(CreateCompanyFailed)
  @ImmutableContext()
  createCompanyFailed(
    { setState }: StateContext<CompaniesCreateStateModel>,
    { errors }: CreateCompanyFailed
  ) {
    setState((state: CompaniesCreateStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CalendarAddEditStateModel } from './calendar_add-edit.state-model';
import {
  CreateTaskInCalendar,
  UpdateTaskInCalendar,
} from './calendar_add-edit.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';

@Injectable()
@State<CalendarAddEditStateModel>({
  name: 'calendar__add_edit',
  defaults: {},
})
export class CalendarAddEditState {
  constructor(private calendarService: CalendarService) {}

  @Action(CreateTaskInCalendar)
  @ImmutableContext()
  createTaskInCalendar(
    {}: StateContext<CalendarAddEditStateModel>,
    { data }: CreateTaskInCalendar
  ) {
    return this.calendarService.create(data);
  }

  @Action(UpdateTaskInCalendar)
  @ImmutableContext()
  updateTaskInCalendar(
    {}: StateContext<CalendarAddEditStateModel>,
    { id, data }: UpdateTaskInCalendar
  ) {
    return this.calendarService.update(id, data);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, combineLatest, map, of } from 'rxjs';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { Bet } from './interfaces/bet';
import { ConfirmedBetUpdateData } from './interfaces/confirmed-bet-update-data';
import { CargoTender } from '../tenders/interfaces/cargo-tender';
import { TenderStates } from '../tenders/tender-states';

@Injectable({
  providedIn: 'root',
})
export class BetsService {
  constructor(private apiService: ApiService) {}

  add(
    cargoTenderTransportationType: CargoTendersTransportationTypes,
    tenderId: number,
    price: number,
    deliveryDate?: string,
    minPrice?: number,
    comment?: string,
    duration?: number,
    markup?: number,
    markupPercent?: number,
    clientSum?: number,
    carrierVat?: boolean,
    clientVat?: boolean
  ) {
    const data: any = {
      price,
    };

    if (deliveryDate) {
      data.deliveryDate = deliveryDate;
      data.priceMin = minPrice;
    }

    if (comment) {
      data.comment = comment;
    }

    if (duration) {
      data.duration = duration;
    }

    if (markup) {
      data.markup = markup;
    }

    if (markupPercent) {
      data.markupPercent = markupPercent;
    }

    if (clientSum) {
      data.clientSum = clientSum;
    }

    if (typeof carrierVat === 'boolean') {
      data.carrierVat = carrierVat;
    }

    if (typeof clientVat === 'boolean') {
      data.clientVat = clientVat;
    }

    return this.apiService.post(
      '/tender/' + tenderId + '/type/' + cargoTenderTransportationType + '/bet',
      data
    );
  }

  changeMarkup(betId: number, markup: number): Observable<unknown> {
    return this.apiService.post<unknown>(
      '/tender/bet/' + betId + '/sea-markup-create',
      {
        markup,
      }
    );
  }

  update(
    cargoTenderTransportationType: CargoTendersTransportationTypes,
    tenderId: number,
    price: number
  ) {
    return this.apiService.post(
      '/tender/' +
        tenderId +
        '/type/' +
        cargoTenderTransportationType +
        '/add-bet',
      { price }
    );
  }

  updateConfirmedBet(
    betId: number,
    data: ConfirmedBetUpdateData
  ): Observable<Bet> {
    return this.apiService.put<Bet>('/tender/bet/' + betId, data);
  }

  remove(bet: Bet): Observable<unknown> {
    return this.apiService.delete('/tender/bet/' + bet.id);
  }

  allowChangeBet(
    isCtEmployee$: Observable<boolean>,
    isSuperAdmin$: Observable<boolean>,
    tender: CargoTender
  ): Observable<boolean> {
    if (!tender.winBet) {
      return of(false);
    }

    return combineLatest([isCtEmployee$, isSuperAdmin$]).pipe(
      map((result: [boolean, boolean]) => {
        if (!result[0] && !result[1]) {
          return false;
        }

        let allowedTenderStates: TenderStates[] = [
          TenderStates.ACTIVE,
          TenderStates.CLOSED,
          TenderStates.ACTIVE_WITH_BET,
          TenderStates.CONFIRMED,
          TenderStates.CONFIRMED_BY_CARRIER,
          TenderStates.IN_PROGRESS,
          TenderStates.CARGO_DELIVERED,
        ];

        return allowedTenderStates.includes(tender.state.code);
      })
    );
  }
}

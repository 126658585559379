import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { CompanyFinancesTendersReportState } from './tenders-report';
import { CompanyFinancesBillsState } from './bills';
import { CompanyFinancesStateModel } from './company-finances-state.model';
import { CompanyFinancesTendersStatementState } from './tenders-statement/company-finances_tenders-statement.state';
import { CompanyFinancesStatisticsState } from './statistics';
import { CompanyFinancesTransportTendersStatementState } from './transport-tenders-statement';

@Injectable()
@State<CompanyFinancesStateModel>({
  name: 'company_finances',
  children: [
    CompanyFinancesTendersStatementState,
    CompanyFinancesTendersReportState,
    CompanyFinancesBillsState,
    CompanyFinancesStatisticsState,
    CompanyFinancesTransportTendersStatementState,
  ],
})
export class CompanyFinancesState {}

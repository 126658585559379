import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { SetCarTendersOnTendersPage } from './tenders-page_car.actions';
import { TendersPageCarStateModel } from './tenders-page_car-state.model';
import { Injectable } from '@angular/core';

@Injectable()
@State<TendersPageCarStateModel>({
  name: 'tenders_page__car',
  defaults: {
    tenders: null,
  },
})
export class TendersPageCarState {
  @Action(SetCarTendersOnTendersPage)
  @ImmutableContext()
  setCarTendersOnTendersPage(
    { setState }: StateContext<TendersPageCarStateModel>,
    { tenders }: SetCarTendersOnTendersPage
  ) {
    return setState((state: TendersPageCarStateModel) => {
      state.tenders = tenders;
      return state;
    });
  }
}

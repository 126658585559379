import { NgModule } from '@angular/core';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';

// modified

import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@envs/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AuthGuard } from './guards/auth';
// import { GuestGuard } from './guards/guest';

// NGXS and its plugins
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';

// NGXS labs
import { NgxsEmitPluginModule } from '@ngxs-labs/emitter';
import { NgxsDispatchPluginModule } from '@ngxs-labs/dispatch-decorator';
import { NgxsActionsExecutingModule } from '@ngxs-labs/actions-executing';

import { IonicStorageModule } from '@ionic/storage-angular';

import { AuthModule } from './modules/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppState } from './store';
import { enableMapSet } from 'immer';

import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import {
  TranslateCompiler,
  TranslateLoader,
  TranslateModule,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// import { AuthInterceptor } from './interceptors/auth';

// const guards = [AuthGuard, GuestGuard];

import { APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { SelectedLanguageInterceptor } from './modules/translate/selected-language.interceptor';

enableMapSet();

export function tokenGetter(): string {
  const auth: string = localStorage.getItem('auth');
  // yes, undefined must be string, because localStorage can store only strings
  if (!auth || auth === 'undefined') {
    return null;
  }

  const json: { token: string } = JSON.parse(auth);
  if (!auth) {
    return null;
  }

  return json.token;
}

const sentryProviders = environment.sentry_dsn
  ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ]
  : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.api_domain],
      },
    }),
    NgxsModule.forRoot(AppState, {
      selectorOptions: {
        suppressErrors: true,
        injectContainerState: false,
      },
      developmentMode: !environment.production,
    }),
    // It is recommended to register the storage plugin before other plugins so initial state can be picked up by those plugins.
    NgxsStoragePluginModule.forRoot({
      key: 'auth',
    }),
    NgxsResetPluginModule.forRoot(),
    NgxsEmitPluginModule.forRoot(),
    NgxsDispatchPluginModule.forRoot(),
    NgxsActionsExecutingModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    IonicStorageModule.forRoot(),
    ...environment.plugins,
    AuthModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'ru',
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...sentryProviders,
    Keyboard,
    Badge,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedLanguageInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

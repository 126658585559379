import { Selector } from '@ngxs/store';
import { TendersPageState } from './tenders-page.state';
import { TendersPageStateModel } from './tenders-page-state.model';

export class TendersPageSelectors {
  @Selector([TendersPageState])
  static page(state: TendersPageStateModel): number {
    return state.page;
  }

  @Selector([TendersPageState])
  static maxPage(state: TendersPageStateModel): number {
    return state.maxPage;
  }
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  HideTransportTendersListFilters,
  ResetTransportTendersListFilters,
  SetTransportTendersListFilters,
  ShowTransportTendersListFilters,
} from './transport-tenders_list_filters.actions';
import { TransportTendersListFiltersStateModel } from './transport-tenders_list_filters-state.model';
import * as moment from 'moment';
import { StateReset } from 'ngxs-reset-plugin';

@Injectable()
@State<TransportTendersListFiltersStateModel>({
  name: 'transport_tenders__list__filters',
  defaults: {
    displayFilters: false,
    isFavorite: false,
    countryFrom: 0,
    countryTo: 0,
    createdAtFrom: null,
    createdAtTo: null,
  },
})
export class TransportTendersListFiltersState {
  constructor(private store: Store) {}

  @Action(ShowTransportTendersListFilters)
  @ImmutableContext()
  showTransportTendersListFilters({
    setState,
  }: StateContext<TransportTendersListFiltersStateModel>) {
    return setState((state: TransportTendersListFiltersStateModel) => {
      state.displayFilters = true;
      return state;
    });
  }

  @Action(HideTransportTendersListFilters)
  @ImmutableContext()
  hideCargoTendersListFilters({
    setState,
  }: StateContext<TransportTendersListFiltersStateModel>) {
    return setState((state: TransportTendersListFiltersStateModel) => {
      state.displayFilters = false;
      return state;
    });
  }

  @Action(ResetTransportTendersListFilters)
  @ImmutableContext()
  resetTransportTendersListFilters() {
    this.store.dispatch(new StateReset(TransportTendersListFiltersState));
  }

  @Action(SetTransportTendersListFilters)
  @ImmutableContext()
  setTransportTendersListFilters(
    { setState }: StateContext<TransportTendersListFiltersStateModel>,
    { filters }: SetTransportTendersListFilters
  ) {
    return setState((state: TransportTendersListFiltersStateModel) => {
      state.displayFilters = false;
      state.isFavorite = !!filters.isFavorite;
      state.createdAtFrom = filters.createdAtFrom;
      state.createdAtTo = filters.createdAtTo;
      state.countryFrom = filters.countryFrom;
      state.countryTo = filters.countryTo;
      return state;
    });
  }
}

import { Company } from '@app/modules/companies/interfaces/company';
import { Selector } from '@ngxs/store';
import { TabsState } from './tabs.state';
import { TabsStateModel } from './tabs-state.model';

export class TabsSelectors {
  @Selector([TabsState])
  static companies(state: TabsStateModel): Company[] {
    return state.companies;
  }
}

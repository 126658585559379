import { ChatTabs } from '@app/modules/chat/chat-tabs';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';

export class ChatStateModel {
  activeTab: ChatTabs;
  isChatExpanded: boolean;
  consultantChatGroupID: number;
  chatGroups: { [key: number]: ChatGroup };
  reopenLater: boolean;
}

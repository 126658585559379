import { User } from '@app/modules/users/interfaces/user';

export class ChatContactsStateModel {
  searchContact: boolean;
  chatContactsSearchValue: string;
  searchResult: User;
  groupSearchContact: boolean;
  groupSearchResult: User;
  chatContacts: User[];
}

import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { TenderPointStates } from '@app/modules/tenders/tender-point-states';

export class CloseSeaCargoTender {
  static readonly type = '[View Sea Cargo Tender] Close sea cargo tender';
  constructor(public id: number) {}
}

export class RefuseSeaCargoTender {
  static readonly type = '[View Sea Cargo Tender] Refuse sea cargo tender';
  constructor(public id: number) {}
}

export class CancelSeaCargoTender {
  static readonly type = '[View Sea Cargo Tender] Cancel sea cargo tender';
  constructor(public id: number) {}
}

export class CompleteSeaCargoTender {
  static readonly type =
    '[View Sea Cargo Tender] Mark sea cargo tender as completed';
  constructor(public id: number) {}
}

export class SetSeaCargoTenderPointState {
  static readonly type =
    '[View Sea Cargo Tender] Set sea cargo tender point state';
  constructor(public pointId: number, public state: TenderPointStates) {}
}

export class ToggleCanChangeTransportInSeaCargoTender {
  static readonly type =
    '[View Sea Cargo Tender] Toggle checkbox: Allow change transport and driver data for carrier in sea cargo tender';
  constructor(public tenderId: number) {}
}

export class RepeatSeaCargoTender {
  static readonly type =
    '[View Sea Cargo Tender] Repeat sea cargo tender (Create sea cargo tender with populated form data)';
  constructor(public tender: SeaCargoTender) {}
}

export class NewBetPlacedInSeaCargoTender {
  static readonly type =
    '[View Sea Cargo Tender / Sea Cargo tender bets] Mercury signal: A new bet was placed in sea cargo tender';
  constructor(public tenderId: number) {}
}

export class SetSeaCargoTenderInProgress {
  static readonly type =
    '[View Sea Cargo Tender] Set sea cargo tender state to in-progress';
  constructor(public tenderId: number) {}
}

export class SetSeaCargoTenderStateCargoDelivered {
  static readonly type =
    '[View Sea Cargo Tender] Set sea cargo tender state to cargo delivered';
  constructor(public tenderId: number) {}
}

export class DeleteSeaCargoTender {
  static readonly type = '[View Sea Cargo Tender] Delete sea cargo tender';
  constructor(public tenderId: number) {}
}

export class UpdateSeaCargoPointArrivalDate {
  static readonly type =
    '[View Sea Cargo Tender] Update sea cargo tender point arrival date';
  constructor(public pointID: number, public arrivalDate: string) {}
}

export class SetSeaCargoTenderBookingNumber {
  static readonly type =
    '[View Sea Cargo Tender] Set sea cargo tender booking number';
  constructor(
    public tenderId: number,
    public bookingNumber: string,
    public additionalBookingNumbers: string[]
  ) {}
}

export class SetSeaCargoTenderContainerNumber {
  static readonly type =
    '[View Sea Cargo Tender] Set sea cargo tender container number';
  constructor(public tenderId: number, public containerNumber: string) {}
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { WidgetConfig } from '@app/modules/widgets/interfaces/widget-config';
import { WidgetTypes } from '@app/modules/widgets/types/widget-types';
import { CompanyTendersOverallStatisticsData } from '@app/store/widgets/company_tenders_overall_statistics/interface/company-tenders-overall-statistics-data';
import { CompanyTendersDistributionData } from '@app/store/widgets/company_tenders_distribution/interface/company-tenders-distribution-data';
import { WidgetsGroups } from '@app/modules/widgets/interfaces/widgets-groups';
import { BirthdayWidgetDataRecord } from '@app/modules/widgets/components/birthday-widget/interfaces/birthday-widget-data-record';
import { User } from '@app/modules/users/interfaces/user';
import { AttendanceSummaryData } from '@app/store/widgets/attendance/interface/attendance-summary-data';

@Injectable({
  providedIn: 'root',
})
export class WidgetsService {
  constructor(private apiService: ApiService) {}

  list(): Observable<WidgetsGroups<WidgetTypes[]>> {
    return this.apiService.get<WidgetsGroups<WidgetTypes[]>>('/widgets');
  }

  getConfigs(): Observable<WidgetsGroups<WidgetConfig[]>> {
    return this.apiService.get<WidgetsGroups<WidgetConfig[]>>(
      '/widgets/setting'
    );
  }

  toggle(widgetId: number, isEnabled: boolean): Observable<unknown> {
    return this.apiService.put('/widget/' + widgetId, { enabled: isEnabled });
  }

  reorder(widgetId: number, order: number): Observable<unknown> {
    return this.apiService.put('/widget/' + widgetId + '/sort', { order });
  }

  getCompanyTendersOverallStatisticsData(
    dateFrom?: string,
    dateTo?: string,
    companyId?: number
  ): Observable<CompanyTendersOverallStatisticsData> {
    return this.apiService.get<CompanyTendersOverallStatisticsData>(
      '/widget/company-tenders-overall-statistics',
      {
        companyId,
        from: dateFrom,
        to: dateTo,
      }
    );
  }

  getCompanyTendersDistributionData(
    dateFrom?: string,
    dateTo?: string,
    companyId?: number
  ): Observable<CompanyTendersDistributionData> {
    return this.apiService.get<CompanyTendersDistributionData>(
      '/widget/company-tenders-distribution',
      {
        companyId,
        from: dateFrom,
        to: dateTo,
      }
    );
  }

  getAttendanceSummaryData(
    dateFrom?: string,
    dateTo?: string
  ): Observable<AttendanceSummaryData> {
    return this.apiService.get<AttendanceSummaryData>('/widget/attendance', {
      from: dateFrom,
      to: dateTo,
    });
  }

  getBirthdayWidgetComponentData(): Observable<BirthdayWidgetDataRecord[]> {
    return this.apiService.get<BirthdayWidgetDataRecord[]>('/widget/birthday');
  }

  getCustomerSupportUser(): Observable<User> {
    return this.apiService.get<User>('/user/edgar', {
      fields: [
        'id',
        'avatar',
        'first_name',
        'middle_name',
        'last_name',
        'isContact',
        'isBlacklist',
      ].join(','),
    });
  }
}

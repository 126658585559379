export class ListTendersChats {
  static readonly type =
    '[Chat -> Tenders chats] Load the list of chats that are related to tenders (the first page only)';
}

export class FetchTendersChats {
  static readonly type =
    '[Chat -> Tenders chats] Fetch the list of chats that are related to tenders (not the first page)';
}

export class UpdateTransportTenderChatGroupID {
  static readonly type =
    '[Chat -> Tenders chats] Update transport tender chat group ID';
  constructor(public transportTenderId: number, public chatGroupID) {}
}

export class SearchTendersChats {
  static readonly type =
    '[Chat -> Tenders chats] Set tenders chats search value';
  constructor(public searchValue: string) {}
}

export class AddChatGroupToTendersChats {
  static readonly type =
    '[Chat -> Tenders chats] Add chat group to tenders chats';
  constructor(public chatGroupID: number, public chatGroupName: string) {}
}

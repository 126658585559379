import { User } from '@app/modules/users/interfaces/user';
import { OnlineInfo } from '@app/modules/users/interfaces/online-info';
import { UsersListFilters } from '@app/pages/users/list/interfaces/users-list-filters';

export class UsersStateModel {
  online: OnlineInfo;
  users: User[];
  page: number;
  maxPage: number;
  filters: UsersListFilters;
}

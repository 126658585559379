import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CalendarStateModel } from './calendar.state-model';
import { CalendarAddEditState } from './add-edit';
import { CalendarListState } from './list';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  DeleteTaskInCalendar,
  SetCalendarActiveDayIsOpen,
  SetCalendarView,
  SetCalendarViewDate,
} from './calendar.actions';
import { CalendarView } from 'angular-calendar';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';

@Injectable()
@State<CalendarStateModel>({
  name: 'calendar',
  defaults: {
    viewDate: new Date(),
    view: CalendarView.Month,
    activeDayIsOpen: false,
  },
  children: [CalendarAddEditState, CalendarListState],
})
export class CalendarState {
  constructor(private calendarService: CalendarService) {}

  @Action(SetCalendarViewDate)
  @ImmutableContext()
  setCalendarViewDate(
    { setState }: StateContext<CalendarStateModel>,
    { date }: SetCalendarViewDate
  ) {
    return setState((state: CalendarStateModel) => {
      state.viewDate = date;
      return state;
    });
  }

  @Action(SetCalendarView)
  @ImmutableContext()
  setCalendarView(
    { setState }: StateContext<CalendarStateModel>,
    { view }: SetCalendarView
  ) {
    return setState((state: CalendarStateModel) => {
      state.view = view;
      return state;
    });
  }

  @Action(SetCalendarActiveDayIsOpen)
  @ImmutableContext()
  setCalendarActiveDayIsOpen(
    { setState }: StateContext<CalendarStateModel>,
    { activeDayIsOpen }: SetCalendarActiveDayIsOpen
  ) {
    return setState((state: CalendarStateModel) => {
      state.activeDayIsOpen = activeDayIsOpen;
      return state;
    });
  }

  @Action(DeleteTaskInCalendar)
  @ImmutableContext()
  deleteTaskInCalendar(
    {}: StateContext<CalendarStateModel>,
    { taskId }: DeleteTaskInCalendar
  ) {
    return this.calendarService.delete(taskId);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RenewalTime } from '@app/modules/renewal-times/interfaces/renewal-time';

@Injectable({
  providedIn: 'root',
})
export class RenewalTimesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<RenewalTime[]> {
    return this.apiService.getAll<RenewalTime>('/renewal-time').pipe(
      map((response: RenewalTime[]) => {
        return response.reverse();
      })
    );
  }
}

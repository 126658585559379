import { Injectable } from '@angular/core';
import { CreateSeaCargoTenderFormData } from '@app/pages/cargo-tenders/create/sea-tender/interfaces/create-sea-cargo-tender.form-data';
import { ApiService } from '@app/services/api.service';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { map, Observable, of } from 'rxjs';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { TenderPointStates } from '@app/modules/tenders/tender-point-states';
import { Bet } from '@app/modules/bets/interfaces/bet';
import { CargoTendersListFiltersFormData } from '@app/pages/cargo-tenders/list/components/filters-form/cargo-tenders-list-filters.form-data';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { ResultsWithFilters } from '@app/interfaces/results-with-filters';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { DatePeriod } from '@app/interfaces/date-period';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { CompanyTendersSummary } from '@app/modules/companies/interfaces/company-tenders-summary';
import { SeaCargoTenderLoadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-load-place';
import { SeaCargoTenderUnloadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-unload-place';
import { SearchEntityParams } from '@app/modules/form-elements/components/select-entity/interfaces/search-entity-params';
import { IdName } from '@app/interfaces/id-name';
import { CompanyTendersTypes } from '@app/pages/companies/view/company-tenders/company-tenders-types';

@Injectable({
  providedIn: 'root',
})
export class CargoTendersSeaService {
  constructor(private apiService: ApiService) {}

  list(
    page: number = 1,
    search: string = null,
    filters: CargoTendersListFiltersFormData = {},
    isBusinessPad = false
  ): Observable<
    PaginationResults<SeaCargoTender, FilteredTendersResultsCount>
  > {
    const data: any = {
      page,
      // fields: [
      //   'id', // USED
      //   // 'companyId', // USELESS
      //   'userId', // USED
      //   // 'cargoTypeId',
      //   'number', // USED
      //   'desiredPrice',
      //   // 'reference',
      //   // 'isVat',
      //   // 'description',
      //   // 'loadingType',
      //   // 'tenderers',
      //   // 'autoRenewal',
      //   // 'renewalTimeId',
      //   // 'currencyId',
      //   // 'betType', // {code: 2, name: "Пошаговые"} // USELESS
      //   'betType.code', // USED
      //   // 'stepBetType', // {code: 1, name: "Сумма"} // USELESS
      //   // 'stepSize', // USELESS
      //   // 'date',
      //   // 'status',
      //   // 'isClosedBets', // USELESS

      //   'expiresIn', // USED
      //   'state', // USED
      //   'currency.icon', // USED

      //   // todo: optimize, when backend will fix this error (property country is being returned instead of the code property)
      //   'points.place', // this line, when the issue will be fixed
      //   // 'points.place.type.code', // USED, uncomment this line, when the issue will be fixed
      //   // 'points.place.address', // USED, uncomment this line, when the issue will be fixed
      //   // 'points.place.country.code', // USED, uncomment this line, when the issue will be fixed

      //   // 'points.state', // USELESS
      //   'points.state.code', // USED
      //   'points.type', // USED
      //   'points.cargoLoad.name', // USED
      //   'points.cargoUnload.name', // USED
      //   'points.date.arrivalTime', // USED

      //   'nextBetPrice', // USED
      //   'chatId', // USED

      //   // 'winBet', // USELESS
      //   'winBet.companyId', // USED
      //   'winBet.price.sum', // USED
      //   'winBet.markupPercent', // USED
      //   'winBet.clientSum', // USED

      //   'unreadMessageCount', // USED
      //   'betsCount', // USED
      //   'isNew', // USED
      //   'isRead', // USED
      //   'company.details.companyName.short', // USED
      //   'overall.weight', // USED
      //   'overall.volume', // USED
      //   'minBet.isSelfBet', // USED
      //   'minBet.price.sum', // USED
      //   'selfBet.price.sum', // USED
      //   'selfBet.date.delivery', // USED
      //   'canChangeTransport',
      //   'distance',
      //   'transportType.transport',
      // ].join(','),
      // expand: [
      //   // user, company, company.details, currency, incoterms, deliveryConditions, containerType, loadPoint.port, unloadPoint.port
      //   // 'company', // USELESS
      //   'company.details', // USED
      //   // user
      //   // transportationType
      //   // cargoType
      //   // renewalTime
      //   'currency', // USED
      //   'points', // USED
      //   'points.cargoLoad', // USED
      //   'points.cargoUnload', // USED
      //   // points.cargoLoad.packagingType
      //   // points.cargoUnload.packagingType
      //   'transportType',
      //   // additionalTransportationRequirement
      //   // tenderUnitedOption
      //   // 'bets', // not used
      //   // 'bets.user', // not used
      //   // 'bets.company', // not used
      //   // 'bets.company.details', // not used
      // ].join(','),

      expand: [
        'user',
        'company',
        'company.details',
        'currency',
        'incoterms',
        'deliveryConditions',
        'containerType',
        'loadPlace.port',
        'unloadPlace.port',
        'transportationType',
      ].join(','),
      ...filters,
    };

    if (search) {
      data.search = search;
    }

    return this.apiService
      .get<ResultsWithFilters<SeaCargoTender, FilteredTendersResultsCount>>(
        '/tender/sea' + (isBusinessPad ? '/business-pad' : ''),
        data
      )
      .pipe(map(this.convertResultsWithFiltersToPaginationResults));
  }

  listOnTendersPage(
    page: number = 1,
    filters: CargoTendersListFiltersFormData = {}
  ): Observable<
    PaginationResults<SeaCargoTender, FilteredTendersResultsCount>
  > {
    const data: any = {
      page,
      fields: [
        'id',
        'number',
        'company.id',
        'company.details.companyName.short',
        'cargo.name',
        'grossWeight',
        'container.amount',
        'loadPlace.pickupAddress.address',
        'loadPlace.pickupAddress.country.country',
        'unloadPlace.deliveryAddress.address',
        'cargo.price',
        'bookingNumber',
        'state.name',
        'company.curators.user.id',
        'company.curators.user.first_name',
        'company.curators.user.middle_name',
        'company.curators.user.last_name',
        'winBet.company.curators.user.id',
        'winBet.company.curators.user.first_name',
        'winBet.company.curators.user.middle_name',
        'winBet.company.curators.user.last_name',
        'winBet.price.sum',
        'currency.icon',
      ].join(','),
      expand: [
        'company.details',
        'currency',
        'company.curators.user',
        'winBet.company.curators.user',
        'points.cargoLoad',
        'points.cargoUnload',
      ].join(','),
      ...filters,
    };

    return this.apiService
      .get<ResultsWithFilters<SeaCargoTender, FilteredTendersResultsCount>>(
        '/tender/sea',
        data
      )
      .pipe(map(this.convertResultsWithFiltersToPaginationResults));
  }

  private convertResultsWithFiltersToPaginationResults(
    response: ResultsWithFilters<SeaCargoTender, FilteredTendersResultsCount>
  ): PaginationResults<SeaCargoTender, FilteredTendersResultsCount> {
    return {
      currentPage: response._meta?.currentPage,
      pageCount: response._meta?.pageCount,
      perPage: response._meta?.perPage,
      totalCount: response._meta?.totalCount,
      maxPage: response._meta ? response._meta.pageCount : 1,
      data: response.dataModels,
      filter: response.filter,
    };
  }

  listTenders(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName, FilteredTendersResultsCount>> {
    const data: any = {
      page: params.page,
      fields: ['id', 'number'].join(','),
    };

    if (params.search) {
      data.search = params.search;
    }

    return this.apiService
      .get<ResultsWithFilters<SeaCargoTender, FilteredTendersResultsCount>>(
        '/tender/sea',
        data
      )
      .pipe(
        map(this.convertResultsWithFiltersToPaginationResults),
        map(
          (
            results: PaginationResults<
              SeaCargoTender,
              FilteredTendersResultsCount
            >
          ) => {
            const preparedResults: PaginationResults<
              IdName,
              FilteredTendersResultsCount
            > = {
              ...results,
              data: results.data.map((tender: SeaCargoTender) => {
                return {
                  id: tender.id,
                  name: tender.number,
                };
              }),
            };

            return preparedResults;
          }
        )
      );
  }

  // todo: optimize query
  get(id?: number, number?: string): Observable<SeaCargoTender> {
    const endpoint = id
      ? '/tender/' + id + '/sea'
      : '/tender/by-number/' + number + '/sea';
    return this.apiService.get<SeaCargoTender>(endpoint, {
      expand: [
        'user',
        'company',
        'company.details',
        'currency',
        'incoterms',
        'deliveryConditions',
        'containerType',
        'loadPlace.port',
        'unloadPlace.port',
        'bets.user',
        'bets.company.details',
        'transportationType',
        'winBet.company.details',
        'connectedTenders',
        // 'company',
        // 'company.details',
        // 'user',
        // 'transportationType',
        // 'cargoType',
        // 'renewalTime',
        // 'currency',
        // 'points',
        // 'points.cargoLoad',
        // 'points.cargoUnload',
        // 'points.cargoLoad.packagingType',
        // 'points.cargoUnload.packagingType',
        // 'points.square',
        // 'points.square.country',
        // 'points.country',
        // 'transportType',
        // 'additionalTransportationRequirement',
        // 'tenderUnitedOption',
        // 'bets',
        // 'bets.user',
        // 'bets.company',
        // 'bets.company.details',
      ].join(','),
    });
  }

  create(data: CreateSeaCargoTenderFormData): Observable<SeaCargoTender> {
    return this.apiService.create<SeaCargoTender>('/tender/sea', data);
  }

  closeTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/sea/close');
  }

  cancelTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/sea/cancel');
  }

  refuseTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/sea/refuse');
  }

  completeTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/sea/done');
  }

  confirmTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<ResponseSuccess<unknown>>(
      '/tender/' + tenderId + '/sea/confirm'
    );
  }

  setTenderPointState(pointId: number, state: TenderPointStates): void {}

  getTenderBetInfo(tenderId: number): Observable<SeaCargoTender> {
    return this.get(tenderId);
  }

  selectBet(bet: Bet): Observable<unknown> {
    return this.apiService.put<ResponseSuccess<unknown>>(
      '/tender/bet/' + bet.id + '/set-winner'
    );
  }

  toggleCanChangeTransport(tenderId: number): Observable<null> {
    return of(null);
  }

  loadSeaCargoTenderPoints(
    countryFrom: number = null,
    countryTo: number = null,
    state: TenderStates = TenderStates.ACTIVE,
    createdAt: DatePeriod = null
  ): Observable<SeaCargoTender[]> {
    const data: any = {
      fields: [
        'id',
        'number',
        'company.id',
        'state.code',
        'loadPlace.id',
        'loadPlace.pickupAddress.latitude',
        'loadPlace.pickupAddress.longitude',
        'loadPlace.pickupAddress.address',
        'loadPlace.port.name',
        'loadPlace.port.latitude',
        'loadPlace.port.longitude',
        'loadPlace.arrivalDate',
        'unloadPlace.id',
        'unloadPlace.deliveryAddress.latitude',
        'unloadPlace.deliveryAddress.longitude',
        'unloadPlace.deliveryAddress.address',
        'unloadPlace.port.name',
        'unloadPlace.port.latitude',
        'unloadPlace.port.longitude',
        'unloadPlace.arrivalDate',
        'unloadPlace.terminal',
        'customs.clearanceAddress.latitude',
        'customs.clearanceAddress.longitude',
        'customs.clearanceAddress.address',
      ].join(','),
      expand: ['company', 'loadPlace.port', 'unloadPlace.port'].join(','),
    };

    if (countryFrom !== null) {
      data.country_from = [countryFrom].join(',');
    }

    if (countryTo !== null) {
      data.country_to = [countryTo].join(',');
    }

    data.state = [state];

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }

    data['per-page'] = 100;

    return this.apiService.getAll<SeaCargoTender>('/tender/sea', data);
  }

  getCompanyTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    const params: any = {};

    if (page > 1) {
      params.page = page;
    }

    if (datePeriod?.from) {
      params.createdAtFrom = datePeriod.from;
    }

    if (datePeriod?.to) {
      params.createdAtTo = datePeriod.to;
    }

    if (state) {
      params.state = state;
    }

    const apiEndpointPathPart =
      companyTendersTypes === CompanyTendersTypes.CREATED
        ? 'for-company'
        : 'for-company-carrier';

    return this.apiService
      .get<
        ResultsWithFilters<
          CompanyTender,
          FilteredTendersResultsCount,
          CompanyTendersSummary
        >
      >('/tender/sea/' + apiEndpointPathPart + '/' + companyId, params)
      .pipe(
        map(
          (
            response: ResultsWithFilters<
              CompanyTender,
              FilteredTendersResultsCount,
              CompanyTendersSummary
            >
          ) => {
            return {
              currentPage: response._meta?.currentPage,
              pageCount: response._meta?.pageCount,
              perPage: response._meta?.perPage,
              totalCount: response._meta?.totalCount,
              maxPage: response._meta ? response._meta.pageCount : 1,
              data: response.dataModels,
              filter: response.filter,
              summary: response.summary,
            };
          }
        )
      );
  }

  setTenderInProgress(tenderId: number): Observable<SeaCargoTender> {
    return this.apiService.put<SeaCargoTender>(
      '/tender/' + tenderId + '/sea/in-progress'
    );
  }

  setTenderStateCargoDelivered(tenderId: number): Observable<SeaCargoTender> {
    return this.apiService.put<SeaCargoTender>(
      '/tender/' + tenderId + '/sea/cargo-delivered'
    );
  }

  delete(tenderId: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/tender/' + tenderId + '/sea');
  }

  updatePointArrivalDate(
    pointID: number,
    arrivalDate: string
  ): Observable<SeaCargoTenderLoadPlace | SeaCargoTenderUnloadPlace> {
    return this.apiService.put<
      SeaCargoTenderLoadPlace | SeaCargoTenderUnloadPlace
    >('/tender/sea/point/' + pointID + '/change-date', {
      arrivalDate,
    });
  }

  extendTender(tenderId: number, validUntil: string): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/sea/extend', {
      validUntil,
    });
  }

  setSeaCargoTenderBookingNumber(
    tenderId: number,
    bookingNumber: string,
    additionalBookingNumbers: string[]
  ): Observable<SeaCargoTender> {
    return this.apiService.put<SeaCargoTender>(
      '/tender/' + tenderId + '/sea/booking-number',
      {
        bookingNumber,
        additionalBookingNumber: additionalBookingNumbers,
      }
    );
  }

  setSeaCargoTenderContainerNumber(
    tenderId: number,
    containerNumber: string
  ): Observable<SeaCargoTender> {
    return this.apiService.put<SeaCargoTender>(
      '/tender/' + tenderId + '/sea/container-number',
      {
        containerNumber,
      }
    );
  }
}

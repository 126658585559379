import { ChatFeedback } from '@app/modules/chat/interfaces/chat-feedback';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { Selector } from '@ngxs/store';
import { ChatSelectedChatGroupStateModel } from './chat_selected-chat-group-state.model';
import { ChatSelectedChatGroupState } from './chat_selected-chat-group.state';

export class ChatSelectedChatGroupSelectors {
  @Selector([ChatSelectedChatGroupState])
  static selectedChatGroupId(state: ChatSelectedChatGroupStateModel): number {
    return state.selectedChatGroupId;
  }

  @Selector([ChatSelectedChatGroupState])
  static chatFeedbacks(state: ChatSelectedChatGroupStateModel): ChatFeedback[] {
    return state.chatFeedbacks;
  }

  @Selector([ChatSelectedChatGroupState])
  static replyChatMessage(state: ChatSelectedChatGroupStateModel): ChatMessage {
    return state.replyChatMessage;
  }

  @Selector([ChatSelectedChatGroupState])
  static forwardChatMessage(
    state: ChatSelectedChatGroupStateModel
  ): ChatMessage {
    return state.forwardChatMessage;
  }
}

import { CTDocument } from '@app/modules/documents/interfaces/ct-document';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { User } from '@app/modules/users/interfaces/user';
import { Selector } from '@ngxs/store';
import { CargoTendersViewCarStateModel } from './cargo-tenders-view_car-state.model';
import { CargoTendersViewCarState } from './cargo-tenders-view_car.state';

export class CargoTendersViewCarSelectors {
  @Selector([CargoTendersViewCarState])
  static tender(state: CargoTendersViewCarStateModel): CarCargoTender {
    return state.tender;
  }

  @Selector([CargoTendersViewCarState])
  static transportList(state: CargoTendersViewCarStateModel): Transport[] {
    return state.transportList;
  }

  @Selector([CargoTendersViewCarState])
  static drivers(state: CargoTendersViewCarStateModel): User[] {
    return state.drivers;
  }
}

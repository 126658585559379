import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';

export class ChangeTendersScopeInCompanyTendersOverallStatistics {
  static readonly type =
    '[Company Tenders Overall Statistics] Change Tenders Scope';
  constructor(public tendersScope: TendersScopes) {}
}

export class ChangeCompanyInCompanyTendersOverallStatistics {
  static readonly type = '[Company Tenders Overall Statistics] Change Company';
  constructor(public companyId: number) {}
}

export class ChangeDatePeriodInCompanyTendersOverallStatistics {
  static readonly type =
    '[Company Tenders Overall Statistics] Change Date Period';
  constructor(public datePeriod: DatePeriodDayjs) {}
}

export class LoadCompanyTendersOverallStatisticsData {
  static readonly type =
    '[Company Tenders Overall Statistics] Locad statistics data';
  constructor() {}
}

import { Component, OnInit, OnDestroy } from '@angular/core';

import { ModalController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Actions, ofActionDispatched, Select, Store } from '@ngxs/store';
import { filter, map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Navigate, RouterCancel } from '@ngxs/router-plugin';
import { RouterState } from '@angular/router';
import { AuthSelectors, LoginSucceess, LogoutSuccess } from './store/auth';
import { LayoutService } from './modules/layout/services/layout.service';
import { ChatSelectors, DetectWhitelabel, LoadWhitelabelConfig } from './store';
import { Badge } from '@awesome-cordova-plugins/badge/ngx';
import { TranslateService } from '@ngx-translate/core';
import { LayoutSelectors } from './store/layout/layout.selectors';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(LayoutSelectors.notRussia)
  notRussia$: Observable<boolean>;

  @Select(ChatSelectors.totalChatsNotificationsCount)
  totalChatsNotificationsCount$: Observable<number>;

  @Select(AuthSelectors.language)
  userLanguage$: Observable<string>;

  private destroy$ = new Subject();

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store: Store,
    private actions$: Actions,
    private modalController: ModalController,
    public layoutService: LayoutService,
    private badge: Badge,
    translateService: TranslateService
  ) {
    this.initializeApp();

    const defaultLang = 'ru';
    // this language will be used as a fallback when a translation isn't found in the current language
    translateService.setDefaultLang(defaultLang);

    this.userLanguage$
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((lang: string) => {
          const langValue = lang || localStorage.getItem('guest_language');

          return langValue
            ? of(langValue)
            : this.notRussia$.pipe(
                takeUntil(this.destroy$),
                filter((notRussia) => {
                  return notRussia !== undefined;
                }),
                take(1),
                map((notRussia: boolean) => {
                  return notRussia ? 'en' : defaultLang;
                })
              );
          // the lang to use, if the lang isn't available, it will use the current loader to get them
        }),
        tap((lang: string) => {
          translateService.use(lang);
        })
      )
      .subscribe();
  }

  initializeApp(): void {
    this.platform.ready().then(async () => {
      if (this.platform.is('hybrid')) {
        this.layoutService.isApp = true;
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        this.totalChatsNotificationsCount$
          .pipe(
            takeUntil(this.destroy$),
            tap((count: number) => {
              this.badge.set(count);
            })
          )
          .subscribe();
      } else {
        this.store.dispatch(new DetectWhitelabel());
      }
      if (this.platform.is('iphone') || this.platform.is('ipad')) {
        this.layoutService.isIphone = true;
      }
    });
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadWhitelabelConfig());

    this.actions$
      .pipe(takeUntil(this.destroy$), ofActionDispatched(LoginSucceess))
      .subscribe(() => {
        this.store.dispatch(new Navigate(['/tabs/dashboard']));
      });

    // Redirect to Login Page when user logouts
    this.actions$
      .pipe(ofActionDispatched(LogoutSuccess))
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.store.dispatch(new DetectWhitelabel());
        this.store.dispatch(new LoadWhitelabelConfig());
        this.store.dispatch(new Navigate(['/']));
        this.modalController.dismiss().catch(() => {});
        location.reload(); // Reload on logout
      });

    this.actions$
      .pipe(ofActionDispatched(RouterCancel))
      .pipe(takeUntil(this.destroy$))
      .subscribe((routerCancel: RouterCancel<RouterState>) => {
        if (this.store.selectSnapshot(AuthSelectors.isAuthenticated)) {
          console.log('isAuthenticated - YES');
          if (routerCancel.event.url !== '/tabs/dashboard') {
            this.store.dispatch(new Navigate(['/tabs/dashboard']));
          }

          return true;
        }

        console.log('isAuthenticated - NO');

        if (routerCancel.event.url !== '/') {
          this.store.dispatch(new Navigate(['/']));
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { State, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { BillsService } from '@app/modules/bills/services/bills.service';
import {
  LoadFinancesAccountsPayableBillsList,
  SetFinancesAccountsPayableBillsListFilters,
} from './finances_bills_accounts-payable.actions';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { Bill } from '@app/modules/bills/interfaces/bill';
import { BillsStateModel } from '@app/store/bills/bills-state.model';
import { BillsSummary } from '@app/modules/bills/interfaces/bills-summary';
import { UpdateBillData } from '@app/store/bills';

@Injectable()
@State<BillsStateModel>({
  name: 'finances__bills__accounts_payable',
  defaults: {
    bills: null,
    filters: null,
  },
})
export class FinancesBillsAccountsPayableState {
  constructor(private billsService: BillsService) {}

  @Action(LoadFinancesAccountsPayableBillsList, {
    cancelUncompleted: true,
  })
  @ImmutableContext()
  loadFinancesAccountsPayableBillsList({
    getState,
    setState,
  }: StateContext<BillsStateModel>) {
    return this.billsService.getCargoTendersCredit(getState().filters).pipe(
      tap((bills: PaginationResults<Bill, void, BillsSummary[]>) => {
        return setState((state: BillsStateModel) => {
          state.bills = bills;
          return state;
        });
      })
    );
  }

  @Action(SetFinancesAccountsPayableBillsListFilters)
  @ImmutableContext()
  setFinancesAccountsPayableBillsListFilters(
    { setState }: StateContext<BillsStateModel>,
    { filters }: SetFinancesAccountsPayableBillsListFilters
  ) {
    return setState((state: BillsStateModel) => {
      state.filters = filters;
      return state;
    });
  }

  @Action(UpdateBillData)
  @ImmutableContext()
  updateBillData(
    { setState }: StateContext<BillsStateModel>,
    { billEntity }: UpdateBillData
  ) {
    return setState((state: BillsStateModel) => {
      if (!state.bills?.data) {
        return state;
      }
      const currentBillRecord = state.bills.data.find((bill: Bill) => {
        return bill.id === billEntity.id;
      });

      if (currentBillRecord) {
        currentBillRecord.paymentSum = billEntity.paymentSum;
        currentBillRecord.date = billEntity.date;
        currentBillRecord.state = billEntity.state;
        currentBillRecord.status = billEntity.status;
      }
      return state;
    });
  }
}

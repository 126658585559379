import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatMyChatsStateModel } from './chat_my-chats-state.model';
import { Injectable } from '@angular/core';
import {
  ListMyChats,
  SearchMyChats,
  FetchMyChats,
  PrivateChatAddedToBlackList,
  AddChatGroupToMyChats,
} from './chat_my-chats.actions';
import { tap } from 'rxjs/operators';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { ChatGroupsService } from '@app/modules/chat/services/chat-groups.service';
import {
  CacheChatGroups,
  DeleteChatGroupSuccess,
} from '@app/store/chat/chat.actions';
import { MultipleResults } from '@app/interfaces/multiple-results';
import { RTS_ChatGroupRemoved } from '@app/store/chat/selected-chat-group/chat_selected-chat-group.actions';

@Injectable()
@State<ChatMyChatsStateModel>({
  name: 'chat__my_chats',
  defaults: {
    myChats: new Set(),
    forceLoad: false,
    myChatsSearchValue: null,
    myChatsTotal: null,
    hasBeenLoadedAtLeastOnce: false,
  },
})
export class ChatMyChatsState {
  constructor(
    private store: Store,
    private chatGroupsService: ChatGroupsService
  ) {}

  @Action(ListMyChats, { cancelUncompleted: true })
  @ImmutableContext()
  listMyChats({ getState, setState }: StateContext<ChatMyChatsStateModel>) {
    if (getState().hasBeenLoadedAtLeastOnce && !getState().forceLoad) {
      return;
    }

    setState((state: ChatMyChatsStateModel) => {
      state.hasBeenLoadedAtLeastOnce = true;
      state.forceLoad = false;
      return state;
    });

    return this.chatGroupsService
      .getMyChats(0, getState().myChatsSearchValue)
      .pipe(
        tap((result: MultipleResults<ChatGroup>) => {
          this.store.dispatch(new CacheChatGroups(result.dataModels));
          setState((state: ChatMyChatsStateModel) => {
            state.myChats.clear();
            result.dataModels.map((chatGroup: ChatGroup) => {
              state.myChats.add(chatGroup.id);
            });
            state.myChatsTotal = result.totalCount;
            return state;
          });
        })
      );
  }

  @Action(FetchMyChats, { cancelUncompleted: true })
  @ImmutableContext()
  fetchMyChats({ getState, setState }: StateContext<ChatMyChatsStateModel>) {
    return this.chatGroupsService
      .getMyChats(getState().myChats.size, getState().myChatsSearchValue)
      .pipe(
        tap((result: MultipleResults<ChatGroup>) => {
          this.store.dispatch(new CacheChatGroups(result.dataModels));
          setState((state: ChatMyChatsStateModel) => {
            result.dataModels.forEach((chatGroup: ChatGroup) => {
              state.myChats.add(chatGroup.id);
            });
            state.myChatsTotal = result.totalCount;
            return state;
          });
        })
      );
  }

  @Action(SearchMyChats)
  @ImmutableContext()
  searchMyChats(
    { setState }: StateContext<ChatMyChatsStateModel>,
    { searchValue }: SearchMyChats
  ) {
    return setState((state: ChatMyChatsStateModel) => {
      state.myChatsSearchValue = searchValue;
      state.forceLoad = true;

      return state;
    });
  }

  @Action(PrivateChatAddedToBlackList)
  @ImmutableContext()
  privateChatAddedToBlackList(
    { setState }: StateContext<ChatMyChatsStateModel>,
    { chatGroupId }: PrivateChatAddedToBlackList
  ) {
    return setState((state: ChatMyChatsStateModel) => {
      if (state.myChats.delete(chatGroupId)) {
        state.myChatsTotal--;
      }
      return state;
    });
  }

  @Action(AddChatGroupToMyChats)
  @ImmutableContext()
  addChatGroupToMyChats(
    { setState }: StateContext<ChatMyChatsStateModel>,
    { chatGroupID, chatGroupName }: AddChatGroupToMyChats
  ) {
    return setState((state: ChatMyChatsStateModel) => {
      const shouldAdd: boolean =
        !state.myChatsSearchValue ||
        state.myChatsSearchValue.length === 0 ||
        chatGroupName
          .toLowerCase()
          .includes(state.myChatsSearchValue.toLowerCase());

      shouldAdd
        ? state.myChats.add(chatGroupID)
        : state.myChats.delete(chatGroupID);

      return state;
    });
  }

  @Action([DeleteChatGroupSuccess, RTS_ChatGroupRemoved])
  @ImmutableContext()
  deleteChatGroupSuccess(
    { setState }: StateContext<ChatMyChatsStateModel>,
    { chatGroupID }: DeleteChatGroupSuccess | RTS_ChatGroupRemoved
  ) {
    setState((state: ChatMyChatsStateModel) => {
      state.myChats.delete(chatGroupID);
      return state;
    });
  }
}

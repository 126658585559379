import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { WarehousesCreateStateModel } from './warehouses_create-state.model';
import { TryCreateWarehouse } from './warehouses_create.actions';
import {
  CreateWarehouse,
  ListWarehouses,
  ViewWarehouse,
} from '@app/store/tabs/tabs.actions';
import { WarehousesService } from '@app/modules/warehouses/services/warehouses.service';
import { UiService } from '@app/services/ui.service';
import { EditWarehouse } from '@app/store/warehouses/warehouses.actions';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';
import {
  ResetWarehouse,
  TryUpdateWarehouse,
  DeleteWarehousePhoto,
  UploadWarehousePhotos,
  SetWarehousePhotos,
} from './warehouses_create.actions';

@Injectable()
@State<WarehousesCreateStateModel>({
  name: 'warehouses_create',
  defaults: {
    warehouse: null,
  },
})
export class WarehousesCreateState {
  constructor(
    private warehousesService: WarehousesService,
    private store: Store,
    private uiService: UiService
  ) {}

  @Action(TryCreateWarehouse)
  @ImmutableContext()
  tryCreateWarehouse(
    {}: StateContext<WarehousesCreateStateModel>,
    { data }: TryCreateWarehouse
  ) {
    return this.warehousesService.create(data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new ListWarehouses());
      })
    );
  }

  @Action(TryUpdateWarehouse)
  @ImmutableContext()
  tryUpdateWarehouse(
    {}: StateContext<WarehousesCreateStateModel>,
    { data }: TryUpdateWarehouse
  ) {
    const warehouseId = data.id;
    delete data.id;
    delete data.photos;
    return this.warehousesService.update(warehouseId, data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new ViewWarehouse(warehouseId));
      })
    );
  }

  @Action(EditWarehouse)
  @ImmutableContext()
  editWarehouse(
    { setState }: StateContext<WarehousesCreateStateModel>,
    { id }: EditWarehouse
  ) {
    return this.warehousesService.getForEditing(id).pipe(
      tap((warehouse: Warehouse) => {
        setState((state: WarehousesCreateStateModel) => {
          state.warehouse = warehouse;
          return state;
        });
        this.store.dispatch(new CreateWarehouse());
      })
    );
  }

  @Action(ResetWarehouse)
  @ImmutableContext()
  resetWarehouse(
    { setState }: StateContext<WarehousesCreateStateModel>,
    {}: ResetWarehouse
  ) {
    return setState((state: WarehousesCreateStateModel) => {
      state.warehouse = null;
      return state;
    });
  }

  @Action(UploadWarehousePhotos)
  @ImmutableContext()
  uploadWarehousePhotos(
    {}: StateContext<WarehousesCreateStateModel>,
    { warehouseId, photos }: UploadWarehousePhotos
  ) {
    return this.warehousesService.uploadPhotos(warehouseId, photos).pipe(
      tap((warehouse: Warehouse) => {
        this.store.dispatch(new SetWarehousePhotos(warehouse.photos));
      })
    );
  }

  @Action(DeleteWarehousePhoto)
  @ImmutableContext()
  deleteWarehousePhoto(
    {}: StateContext<WarehousesCreateStateModel>,
    { warehouseId, photoId }: DeleteWarehousePhoto
  ) {
    return this.warehousesService.deletePhoto(warehouseId, photoId);
  }
}

import { JwtHelperService } from '@auth0/angular-jwt';
import { Selector } from '@ngxs/store';
import { AuthStateModel } from './auth-state.model';
import { AuthState } from './auth.state';
import { Company } from '@app/modules/companies/interfaces/company';
import { User } from '@app/modules/users/interfaces/user';
import { Permission } from '@app/modules/permissions/interfaces/permission';

export class AuthSelectors {
  @Selector([AuthState])
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector([AuthState])
  static mercureTopic(state: AuthStateModel): string | null {
    return state.accessTokenPayload?.mercure?.subscribe[0];
  }

  @Selector([AuthState])
  static mercureSubscriptions(state: AuthStateModel): string | null {
    return state.accessTokenPayload?.mercure?.subscribe[1];
  }

  @Selector([AuthState])
  static user(state: AuthStateModel): User | null {
    return state.accessTokenPayload?.data;
  }

  @Selector([AuthState])
  static language(state: AuthStateModel): string {
    return state.accessTokenPayload?.data?.language;
  }

  @Selector([AuthState])
  static user_id(state: AuthStateModel): number {
    return state.accessTokenPayload?.data?.id;
  }

  @Selector([AuthState])
  static isConsultant(state: AuthStateModel): boolean {
    return state.accessTokenPayload?.data?.isConsultant;
  }

  @Selector([AuthState])
  static isCT(state: AuthStateModel): boolean {
    return state.accessTokenPayload?.data?.isCT;
  }

  @Selector([AuthState])
  static is_super_admin(state: AuthStateModel): boolean {
    return state.accessTokenPayload?.data?.is_super_admin;
  }

  @Selector([AuthState])
  static company(state: AuthStateModel): Company | null {
    return state.accessTokenPayload?.data?.company;
  }

  @Selector([AuthState])
  static isVatCompany(state: AuthStateModel): boolean | null {
    return state.accessTokenPayload?.data?.company?.vat;
  }

  @Selector([AuthState])
  static currentCompanyId(state: AuthStateModel): number | null {
    return state.accessTokenPayload?.data?.company?.id;
  }

  @Selector([AuthState])
  static refreshToken(state: AuthStateModel): string | null {
    return state.refreshToken;
  }

  @Selector([AuthState])
  static isAuthenticated(state: AuthStateModel): boolean {
    if (state.token) {
      const jwtHelper = new JwtHelperService();

      if (!jwtHelper.isTokenExpired(state.token)) {
        return true;
      }
    }
    return false;
  }

  @Selector([AuthState])
  static wrongCredentials(state: AuthStateModel): boolean {
    return state.wrongCredentials;
  }

  @Selector([AuthState])
  static userPermissions(state: AuthStateModel): Permission[] {
    return state.userPermissions;
  }

  @Selector([AuthState])
  static companyPermissions(state: AuthStateModel): Permission[] {
    return state.companyPermissions;
  }
}

import { ChatTabs } from '@app/modules/chat/chat-tabs';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { MessageFeedback } from '@app/modules/chat/interfaces/meesage-feedback';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { User } from '@app/modules/users/interfaces/user';

export class ExpandChats {
  static readonly type = '[Chat] Expand';
}

export class ReopenChats {
  static readonly type = '[Chat] Reopen chats';
}

export class SwitchTab {
  static readonly type = '[Chat] Switch tab';
  constructor(public tab: ChatTabs) {}
}

export class SwitchTabKeepCollapsed {
  static readonly type = '[Chat] Switch tab (keep collapsed)';
  constructor(public tab: ChatTabs) {}
}

export class SwitchTabAndCollapse {
  static readonly type = '[Chat] Switch tab and collapse';
  constructor(public tab: ChatTabs, public reopenLater?: boolean) {}
}

export class CacheChatGroups {
  static readonly type = '[Chat] Cache chat groups';
  constructor(public chatGroups: ChatGroup[]) {}
}

export class RemoveFromCache {
  static readonly type = '[Chat] Remove chat group from the cache';
  constructor(public chatGroupID: number) {}
}

export class OpenTransportTenderChat {
  static readonly type = '[Chat] Open transport tender chat';
  constructor(public transportTender: TransportTender) {}
}

export class OpenChatGroup {
  static readonly type = '[Chat] Open chat group';
  constructor(
    public chatGroupId: number,
    public chatGroupName?: string,
    public isUncreatedTransportTenderChat: boolean = false
  ) {}
}

export class OpenConsultantChatGroup {
  static readonly type =
    '[Chat] Open consultant chat group (spiecial chat group)';
  constructor(public chatGroupId: number) {}
}

export class LoadConsultantChatGroup {
  static readonly type = '[Chat] Load consultant chat group';
  constructor(public chatGroupId?: number) {}
}

export class OpenExistingChatGroup {
  static readonly type =
    '[Chat] Open chat group that should exist (just load to the memory and open - do not add to any chat list';
  constructor(public chatGroupId: number) {}
}

export class InsertChatGroup {
  static readonly type = '[Chat] Insert chat group';
  constructor(public chatGroup: ChatGroup) {}
}

export class InsertChatMessage {
  static readonly type = '[Chat] Insert chat message';
  constructor(
    public chatId: number,
    public message: ChatMessage,
    public isOutgoing: boolean,
    public increaseUnreadCount: boolean = true
  ) {}
}

export class ChatImageDidLoad {
  static readonly type = '[Chat] Chat Image Did Load';
}

export class InsertMessageFeedback {
  static readonly type = '[Chat] Insert message feedback';
  constructor(public messageFeedback: MessageFeedback) {}
}

export class RemoveChatMessage {
  static readonly type = '[Chat] Remove message';
  constructor(public chatId: number, public messageId: number) {}
}

export class UpdateChatMessage {
  static readonly type = '[Chat] Update message';
  constructor(
    public chatId: number,
    public messageId: number,
    public message: ChatMessage
  ) {}
}

export class OpenPrivateChat {
  static readonly type = '[Chat] Open private chat';
  constructor(public user: User) {}
}

export class CreatePrivateChat {
  static readonly type = '[Chat] Create private chat';
  constructor(public userID: number) {}
}

export class CreateTransportTenderChatGroup {
  static readonly type = '[Chat] Try to create transport tender chat group';
  constructor(public transportTenderId: number) {}
}

export class CreateConsultantChatGroup {
  static readonly type = '[Chat] Try to create consultant chat group';
  constructor(public uncreatedConsultantChatGroupID: number) {}
}

export class LoadPrivateChat {
  static readonly type = '[Chat] Load private chat';
  constructor(public userID: number) {}
}

export class LoadNonPrivateChat {
  static readonly type = '[Chat] Load non-private chat';
  constructor(public chatGroupId: number) {}
}

export class SaveChatMessage {
  static readonly type = '[Chat] Try to save chat message';
  constructor(
    public chatID: number,
    public messageID: number,
    public messageContent: string,
    public files: number[],
    public answeredMessageId?: number,
    public forwardMessageId?: number,
    public contactId?: number
  ) {}
}

export class SaveChatMessageSuccess {
  static readonly type = '[Chat] The chat message has been successfully saved';
  constructor(
    public chatID: number,
    public replaceMessageID: number,
    public message: ChatMessage
  ) {}
}

export class SaveChatMessageFailed {
  static readonly type = '[Chat] Failed to save the chat message';
  constructor(public chatID: number, public messageID: number) {}
}

export class DeleteChatGroupSuccess {
  static readonly type = '[Chat] Delete chat group success';
  constructor(public chatGroupID: number) {}
}

export class SetUnreadMessagesCountInTenderChat {
  static readonly type = '[Chat] Set tender chat group unread messages count';
  constructor(public chatGroupID: number, public unreadMessagesCount: number) {}
}

export class AddAttachments {
  static readonly type = '[Chat] Add attachments';
  constructor(public files: File[]) {}
}

export class HideChatMenuOptions {
  static readonly type = '[Chat] Hide chat menu options';
  constructor() {}
}

export class MarkAsRead {
  static readonly type =
    '[Chat] Mark messages as read in the provided chat group';
  constructor(public chatGroupID: number) {}
}

import { Injectable } from '@angular/core';
import { SaveCompanyBankFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-bank/save-company-bank-form-data';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyBank } from '@app/modules/companies/interfaces/company-bank';

@Injectable({
  providedIn: 'root',
})
export class CompanyBanksService {
  constructor(private apiService: ApiService) {}

  list(companyId: number): Observable<CompanyBank[]> {
    return this.apiService.getAll<CompanyBank>(
      '/company/' + companyId + '/bank'
    );
  }

  get(companyId: number, bankId: number): Observable<CompanyBank> {
    return this.apiService.get<CompanyBank>(
      '/company/' + companyId + '/bank/' + bankId
    );
  }

  create(data: SaveCompanyBankFormData): Observable<unknown> {
    const companyId = data.companyId;
    delete data.companyId;
    return this.apiService.post<unknown>(
      '/company/' + companyId + '/bank',
      data
    );
  }

  update(data: SaveCompanyBankFormData): Observable<unknown> {
    const companyId = data.companyId;
    delete data.companyId;
    const bankId = data.id;
    delete data.id;
    return this.apiService.put<unknown>(
      '/company/' + companyId + '/bank/' + bankId,
      data
    );
  }

  markCompanyBankAsMain(data: SaveCompanyBankFormData): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/company/' + data.companyId + '/bank/' + data.id + '/set-main',
      data
    );
  }

  delete(companyId: number, bankId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/company/' + companyId + '/bank/' + bankId
    );
  }
}

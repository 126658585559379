import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { UserAnalyticsStateModel } from './analytics-state.model';
import { Injectable } from '@angular/core';
import { LoadUserAnalytics } from './analytics.actions';
import { tap } from 'rxjs/operators';
import { UsersService } from '@app/modules/users/services/users.service';
import { UserAnalytics } from '@app/modules/users/interfaces/user-analytics';

@Injectable()
@State<UserAnalyticsStateModel>({
  name: 'analytics',
  defaults: {
    analytics: null,
  },
})
export class UserAnalyticsState {
  constructor(private usersService: UsersService) {}

  @Action(LoadUserAnalytics, { cancelUncompleted: true })
  @ImmutableContext()
  loadUserNotifications(
    { setState }: StateContext<UserAnalyticsStateModel>,
    { userID }: LoadUserAnalytics
  ) {
    return this.usersService.getUserAnalytics(userID).pipe(
      tap((analytics: UserAnalytics) => {
        setState((state: UserAnalyticsStateModel) => {
          state.analytics = analytics;
          return state;
        });
      })
    );
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FavoritesStateModel } from './favorites.state-model';
import {
  AddCarCargoTenderToFavorites,
  AddCompanyToFavorites,
  AddSeaCargoTenderToFavorites,
  AddTransportTenderToFavorites,
  AddTransportToFavorites,
  AddUserToFavorites,
  RemoveCarCargoTenderFromFavorites,
  RemoveCompanyFromFavorites,
  RemoveSeaCargoTenderFromFavorites,
  RemoveTransportFromFavorites,
  RemoveTransportTenderFromFavorites,
  RemoveUserFromFavorites,
} from './favorites.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { FavoritesService } from '@app/modules/favorites/services/favorites.service';
import { FavoriteEntityTypes } from '@app/modules/favorites/interfaces/favorite-entity-types';

@Injectable()
@State<FavoritesStateModel>({
  name: 'favorites',
})
export class FavoritesState {
  constructor(private favoritesService: FavoritesService) {}

  // User
  @Action(AddUserToFavorites)
  @ImmutableContext()
  addUserToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddUserToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.USER,
      entityId,
    });
  }

  @Action(RemoveUserFromFavorites)
  @ImmutableContext()
  removeUserFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveUserFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.USER,
      entityId,
    });
  }

  // Company
  @Action(AddCompanyToFavorites)
  @ImmutableContext()
  cddCompanyToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddCompanyToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.COMPANY,
      entityId,
    });
  }

  @Action(RemoveCompanyFromFavorites)
  @ImmutableContext()
  removeCompanyFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveCompanyFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.COMPANY,
      entityId,
    });
  }

  // Transport
  @Action(AddTransportToFavorites)
  @ImmutableContext()
  addTransportToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddTransportToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.TRANSPORT,
      entityId,
    });
  }

  @Action(RemoveTransportFromFavorites)
  @ImmutableContext()
  removeTransportFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveTransportFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.TRANSPORT,
      entityId,
    });
  }

  // Car Cargo Tender
  @Action(AddCarCargoTenderToFavorites)
  @ImmutableContext()
  addCarCargoTenderToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddCarCargoTenderToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.CAR_CARGO_TENDER,
      entityId,
    });
  }

  @Action(RemoveCarCargoTenderFromFavorites)
  @ImmutableContext()
  removeCarCargoTenderFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveCarCargoTenderFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.CAR_CARGO_TENDER,
      entityId,
    });
  }

  // Transport Tender
  @Action(AddTransportTenderToFavorites)
  @ImmutableContext()
  addTransportTenderToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddTransportTenderToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.TRANSPORT_TENDER,
      entityId,
    });
  }

  @Action(RemoveTransportTenderFromFavorites)
  @ImmutableContext()
  removeTransportTenderFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveTransportTenderFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.TRANSPORT_TENDER,
      entityId,
    });
  }

  // Sea Cargo Tender
  @Action(AddSeaCargoTenderToFavorites)
  @ImmutableContext()
  addSeaCargoTenderToFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: AddSeaCargoTenderToFavorites
  ) {
    return this.favoritesService.add({
      entityType: FavoriteEntityTypes.SEA_CARGO_TENDER,
      entityId,
    });
  }

  @Action(RemoveSeaCargoTenderFromFavorites)
  @ImmutableContext()
  removeSeaCargoTenderFromFavorites(
    {}: StateContext<FavoritesStateModel>,
    { entityId }: RemoveSeaCargoTenderFromFavorites
  ) {
    return this.favoritesService.remove({
      entityType: FavoriteEntityTypes.SEA_CARGO_TENDER,
      entityId,
    });
  }
}

import { WidgetColumns } from '@app/modules/widgets/interfaces/widget-columns';

export class LoadWidgets {
  static readonly type = '[Widgets] Load widgets';
  constructor(public forceLoad = false) {}
}

export class ClearWidgetsList {
  static readonly type = '[Widgets] Clear widgets list';
  constructor() {}
}

export class LoadWidgetsConfigs {
  static readonly type = '[Widgets] Load widgets configurations';
  constructor() {}
}

export class ToggleWidget {
  static readonly type = '[Widgets] Toggle widget';
  constructor(
    public column: WidgetColumns,
    public widgetId: number,
    public isEnabled: boolean
  ) {}
}

export class MoveWidget {
  static readonly type = '[Widgets] Move widget';
  constructor(
    public column: WidgetColumns,
    public from: number,
    public to: number
  ) {}
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  InitializeCreateCargoTenderPage,
  CreateCarCargoTender,
  ResetInitialCargoTenderData,
  CreateSeaCargoTender,
  CreateOrderCargoTender,
} from './cargo-tenders_create.actions';
import { CargoTendersCreateStateModel } from './cargo-tenders_create-state.model';
import { Injectable } from '@angular/core';
import { Company } from '@app/modules/companies/interfaces/company';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { UsersService } from '@app/modules/users/services/users.service';
import { RepeatCarCargoTender } from '@app/store/cargo-tenders/view/car/cargo-tenders-view_car.actions';
import { RepeatSeaCargoTender } from '@app/store/cargo-tenders/view/sea/cargo-tenders-view_sea.actions';
import { PrimaryBlockSetCargoTendersView } from '@app/store';
import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { ViewCarCargoTender, ViewSeaCargoTender } from '@app/store/tabs';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CargoTendersOrderService } from '@app/modules/tenders/services/cargo-tenders-order.service';

@Injectable()
@State<CargoTendersCreateStateModel>({
  name: 'cargo_tenders__create',
  defaults: {
    companies: [],
    initialTenderData: null,
  },
})
export class CargoTendersCreateState {
  constructor(
    private store: Store,
    private cargoTendersCarService: CargoTendersCarService,
    private usersService: UsersService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private cargoTendersOrderService: CargoTendersOrderService
  ) {}

  @Action(InitializeCreateCargoTenderPage)
  @ImmutableContext()
  initializeCreateCargoTenderPage({
    setState,
  }: StateContext<CargoTendersCreateStateModel>) {
    const companies$: Observable<Company[]> = this.usersService
      .getMyCompanies()
      .pipe(
        map((companies: Company[]) => {
          return companies;
        })
      );

    return companies$.pipe(
      tap((companies: Company[]) => {
        setState((state: CargoTendersCreateStateModel) => {
          state.companies = companies;
          return state;
        });
      })
    );
  }

  @Action(CreateCarCargoTender)
  @ImmutableContext()
  createCarCargoTender(
    {}: StateContext<CargoTendersCreateStateModel>,
    { data }: CreateCarCargoTender
  ) {
    return this.cargoTendersCarService.create(data).pipe(
      tap((tender: CarCargoTender) => {
        this.store.dispatch(new ViewCarCargoTender(tender.id));
      })
    );
  }

  @Action(CreateOrderCargoTender)
  @ImmutableContext()
  createOrderCargoTender(
    {}: StateContext<CargoTendersCreateStateModel>,
    { data }: CreateOrderCargoTender
  ) {
    return this.cargoTendersOrderService.create(data).pipe(
      tap((order: any) => {
        this.store.dispatch(
          new PrimaryBlockSetCargoTendersView(
            PrimaryBlockCargoTendersViews.LIST
          )
        );
      })
    );
  }

  @Action(RepeatCarCargoTender)
  @ImmutableContext()
  repeatCarCargoTender(
    { setState }: StateContext<CargoTendersCreateStateModel>,
    { tender }: RepeatCarCargoTender
  ) {
    this.store.dispatch(
      new PrimaryBlockSetCargoTendersView(PrimaryBlockCargoTendersViews.CREATE)
    );

    return setState((state: CargoTendersCreateStateModel) => {
      state.initialTenderData = tender;
      return state;
    });
  }

  @Action(RepeatSeaCargoTender)
  @ImmutableContext()
  repeatSeaCargoTender(
    { setState }: StateContext<CargoTendersCreateStateModel>,
    { tender }: RepeatSeaCargoTender
  ) {
    this.store.dispatch(
      new PrimaryBlockSetCargoTendersView(PrimaryBlockCargoTendersViews.CREATE)
    );

    return setState((state: CargoTendersCreateStateModel) => {
      state.initialTenderData = tender;
      return state;
    });
  }

  @Action(ResetInitialCargoTenderData)
  @ImmutableContext()
  resetInitialCargoTenderData(
    { setState }: StateContext<CargoTendersCreateStateModel>,
    {}: ResetInitialCargoTenderData
  ) {
    return setState((state: CargoTendersCreateStateModel) => {
      state.initialTenderData = null;
      return state;
    });
  }

  @Action(CreateSeaCargoTender)
  @ImmutableContext()
  createSeaCargoTender(
    {}: StateContext<CargoTendersCreateStateModel>,
    { data }: CreateSeaCargoTender
  ) {
    return this.cargoTendersSeaService.create(data).pipe(
      tap((tender: SeaCargoTender) => {
        this.store.dispatch(new ViewSeaCargoTender(tender.id));
      })
    );
  }
}

import { TenderStates } from '@app/modules/tenders/tender-states';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { User } from '@app/modules/users/interfaces/user';

export class CargoTendersListFiltersStateModel {
  isFavorite: boolean;
  cargoTendersTransportationType: CargoTendersTransportationTypes;
  filteredResultsCount: FilteredTendersResultsCount;
  displayFilters: boolean;
  states?: TenderStates[];
  createdAtFrom?: string;
  createdAtTo?: string;
  isMyTenders?: boolean;
  isInternational?: boolean;
  userId?: number;
  companyMembers?: User[];
  partnerId?: number;
  partners?: CompanyPartnership[];
  countryFrom?: number;
  countryTo?: number;
}

import { Injectable } from '@angular/core';
import { ResultsWithSummary } from '@app/interfaces/results-with-summary';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { TendersStatementRecord } from '@app/modules/company-finances/interfaces/tenders-statement-record';
import { TendersStatementSummary } from '@app/modules/company-finances/interfaces/tenders-statement-summary';
import { TendersStatementFileData } from '@app/modules/company-finances/interfaces/tenders-statement-file-data';
import { TendersReportRecord } from '@app/modules/company-finances/interfaces/tenders-report-record';
import { TendersReportFileData } from '@app/modules/company-finances/interfaces/tenders-report-file-data';
import { TenderStatementTypes } from '@app/pages/company-finances/tender-statement-types';

@Injectable({
  providedIn: 'root',
})
export class CompanyFinancesService {
  constructor(private apiService: ApiService) {}

  getTendersStatement(
    doneAtFrom?: string,
    doneAtTo?: string,
    type?: TenderStatementTypes
  ): Observable<
    ResultsWithSummary<TendersStatementRecord, TendersStatementSummary>
  > {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }

    if (type) {
      data.type = type;
    }

    return this.apiService.get<
      ResultsWithSummary<TendersStatementRecord, TendersStatementSummary>
    >('/tender/statement', data);
  }

  downloadTendersStatement(
    doneAtFrom?: string,
    doneAtTo?: string
  ): Observable<TendersStatementFileData> {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }

    return this.apiService.get<TendersStatementFileData>(
      '/tender/statement/download',
      data
    );
  }

  getTendersReport(
    doneAtFrom?: string,
    doneAtTo?: string
  ): Observable<TendersReportRecord[]> {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }

    return this.apiService.getAll<TendersReportRecord>('/tender/report', data);
  }

  downloadTendersReport(
    doneAtFrom?: string,
    doneAtTo?: string
  ): Observable<TendersReportFileData> {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }
    return this.apiService.get<TendersReportFileData>(
      '/tender/report/download',
      data
    );
  }

  getTransportTendersStatement(
    doneAtFrom?: string,
    doneAtTo?: string,
    type?: TenderStatementTypes
  ): Observable<
    ResultsWithSummary<TendersStatementRecord, TendersStatementSummary>
  > {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }

    if (type) {
      data.type = type;
    }

    return this.apiService.get<
      ResultsWithSummary<TendersStatementRecord, TendersStatementSummary>
    >('/exchange/tender/statement', data);
  }

  downloadTransportTendersStatement(
    doneAtFrom?: string,
    doneAtTo?: string
  ): Observable<TendersStatementFileData> {
    const data: any = {};

    if (doneAtFrom) {
      data.doneAtFrom = doneAtFrom;
    }

    if (doneAtTo) {
      data.doneAtTo = doneAtTo;
    }

    return this.apiService.get<TendersStatementFileData>(
      '/exchange/tender/statement/download',
      data
    );
  }
}

import { Bet } from '@app/modules/bets/interfaces/bet';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { CreateAdditionalInvoiceFormData } from '@app/modules/tenders/components/create-additional-invoice/form/create-additional-invoice.form-data';
import { LinkTendersFormFormData } from '@app/modules/tenders/components/link-tenders/form/link-tenders-form.form-data';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { TenderPointStates } from '@app/modules/tenders/tender-point-states';

export class SetCargoTenderPointState {
  static readonly type = '[View cargo tender] Set tender point state';
  constructor(public pointId: number, public state: TenderPointStates) {}
}

export class CreateDirectory {
  static readonly type = '[View cargo tender] Create directory';
  constructor(public directoryName: string, public parentId?: number) {}
}

export class RenameDocument {
  static readonly type = '[Files Explorer] Rename document';
  constructor(public documentId: number, public name: string) {}
}

export class UploadDocument {
  static readonly type = '[View cargo tender] Upload document';
  constructor(public file: File, public directoryId: number) {}
}

export class TryDeleteDocument {
  static readonly type = '[View cargo tender] Try to delete document';
  constructor(public documentId: number) {}
}

export class DeleteDocumentSuccess {
  static readonly type =
    '[View cargo tender] The document has been successfully deleted';
  constructor() {}
}

export class DeleteDocumentFailed {
  static readonly type = '[View cargo tender] Failed to delete the document';
  constructor() {}
}

export class LoadCargoTenderTransportAndDriversList {
  static readonly type =
    '[Cargo tenders] Load cargo tender transport list and driver list';
  constructor(public tenderId: number) {}
}

export class TryCreateAdditionalInvoice {
  static readonly type = '[Cargo tenders] Try to create additional invoice';
  constructor(public data: CreateAdditionalInvoiceFormData) {}
}

export class CreateAdditionalInvoiceSuccess {
  static readonly type =
    '[Cargo tenders] Additional invoice was successfully created';
  constructor() {}
}

export class CreateAdditionalInvoiceFailed {
  static readonly type = '[Cargo tenders] Failed to create additional invoice';
  constructor(public errors: string[]) {}
}

export class LinkCargoTenders {
  static readonly type = '[Cargo tenders] Link cargo tenders';
  constructor(
    public tenderID: number,
    public tenderType: CargoTendersTransportationTypes,
    public data: LinkTendersFormFormData
  ) {}
}

export class UnlinkCargoTenders {
  static readonly type = '[Cargo tenders] Unlink cargo tenders';
  constructor(public connectionID: number) {}
}

export class SetWinBet {
  static readonly type = '[Cargo tenders] Set winbet';
  constructor(public bet: Bet) {}
}

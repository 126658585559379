import { IcebergPopupData } from '@app/pages/main-page/components/iceberg-popup/interfaces/iceberg-popup.data';

export class DisplayFragment {
  static readonly type = '[Main Page] Display fragment';
  constructor(public fragmentName: number) {}
}

export class EnableIntervalRotation {
  static readonly type = '[Main Page] Enable fragments interval rotation';
}

export class DisplayNextFragment {
  static readonly type = '[Main Page] Display next fragment';
}

export class SetIsMouseOverIceberg {
  static readonly type = '[Main Page] Set flag "is mouse over iceberg"';
  constructor(public isMouseOverIceberg: boolean) {}
}

export class OpenIcebergPopup {
  static readonly type = '[Main Page] Open iceberg popup';
  constructor(public data: IcebergPopupData) {}
}

export class CloseIcebergPopup {
  static readonly type = '[Main Page] Close iceberg popup';
}

export class OpenIcebergVideoPopup {
  static readonly type = '[Main Page] Open iceberg video popup';
  constructor(public videoUrl: string) {}
}

export class CloseIcebergVideoPopup {
  static readonly type = '[Main Page] Close iceberg video popup';
}

import { AddEditUserTurnoverDataFormData } from '@app/pages/profile/user-finances/components/add-user-turnover-data/add-edit-user-turnover-data.form-data';
import { SetUserSalaryFormData } from '@app/pages/profile/user-finances/components/set-user-salary/set-user-salary.form-data';

export class SetUserSalary {
  static readonly type = '[User Finances] Set user salary';
  constructor(public userId: number, public data: SetUserSalaryFormData) {}
}

export class AddUserTurnoverData {
  static readonly type = '[User Finances] Add user turnover data';
  constructor(
    public userId: number,
    public data: AddEditUserTurnoverDataFormData
  ) {}
}

export class EditUserTurnoverData {
  static readonly type = '[User Finances] Edit user turnover data';
  constructor(
    public turnoverID: number,
    public data: AddEditUserTurnoverDataFormData
  ) {}
}

export class DeleteUserTurnoverData {
  static readonly type = '[User Finances] Delete user turnover data';
  constructor(public turnoverID: number) {}
}

export class LoadUserFinancesStatistics {
  static readonly type = '[User Finances] Load statistics';
  constructor(
    public userId: number,
    public month: string,
    public year: string
  ) {}
}

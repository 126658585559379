import { Transport } from '@app/modules/transport/interfaces/transport';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { Selector } from '@ngxs/store';
import { TransportTendersCreateStateModel } from './transport-tenders_create-state.model';
import { TransportTendersCreateState } from './transport-tenders_create.state';
import { TransportModeTypes } from '@app/modules/transport/transport-mode-types';

export class TransportTendersCreateSelectors {
  @Selector([TransportTendersCreateState])
  static availableTransportList(
    state: TransportTendersCreateStateModel
  ): Transport[] {
    return state.availableTransportList;
  }

  @Selector([TransportTendersCreateState])
  static availableTransportListExceptTrailer(
    state: TransportTendersCreateStateModel
  ): Transport[] {
    return state.availableTransportList.filter((transport: Transport) => {
      return transport.transportType.mode.code !== TransportModeTypes.TRAILER;
    });
  }

  @Selector([TransportTendersCreateState])
  static availableTransportListExceptTrailerAndShips(
    state: TransportTendersCreateStateModel
  ): Transport[] {
    return state.availableTransportList.filter((transport: Transport) => {
      return (
        transport.transportType.mode.code !== TransportModeTypes.TRAILER &&
        transport.transportType.mode.code !== TransportModeTypes.SHIP
      );
    });
  }

  @Selector([TransportTendersCreateState])
  static availableTrailerList(
    state: TransportTendersCreateStateModel
  ): Transport[] {
    return state.availableTransportList.filter((transport: Transport) => {
      return transport.transportType.mode.code === TransportModeTypes.TRAILER;
    });
  }

  @Selector([TransportTendersCreateState])
  static initialTenderData(
    state: TransportTendersCreateStateModel
  ): TransportTender {
    return state.initialTenderData;
  }
}

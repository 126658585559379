import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';

import { tap } from 'rxjs/operators';
import { ProfileCompaniesStateModel } from './profile-companies-state.model';
import { ProfileCompaniesService } from '@app/modules/profile/services/profile-companies.service';
import {
  LoadProfileCompanies,
  SetUserProfileCompaniesFilters,
} from './profile-companies.actions';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { Company } from '@app/modules/companies/interfaces/company';

@Injectable()
@State<ProfileCompaniesStateModel>({
  name: 'profile__companies',
  defaults: {
    companies: null,
    filters: null,
  },
})
export class ProfileCompaniesState {
  constructor(private profileCompaniesService: ProfileCompaniesService) {}

  @Action(LoadProfileCompanies, {
    cancelUncompleted: true,
  })
  @ImmutableContext()
  loadProfileCompanies({
    getState,
    setState,
  }: StateContext<ProfileCompaniesStateModel>) {
    const filters = getState().filters;
    return this.profileCompaniesService
      .getUserCompanies(filters.profileUserId, filters.page)
      .pipe(
        tap((companies: PaginationResults<Company, void, void>) => {
          return setState((state: ProfileCompaniesStateModel) => {
            state.companies = companies;
            return state;
          });
        })
      );
  }

  @Action(SetUserProfileCompaniesFilters)
  @ImmutableContext()
  setUserProfileCompaniesFilters(
    { setState }: StateContext<ProfileCompaniesStateModel>,
    { filters }: SetUserProfileCompaniesFilters
  ) {
    return setState((state: ProfileCompaniesStateModel) => {
      state.filters = filters;
      return state;
    });
  }
}

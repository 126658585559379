import { Selector } from '@ngxs/store';
import { ProfileStateModel } from './profile-state.model';
import { ProfileState } from './profile.state';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { Company } from '@app/modules/companies/interfaces/company';
import { User } from '@app/modules/users/interfaces/user';
import { UserComment } from '@app/modules/profile/interfaces/user-comment';

export class ProfileSelectors {
  @Selector([ProfileState])
  static profile(state: ProfileStateModel): User {
    return state.profile;
  }

  @Selector([ProfileState])
  static isCTEmployee(state: ProfileStateModel): boolean {
    return state.profile.isCTEmployee;
  }

  @Selector([ProfileState])
  static isDriver(state: ProfileStateModel): boolean {
    return state.profile.isDriver;
  }

  @Selector([ProfileState])
  static userId(state: ProfileStateModel): number {
    return state.profile.id;
  }

  @Selector([ProfileState])
  static signature(state: ProfileStateModel): string {
    return state.profile.signature;
  }

  @Selector([ProfileState])
  static companies(state: ProfileStateModel): Company[] {
    return state.profile.companies;
  }

  @Selector([ProfileState])
  static accessToken(state: ProfileStateModel): string {
    return state.accessToken;
  }

  @Selector()
  static fieldErrors(state: ProfileStateModel): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      errors.push({
        field: key,
        errors: state.fieldsErrors[key],
      });
    });

    return errors;
  }

  @Selector([ProfileState])
  static comments(state: ProfileStateModel): UserComment[] {
    return state.comments;
  }
}

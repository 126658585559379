import { Injectable, RendererFactory2 } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { delay, map, merge, Observable, of, startWith, Subject } from 'rxjs';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { WhitelabelConfig } from '../interfaces/whitelabel-config';
import { ApiService } from '@app/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private mouseClick$ = new Subject<Event>();
  onClick$: Observable<Event> = this.mouseClick$.asObservable();

  private unfilteredClick$ = new Subject<Event>();
  onUnfilteredClick$: Observable<Event> = this.unfilteredClick$.asObservable();

  private pausedMouseClick$ = new Subject<Event>();
  onPausedClick$: Observable<Event> = this.pausedMouseClick$.asObservable();

  paused = false;

  private isDragging = new Subject<boolean>();
  isDragging$: Observable<boolean> = this.isDragging.asObservable();
  private isDraggingCounter = 0;

  hasKeyboard$: Observable<boolean>;

  isIphone: boolean = false;
  isApp: boolean = false;

  primaryBlockWidthPercent = 35;

  constructor(
    private navCtrl: NavController,
    private router: Router,
    private rendererFactory2: RendererFactory2,
    private keyboard: Keyboard,
    private apiService: ApiService,
    private platform: Platform
  ) {
    this.hasKeyboard$ = this.platform.is('hybrid')
      ? merge(
          this.keyboard.onKeyboardWillShow().pipe(map(() => true)),
          this.keyboard.onKeyboardDidShow().pipe(map(() => true)),
          this.keyboard.onKeyboardWillHide().pipe(map(() => false)),
          this.keyboard.onKeyboardDidHide().pipe(map(() => false))
        ).pipe(startWith(this.keyboard.isVisible))
      : of(false);

    const renderer = this.rendererFactory2.createRenderer(null, null);
    renderer.listen('document', 'click', (event: Event) => {
      this.mouseClick$.next(event);
      if (!this.paused) {
        this.pausedMouseClick$.next(event);
      }
    });

    renderer.listen('document', 'dragenter', (event: Event) => {
      this.isDraggingCounter++;
      if (this.isDraggingCounter === 1) {
        this.isDragging.next(true);
      }
    });

    renderer.listen('document', 'dragleave', (event: Event) => {
      this.isDraggingCounter--;
      if (this.isDraggingCounter === 0) {
        this.isDragging.next(false);
      }
    });

    renderer.listen('document', 'dragover', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    });

    renderer.listen('document', 'drop', (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      this.dragEnd();
    });
  }

  dragEnd(): void {
    this.isDraggingCounter = 0;
    this.isDragging.next(false);
  }

  click(event: Event): void {
    this.unfilteredClick$.next(event);
    if (!this.paused) {
      this.pausedMouseClick$.next(event);
    }
  }

  back() {
    const urlBeforePop = this.router.url;
    this.navCtrl.pop().then(() => {
      if (this.router.url === urlBeforePop) {
        this.navCtrl.navigateRoot('/tabs/dashboard');
      }
    });
  }

  cancelEvent(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    this.click(event);
  }

  getLayoutConfig(): Observable<WhitelabelConfig> {
    return this.apiService.get<WhitelabelConfig>('/config');
  }
}

import { Bet } from '@app/modules/bets/interfaces/bet';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { TenderTypes } from '@app/modules/tenders/tender-types';
import { ChangeTransportAndDriverFormData } from '@app/pages/change-transport-and-driver/change-transport-and-driver.form-data';
import { ConfirmCargoTenderFormData } from '@app/pages/select-offer/confirm-cargo-tender.form-data';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';

export class SelectCarCargoTenderBet {
  static readonly type = '[Cargo Tenders] Select car cargo tender bet';
  constructor(public bet: Bet) {}
}
export class SelectSeaCargoTenderBet {
  static readonly type = '[Cargo Tenders] Select sea cargo tender bet';
  constructor(public bet: Bet) {}
}

export class CargoTenderBetSelected {
  static readonly type =
    '[View Cargo Tender Page] Cargo tender winner bet was selected';
  constructor(public tender: CarCargoTender) {}
}

export class SeaCargoTenderBetSelected {
  static readonly type =
    '[View Sea Cargo Tender Page] Sea cargo tender winner bet was selected';
  constructor(public tender: SeaCargoTender) {}
}

export class ConfirmCargoTender {
  static readonly type =
    '[Cargo Tenders] Confirm car cargo tender (set transport and driver)';
  constructor(
    public tenderId: number,
    public offer: ConfirmCargoTenderFormData
  ) {}
}

export class ConfirmSeaCargoTender {
  static readonly type = '[Cargo Tenders] Confirm sea cargo tender';
  constructor(public tenderId: number) {}
}

export class SetCargoTenderTransportAndDriver {
  static readonly type = '[Cargo Tenders] Set tender transport and driver';
  constructor(
    public tenderId: number,
    public data: ChangeTransportAndDriverFormData
  ) {}
}

export class CargoTenderWon {
  static readonly type =
    '[Cargo Tenders] A company has won in a tender (SSE Message)';
  constructor(public tenderId: number) {}
}

export class SignDocuments {
  static readonly type = '[Cargo Tenders] Sign documents';
  constructor(
    public thumbPrint: string,
    public documentIDs: number[],
    public tenderId: number,
    public tenderType: TenderTypes
  ) {}
}

export class ToggleShareDocument {
  static readonly type = '[File Explorer] Toggle share documents';
  constructor(public documentId: number, public companyId: number) {}
}

export class ExtendCargoTender {
  static readonly type = '[View Cargo Tender] Extend cargo tender';
  constructor(
    public id: number,
    public type: CargoTendersTransportationTypes,
    public validUntil: string
  ) {}
}

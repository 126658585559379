import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { BillsService } from '@app/modules/bills/services/bills.service';
import { PayForBillStateModel } from './company-finances_bills_pay-for-bill-state.model';
import { PayForBill } from './company-finances_bills_pay-for-bill.actions';
import { tap } from 'rxjs';
import { BillEntity } from '@app/modules/bills/interfaces/bill-entity';
import { UpdateBillData } from '@app/store/bills/bills.actions';

@Injectable()
@State<PayForBillStateModel>({
  name: 'company_finances__bills__pay_for_bill',
})
export class PayForBillState {
  constructor(private billsService: BillsService, private store: Store) {}

  @Action(PayForBill)
  @ImmutableContext()
  payForBill(
    {}: StateContext<PayForBillStateModel>,
    { billID, data }: PayForBill
  ) {
    return this.billsService.payForBill(billID, data).pipe(
      tap((billEntity: BillEntity) => {
        this.store.dispatch(new UpdateBillData(billEntity));
      })
    );
  }
}

import { TransportAddEditFormData } from '@app/pages/transport/add-edit/interfaces/transport_add-edit.form-data';
import { FieldError } from '@app/interfaces/field-error';

export class InitAddEditTransportForm {
  static readonly type = '[Add/Edit Transport] Initialize the form';
  constructor() {}
}

export class SaveAddTransportFormData {
  static readonly type =
    '[Add/Edit Transport] Save form data (only for transport creation)';
  constructor(public transport: TransportAddEditFormData) {}
}

export class TrySaveTransport {
  static readonly type =
    '[Add/Edit Transport] Try save transport (add or edit)';
  constructor(public transport: TransportAddEditFormData) {}
}
export class SaveTransportFailed {
  static readonly type =
    '[Add/Edit Transport] Unable to save a transport. Wrong data provided?';
  constructor(public errors: FieldError[]) {}
}
export class SaveTransportSucceess {
  static readonly type =
    '[Add/Edit transport] The transport was successfully saved!';
  constructor() {}
}

export class AssignPermission {
  static readonly type = '[Permissions] Assign a permission to a user';
  constructor(
    public permissionName: string,
    public userId: number,
    public companyId: number = 0
  ) {}
}

export class RevokePermission {
  static readonly type = '[Permissions] Revoke a permission from a user';
  constructor(
    public permissionName: string,
    public userId: number,
    public companyId: number = 0
  ) {}
}

export class LoadUserPermissions {
  static readonly type = '[Permissions] Load user permissions';
  constructor(public userId: number, public companyId: number = 0) {}
}

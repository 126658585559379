import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CreateWarehouseFormData } from '@app/pages/warehouses/create/interfaces/create-warehouse.form-data';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';
import { WarehouseTypes } from '@app/modules/warehouses/warehouse-types';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { DatePeriod } from '@app/interfaces/date-period';

marker('MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Холодильник');
marker('MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Неотапливаемый');
marker('MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Отапливаемый');
marker('MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Неизвестный');

@Injectable({
  providedIn: 'root',
})
export class WarehousesService {
  constructor(private apiService: ApiService) {}

  list(page: number): Observable<PaginationResults<Warehouse>> {
    return this.apiService.getPaginatedResults<Warehouse>('/warehouse', {
      page,
      fields: [
        'address',
        'area',
        'user.id',
        'user.avatar',
        'user.first_name',
        'user.middle_name',
        'user.last_name',
        'user.isContact',
        'user.isBlacklist',
        'currency.code',
        'freeArea',
        'freePalletPlaces',
        'id',
        'name',
        'phone',
        'priceForM2',
        'priceForPalletPlace',
        'priceForWork',
        'photos.url',
        'type',
      ].join(','),
      expand: ['currency', 'user'].join(','),
    });
  }

  listAll(
    isSuperAdmin: boolean,
    createdAt: DatePeriod = null
  ): Observable<Warehouse[]> {
    const data: any = {};
    data.fields = [
      'id',
      'coords.latitude',
      'coords.longitude',
      'address',
      'area',
      'type',
    ];

    if (isSuperAdmin) {
      data.fields.push([
        'user.id',
        'user.first_name',
        'user.middle_name',
        'user.last_name',
      ]);
      data.expand = 'user';
    }

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }

    return this.apiService.getAll<Warehouse>('/warehouse', data);
  }

  get(id: number): Observable<Warehouse> {
    return this.apiService.get<Warehouse>('/warehouse/' + id, {
      fields: [
        'address',
        'area',
        'currency.code',
        'freeArea',
        'freePalletPlaces',
        'id',
        'name',
        'phone',
        'priceForM2',
        'priceForPalletPlace',
        'priceForWork',
        'photos.url',
        'type',
        'user.id',
        'user.avatar',
        'user.first_name',
        'user.middle_name',
        'user.last_name',
        'user.isContact',
        'user.isBlacklist',
        'user.phone',
        'description',
      ].join(','),
      expand: ['currency', 'user'].join(','),
    });
  }

  getForEditing(id: number): Observable<Warehouse> {
    return this.apiService.get<Warehouse>('/warehouse/' + id, {
      fields: [
        'id',
        'address',
        'coords.latitude',
        'coords.longitude',
        'currencyId',
        'name',
        'area',
        'freeArea',
        'priceForM2',
        'freePalletPlaces',
        'priceForPalletPlace',
        'priceForWork',
        'type',
        'phone',
        'description',
        'photos.id',
        'photos.url',
      ].join(','),
    });
  }

  create(warehouse: CreateWarehouseFormData): Observable<Warehouse> {
    return this.apiService.create<Warehouse>('/warehouse', warehouse);
  }

  delete(id: number): Observable<unknown> {
    return this.apiService.delete<unknown>('/warehouse/' + id);
  }

  getTypeName(type: WarehouseTypes): string {
    switch (type) {
      case WarehouseTypes.FRIDGE:
        return 'MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Холодильник';
      case WarehouseTypes.UNHEATED:
        return 'MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Неотапливаемый';
      case WarehouseTypes.WARM:
        return 'MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Отапливаемый';
      default:
        return 'MODULES.WAREHOUSES.SERVICES.WAREHOUSES.Неизвестный';
    }
  }

  uploadPhotos(warehouseId: number, photos: string[]): Observable<Warehouse> {
    return this.apiService.put<Warehouse>(
      '/warehouse/' + warehouseId + '/photos',
      { photos }
    );
  }

  deletePhoto(warehouseId: number, photoId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>(
        '/warehouse/' + warehouseId + '/photos/' + photoId
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }

  update(id: number, data: CreateWarehouseFormData): Observable<Warehouse> {
    return this.apiService.put<Warehouse>('/warehouse/' + id, data);
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { User } from '@app/modules/users/interfaces/user';
import { UpdateProfileFormData } from '@app/pages/profile/interfaces/update-profile.form-data';
import { ApiService } from '@app/services/api.service';
import { Observable, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiService: ApiService, private router: Router) {}

  setCompany(companyId?: number): Observable<NewAccessTokenResponse> {
    return this.apiService.put<NewAccessTokenResponse>(
      '/profile/change-company',
      {
        companyId,
      }
    );
  }

  updateProfile(
    data: UpdateProfileFormData,
    userId: number
  ): Observable<NewAccessTokenResponse> {
    return this.apiService.put<NewAccessTokenResponse>(
      userId ? '/user/' + userId : '/profile',
      data
    );
  }

  setUserTimeBlocking(userId: number, timeBlocking?: string): Observable<User> {
    return this.apiService.put<User>('/user/' + userId + '/time-blocking', {
      timeBlocking,
    });
  }

  deletePersonalAccount(): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>('/profile/user')
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }
}

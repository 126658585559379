import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { Bill } from '@app/modules/bills/interfaces/bill';
import { BillsListFilters } from '@app/modules/bills/interfaces/bills-list-filters';
import { CreateAdditionalInvoiceFormData } from '@app/modules/tenders/components/create-additional-invoice/form/create-additional-invoice.form-data';
import { BillsSummary } from '@app/modules/bills/interfaces/bills-summary';
import { PayForBillFormData } from '@app/modules/bills/components/pay-for-bill/pay-for-bill.form-data';
import { BillEntity } from '@app/modules/bills/interfaces/bill-entity';

@Injectable({
  providedIn: 'root',
})
export class BillsService {
  constructor(private apiService: ApiService) {}

  getCompanyDebit(
    filters: BillsListFilters
  ): Observable<PaginationResults<Bill, void, BillsSummary[]>> {
    return this.apiService.getPaginatedResults<Bill, BillsSummary[]>(
      '/invoice/debit',
      {
        ...filters,
      }
    );
  }

  getCompanyCredit(
    filters: BillsListFilters
  ): Observable<PaginationResults<Bill, void, BillsSummary[]>> {
    return this.apiService.getPaginatedResults<Bill, BillsSummary[]>(
      '/invoice/credit',
      {
        ...filters,
      }
    );
  }

  getCargoTendersDebit(
    filters: BillsListFilters
  ): Observable<PaginationResults<Bill, void, BillsSummary[]>> {
    return this.apiService.getPaginatedResults<Bill, BillsSummary[]>(
      '/invoice/ct/debit',
      {
        ...filters,
      }
    );
  }

  getCargoTendersCredit(
    filters: BillsListFilters
  ): Observable<PaginationResults<Bill, void, BillsSummary[]>> {
    return this.apiService.getPaginatedResults<Bill, BillsSummary[]>(
      '/invoice/ct/credit',
      {
        ...filters,
      }
    );
  }

  createAdditionalInvoice(
    data: CreateAdditionalInvoiceFormData
  ): Observable<unknown> {
    return this.apiService.post<unknown>('/invoice', data);
  }

  payForBill(billID: number, data: PayForBillFormData): Observable<BillEntity> {
    return this.apiService.put<BillEntity>(
      '/invoice/' + billID + '/payment',
      data
    );
  }

  deactivate(billID: number): Observable<BillEntity> {
    return this.apiService.put<BillEntity>(
      '/invoice/' + billID + '/deactivate'
    );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExchangeCargoType } from '@app/modules/exchange-cargo-types/interfaces/exchange-cargo-type';

@Injectable({
  providedIn: 'root',
})
export class ExchangeCargoTypesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<ExchangeCargoType[]> {
    return this.apiService
      .getAll<ExchangeCargoType>('/exchange/cargo-type')
      .pipe(
        map((cargoTypes: ExchangeCargoType[]) => {
          return cargoTypes.reverse();
        })
      );
  }
}

export class SetViewMonthInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Set view month in the tasks calendar widget';
  constructor(public month: number) {}
}

export class SetViewYearInTasksCalendarWidget {
  static readonly type =
    '[Widgets -> Tasks calendar] Set view year in the tasks calendar widget';
  constructor(public year: number) {}
}

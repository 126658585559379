import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { Company } from '@app/modules/companies/interfaces/company';
import { ApiService } from '@app/services/api.service';
import { AuthSelectors } from '@app/store/auth/auth.selectors';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { User } from '@app/modules/users/interfaces/user';
import { UsersListFilters } from '@app/pages/users/list/interfaces/users-list-filters';
import { ChangePasswordFormData } from '@app/pages/profile/tab1/interfaces/change-password.form-data';
import { UserGenders } from '@app/modules/users/user-genders';
import { UsersSelectors } from '@app/store';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { UserAnalytics } from '../interfaces/user-analytics';

marker('GENDERS.Мужской');
marker('GENDERS.Женский');

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private apiService: ApiService, private store: Store) {}

  getUsers(
    page: number = 1,
    filters: UsersListFilters
  ): Observable<PaginationResults<User>> {
    for (var propName in filters) {
      if (
        filters[propName] === null ||
        filters[propName] === undefined ||
        filters[propName] === false
      ) {
        delete filters[propName];
      }

      if (filters[propName] === true) {
        filters[propName] = 1;
      }
    }

    const data: any = {
      page,
      // fields: [
      //   'id',
      //   'first_name',
      //   'middle_name',
      //   'last_name',
      //   'email',
      //   'created_companies_count',
      //   'work_in_companies_count',
      //   'status',
      //   'is_favorite',
      //   'is_blocked',
      //   'is_deleted',
      //   'login_at',
      // ].join(','),
      expand: ['checkDocumentsStatus'],
      ...filters,
    };

    return this.apiService.getPaginatedResults<User>('/user', data);
  }

  getUser(userId: number): Observable<User> {
    /*
    important note:
    the results must include the next fields, to correctly work with chats:
    'id',
    'avatar',
    'first_name',
    'middle_name',
    'last_name',
    'isContact',
    'isBlacklist',
    */
    // return this.apiService.get<User>('/user/' + userId, {
    //   expand: ['companies', 'companies.details'].join(','),
    // });
    return this.apiService.get<User>('/user/' + userId);
  }

  getCompanies(userId: number): Observable<Company[]> {
    return this.apiService
      .get<User>('/user/' + userId, {
        fields: ['companies'].join(','),
        expand: ['companies', 'companies.details'].join(','),
      })
      .pipe(
        map((user: User) => {
          return user.companies;
        })
      );
  }

  // getMyCompaniesNew(page: number = 1): Observable<PaginationResults<Company>> {
  //   return this.store.selectOnce<User>(AuthSelectors.user).pipe(
  //     mergeMap((user: User) => {
  //       if (user) {
  //         return this.getUserCompanies(user.id, page);
  //       }
  //       // Empty result for pagination
  //       return of({
  //         maxPage: 1,
  //         currentPage: 1,
  //         pageCount: 1,
  //         perPage: 20,
  //         totalCount: 0,
  //         data: [],
  //       });
  //     })
  //   );
  // }

  getMyCompanies(): Observable<Company[]> {
    return this.store.selectOnce<User>(AuthSelectors.user).pipe(
      mergeMap((user: User) => {
        if (user) {
          return this.getCompanies(user.id);
        }
        return of([]);
      })
    );
  }

  changePassword(formData: ChangePasswordFormData): Observable<unknown> {
    return this.apiService.put<unknown>('/profile/change-password', formData);
  }

  lockUser(userId: number): Observable<User> {
    return this.apiService.put<User>('/user/' + userId + '/deactivate');
  }

  unlockUser(userId: number): Observable<User> {
    return this.apiService.put<User>('/user/' + userId + '/activate');
  }

  getGenderName(gender: UserGenders): string {
    return gender === UserGenders.MALE ? 'GENDERS.Мужской' : 'GENDERS.Женский';
  }

  isOnline(userID: number): Observable<boolean> {
    return this.store.select(UsersSelectors.isOnline(userID));
  }

  getUserProfileForChat(userID: number): Observable<User> {
    return this.apiService.get<User>('/user/' + userID, {
      fields: [
        'avatar',
        'first_name',
        'middle_name',
        'last_name',
        'birthday',
        'email',
        'phone',
        'internalPhone',
        'login_at',
        'tin',
        'sex',
        'workEmail',
        'registrationAddress',
        'actualAddress',
        'created_at',
        // 'companies.companyType.name',
        // 'companies.details.companyName.short',
        // 'companies.details.tin',
      ].join(','),
      // expand: ['companies.details'].join(','),
    });
  }

  getUserAnalytics(userID: number): Observable<UserAnalytics> {
    return this.apiService.get<UserAnalytics>('/user/' + userID + '/analytics');
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, of, Subscriber } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { CTDocument } from '@app/modules/documents/interfaces/ct-document';
import { EDFDocument } from '@app/modules/documents/interfaces/edf-document';
import { DocumentContent } from '@app/modules/documents/interfaces/document-content';
import { SignedDocument } from '@app/modules/documents/interfaces/signed-document';
import { TenderTypes } from '@app/modules/tenders/tender-types';
import { DocumentShareInfo } from '@app/modules/documents/interfaces/document-share-info';
import { FileService } from '@app/services/file.service';
import { UiService } from '@app/services/ui.service';
import { User } from '@app/modules/users/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  constructor(
    private apiService: ApiService,
    private fileService: FileService,
    private uiService: UiService
  ) {}

  loadDirectoryContents(directoryId: number): Observable<CTDocument[]> {
    return this.apiService.getAll<CTDocument>(
      '/document/folder/' + directoryId
    );
  }

  createDirectory(
    directoryName: string,
    parentId?: number
  ): Observable<CTDocument> {
    return this.apiService.post('/document/folder', {
      name: directoryName,
      parent_id: parentId,
    });
  }

  renameDocument(documentId: number, name: string): Observable<CTDocument> {
    return this.apiService.put('/document/' + documentId + '/rename', {
      name,
    });
  }

  public uploadDocument(file: File, directoryId: number): Observable<unknown> {
    return new Observable<string>((subscriber: Subscriber<string>) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        subscriber.next(reader.result as string);
        subscriber.complete();
      };

      reader.onerror = () => {
        subscriber.error();
        subscriber.complete();
      };
    }).pipe(
      mergeMap((fileAsBase64: string) => {
        return this.apiService.post('/document/upload-file', {
          parent_id: directoryId,
          name: file.name,
          file: fileAsBase64,
        });
      })
    );
  }

  getDocument(documentId: number): Observable<CTDocument> {
    return this.apiService.get('/document/' + documentId);
  }

  deleteDocument(documentId: number): Observable<unknown> {
    return this.apiService.delete<unknown>('/document/' + documentId);
  }

  getDocumentContent(documentId: number): Observable<string> {
    return this.apiService
      .get<DocumentContent>('/document/' + documentId + '/content')
      .pipe(
        map((documentContent: DocumentContent) => {
          return documentContent.content;
        })
      );
  }

  signDocuments(
    thumbPrint: string,
    signedDocuments: SignedDocument[],
    tenderId: number,
    tenderType: TenderTypes
  ): Observable<EDFDocument[]> {
    return this.apiService.create<EDFDocument[]>('/edf/sign', {
      fingerprint: thumbPrint,
      documents: signedDocuments,
      tender: {
        id: tenderId,
        type: tenderType,
      },
    });
  }

  getShareCompanies(documentId: number): Observable<DocumentShareInfo[]> {
    return this.apiService.getAll<DocumentShareInfo>(
      '/document/' + documentId + '/display',
      {
        fields: [
          'isVisible',
          'company.id',
          'company.details.companyName.short',
        ].join(','),
        expand: ['company.details'].join(','),
      }
    );
  }

  toggleShareCompany(
    documentId: number,
    companyId: number
  ): Observable<CTDocument> {
    return this.apiService.put<CTDocument>(
      '/document/' + documentId + '/display/' + companyId
    );
  }

  uploadUserSignature(userId: number, file: File): Observable<string> {
    return this.fileService.fileAsBase64(file).pipe(
      mergeMap((fileAsBase64: string) => {
        return this.apiService
          .put<User>('/user/' + userId + '/signature', {
            image: fileAsBase64,
          })
          .pipe(
            catchError((err: any) => {
              this.uiService.displayProvidedErrors(
                err.originalError.error.errors,
                true
              );
              return of(null);
            }),
            map((user: User) => {
              return user?.signature;
            })
          );
      })
    );
  }
}

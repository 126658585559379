export class LoadApiToken {
  static readonly type = '[Profile Page -> API Key] Load (get) the API token';
  constructor() {}
}

export class GenerateApiToken {
  static readonly type = '[Profile Page -> API Key] Generate new API token';
  constructor(public ip: string) {}
}

export class RevokeApiToken {
  static readonly type = '[Profile Page -> API Key] Revoke the API token';
  constructor() {}
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { catchError, tap } from 'rxjs/operators';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { UiService } from '@app/services/ui.service';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportBetsService } from '@app/modules/transport-bets/services/transport-bets.service';
import { TransportTendersListStateModel } from './transport-tenders_list-state.model';
import {
  ConfirmTransportTender,
  ExportTransportTenders,
  FetchTransportTenders,
  ImportTransportTenders,
  ImportTransportTendersErrors,
  SetTransportTendersSearchValue,
} from './transport-tenders_list.actions';
import {
  AddTransportBetFailed,
  AddTransportBetSuccess,
  TryAddTransportBet,
} from '@app/store/transport_bets';
import {
  SetUnreadMessagesCountInTenderChat,
  UpdateTransportTenderChatGroupID,
} from '@app/store/chat';
import { NewTransportBetPlaced } from '@app/store/bets';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';
import { ImportResponse } from '@app/modules/transport-tender/interfaces/import-response';
import {
  DeleteTransportTender,
  TransportTenderBetSelected,
} from '@app/store/transport-tenders/view';
import { PublishAllTransportTenders } from './transport-tenders_list.actions';
import { SetTransportTenderValidUntil } from '@app/store/transport-tenders/transport-tenders.actions';
import { ReadTransportTender } from './transport-tenders_list.actions';
import {
  ResetTransportTendersListFilters,
  SetTransportTendersListFilters,
  TransportTendersListFiltersState,
} from './filters';
import { TransportTendersListFiltersStateModel } from './filters/transport-tenders_list_filters-state.model';
import { TransportTendersListFiltersFormData } from '@app/pages/transport-tender/list/components/filters-form/transport-tenders-list-filters.form-data';
import {
  AddTransportTenderToFavorites,
  RemoveTransportTenderFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<TransportTendersListStateModel>({
  name: 'transport_tenders__list',
  defaults: {
    searchValue: null,
    tenders: null,
    page: 1,
    maxPage: null,
  },
  children: [TransportTendersListFiltersState],
})
export class TransportTendersListState {
  constructor(
    private transportTenderService: TransportTenderService,
    private transportBetsService: TransportBetsService,
    private store: Store,
    private uiService: UiService
  ) {}

  @Action([ResetTransportTendersListFilters, SetTransportTendersListFilters])
  @ImmutableContext()
  onFilterValueChange({
    setState,
  }: StateContext<TransportTendersListStateModel>) {
    return setState((state: TransportTendersListStateModel) => {
      state.page = 1;
      return state;
    });
  }

  @Action(SetTransportTendersSearchValue)
  @ImmutableContext()
  setTransportTendersSearchValue(
    { setState }: StateContext<TransportTendersListStateModel>,
    { searchValue }: SetTransportTendersSearchValue
  ) {
    return setState((state: TransportTendersListStateModel) => {
      state.searchValue = searchValue;
      state.page = 1;
      return state;
    });
  }

  @Action(SetTransportTendersListFilters)
  @ImmutableContext()
  setTransportTendersListFilters(
    { setState }: StateContext<TransportTendersListFiltersStateModel>,
    { filters }: SetTransportTendersListFilters
  ) {
    return setState((state: TransportTendersListFiltersStateModel) => {
      state.displayFilters = false;
      state.isFavorite = !!filters.isFavorite;
      state.createdAtFrom = filters.createdAtFrom;
      state.createdAtTo = filters.createdAtTo;
      state.countryFrom = filters.countryFrom;
      state.countryTo = filters.countryTo;
      return state;
    });
  }

  @Action(FetchTransportTenders)
  @ImmutableContext()
  fetchTransportTenders(
    { getState, setState }: StateContext<TransportTendersListStateModel>,
    { page }: FetchTransportTenders
  ) {
    const newPage = page || getState().page;

    const filtersModel: TransportTendersListFiltersStateModel =
      this.store.selectSnapshot(TransportTendersListFiltersState);

    let filters: TransportTendersListFiltersFormData = {
      isFavorite: filtersModel.isFavorite ? 1 : 0,
      createdAtFrom: filtersModel.createdAtFrom,
      createdAtTo: filtersModel.createdAtTo,
      countryFrom: filtersModel.countryFrom,
      countryTo: filtersModel.countryTo,
    };

    filters = Object.keys(filters)
      .filter((key) => filters[key] !== 0)
      .reduce((newObj, key) => {
        newObj[key] = filters[key];
        return newObj;
      }, {});

    return this.transportTenderService
      .getTenders(newPage, getState().searchValue, filters)
      .pipe(
        tap((result: PaginationResults<TransportTender>) => {
          setState((state: TransportTendersListStateModel) => {
            state.tenders = result.data;
            state.page = newPage;
            state.maxPage = result.maxPage;
            return state;
          });
        })
      );
  }

  @Action(TryAddTransportBet)
  @ImmutableContext()
  addBet(
    {}: StateContext<TransportTendersListStateModel>,
    { data }: TryAddTransportBet
  ) {
    this.uiService.displayLoading();
    return this.transportBetsService.add(data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new AddTransportBetSuccess());
      }),
      catchError((error: any) => {
        if (error instanceof UnprocessableEntity) {
          const errorsObj = (error.originalError as HttpErrorResponse).error
            .errors;
          const errorMessages = [];
          Object.keys(errorsObj).forEach((key: string) => {
            errorMessages.push(errorsObj[key]);
          });
          this.store.dispatch(new AddTransportBetFailed(errorMessages));
          this.uiService.dismissLoading();
          return of(null);
        }
      })
    );
  }

  @Action(UpdateTransportTenderChatGroupID)
  @ImmutableContext()
  updateTransportTenderChatGroupID(
    { setState }: StateContext<TransportTendersListStateModel>,
    { transportTenderId, chatGroupID }: UpdateTransportTenderChatGroupID
  ) {
    return setState((state: TransportTendersListStateModel) => {
      const transportTender: TransportTender = state.tenders.find(
        (transportTender: TransportTender) => {
          return transportTender.id === transportTenderId;
        }
      );

      if (transportTender) {
        transportTender.chatId = chatGroupID;
      }

      return state;
    });
  }

  @Action(NewTransportBetPlaced)
  @ImmutableContext()
  newTransportBetPlaced(
    { getState, setState }: StateContext<TransportTendersListStateModel>,
    { tender_id }: NewTransportBetPlaced
  ) {
    const tenders = getState().tenders;
    if (!tenders) {
      return;
    }

    let exists = -1;

    for (let i = 0; i < tenders.length; i++) {
      if (tenders[i].id === tender_id) {
        exists = i;
        break;
      }
    }
    if (exists === -1) {
      return;
    }

    return this.transportTenderService.getTenderBetInfo(tender_id).pipe(
      tap((tender: TransportTender) => {
        setState((state: TransportTendersListStateModel) => {
          state.tenders[exists] = { ...state.tenders[exists], ...tender };
          return state;
        });
      })
    );
  }

  @Action(ConfirmTransportTender)
  @ImmutableContext()
  confirmTransportTender(
    {}: StateContext<TransportTendersListStateModel>,
    { tenderId, data }: ConfirmTransportTender
  ) {
    return this.transportTenderService.confirmTransportTender(tenderId, data);
  }

  @Action(ImportTransportTenders)
  @ImmutableContext()
  importTransportTenders(
    {}: StateContext<TransportTendersListStateModel>,
    { data }: ImportTransportTenders
  ) {
    return this.transportTenderService.import(data).pipe(
      tap((response: ImportResponse) => {
        if (response.errors) {
          this.store.dispatch(
            new ImportTransportTendersErrors(response.errors)
          );
        }
      })
    );
  }

  @Action(ExportTransportTenders)
  @ImmutableContext()
  exportTransportTenders() {
    return this.transportTenderService.export().subscribe((response: any) => {
      let binaryData = [];
      binaryData.push(JSON.stringify(response));
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob(binaryData, { type: 'application/json' })
      );
      downloadLink.setAttribute('download', 'tenders.json');
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

  @Action(SetUnreadMessagesCountInTenderChat)
  @ImmutableContext()
  setUnreadMessagesCountInTenderChat(
    { setState }: StateContext<TransportTendersListStateModel>,
    { chatGroupID, unreadMessagesCount }: SetUnreadMessagesCountInTenderChat
  ) {
    setState((state: TransportTendersListStateModel) => {
      if (!state.tenders) {
        return state;
      }
      const tender: TransportTender = state.tenders.find(
        (tender: TransportTender) => {
          return tender.chatId === chatGroupID;
        }
      );

      if (tender) {
        if (unreadMessagesCount < 0) {
          tender.unreadMessageCount -= unreadMessagesCount;
        } else {
          tender.unreadMessageCount = unreadMessagesCount;
        }
      }

      return state;
    });
  }

  @Action(TransportTenderBetSelected)
  @ImmutableContext()
  transportTenderBetSelected(
    { setState }: StateContext<TransportTendersListStateModel>,
    { tender }: TransportTenderBetSelected
  ) {
    return setState((state: TransportTendersListStateModel) => {
      const tenderIndex = state.tenders.findIndex((t: TransportTender) => {
        return t.id === tender.id;
      });

      if (tenderIndex !== -1) {
        state.tenders[tenderIndex] = {
          ...state.tenders[tenderIndex],
          ...tender,
        };
      }

      return state;
    });
  }

  @Action(PublishAllTransportTenders)
  @ImmutableContext()
  publishAllTransportTenders({}: StateContext<TransportTendersListStateModel>) {
    return this.transportTenderService.publishAll();
  }

  @Action(SetTransportTenderValidUntil)
  @ImmutableContext()
  setTransportTenderValidUntil(
    { setState }: StateContext<TransportTendersListStateModel>,
    { data }: SetTransportTenderValidUntil
  ) {
    return setState((state: TransportTendersListStateModel) => {
      const tenderIndex = state.tenders.findIndex((t: TransportTender) => {
        return t.id === data.tenderId;
      });

      if (tenderIndex !== -1) {
        state.tenders[tenderIndex].date.validUntil = data.validUntil;
      }

      return state;
    });
  }

  @Action(ReadTransportTender)
  @ImmutableContext()
  readTransportTender(
    { setState }: StateContext<TransportTendersListStateModel>,
    { tenderId }: ReadTransportTender
  ) {
    return this.transportTenderService.markTenderAsRead(tenderId).pipe(
      tap(() => {
        setState((state: TransportTendersListStateModel) => {
          const tenderIndex = state.tenders.findIndex(
            (tender: TransportTender) => {
              return tender.id === tenderId;
            }
          );

          if (tenderIndex !== -1) {
            state.tenders[tenderIndex].isNew = false;
            state.tenders[tenderIndex].isRead = true;
          }

          return state;
        });
      })
    );
  }

  @Action(DeleteTransportTender)
  @ImmutableContext()
  deleteTransportTender(
    { setState }: StateContext<TransportTendersListStateModel>,
    { tenderId }: DeleteTransportTender
  ) {
    return setState((state: TransportTendersListStateModel) => {
      state.tenders = state.tenders.filter((tender: TransportTender) => {
        return tender.id !== tenderId;
      });
      return state;
    });
  }

  @Action(AddTransportTenderToFavorites)
  @ImmutableContext()
  addTransportTenderToFavorites(
    { setState }: StateContext<TransportTendersListStateModel>,
    { entityId }: AddTransportTenderToFavorites
  ) {
    return setState((state: TransportTendersListStateModel) => {
      const tender = state.tenders.find((tender: TransportTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveTransportTenderFromFavorites)
  @ImmutableContext()
  removeTransportTenderFromFavorites(
    { setState }: StateContext<TransportTendersListStateModel>,
    { entityId }: RemoveTransportTenderFromFavorites
  ) {
    return setState((state: TransportTendersListStateModel) => {
      const tender = state.tenders.find((tender: TransportTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = false;
      }

      return state;
    });
  }
}

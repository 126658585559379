import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { DeliveryCondition } from '@app/modules/delivery-conditions/interfaces/delivery-condition';

@Injectable({
  providedIn: 'root',
})
export class DeliveryConditionsService {
  constructor(private apiService: ApiService) {}

  list(): Observable<DeliveryCondition[]> {
    return this.apiService.getAll<DeliveryCondition>('/delivery-conditions');
  }
}

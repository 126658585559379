import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SeaShipmentsStateModel } from './sea_shipments-state.model';
import {
  RemoveSeaShipment,
  AddSeaShipment,
  LoadSeaShipments,
} from './sea_shipments.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs/operators';
import { SeaShipmentsService } from '@app/modules/sea-shipments/services/sea-shipments.service';
import { SeaShipment } from '@app/modules/sea-shipments/interfaces/sea-shipment';

@Injectable()
@State<SeaShipmentsStateModel>({
  name: 'widgets__sea_shipments',
  defaults: {
    seaShipments: null,
  },
})
export class SeaShipmentsState {
  constructor(private seaShipmentsService: SeaShipmentsService) {}

  @Action(LoadSeaShipments)
  @ImmutableContext()
  loadSeaShipments({ setState }: StateContext<SeaShipmentsStateModel>) {
    return this.seaShipmentsService.list().pipe(
      tap((seaShipments: SeaShipment[]) => {
        return setState((state: SeaShipmentsStateModel) => {
          state.seaShipments = seaShipments;
          return state;
        });
      })
    );
  }

  @Action(AddSeaShipment)
  @ImmutableContext()
  addSeaShipment(
    {}: StateContext<SeaShipmentsStateModel>,
    { data }: AddSeaShipment
  ) {
    return this.seaShipmentsService.create(data);
  }

  @Action(RemoveSeaShipment)
  @ImmutableContext()
  RemoveSeaShipment(
    { setState }: StateContext<SeaShipmentsStateModel>,
    { seaShipmentID }: RemoveSeaShipment
  ) {
    return this.seaShipmentsService.delete(seaShipmentID).pipe(
      tap(() => {
        setState((state: SeaShipmentsStateModel) => {
          state.seaShipments = state.seaShipments.filter(
            (seaShipment: SeaShipment) => {
              return seaShipment.id !== seaShipmentID;
            }
          );
          return state;
        });
      })
    );
  }
}

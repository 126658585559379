import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TransportService } from '@app/modules/transport/services/transport.service';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { TransportViewStateModel } from './transport_view-state.model';
import {
  LoadTransport,
  AddTransportPhoto,
  RemoveTransportPhoto,
} from './transport_view.actions';
import { TransportPhoto } from '@app/modules/transport/interfaces/transport-photo';

@Injectable()
@State<TransportViewStateModel>({
  name: 'transport__view',
  defaults: {
    transport: null,
  },
})
export class TransportViewState {
  constructor(private transportService: TransportService) {}

  @Action(LoadTransport)
  @ImmutableContext()
  loadTransport(
    { setState }: StateContext<TransportViewStateModel>,
    { transportId }: LoadTransport
  ) {
    return this.transportService.get(transportId).pipe(
      tap((transport: Transport) => {
        setState((state: TransportViewStateModel) => {
          state.transport = transport;
          return state;
        });
      })
    );
  }

  @Action(AddTransportPhoto)
  @ImmutableContext()
  addTransportPhoto(
    { getState, setState }: StateContext<TransportViewStateModel>,
    { photoBase64 }: AddTransportPhoto
  ) {
    const transportId = getState().transport.id;
    return this.transportService
      .addTransportPhoto(transportId, photoBase64)
      .pipe(
        tap((transport: Transport) => {
          setState((state: TransportViewStateModel) => {
            state.transport = transport;
            return state;
          });
        })
      );
  }

  @Action(RemoveTransportPhoto)
  @ImmutableContext()
  removeTransportPhoto(
    { getState, setState }: StateContext<TransportViewStateModel>,
    { id }: RemoveTransportPhoto
  ) {
    const transportId = getState().transport.id;
    return this.transportService.removeTransportPhoto(transportId, id).pipe(
      tap(() => {
        setState((state: TransportViewStateModel) => {
          state.transport.photos = state.transport.photos.filter(
            (photo: TransportPhoto) => {
              return photo.id !== id;
            }
          );
          return state;
        });
      })
    );
  }
}

import { BillsListFilters } from '@app/modules/bills/interfaces/bills-list-filters';

export class LoadCompanyFinancesAccountsPayableBillsList {
  static readonly type =
    '[Company finances -> Bills -> Accounts Payable] Load bills list';
}

export class SetCompanyFinancesAccountsPayableBillsListFilters {
  static readonly type =
    '[Company finances -> Bills -> Accounts Payable] Set filters';
  constructor(public filters: BillsListFilters) {}
}

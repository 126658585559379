import { Selector } from '@ngxs/store';
import { MainPageState } from './main-page.state';
import { MainPageStateModel } from './main-page-state.model';
import { IcebergPopupData } from '@app/pages/main-page/components/iceberg-popup/interfaces/iceberg-popup.data';

export class MainPageSelectors {
  @Selector([MainPageState])
  static isMouseOverIceberg(state: MainPageStateModel): boolean {
    return state.isMouseOverIceberg;
  }

  @Selector([MainPageState])
  static hoveredFragmentName(state: MainPageStateModel): number | null {
    return state.hoveredFragmentName;
  }

  @Selector([MainPageState])
  static activeFragmentName(state: MainPageStateModel): number | null {
    return state.activeFragmentName;
  }

  @Selector([MainPageState])
  static intervalRotationEnabled(state: MainPageStateModel): boolean {
    return state.intervalRotationEnabled;
  }

  @Selector([MainPageState])
  static popupData(state: MainPageStateModel): IcebergPopupData {
    return state.popupData;
  }

  @Selector([MainPageState])
  static videoUrl(state: MainPageStateModel): string {
    return state.videoUrl;
  }
}

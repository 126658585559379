import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { PermissionsStateModel } from './permissions-state.model';
import {
  AssignPermission,
  LoadUserPermissions,
  RevokePermission,
} from './permissions.actions';
import { PermissionsService } from '@app/modules/permissions/services/permissions.service';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { tap } from 'rxjs/operators';

@Injectable()
@State<PermissionsStateModel>({
  name: 'permissions',
  defaults: {
    userPermissions: null,
  },
})
export class PermissionsState {
  constructor(private permissionsService: PermissionsService) {}

  @Action(LoadUserPermissions)
  @ImmutableContext()
  loadUserPermissions(
    { setState }: StateContext<PermissionsStateModel>,
    { userId, companyId }: LoadUserPermissions
  ) {
    return this.permissionsService.getUserPermissions(userId, companyId).pipe(
      tap((permissions: Permission[]) => {
        setState((state: PermissionsStateModel) => {
          state.userPermissions = permissions;
          return state;
        });
      })
    );
  }

  @Action(AssignPermission)
  @ImmutableContext()
  assignPermission(
    {}: StateContext<PermissionsStateModel>,
    { permissionName, userId, companyId }: AssignPermission
  ) {
    return this.permissionsService.assignPermission(
      permissionName,
      userId,
      companyId
    );
  }

  @Action(RevokePermission)
  @ImmutableContext()
  revokePermission(
    {}: StateContext<PermissionsStateModel>,
    { permissionName, userId, companyId }: RevokePermission
  ) {
    return this.permissionsService.revokePermission(
      permissionName,
      userId,
      companyId
    );
  }
}

import { CompanyFinancesTendersStatisticsFilters } from '@app/modules/company-finances/statistics/interfaces/company-finances-tenders-statistics-filters';

export class LoadCompanyFinancesSeaTendersStatistics {
  static readonly type =
    '[Company finances -> Statistics -> Sea tenders] Load sea tenders statistics';
}

export class SetCompanyFinancesSeaTendersStatisticsFilters {
  static readonly type =
    '[Company finances -> Statistics -> Sea tenders] Set filters';
  constructor(public filters: CompanyFinancesTendersStatisticsFilters) {}
}

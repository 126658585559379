import { Selector } from '@ngxs/store';
import { ChatMyChatsStateModel } from './chat_my-chats-state.model';
import { ChatMyChatsState } from './chat_my-chats.state';

export class ChatMyChatsSelectors {
  @Selector([ChatMyChatsState])
  static myChatsSearchValue(state: ChatMyChatsStateModel): string {
    return state.myChatsSearchValue;
  }

  @Selector([ChatMyChatsState])
  static hasMoreResults(state: ChatMyChatsStateModel): boolean {
    return state.myChatsTotal > state.myChats?.size;
  }
}

import { Warehouse } from '@app/modules/warehouses/interfaces/warehouse';
import { Selector } from '@ngxs/store';
import { WarehousesCreateState } from '.';
import { WarehousesCreateStateModel } from './warehouses_create-state.model';

export class WarehousesCreateSelectors {
  @Selector([WarehousesCreateState])
  static warehouse(state: WarehousesCreateStateModel): Warehouse {
    return state.warehouse;
  }
}

import { Selector } from '@ngxs/store';
import { SeaShipmentsState } from './sea_shipments.state';
import { SeaShipmentsStateModel } from './sea_shipments-state.model';
import { SeaShipment } from '@app/modules/sea-shipments/interfaces/sea-shipment';

export class SeaShipmentsSelectors {
  @Selector([SeaShipmentsState])
  static seaShipments(state: SeaShipmentsStateModel): SeaShipment[] {
    return state.seaShipments;
  }
}

import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompaniesAddMemberFormData } from '@app/pages/companies/add-member/interfaces/companies_add-member.form-data';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyMembersFilters } from '@app/modules/companies/interfaces/company-members-filters';

@Injectable({
  providedIn: 'root',
})
export class CompanyMembersService {
  constructor(private apiService: ApiService) {}

  getCompanyMembers(
    companyId: number,
    filters: CompanyMembersFilters
  ): Observable<PaginationResults<CompanyMember>> {
    for (var propName in filters) {
      if (filters[propName] === null || filters[propName] === undefined) {
        delete filters[propName];
      }
    }

    return this.apiService.getPaginatedResults<CompanyMember>(
      '/company/' + companyId + '/staff',
      {
        fields: [
          'id',
          'companyId',
          'date.createdAt',
          'createdBy.id',
          'createdBy.first_name',
          'createdBy.middle_name',
          'createdBy.last_name',
          'createdBy.avatar',
          'isOwner',
          'date.lastVisit',
          'user.id',
          'user.first_name',
          'user.middle_name',
          'user.last_name',
          'user.avatar',
          'user.kpi',
          'position.position',
        ].join(','),
        expand: ['createdBy', 'user', 'position'].join(','), // user.kpi
        company: companyId,
        ...filters,
      }
    );
  }

  addMember(data: CompaniesAddMemberFormData): Observable<CompanyMember> {
    const companyId = data.companyId;
    delete data.companyId;

    return this.apiService.post<CompanyMember>(
      '/company/' + companyId + '/staff',
      data
    );
  }

  removeMember(companyId: number, memberId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/company/' + companyId + '/staff/' + memberId
    );
  }

  setOwner(companyId: number, member: CompanyMember): Observable<unknown> {
    return this.apiService.put('/company/' + companyId + '/owner', {
      userId: member.user.id,
    });
  }
}

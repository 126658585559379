import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CargoTendersBetsHistoryStateModel } from './cargo-tenders_bets-history-state.model';
import { LoadCargoTenderBetsHistory } from './cargo-tenders_bets-history.actions';
import { Bet } from '@app/modules/bets/interfaces/bet';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';

@Injectable()
@State<CargoTendersBetsHistoryStateModel>({
  name: 'cargo_tenders__bets_history',
  defaults: {
    bets: [],
    page: 1,
    maxPage: 1,
  },
})
export class CargoTendersBetsHistoryState {
  constructor(private cargoTendersCarService: CargoTendersCarService) {}

  @Action(LoadCargoTenderBetsHistory)
  @ImmutableContext()
  loadCargoTenderBetsHistory(
    { getState, setState }: StateContext<CargoTendersBetsHistoryStateModel>,
    { tenderId, type, page }: LoadCargoTenderBetsHistory
  ) {
    const newPage = page || getState().page;

    return this.cargoTendersCarService
      .getBetsHistory(tenderId, type, newPage)
      .pipe(
        tap((result: PaginationResults<Bet>) => {
          setState((state: CargoTendersBetsHistoryStateModel) => {
            state.bets = result.data;
            state.page = newPage;
            state.maxPage = result.maxPage;
            return state;
          });
        })
      );
  }
}

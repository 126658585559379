import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { CompanyStructuresStateModel } from './company-structures-state.model';
import {
  DeleteCompanyStructureSuccess,
  LoadCompanyStructures,
  SaveCompanyStructureFailed,
  SaveCompanyStructureSuccess,
  TryDeleteCompanyStructure,
  TrySaveCompanyStructure,
} from './company-structures.actions';
import { UiService } from '@app/services/ui.service';
import { of } from 'rxjs';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { ResponseError } from '@app/interfaces/response-error';
import { CompanyStructuresService } from '@app/modules/companies/services/company-structures.service';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';

@Injectable()
@State<CompanyStructuresStateModel>({
  name: 'company_structures',
  defaults: {
    structures: null,
    fieldsErrors: null,
  },
})
export class CompanyStructuresState {
  constructor(
    private companyStructuresService: CompanyStructuresService,
    private uiService: UiService,
    private store: Store
  ) {}

  @Action(TrySaveCompanyStructure)
  @ImmutableContext()
  trySaveCompanyStructure(
    { setState }: StateContext<CompanyStructuresStateModel>,
    { data }: TrySaveCompanyStructure
  ) {
    this.uiService.displayLoading();

    setState((state: CompanyStructuresStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    const methodName = data.id ? 'update' : 'create';
    return this.companyStructuresService[methodName](data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveCompanyStructureSuccess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new SaveCompanyStructureFailed(
              (err.originalError.error as ResponseError).errors
            )
          );

          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(SaveCompanyStructureFailed)
  @ImmutableContext()
  saveCompanyStructureFailed(
    { setState }: StateContext<CompanyStructuresStateModel>,
    { errors }: SaveCompanyStructureFailed
  ) {
    setState((state: CompanyStructuresStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }

  @Action(LoadCompanyStructures)
  @ImmutableContext()
  loadCompanyStructures(
    { setState }: StateContext<CompanyStructuresStateModel>,
    { companyId }: LoadCompanyStructures
  ) {
    return this.companyStructuresService.list(companyId).pipe(
      tap((structures: CompanyStructure[]) => {
        setState((state: CompanyStructuresStateModel) => {
          state.structures = structures;
          return state;
        });
      })
    );
  }

  @Action(TryDeleteCompanyStructure)
  @ImmutableContext()
  tryDeleteCompanyStructure(
    {}: StateContext<CompanyStructuresStateModel>,
    { companyId, structureId }: TryDeleteCompanyStructure
  ) {
    return this.companyStructuresService.delete(companyId, structureId).pipe(
      tap(() => {
        this.store.dispatch(new DeleteCompanyStructureSuccess());
      })
    );
  }
}

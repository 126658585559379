import { Selector } from '@ngxs/store';
import { TendersPageSeaState } from './tenders-page_sea.state';
import { TendersPageSeaStateModel } from './tenders-page_sea-state.model';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';

export class TendersPageSeaSelectors {
  @Selector([TendersPageSeaState])
  static tenders(state: TendersPageSeaStateModel): SeaCargoTender[] {
    return state.tenders;
  }
}

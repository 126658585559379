import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WhitelabelService {
  isWhitelabel(): boolean {
    const cargotendersHosts = [
      'localhost',
      'sandbox.cargotenders.com',
      'cargotenders.com',
    ];
    return !cargotendersHosts.includes(location.hostname);
  }
}

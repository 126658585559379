import { User } from '@app/modules/users/interfaces/user';

export class ListChatContacts {
  static readonly type =
    '[Chat -> Contacts] Load the list of chat contacts (the first page only)';
  constructor() {}
}

export class TrySearchContact {
  static readonly type = '[Chat -> Contacts] Try to search contact';
  constructor(public searchQuery: string) {}
}

export class CancelSearchContact {
  static readonly type = '[Chat -> Contacts] Cancel search contact';
  constructor() {}
}

export class TryGroupSearchContact {
  static readonly type =
    '[Chat -> Contacts] Try to search contact in create group view';
  constructor(public searchQuery: string) {}
}

export class CancelGroupSearchContact {
  static readonly type =
    '[Chat -> Contacts] Cancel search contact in gorup view';
  constructor() {}
}

export class TryBlockUser {
  static readonly type = '[Chat -> Contacts] Try to block user';
  constructor(public userId: number) {}
}

export class TryUnblockUser {
  static readonly type = '[Chat -> Contacts] Try to unblock user';
  constructor(public userId: number) {}
}

export class UpdatedUserBlockRelation {
  static readonly type =
    '[Chat -> Contacts] Updated user block (black list) relation';
  constructor(public user: User) {}
}

export class TryAddContact {
  static readonly type = '[Chat -> Contacts] Try to add contact';
  constructor(public contactId: number) {}
}

export class TryDeleteContact {
  static readonly type = '[Chat -> Contacts] Try to delete contact';
  constructor(public contactId: number) {}
}

export class UpdatedUserContactRelation {
  static readonly type = '[Chat -> Contacts] Updated user contact relation';
  constructor(public contact: User) {}
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { State, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { SetCompanyFinancesCarTendersStatisticsFilters } from './company-finances_statistics_car-tenders.actions';
import { StatisticsStateModel } from '@app/store/statistics/statistics-state.model';
import { LoadCompanyFinancesCarTendersStatistics } from './company-finances_statistics_car-tenders.actions';
import { CompanyFinancesStatisticsService } from '@app/modules/company-finances/statistics/servcices/company-finances-statistics.service';
import { CompanyFinancesTendersStatisticsRecord } from '@app/modules/company-finances/statistics/interfaces/company-finances-tenders-statistics-record';
import { CompanyFinancesTendersStatisticsSummary } from '@app/modules/company-finances/statistics/interfaces/company-finances-tenders-statistics-summary';
import { ResultsWithSummary } from '@app/interfaces/results-with-summary';
import { PaginationResults } from '@app/interfaces/pagination-results';

@Injectable()
@State<StatisticsStateModel>({
  name: 'company_finances__statistics__car_tenders',
  defaults: {
    statistics: null,
    filters: null,
  },
})
export class CompanyFinancesStatisticsCarTendersState {
  constructor(
    private companyFinancesStatisticsService: CompanyFinancesStatisticsService
  ) {}

  @Action(LoadCompanyFinancesCarTendersStatistics, {
    cancelUncompleted: true,
  })
  @ImmutableContext()
  loadCompanyFinancesCarTendersStatistics({
    getState,
    setState,
  }: StateContext<StatisticsStateModel>) {
    return this.companyFinancesStatisticsService
      .getCarTendersStatistics(getState().filters)
      .pipe(
        tap(
          (
            statistics: PaginationResults<
              CompanyFinancesTendersStatisticsRecord,
              void,
              CompanyFinancesTendersStatisticsSummary
            >
          ) => {
            return setState((state: StatisticsStateModel) => {
              state.statistics = statistics;
              return state;
            });
          }
        )
      );
  }

  @Action(SetCompanyFinancesCarTendersStatisticsFilters)
  @ImmutableContext()
  setCompanyFinancesCarTendersStatisticsFilters(
    { setState }: StateContext<StatisticsStateModel>,
    { filters }: SetCompanyFinancesCarTendersStatisticsFilters
  ) {
    return setState((state: StatisticsStateModel) => {
      state.filters = filters;
      return state;
    });
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WarehousesStateModel } from './warehouses-state.model';
import { WarehousesCreateState } from './create';
import { WarehousesListState } from './list';
import { WarehousesViewState } from './view';
import { DeleteWarehouse } from '.';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { WarehousesService } from '@app/modules/warehouses/services/warehouses.service';

@Injectable()
@State<WarehousesStateModel>({
  name: 'warehouses',
  children: [WarehousesCreateState, WarehousesListState, WarehousesViewState],
})
export class WarehousesState {
  constructor(private warehousesService: WarehousesService) {}

  @Action(DeleteWarehouse)
  @ImmutableContext()
  deleteWarehouse(
    {}: StateContext<WarehousesStateModel>,
    { id }: DeleteWarehouse
  ) {
    return this.warehousesService.delete(id);
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, of, map } from 'rxjs';
import { StickyNote } from '@app/modules/sticky-notes/interfaces/sticky-note';
import { StickyNoteColor } from '@app/modules/sticky-notes/interfaces/sticky-note-color';
import { UpdateStickyNoteFormData } from '@app/modules/sticky-notes/interfaces/update-sticky-notes.form-dats';
import { StickyNoteColors } from '@app/modules/sticky-notes/sticky-note-colors';

@Injectable({
  providedIn: 'root',
})
export class StickyNotesService {
  constructor(private apiService: ApiService) {}

  getStickyNotesColors(): Observable<StickyNoteColor[]> {
    return of([
      {
        id: StickyNoteColors.RED,
        name: 'red',
        code: '#FF9999',
      },

      {
        id: StickyNoteColors.ORANGE,
        name: 'orange',
        code: '#FFCC99',
      },

      {
        id: StickyNoteColors.YELLOW,
        name: 'yellow',
        code: '#FFFF99',
      },

      {
        id: StickyNoteColors.GREEN,
        name: 'green',
        code: '#CCFF99',
      },

      {
        id: StickyNoteColors.LIGHTBLUE,
        name: 'lightlblue',
        code: '#CCFFFF',
      },

      {
        id: StickyNoteColors.BLUE,
        name: 'blue',
        code: '#99CCFF',
      },

      {
        id: StickyNoteColors.VIOLET,
        name: 'violet',
        code: '#CC99FF',
      },
    ]);
  }

  create(): Observable<StickyNote> {
    return this.apiService.create<StickyNote>('/widget/notes');
  }

  update(
    noteID: number,
    data: UpdateStickyNoteFormData
  ): Observable<StickyNote> {
    return this.apiService.put<StickyNote>('/widget/notes/' + noteID, data);
  }

  delete(noteID: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/widget/notes/' + noteID);
  }

  list(): Observable<StickyNote[]> {
    return this.apiService.get<StickyNote[]>('/widget/notes');
  }

  count(): Observable<number> {
    return this.apiService.get<{ count: number }>('/widget/notes/count').pipe(
      map((response: { count: number }) => {
        return response.count;
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { CompanyComment } from '@app/modules/companies/interfaces/company-comment';
import { CommentTypes } from '@app/modules/comments/comment-types';
import { CompanyAddCommentFormData } from '@app/pages/companies/view/company-add-comment.form-data';
import { ResponseSuccess } from '@app/interfaces/response-success';

@Injectable({
  providedIn: 'root',
})
export class CompanyCommentsService {
  constructor(private apiService: ApiService) {}

  list(companyId: number, type: CommentTypes): Observable<CompanyComment[]> {
    return this.apiService.getAll<CompanyComment>(
      '/company/' + companyId + '/comment',
      {
        type,
        extends: ['createdBy'].join(','),
      }
    );
  }

  create(data: CompanyAddCommentFormData): Observable<CompanyComment> {
    const companyId = data.entityId;
    delete data.entityId;
    return this.apiService.post<CompanyComment>(
      '/company/' + companyId + '/comment',
      data
    );
  }

  delete(comment: CompanyComment): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>(
        '/company/' + comment.companyId + '/comment/' + comment.id
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthSelectors } from '@app/store';
import { User } from '@app/modules/users/interfaces/user';

@Injectable()
export class IsSuperAdminGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AuthSelectors.user).pipe(
      mergeMap((user: User) => {
        return of(user?.is_super_admin);
      })
    );
  }
}

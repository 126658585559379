import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { ResponseError } from '@app/interfaces/response-error';
import {
  AddCommentFailed,
  AddCommentSuccess,
  InitAddCommentForm,
  SetReplyToComment,
} from './comments_create.actions';
import { CommentsCreateStateModel } from './comments_create.state-model';
import { TryAddComment } from './comments_create.actions';

@Injectable()
@State<CommentsCreateStateModel>({
  name: 'comments_create',
  defaults: {
    fieldsErrors: null,
    replyTo: null,
  },
})
export class CommentsCreateState {
  constructor(private store: Store) {}

  @Action(InitAddCommentForm)
  @ImmutableContext()
  initAddCommentForm({ setState }: StateContext<CommentsCreateStateModel>) {
    return setState((state: CommentsCreateStateModel) => {
      state.fieldsErrors = null;
      return state;
    });
  }

  @Action(TryAddComment)
  @ImmutableContext()
  tryAddComment(
    { setState }: StateContext<CommentsCreateStateModel>,
    { request$ }: TryAddComment
  ) {
    setState((state: CommentsCreateStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    return request$.pipe(
      tap(() => {
        this.store.dispatch(new AddCommentSuccess());
        this.store.dispatch(new SetReplyToComment(null));
      }),
      catchError((err) => {
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new AddCommentFailed(
              (err.originalError.error as ResponseError).errors
            )
          );
          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(AddCommentFailed)
  @ImmutableContext()
  addCompanyCommentFailed(
    { setState }: StateContext<CommentsCreateStateModel>,
    { errors }: AddCommentFailed
  ) {
    setState((state: CommentsCreateStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }

  @Action(SetReplyToComment)
  @ImmutableContext()
  setReplyToComment(
    { setState }: StateContext<CommentsCreateStateModel>,
    { comment }: SetReplyToComment
  ) {
    setState((state: CommentsCreateStateModel) => {
      state.replyTo = comment;
      return state;
    });
  }
}

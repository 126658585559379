import { Injectable } from '@angular/core';
import { UserFinancesTendersWithMarkup } from '@app/pages/profile/user-finances/interfaces/user-finances-tenders-with-markup';
import { UserFinancesTurnover } from '@app/pages/profile/user-finances/interfaces/user-finances-turnover';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { SetUserSalaryFormData } from '@app/pages/profile/user-finances/components/set-user-salary/set-user-salary.form-data';
import { AddEditUserTurnoverDataFormData } from '@app/pages/profile/user-finances/components/add-user-turnover-data/add-edit-user-turnover-data.form-data';
import { UserFinancesTurnoverEntity } from '@app/pages/profile/user-finances/interfaces/user-finances-turnover-entity';
import { UserFinancesSalaryEntity } from '@app/pages/profile/user-finances/interfaces/user-finances-salary-entity';

@Injectable({
  providedIn: 'root',
})
export class UserFinancesService {
  constructor(private apiService: ApiService) {}

  getUserSalary(userId: number): Observable<UserFinancesSalaryEntity> {
    return this.apiService.get<UserFinancesSalaryEntity>(
      '/user/' + userId + '/salary',
      {
        fields: ['salary', 'currencyId'].join(','),
      }
    );
  }

  setUserSalary(
    userId: number,
    data: SetUserSalaryFormData
  ): Observable<UserFinancesSalaryEntity> {
    return this.apiService.create<UserFinancesSalaryEntity>(
      '/user/' + userId + '/salary',
      data
    );
  }

  addUserTurnoverData(
    userId: number,
    data: AddEditUserTurnoverDataFormData
  ): Observable<UserFinancesTurnoverEntity> {
    return this.apiService.create<UserFinancesTurnoverEntity>(
      '/user/' + userId + '/finance',
      data
    );
  }

  editUserTurnoverData(
    turnoverID: number,
    data: AddEditUserTurnoverDataFormData
  ): Observable<UserFinancesTurnoverEntity> {
    return this.apiService.put<UserFinancesTurnoverEntity>(
      '/user/finance/' + turnoverID,
      data
    );
  }

  deleteUserTurnoverData(turnoverID: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/user/finance/' + turnoverID);
  }

  getTendersWithMarkup(
    userId: number,
    month: string,
    year: string
  ): Observable<UserFinancesTendersWithMarkup> {
    return this.apiService.get<UserFinancesTendersWithMarkup>(
      '/user/' + userId + '/percent-deals',
      { date: month + '.' + year }
    );
  }

  getTurnover(
    userId: number,
    month: string,
    year: string
  ): Observable<UserFinancesTurnover> {
    return this.apiService.get<UserFinancesTurnover>(
      '/user/' + userId + '/finance-data',
      { date: month + '.' + year }
    );
  }
}

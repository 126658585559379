export class LoadTransport {
  static readonly type = '[View Transport Page] View transport';
  constructor(public transportId: number) {}
}

export class AddTransportPhoto {
  static readonly type = '[View Transport Page] Add (upload) transport photo';
  constructor(public photoBase64: string) {}
}

export class RemoveTransportPhoto {
  static readonly type =
    '[Remove Transport Page] Remove (delete) transport photo';
  constructor(public id: number) {}
}

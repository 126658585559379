import { Injectable } from '@angular/core';
import { AddCommentFormData } from '@app/modules/comments/interfaces/add-comment.form-data';
import { Comment } from '@app/modules/comments/interfaces/comment';
import { ApiService } from '@app/services/api.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TaskCommentsService {
  constructor(private apiService: ApiService) {}

  list(taskId: number): Observable<Comment[]> {
    return this.apiService
      .getAll<Comment>('/calendar-task/' + taskId + '/comment')
      .pipe(
        map((comments: Comment[]) =>
          this.commentsToCommentsWithAnswers(comments)
        )
      );
  }

  create(data: AddCommentFormData): Observable<Comment> {
    const taskId = data.entityId;
    delete data.entityId;

    return this.apiService.post<Comment>(
      '/calendar-task/' + taskId + '/comment',
      data
    );
  }

  delete(taskId: number, commentId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/calendar-task/' + taskId + '/comment/' + commentId
    );
  }

  commentsToCommentsWithAnswers(comments: Comment[]): Comment[] {
    let result: Comment[] = comments.filter((comment: Comment) => {
      return comment.answeredCommentId === undefined;
    });
    comments
      .filter((comment: Comment) => {
        return comment.answeredCommentId !== undefined;
      })
      .forEach((answer: Comment) => {
        const parentComment = result.find((searchComment: Comment) => {
          return searchComment.id === answer.answeredCommentId;
        });
        if (parentComment) {
          if (!parentComment.answers) {
            parentComment.answers = [];
          }
          parentComment.answers.push(answer);
        }
      });
    return result;
  }

  // delete(taskId: number, commentId: number): Observable<unknown> {
  //   return this.apiService.delete<unknown>(
  //     '/document/' + taskId + '/comment/' + commentId
  //   );
  // }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthSelectors } from '@app/store';
import { Company } from '@app/modules/companies/interfaces/company';

@Injectable()
export class IsCompanyGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AuthSelectors.company).pipe(
      mergeMap((company: Company) => {
        return of(!!company);
      })
    );
  }
}

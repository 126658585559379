import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CalendarListStateModel } from './calendar_list.state-model';
import {
  ApplyFiltersInCalendar,
  DisplayFiltersInCalendar,
  ListTasksInCalendar,
  SetDateFilterValueInCalendar,
} from './calendar_list.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';
import * as moment from 'moment';
import { CalendarService } from '@app/pages/calendar/services/calendar.service';

const now: moment.Moment = moment();

@Injectable()
@State<CalendarListStateModel>({
  name: 'calendar__list',
  defaults: {
    tasks: null,
    displayFilters: false,
    filters: {
      date: now.format('MM.YYYY'),
      state: null,
      type: null,
    },
  },
})
export class CalendarListState {
  constructor(private calendarService: CalendarService) {}

  @Action(ListTasksInCalendar)
  @ImmutableContext()
  listTasksInCalendar({
    getState,
    setState,
  }: StateContext<CalendarListStateModel>) {
    const filters = getState().filters;
    const dateFilterValue: string = filters.date;
    const dateFilterValueParts = dateFilterValue.split('.');
    return this.calendarService.list(filters).pipe(
      tap((tasks: CalendarTask[]) => {
        setState((state: CalendarListStateModel) => {
          if (!state.tasks) {
            state.tasks = {};
          }
          if (!state.tasks[+dateFilterValueParts[1]]) {
            state.tasks[+dateFilterValueParts[1]] = {};
          }

          state.tasks[+dateFilterValueParts[1]][+dateFilterValueParts[0]] =
            tasks;
          return state;
        });
      })
    );
  }

  @Action(SetDateFilterValueInCalendar)
  @ImmutableContext()
  setDateFilterValueInCalendar(
    { setState }: StateContext<CalendarListStateModel>,
    { date }: SetDateFilterValueInCalendar
  ) {
    setState((state: CalendarListStateModel) => {
      state.filters.date = date;
      return state;
    });
  }

  @Action(DisplayFiltersInCalendar)
  @ImmutableContext()
  displayFiltersInCalendar({ setState }: StateContext<CalendarListStateModel>) {
    setState((state: CalendarListStateModel) => {
      state.displayFilters = true;
      return state;
    });
  }

  @Action(ApplyFiltersInCalendar)
  @ImmutableContext()
  applyFiltersInCalendar(
    { setState }: StateContext<CalendarListStateModel>,
    { filters }: ApplyFiltersInCalendar
  ) {
    setState((state: CalendarListStateModel) => {
      if (filters.date) {
        state.filters.date = filters.date;
      }

      state.filters.type = filters.type;
      if (filters.state) {
        state.filters.state = filters.state;
      } else {
        state.filters.state = null;
      }
      state.displayFilters = false;
      return state;
    });
  }
}

import { RegisterUserWithCompanyFormData } from '@app/pages/register-user-with-company/interfaces/register-user-with-company.form-data';
import { ResponseError } from '@app/interfaces/response-error';

export class InitRegisterUserWithCompanyPage {
  static readonly type = '[Register User With Company Page] Initialize';
}

export class TryRegisterUserWithCompany {
  static readonly type = '[Register User With Company Page] Try register';
  constructor(public payload: RegisterUserWithCompanyFormData) {}
}
export class RegisterUserWithCompanyFailed {
  static readonly type =
    '[Register User With Company Page] Registration failed, wrong data provided?';
  constructor(public responseError: ResponseError) {}
}
export class RegisterUserWithCompanySucceess {
  static readonly type =
    '[Register User With Company Page] Registration succeeded, redirect to dashboard...';
}

import { AddCompanyCuratorFormData } from '@app/pages/companies/view/tab1/components/add-company-curator/add-company-curator.form-data';
import { EditCompanyCuratorFormData } from '@app/pages/companies/view/tab1/components/edit-company-curator/edit-company-curator.form-data';

export class LoadCompanyCurators {
  static readonly type =
    '[View Company Page -> Curators] Load company curators';
  constructor(public companyId: number) {}
}
export class AddCompanyCurator {
  static readonly type = '[View Company Page -> Curators] Add company curator';
  constructor(public data: AddCompanyCuratorFormData) {}
}

export class UpdateCompanyCurator {
  static readonly type =
    '[View Company Page -> Curators] Update company curator';
  constructor(public data: EditCompanyCuratorFormData) {}
}

export class DeleteCompanyCurator {
  static readonly type =
    '[View Company Page -> Curators] Delete company curator';
  constructor(public companyId: number, public userId: number) {}
}

import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError, of, Subject, EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

// Errors
import { BadRequest } from '@app/errors/response-errors/bad-request';
import { NotAcceptable } from '@app/errors/response-errors/not-acceptable';
import { NotFound } from '@app/errors/response-errors/not-found';
import { Unauthorized } from '@app/errors/response-errors/unauthorized';
import { AppError } from '@app/errors/app-error';
import { NoConnection } from '@app/errors/no-connection';
import { Store } from '@ngxs/store';
import { NewAccessTokenResponse } from './interfaces/new-access-token-response';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UiService } from '@app/services/ui.service';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { AuthStateModel } from '@app/store/auth/auth-state.model';
import { SaveAccessAndRefreshTokens, LogoutSuccess } from '@app/store';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isBusy = false;
  private repeatWhenUpdated$ = new Subject();

  constructor(
    private authService: AuthService,
    private store: Store,
    private uiService: UiService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.handle(req, next).pipe(
      catchError((error: any) => {
        this.uiService.dismissLoading();
        return this.refreshTokenFailureHandler(error);
      })
    );
  }

  private handle(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // If request does not have authorization header then pass it
    if (
      !req.headers.get('authorization') ||
      req.url.endsWith('/refresh-token') ||
      req.url.endsWith('/login')
    ) {
      return next.handle(req);
    }

    return this.store
      .selectOnce((state: any) => {
        return state.auth;
      })
      .pipe(
        mergeMap((state: AuthStateModel) => {
          if (state.token) {
            const jwtHelper = new JwtHelperService();
            if (!jwtHelper.isTokenExpired(state.token, 30)) {
              return this.nextReqHandle(req, next);
            }

            if (this.isBusy) {
              return this.repeatWhenUpdated$.pipe(
                mergeMap((token: string) => {
                  const newReq = req.clone({
                    setHeaders: {
                      authorization: 'Bearer ' + token,
                    },
                  });

                  return this.nextReqHandle(newReq, next);
                })
              );
            }

            this.isBusy = true;
            return this.authService.renewAccessToken(state.refreshToken).pipe(
              mergeMap((response: NewAccessTokenResponse) => {
                this.store.dispatch(new SaveAccessAndRefreshTokens(response));
                const newReq = req.clone({
                  setHeaders: {
                    authorization: 'Bearer ' + response.token,
                  },
                });
                this.isBusy = false;
                this.repeatWhenUpdated$.next(response.token);

                return this.nextReqHandle(newReq, next);
              }),
              catchError((refreshTokenError: any) => {
                return this.refreshTokenFailureHandler(refreshTokenError);
              })
            );
          }
        }),
        catchError((error: any) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
              // JWT expired, go to login
              if (
                error.error.message === 'Expired JWT Token' ||
                error.error.message === 'Invalid JWT Token' ||
                error.error.message ===
                  'Your request was made with invalid credentials.'
              ) {
                if (this.isBusy) {
                  return this.repeatWhenUpdated$.pipe(
                    mergeMap((token: string) => {
                      const newReq = req.clone({
                        setHeaders: {
                          authorization: 'Bearer ' + token,
                        },
                      });

                      return this.nextReqHandle(newReq, next);
                    })
                  );
                }

                this.isBusy = true;
                return this.store
                  .selectOnce((state: any) => {
                    return state.auth;
                  })
                  .pipe(
                    mergeMap((state: AuthStateModel) => {
                      return this.authService
                        .renewAccessToken(state.refreshToken)
                        .pipe(
                          mergeMap((response: NewAccessTokenResponse) => {
                            this.store.dispatch(
                              new SaveAccessAndRefreshTokens(response)
                            );
                            const newReq = req.clone({
                              setHeaders: {
                                authorization: 'Bearer ' + response.token,
                              },
                            });

                            this.isBusy = false;
                            this.repeatWhenUpdated$.next(response.token);

                            return this.nextReqHandle(newReq, next);
                          })
                        );
                    }),
                    catchError((refreshTokenError: any) => {
                      return this.refreshTokenFailureHandler(refreshTokenError);
                    })
                  );
              }
            }
          }

          return throwError(() => error);
        })
      );
  }

  private refreshTokenFailureHandler(err: any): Observable<any> {
    return this.convertErrorToAppError(err).pipe(
      catchError((error: any) => {
        if (error instanceof Unauthorized) {
          this.store.dispatch(new LogoutSuccess());
          return of(null);
        }

        return throwError(() => error);
      })
    );
  }

  nextReqHandle(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.url.endsWith('/just-update-refresh-token')) {
      return EMPTY;
    }

    return next.handle(req);
  }

  private convertErrorToAppError(error: any) {
    if (error instanceof AppError) {
      return throwError(() => error);
    }

    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        return throwError(() => new Unauthorized(error));
      }

      if (error.status === 400) {
        return throwError(() => new BadRequest(error));
      }

      if (error.status === 404) {
        return throwError(() => new NotFound(error));
      }

      if (error.status === 406) {
        return throwError(() => new NotAcceptable(error));
      }

      if (error.status === 422) {
        return throwError(() => new UnprocessableEntity(error));
      }

      if (error.status === 0) {
        console.error('Connection error...');
        return throwError(() => new NoConnection());
      }
    }

    return throwError(() => new AppError(error));
  }
}

import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { BetTypes } from '@app/enums/bet-types';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { CreateCargoTenderFormData } from '@app/pages/cargo-tenders/create/car-tender/interfaces/create-cargo-tender.form-data';
import { map } from 'rxjs/operators';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { Bet } from '@app/modules/bets/interfaces/bet';
import { OverallStatistics } from '@app/modules/widgets/components/overall-statistics-widget/interfaces/overall-statistics';
import { TenderPointStates } from '@app/modules/tenders/tender-point-states';
import { ConfirmCargoTenderFormData } from '@app/pages/select-offer/confirm-cargo-tender.form-data';
import { ChangeTransportAndDriverFormData } from '@app/pages/change-transport-and-driver/change-transport-and-driver.form-data';
import { ResultsWithFilters } from '@app/interfaces/results-with-filters';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CargoTendersListFiltersFormData } from '@app/pages/cargo-tenders/list/components/filters-form/cargo-tenders-list-filters.form-data';
import { CargoTender } from '@app/modules/tenders/interfaces/cargo-tender';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { CompanyTendersSummary } from '@app/modules/companies/interfaces/company-tenders-summary';
import { DatePeriod } from '@app/interfaces/date-period';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { TenderPoint } from '@app/modules/points/interfaces/tender-point';
import { SearchEntityParams } from '@app/modules/form-elements/components/select-entity/interfaces/search-entity-params';
import { IdName } from '@app/interfaces/id-name';
import { LinkTendersFormFormData } from '../components/link-tenders/form/link-tenders-form.form-data';
import { TenderConnection } from '../interfaces/tender-connection';
import { CompanyTendersTypes } from '@app/pages/companies/view/company-tenders/company-tenders-types';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Ожидание');
marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.В пути к точке');
marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Прибыл на точку');
marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Загрузка/Разгрузка');
marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Груз не принят');
marker('MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Груз принят');

@Injectable({
  providedIn: 'root',
})
export class CargoTendersCarService {
  constructor(private apiService: ApiService) {}

  create(data: CreateCargoTenderFormData): Observable<CarCargoTender> {
    return this.apiService.create<CarCargoTender>('/tender', data);
  }

  list(
    page: number = 1,
    search: string = null,
    filters: CargoTendersListFiltersFormData = {}
  ): Observable<
    PaginationResults<CarCargoTender, FilteredTendersResultsCount>
  > {
    const data: any = {
      page,
      fields: [
        'id', // USED
        // 'companyId', // USELESS
        'userId', // USED
        // 'cargoTypeId',
        'number', // USED
        'desiredPrice',
        // 'reference',
        // 'isVat',
        // 'description',
        // 'loading',
        // 'tenderers',
        // 'autoRenewal',
        // 'renewalTimeId',
        // 'currencyId',
        // 'betType', // {code: 2, name: "Пошаговые"} // USELESS
        'betType.code', // USED
        // 'stepBetType', // {code: 1, name: "Сумма"} // USELESS
        // 'stepSize', // USELESS
        // 'date',
        // 'status',
        // 'isClosedBets', // USELESS

        'expiresIn', // USED
        'state', // USED
        'currency.icon', // USED

        // todo: optimize, when backend will fix this error (property country is being returned instead of the code property)
        'points.place', // this line, when the issue will be fixed
        // 'points.place.type.code', // USED, uncomment this line, when the issue will be fixed
        // 'points.place.address', // USED, uncomment this line, when the issue will be fixed
        // 'points.place.country.code', // USED, uncomment this line, when the issue will be fixed

        // 'points.state', // USELESS
        'points.id',
        'points.state.code', // USED
        'points.type', // USED
        'points.cargoLoad.name', // USED
        'points.cargoUnload.name', // USED
        'points.date.arrivalTime', // USED

        'nextBetPrice', // USED
        'chatId', // USED

        // 'winBet', // USELESS
        'winBet.companyId', // USED
        'winBet.price.sum', // USED
        'winBet.markupPercent', // USED
        'winBet.clientSum', // USED

        'unreadMessageCount', // USED
        'betsCount', // USED
        'isNew', // USED
        'isRead', // USED
        'company.id',
        'company.details.companyName.short', // USED
        'overall.weight', // USED
        'overall.volume', // USED
        'minBet.isSelfBet', // USED
        'minBet.price.sum', // USED
        'selfBet.price.sum', // USED
        'selfBet.date.delivery', // USED
        'selfBet.priceMin', // USED
        'selfBet.duration', // USED
        'selfBet.comment', // USED
        'canChangeTransport',
        'distance',
        'distance2Gis',
        'pricePerKm2Gis',
        'transportType.transport',
        'isFavorite',
        'tenderMarkup',
      ].join(','),
      expand: [
        // 'company', // USELESS
        'company.details', // USED
        // user
        'transportationType',
        // cargoType
        // renewalTime
        'currency', // USED
        'points', // USED
        'points.cargoLoad', // USED
        'points.cargoUnload', // USED
        // points.cargoLoad.packagingType
        // points.cargoUnload.packagingType
        'transportType',
        // additionalTransportationRequirement
        // tenderUnitedOption
        // 'bets', // not used
        // 'bets.user', // not used
        // 'bets.company', // not used
        // 'bets.company.details', // not used
      ].join(','),
      ...filters,
    };

    if (search) {
      data.search = search;
    }

    return this.apiService
      .get<ResultsWithFilters<CarCargoTender, FilteredTendersResultsCount>>(
        '/tender',
        data
      )
      .pipe(map(this.convertResultsWithFiltersToPaginationResults));
  }

  listOnTendersPage(
    page: number = 1,
    filters: CargoTendersListFiltersFormData = {},
    isBusinessPad = false
  ): Observable<
    PaginationResults<CarCargoTender, FilteredTendersResultsCount>
  > {
    const data: any = {
      page,
      fields: [
        'id',
        'number',
        'company.id',
        'company.details.companyName.short',
        'points.cargoLoad.name',
        'overall.weight',
        'overall.numberPackages',
        'points.type.code',
        'points.place.address.full',
        'points.place.country.country',
        'overall.cargoCost',
        'state.name',
        'company.curators.user.id',
        'company.curators.user.first_name',
        'company.curators.user.middle_name',
        'company.curators.user.last_name',
        'winBet.company.curators.user.id',
        'winBet.company.curators.user.first_name',
        'winBet.company.curators.user.middle_name',
        'winBet.company.curators.user.last_name',
        'winBet.price.sum',
        'currency.icon',
      ].join(','),
      expand: [
        'company.details',
        'currency',
        'company.curators.user',
        'winBet.company.curators.user',
        'points.cargoLoad',
        'points.cargoUnload',
      ].join(','),
      ...filters,
    };

    return this.apiService
      .get<ResultsWithFilters<CarCargoTender, FilteredTendersResultsCount>>(
        '/tender' + (isBusinessPad ? '/business-pad' : ''),
        data
      )
      .pipe(map(this.convertResultsWithFiltersToPaginationResults));
  }

  private convertResultsWithFiltersToPaginationResults(
    response: ResultsWithFilters<CarCargoTender, FilteredTendersResultsCount>
  ): PaginationResults<CarCargoTender, FilteredTendersResultsCount> {
    return {
      currentPage: response._meta?.currentPage,
      pageCount: response._meta?.pageCount,
      perPage: response._meta?.perPage,
      totalCount: response._meta?.totalCount,
      maxPage: response._meta ? response._meta.pageCount : 1,
      data: response.dataModels,
      filter: response.filter,
    };
  }

  listTenders(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName, FilteredTendersResultsCount>> {
    const data: any = {
      page: params.page,
      fields: ['id', 'number'].join(','),
    };

    if (params.search) {
      data.search = params.search;
    }

    return this.apiService
      .get<ResultsWithFilters<CarCargoTender, FilteredTendersResultsCount>>(
        '/tender',
        data
      )

      .pipe(
        map(this.convertResultsWithFiltersToPaginationResults),
        map(
          (
            results: PaginationResults<
              CarCargoTender,
              FilteredTendersResultsCount
            >
          ) => {
            const preparedResults: PaginationResults<
              IdName,
              FilteredTendersResultsCount
            > = {
              ...results,
              data: results.data.map((tender: CarCargoTender) => {
                return {
                  id: tender.id,
                  name: tender.number,
                };
              }),
            };

            return preparedResults;
          }
        )
      );
  }

  // todo: optimize query
  get(id?: number, number?: string): Observable<CarCargoTender> {
    const endpoint = id ? '/tender/' + id : '/tender/by-number/' + number;
    return this.apiService.get<CarCargoTender>(endpoint, {
      expand: [
        'company',
        'company.details',
        'user',
        'transportationType',
        'cargoType',
        'renewalTime',
        'currency',
        'points',
        'points.cargoLoad',
        'points.cargoUnload',
        'points.cargoLoad.packagingType',
        'points.cargoUnload.packagingType',
        'points.square',
        'points.square.country',
        'points.country',
        'transportType',
        'loadingType',
        'additionalTransportationRequirement',
        'tenderUnitedOption',
        'bets',
        'bets.user',
        'bets.company',
        'bets.company.details',
        'winBet.company.details',
        'connectedTenders',
      ].join(','),
    });
  }

  isBetTypeStep(tender: CargoTender): boolean {
    return tender.betType.code === BetTypes.STEP;
  }

  getNextBetPrice(tender: CargoTender): number {
    if (this.isBetTypeStep(tender)) {
      return tender.nextBetPrice;
    }

    if (tender.minBet) {
      return Math.max(tender.minBet?.price.sum - 1, 1);
    }

    return tender.selfBet?.price
      ? Math.max(tender.selfBet?.price.sum - 1, 1)
      : null;
  }

  closeTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/close');
  }

  extendTender(tenderId: number, validUntil: string): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/extend', {
      validUntil,
    });
  }

  refuseTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/refuse');
  }

  cancelTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/cancel');
  }

  completeTender(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>('/tender/' + tenderId + '/done');
  }

  setTenderPointState(
    pointId: number,
    state: TenderPointStates
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/tender-point/' + pointId + '/change-state',
      {
        state,
      }
    );
  }

  loadOverallStatistics(): Observable<OverallStatistics> {
    return this.apiService.get<OverallStatistics>('/widget/common-stat');
  }

  loadCarCargoTenderPoints(
    countryFrom: number = null,
    countryTo: number = null,
    state: TenderStates = TenderStates.ACTIVE,
    createdAt: DatePeriod = null
  ): Observable<CarCargoTender[]> {
    const data: any = {
      fields: [
        'id',
        'number',
        'company.id',
        'state.code',
        'point.place.coords.lat',
        'point.place.coords.lng',
        'point.type.code',
        'point.place.address.full',
        'transportType.transport',
        'transportType.isClosed',
        'companyWithSameRoute',
        'wlCompanyWithSameRoute',
      ].join(','),
      expand: ['company', 'points', 'transportType'].join(','),
    };

    if (countryFrom !== null) {
      data.country_from = [countryFrom].join(',');
    }

    if (countryTo !== null) {
      data.country_to = [countryTo].join(',');
    }

    data.state = [state];

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }

    data['per-page'] = 100;

    return this.apiService.getAll<CarCargoTender>('/tender', data);
  }

  loadCargoReport(
    state: TenderStates = TenderStates.ACTIVE,
    createdAt: DatePeriod = null
  ): Observable<any> {
    const data: any = {};

    data.state = [state];

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }

    return this.apiService.get<any>('/tender/report/cargo', data);
  }

  selectBet(bet: Bet): Observable<CarCargoTender> {
    return this.apiService.put<CarCargoTender>(
      '/tender/bet/' + bet.id + '/set-winner'
    );
  }

  // Set company (optional, only for CargoTender company and its won bet) transport, trailer (optional), driver and payment type
  confirmTender(
    tenderId: number,
    offer: ConfirmCargoTenderFormData
  ): Observable<unknown> {
    return this.apiService.put<ResponseSuccess<unknown>>(
      '/tender/' + tenderId + '/confirm',
      offer
    );
  }

  setTenderTransportAndDriver(
    tenderId: number,
    data: ChangeTransportAndDriverFormData
  ): Observable<unknown> {
    return this.apiService.put<ResponseSuccess<unknown>>(
      '/tender/' + tenderId + '/change-transport',
      data
    );
  }

  getTenderBetInfo(tenderId: number): Observable<CarCargoTender> {
    return this.apiService.get('/tender/' + tenderId, {
      fields: [
        'betsCount',
        'betType',
        'nextBetPrice',
        'minBet',
        'selfBet',
      ].join(','),
    });
  }

  getBetsHistory(
    tenderId: number,
    type: CargoTendersTransportationTypes,
    page: number
  ): Observable<PaginationResults<Bet>> {
    return this.apiService.getPaginatedResults<Bet>(
      '/tender/' + tenderId + '/type/' + type + '/bet',
      {
        page,
        expand: ['company', 'company.details'].join(','),
      }
    );
  }

  markTenderAsRead(tenderId: number): Observable<unknown> {
    return this.apiService.post<unknown>('/tender/view', {
      tenderId,
    });
  }

  getNewTendersCount(): Observable<number> {
    return this.apiService
      .get<number>('/tender/new')
      .pipe(map((r: any) => r.count));
  }

  getTenderPointStateName(state: TenderPointStates): string {
    const availableStates = {
      [TenderPointStates.EXPECTATION]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Ожидание',
      [TenderPointStates.ON_WAY]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.В пути к точке',
      [TenderPointStates.ARRIVED]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Прибыл на точку',
      [TenderPointStates.IN_PROCESS]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Загрузка/Разгрузка',
      [TenderPointStates.CARGO_NOT_ACCEPTED]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Груз не принят',
      [TenderPointStates.CARGO_ACCEPTED]:
        'MODULES.TENDERS.SERVICES.CARGO_TENDERS.CAR.Груз принят',
    };

    return availableStates[state];
  }

  toggleCanChangeTransport(tenderId): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/tender/' + tenderId + '/can-change-transport'
    );
  }

  getCompanyTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    const params: any = {};

    if (page > 1) {
      params.page = page;
    }

    if (datePeriod?.from) {
      params.createdAtFrom = datePeriod.from;
    }

    if (datePeriod?.to) {
      params.createdAtTo = datePeriod.to;
    }

    if (state) {
      params.state = state;
    }

    const apiEndpointPathPart =
      companyTendersTypes === CompanyTendersTypes.CREATED
        ? 'for-company'
        : 'for-company-carrier';

    return this.apiService
      .get<
        ResultsWithFilters<
          CompanyTender,
          FilteredTendersResultsCount,
          CompanyTendersSummary
        >
      >('/tender/' + apiEndpointPathPart + '/' + companyId, params)
      .pipe(
        map(
          (
            response: ResultsWithFilters<
              CompanyTender,
              FilteredTendersResultsCount,
              CompanyTendersSummary
            >
          ) => {
            return {
              currentPage: response._meta?.currentPage,
              pageCount: response._meta?.pageCount,
              perPage: response._meta?.perPage,
              totalCount: response._meta?.totalCount,
              maxPage: response._meta ? response._meta.pageCount : 1,
              data: response.dataModels,
              filter: response.filter,
              summary: response.summary,
            };
          }
        )
      );
  }

  setTenderInProgress(tenderId: number): Observable<CarCargoTender> {
    return this.apiService.put<CarCargoTender>(
      '/tender/' + tenderId + '/in-progress'
    );
  }

  setTenderStateCargoDelivered(tenderId: number): Observable<CarCargoTender> {
    return this.apiService.put<CarCargoTender>(
      '/tender/' + tenderId + '/cargo-delivered'
    );
  }

  delete(tenderId: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/tender/' + tenderId);
  }

  updatePointArrivalTime(
    pointID: number,
    arrivalTime: string
  ): Observable<TenderPoint> {
    return this.apiService.put<TenderPoint>(
      '/tender/point/' + pointID + '/change-date',
      {
        arrivalTime,
      }
    );
  }

  linkTenders(
    tenderID: number,
    tenderType: CargoTendersTransportationTypes,
    data: LinkTendersFormFormData
  ): Observable<TenderConnection> {
    return this.apiService.post<TenderConnection>(
      '/tender/' + tenderID + '/type/' + tenderType + '/connection',
      data
    );
  }

  unlinkTenders(connectionID: number): Observable<boolean> {
    return this.apiService.delete<boolean>(
      '/tender/connection/' + connectionID
    );
  }

  setCarCargoTenderContainerNumber(
    tenderId: number,
    containerNumber: string
  ): Observable<CarCargoTender> {
    return this.apiService.put<CarCargoTender>(
      '/tender/' + tenderId + '/container-number',
      {
        containerNumber,
      }
    );
  }
}

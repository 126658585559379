import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStatementTypes } from '@app/pages/company-finances/tender-statement-types';

export class LoadTendersStatement {
  static readonly type =
    '[Company finances -> Tenders statement] Load tenders statement data';
  constructor() {}
}

export class DownloadTendersStatement {
  static readonly type =
    '[Company finances -> Tenders statement] Download tenders statement';
  constructor() {}
}

export class SetDatePeriodInCompanyTendersStatement {
  static readonly type =
    '[Company finances -> Tenders statement] Set Date Period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetTypeInCompanyTendersStatement {
  static readonly type = '[Company finances -> Tenders statement] Set Type';
  constructor(public type: TenderStatementTypes) {}
}

import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { MapFilters } from '@app/pages/map/interfaces/map-filters.interface';
import { Selector } from '@ngxs/store';
import { MapStateModel } from './map-state.model';
import { MapState } from './map.state';

export class MapSelectors {
  @Selector([MapState])
  static displayFilters(state: MapStateModel): boolean {
    return state.displayFilters;
  }

  @Selector([MapState])
  static filters(state: MapStateModel): MapFilters {
    return state.filters;
  }

  @Selector([MapState])
  static carCargoTenderPoints(state: MapStateModel): CarCargoTender[] {
    return state.carCargoTenderPoints;
  }

  @Selector([MapState])
  static seaCargoTenderPoints(state: MapStateModel): SeaCargoTender[] {
    return state.seaCargoTenderPoints;
  }

  @Selector([MapState])
  static transportPoints(state: MapStateModel): TransportTender[] {
    return state.transportPoints;
  }

  @Selector([MapState])
  static warehousePoints(state: MapStateModel): any[] {
    return state.warehousePoints;
  }

  @Selector([MapState])
  static structures(state: MapStateModel): CompanyStructure[] {
    return state.structures;
  }

  @Selector([MapState])
  static popularRoutes(state: MapStateModel): CompanyPopularRoute[] {
    return state.popularRoutes;
  }

  @Selector([MapState])
  static allCargoPopularRoutes(state: MapStateModel): CompanyPopularRoute[] {
    return state.allCargoPopularRoutes;
  }

  @Selector([MapState])
  static allTransportPopularRoutes(
    state: MapStateModel
  ): CompanyPopularRoute[] {
    return state.allTransportPopularRoutes;
  }

  @Selector([MapState])
  static companies(state: MapStateModel): Company[] {
    return state.companies;
  }
}

export class ArrayHelpers {
  public static move<T>(array, oldIndex, newIndex): Array<T> {
    if (newIndex >= array.length) {
      let k: number = newIndex - array.length + 1;
      while (k--) {
        array.push(undefined);
      }
    }

    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
    return array;
  }
}

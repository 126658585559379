import { Selector } from '@ngxs/store';
import { TransportTendersListFiltersState } from './transport-tenders_list_filters.state';
import { TransportTendersListFiltersStateModel } from './transport-tenders_list_filters-state.model';

export class TransportTendersListFiltersSelectors {
  @Selector([TransportTendersListFiltersState])
  static displayFilters(state: TransportTendersListFiltersStateModel): boolean {
    return state.displayFilters;
  }

  @Selector([TransportTendersListFiltersState])
  static isFavorite(state: TransportTendersListFiltersStateModel): boolean {
    return state.isFavorite;
  }

  @Selector([TransportTendersListFiltersState])
  static createdAtFrom(state: TransportTendersListFiltersStateModel): string {
    return state.createdAtFrom;
  }

  @Selector([TransportTendersListFiltersState])
  static createdAtTo(state: TransportTendersListFiltersStateModel): string {
    return state.createdAtTo;
  }

  @Selector([TransportTendersListFiltersState])
  static countryFrom(state: TransportTendersListFiltersStateModel): number {
    return state.countryFrom;
  }

  @Selector([TransportTendersListFiltersState])
  static countryTo(state: TransportTendersListFiltersStateModel): number {
    return state.countryTo;
  }
}

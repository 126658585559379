import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext } from '@ngxs/store';
import {
  ApplyFiltersOnTendersPage,
  SetCargoTendersTransportationTypeOnTendersPage,
  SetSearchValueOnTendersPage,
  SetTendersPageFiltersDatePeriod,
  ToggleFiltersOnTendersPage,
} from './tenders-page_filters.actions';
import { TendersPageFiltersStateModel } from './tenders-page_filters-state.model';
import { TenderStates } from '@app/modules/tenders/tender-states';

@Injectable()
@State<TendersPageFiltersStateModel>({
  name: 'tenders_page__filters',
  defaults: {
    cargoTendersTransportationType: null,
    states: [
      TenderStates.ACTIVE,
      TenderStates.CONFIRMED,
      TenderStates.CONFIRMED_BY_CARRIER,
      TenderStates.IN_PROGRESS,
    ],
    searchValue: null,
    displayFilters: false,
    createdAtPeriod: null,
    curator: null,
    company: null,
  },
})
export class TendersPageFiltersState {
  @Action(SetCargoTendersTransportationTypeOnTendersPage)
  @ImmutableContext()
  setCargoTendersTransportationTypeOnTendersPage(
    { setState }: StateContext<TendersPageFiltersStateModel>,
    {
      cargoTendersTransportationType,
    }: SetCargoTendersTransportationTypeOnTendersPage
  ) {
    return setState((state: TendersPageFiltersStateModel) => {
      state.cargoTendersTransportationType = cargoTendersTransportationType;
      return state;
    });
  }

  @Action(SetSearchValueOnTendersPage)
  @ImmutableContext()
  setSearchValueOnTendersPage(
    { setState }: StateContext<TendersPageFiltersStateModel>,
    { searchValue }: SetSearchValueOnTendersPage
  ) {
    return setState((state: TendersPageFiltersStateModel) => {
      state.searchValue = searchValue;
      state.displayFilters = false;
      return state;
    });
  }

  @Action(ToggleFiltersOnTendersPage)
  @ImmutableContext()
  toggleFiltersOnTendersPage({
    setState,
  }: StateContext<TendersPageFiltersStateModel>) {
    return setState((state: TendersPageFiltersStateModel) => {
      state.displayFilters = !state.displayFilters;
      return state;
    });
  }

  @Action(SetTendersPageFiltersDatePeriod)
  @ImmutableContext()
  setTendersPageFiltersDatePeriod(
    { setState }: StateContext<TendersPageFiltersStateModel>,
    { datePeriod }: SetTendersPageFiltersDatePeriod
  ) {
    return setState((state: TendersPageFiltersStateModel) => {
      state.createdAtPeriod = datePeriod;
      state.displayFilters = false;
      return state;
    });
  }

  @Action(ApplyFiltersOnTendersPage)
  @ImmutableContext()
  applyFiltersOnTendersPage(
    { setState }: StateContext<TendersPageFiltersStateModel>,
    { filters }: ApplyFiltersOnTendersPage
  ) {
    return setState((state: TendersPageFiltersStateModel) => {
      state.company = filters.company;
      state.curator = filters.curator;
      state.displayFilters = false;
      return state;
    });
  }
}

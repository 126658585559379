import { Selector } from '@ngxs/store';
import { CompanyFinancesStatisticsState } from './company-finances_statistics.state';
import { CompanyFinancesStatisticsStateModel } from './company-finances_statistics-state.model';
import { CompanyFinancesStatisticsTabs } from '@app/pages/company-finances/statistics/company-finances-statitscs-tabs';

export class CompanyFinancesStatisticsSelectors {
  @Selector([CompanyFinancesStatisticsState])
  static activeTab(
    state: CompanyFinancesStatisticsStateModel
  ): CompanyFinancesStatisticsTabs {
    return state.activeTab;
  }
}

import { DatePeriod } from '@app/interfaces/date-period';

export class LoadTendersReport {
  static readonly type =
    '[Company finances -> Tenders report] Load tenders report data';
  constructor() {}
}

export class DownloadTendersReport {
  static readonly type =
    '[Company finances -> Tenders report] Download tenders report';
  constructor() {}
}

export class SetDatePeriodInCompanyTendersReport {
  static readonly type = '[Company finances -> Tenders report] Set Date Period';
  constructor(public datePeriod: DatePeriod) {}
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersCreatedTransport {
  static readonly type =
    '[Company Tenders Created Transport] Load company tenders ';
}

export class SetCompanyTendersCreatedTransportPage {
  static readonly type =
    '[Company Tenders Created Transport] Set company tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersCreatedTransportDatePeriod {
  static readonly type =
    '[Company Tenders Created Transport] Set company tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersCreatedTransportState {
  static readonly type =
    '[Company Tenders Created Transport] Set company tenders state';
  constructor(public states: TenderStates[]) {}
}

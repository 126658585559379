import { DatePeriod } from '@app/interfaces/date-period';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { CompanyTendersMarkup } from '@app/modules/companies/interfaces/company-tenders-markup';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class CompaniesViewTendersStateModel {
  datePeriod: DatePeriod; // filter
  tenders: CompanyTender[];
  markups: CompanyTendersMarkup[];
  page: number;
  maxPage: number;
  state: TenderStates[]; // filter
  filteredResultsCount: FilteredTendersResultsCount;
}

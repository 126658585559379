import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatContactsStateModel } from './chat_contacts-state.model';
import { Injectable } from '@angular/core';
import {
  ListChatContacts,
  TrySearchContact,
  CancelSearchContact,
  TryAddContact,
  TryDeleteContact,
  UpdatedUserContactRelation,
  TryBlockUser,
  TryUnblockUser,
  UpdatedUserBlockRelation,
  TryGroupSearchContact,
  CancelGroupSearchContact,
} from './chat_contacts.actions';
import { tap } from 'rxjs/operators';
import { User } from '@app/modules/users/interfaces/user';
import { ChatContactsService } from '@app/modules/chat/services/chat-contacts.service';
import { ChatBlackListService } from '@app/modules/chat/services/chat-black-list.service';

@Injectable()
@State<ChatContactsStateModel>({
  name: 'chat__chat_contacts',
  defaults: {
    chatContacts: null,
    searchContact: false,
    chatContactsSearchValue: '',
    searchResult: null,
    groupSearchContact: false,
    groupSearchResult: null,
  },
})
export class ChatContactsState {
  constructor(
    private store: Store,
    private chatContactsService: ChatContactsService,
    private chatBlackListService: ChatBlackListService
  ) {}

  @Action(ListChatContacts)
  @ImmutableContext()
  listChatContacts({
    getState,
    setState,
  }: StateContext<ChatContactsStateModel>) {
    if (getState().chatContacts !== null) {
      return;
    }

    return this.chatContactsService.listAll().pipe(
      tap((contacts: User[]) => {
        setState((state: ChatContactsStateModel) => {
          state.chatContacts = contacts;
          return state;
        });
      })
    );
  }

  @Action(CancelSearchContact)
  @ImmutableContext()
  cancelSearchContact({ setState }: StateContext<ChatContactsStateModel>) {
    return setState((state: ChatContactsStateModel) => {
      state.searchContact = false;
      state.searchResult = null;
      return state;
    });
  }

  @Action(CancelGroupSearchContact)
  @ImmutableContext()
  cancelGroupSearchContact({ setState }: StateContext<ChatContactsStateModel>) {
    return setState((state: ChatContactsStateModel) => {
      state.groupSearchContact = false;
      state.groupSearchResult = null;
      return state;
    });
  }

  @Action(TrySearchContact)
  @ImmutableContext()
  trySearchContact(
    { setState }: StateContext<ChatContactsStateModel>,
    { searchQuery }: TrySearchContact
  ) {
    return this.chatContactsService.searchContact(searchQuery).pipe(
      tap((searchResult: User) => {
        setState((state: ChatContactsStateModel) => {
          state.searchContact = true;
          state.searchResult = searchResult;
          return state;
        });
      })
    );
  }

  @Action(TryGroupSearchContact)
  @ImmutableContext()
  tryGroupSearchContact(
    { setState }: StateContext<ChatContactsStateModel>,
    { searchQuery }: TryGroupSearchContact
  ) {
    return this.chatContactsService.searchContact(searchQuery).pipe(
      tap((searchResult: User) => {
        setState((state: ChatContactsStateModel) => {
          state.groupSearchContact = true;
          state.groupSearchResult = searchResult;
          return state;
        });
      })
    );
  }

  @Action(TryAddContact)
  @ImmutableContext()
  tryAddContact(
    {}: StateContext<ChatContactsStateModel>,
    { contactId }: TryAddContact
  ) {
    return this.chatContactsService.add(contactId).pipe(
      tap((user: User) => {
        this.store.dispatch(new UpdatedUserContactRelation(user));
      })
    );
  }

  @Action(TryDeleteContact)
  @ImmutableContext()
  tryDeleteContact(
    {}: StateContext<ChatContactsStateModel>,
    { contactId }: TryDeleteContact
  ) {
    return this.chatContactsService.delete(contactId).pipe(
      tap((result: boolean) => {
        if (result) {
          this.store.dispatch(
            new UpdatedUserContactRelation({ id: contactId, isContact: false })
          );
        }
      })
    );
  }

  @Action(UpdatedUserContactRelation)
  @ImmutableContext()
  updatedUserContactRelation(
    { setState }: StateContext<ChatContactsStateModel>,
    { contact }: UpdatedUserContactRelation
  ) {
    return setState((state: ChatContactsStateModel) => {
      if (state.chatContacts) {
        if (contact.isContact) {
          state.chatContacts.push(contact);
          state.chatContacts.sort((a: User, b: User) => {
            const aName: string =
              a.first_name + ' ' + a.middle_name + ' ' + a.last_name;
            const bName: string =
              b.first_name + ' ' + b.middle_name + ' ' + b.last_name;

            if (aName < bName) {
              return -1;
            }

            if (aName > bName) {
              return 1;
            }

            if (a.id < b.id) {
              return -1;
            }

            return 1;
          });
        } else {
          state.chatContacts = state.chatContacts.filter(
            (currentContact: User) => {
              return currentContact.id !== contact.id;
            }
          );
        }
      }

      // Update search result
      if (state.searchResult?.id === contact.id) {
        state.searchResult.isContact = contact.isContact;
      }

      return state;
    });
  }

  @Action(TryBlockUser)
  @ImmutableContext()
  tryBlockUser(
    {}: StateContext<ChatContactsStateModel>,
    { userId }: TryBlockUser
  ) {
    return this.chatBlackListService.add(userId).pipe(
      tap((user: User) => {
        this.store.dispatch(new UpdatedUserBlockRelation(user));
      })
    );
  }

  @Action(TryUnblockUser)
  @ImmutableContext()
  tryUnblockUser(
    {}: StateContext<ChatContactsStateModel>,
    { userId }: TryUnblockUser
  ) {
    return this.chatBlackListService.delete(userId).pipe(
      tap((result: boolean) => {
        if (result) {
          this.store.dispatch(
            new UpdatedUserBlockRelation({
              id: userId,
              isBlacklist: false,
              isContact: false,
            })
          );
        }
      })
    );
  }

  // @todo: the user should be added/removed from the black list (component? the black list itself), which is not implemented yet
  @Action(UpdatedUserBlockRelation)
  @ImmutableContext()
  updatedUserBlockRelation(
    { setState }: StateContext<ChatContactsStateModel>,
    { user }: UpdatedUserBlockRelation
  ) {
    return setState((state: ChatContactsStateModel) => {
      // Remove blacklist users from contacts list
      if (user.isBlacklist && state.chatContacts) {
        state.chatContacts = state.chatContacts.filter(
          (currentContact: User) => {
            return currentContact.id !== user.id;
          }
        );
      }

      // Update search result
      if (state.searchResult?.id === user.id) {
        state.searchResult.isContact = user.isContact;
        state.searchResult.isBlacklist = user.isBlacklist;
      }

      return state;
    });
  }
}

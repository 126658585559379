import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { TransportService } from '@app/modules/transport/services/transport.service';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { TransportListStateModel } from './transport_list-state.model';
import {
  DeleteTransport,
  LoadTransportList,
  LockTransport,
  SetSearchValue,
  SetTransportIsFavoriteFilterValue,
  UnlockTransport,
} from './transport_list.actions';
import {
  AddTransportToFavorites,
  RemoveTransportFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<TransportListStateModel>({
  name: 'transport__list',
  defaults: {
    searchValue: null,
    transport: [],
    page: 1,
    maxPage: 1,
    isFavorite: false,
  },
})
export class TransportListState {
  constructor(private transportService: TransportService) {}

  @Action(LoadTransportList)
  @ImmutableContext()
  loadCompaniesList(
    { getState, setState }: StateContext<TransportListStateModel>,
    { page, companyId }: LoadTransportList
  ) {
    const newPage = page || getState().page;

    const searchValue = getState().searchValue;

    return this.transportService
      .list(newPage, companyId, searchValue, getState().isFavorite)
      .pipe(
        tap((result: PaginationResults<Transport>) => {
          setState((state: TransportListStateModel) => {
            state.transport = result.data;
            state.page = newPage;
            state.maxPage = result.maxPage;
            return state;
          });
        })
      );
  }

  @Action(DeleteTransport)
  @ImmutableContext()
  deleteCompany(
    {}: StateContext<TransportListStateModel>,
    { transportId }: DeleteTransport
  ) {
    return this.transportService.delete(transportId);
  }

  @Action(LockTransport)
  @ImmutableContext()
  lockTransport(
    { setState }: StateContext<TransportListStateModel>,
    { transportId }: LockTransport
  ) {
    return this.transportService.lockTransport(transportId).pipe(
      tap((transport: Transport) => {
        setState((state: TransportListStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.transport.length; i++) {
            if (state.transport[i].id === transportId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.transport[exists] = {
              ...state.transport[exists],
              ...{ status: transport.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(UnlockTransport)
  @ImmutableContext()
  unlockTransport(
    { setState }: StateContext<TransportListStateModel>,
    { transportId }: UnlockTransport
  ) {
    return this.transportService.unlockTransport(transportId).pipe(
      tap((transport: Transport) => {
        setState((state: TransportListStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.transport.length; i++) {
            if (state.transport[i].id === transportId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.transport[exists] = {
              ...state.transport[exists],
              ...{ status: transport.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(SetSearchValue)
  @ImmutableContext()
  setSearchValue(
    { setState }: StateContext<TransportListStateModel>,
    { searchValue }: SetSearchValue
  ) {
    return setState((state: TransportListStateModel) => {
      state.searchValue = searchValue;
      return state;
    });
  }

  @Action(SetTransportIsFavoriteFilterValue)
  @ImmutableContext()
  setTransportIsFavoriteFilterValue(
    { setState }: StateContext<TransportListStateModel>,
    { isFavorite }: SetTransportIsFavoriteFilterValue
  ) {
    return setState((state: TransportListStateModel) => {
      state.isFavorite = isFavorite;
      return state;
    });
  }

  @Action(AddTransportToFavorites)
  @ImmutableContext()
  addTransportToFavorites(
    { setState }: StateContext<TransportListStateModel>,
    { entityId }: AddTransportToFavorites
  ) {
    return setState((state: TransportListStateModel) => {
      const transport = state.transport.find((transport: Transport) => {
        return transport.id === entityId;
      });

      if (transport) {
        transport.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveTransportFromFavorites)
  @ImmutableContext()
  removeTransportFromFavorites(
    { setState }: StateContext<TransportListStateModel>,
    { entityId }: RemoveTransportFromFavorites
  ) {
    return setState((state: TransportListStateModel) => {
      const transport = state.transport.find((transport: Transport) => {
        return transport.id === entityId;
      });

      if (transport) {
        transport.isFavorite = false;
      }

      return state;
    });
  }
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Action, State, StateContext, Store } from '@ngxs/store';
import {
  HideCargoTendersListFilters,
  ResetCargoTendersListFilters,
  SearchCompanyMembersInCargoTendersListFilters,
  SearchPartnerInCargoTendersListFilters,
  SetCargoTendersListFilteredResultsCount,
  SetCargoTendersListFilters,
  SetCargoTendersTransportationType,
  ShowCargoTendersListFilters,
} from './cargo-tenders_list_filters.actions';
import { CargoTendersListFiltersStateModel } from './cargo-tenders_list_filters-state.model';
import { StateReset } from 'ngxs-reset-plugin';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { AuthSelectors } from '@app/store/auth';
import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyMembersService } from '@app/modules/companies/services/company-members.service';
import { tap } from 'rxjs';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { CompanyPartnershipsService } from '@app/modules/companies/services/company-partnerships.service';

@Injectable()
@State<CargoTendersListFiltersStateModel>({
  name: 'cargo_tenders__list__filters',
  defaults: {
    isFavorite: false,
    cargoTendersTransportationType: CargoTendersTransportationTypes.CAR,
    filteredResultsCount: null,
    displayFilters: false,
    states: [
      TenderStates.ACTIVE,
      TenderStates.CONFIRMED,
      TenderStates.CONFIRMED_BY_CARRIER,
      TenderStates.IN_PROGRESS,
    ],
    countryFrom: 0,
    countryTo: 0,
    createdAtFrom: null, //moment().subtract(7, 'd').format('YYYY-MM-DD'),
    createdAtTo: null, //moment().format('YYYY-MM-DD'),
    isMyTenders: null,
    isInternational: null,
    userId: null,
    companyMembers: null,
    partnerId: null,
    partners: null,
  },
})
export class CargoTendersListFiltersState {
  constructor(
    private store: Store,
    private companyMembersService: CompanyMembersService,
    private companyPartnershipsService: CompanyPartnershipsService
  ) {}

  @Action(ShowCargoTendersListFilters)
  @ImmutableContext()
  showCargoTendersListFilters({
    setState,
  }: StateContext<CargoTendersListFiltersStateModel>) {
    return setState((state: CargoTendersListFiltersStateModel) => {
      state.displayFilters = true;
      return state;
    });
  }

  @Action(HideCargoTendersListFilters)
  @ImmutableContext()
  hideCargoTendersListFilters({
    setState,
  }: StateContext<CargoTendersListFiltersStateModel>) {
    return setState((state: CargoTendersListFiltersStateModel) => {
      state.displayFilters = false;
      return state;
    });
  }

  @Action(ResetCargoTendersListFilters)
  @ImmutableContext()
  resetCargoTendersListFilters() {
    this.store.dispatch(new StateReset(CargoTendersListFiltersState));
  }

  @Action(SetCargoTendersListFilters)
  @ImmutableContext()
  setCargoTendersListFilters(
    { setState }: StateContext<CargoTendersListFiltersStateModel>,
    { filters }: SetCargoTendersListFilters
  ) {
    return setState((state: CargoTendersListFiltersStateModel) => {
      state.displayFilters = false;
      state.cargoTendersTransportationType =
        filters.cargoTendersTransportationType;
      state.states = filters.state;
      state.createdAtFrom = filters.createdAtFrom;
      state.createdAtTo = filters.createdAtTo;
      state.countryFrom = filters.countryFrom;
      state.countryTo = filters.countryTo;
      state.isMyTenders = filters.isMyTenders as boolean;
      state.isInternational = filters.isInternational as boolean;
      state.userId = filters.userId as number;
      state.partnerId = filters.partnerId;
      state.isFavorite = !!filters.isFavorite;
      return state;
    });
  }

  @Action(SetCargoTendersTransportationType)
  @ImmutableContext()
  setCargoTendersTransportationType(
    { setState }: StateContext<CargoTendersListFiltersStateModel>,
    { cargoTendersTransportationType }: SetCargoTendersTransportationType
  ) {
    return setState((state: CargoTendersListFiltersStateModel) => {
      state.cargoTendersTransportationType = cargoTendersTransportationType;
      return state;
    });
  }

  @Action(SetCargoTendersListFilteredResultsCount)
  @ImmutableContext()
  setCargoTendersListFilteredResultsCount(
    { setState }: StateContext<CargoTendersListFiltersStateModel>,
    { filteredResultsCount }: SetCargoTendersListFilteredResultsCount
  ) {
    return setState((state: CargoTendersListFiltersStateModel) => {
      state.filteredResultsCount = filteredResultsCount;
      return state;
    });
  }

  @Action(SearchCompanyMembersInCargoTendersListFilters)
  @ImmutableContext()
  searchCompanyMembersInCargoTendersListFilters(
    { setState }: StateContext<CargoTendersListFiltersStateModel>,
    { searchValue }: SearchCompanyMembersInCargoTendersListFilters
  ) {
    const currentCompany: Company = this.store.selectSnapshot(
      AuthSelectors.company
    );
    return this.companyMembersService
      .getCompanyMembers(currentCompany.id, { search: searchValue })
      .pipe(
        tap((results: PaginationResults<CompanyMember>) => {
          setState((state: CargoTendersListFiltersStateModel) => {
            state.companyMembers = results.data.map(
              (companyMember: CompanyMember) => {
                return companyMember.user;
              }
            );
            return state;
          });
        })
      );
  }

  @Action(SearchPartnerInCargoTendersListFilters)
  @ImmutableContext()
  searchPartnerInCargoTendersListFilters(
    { setState }: StateContext<CargoTendersListFiltersStateModel>,
    { searchValue }: SearchPartnerInCargoTendersListFilters
  ) {
    const currentCompany: Company = this.store.selectSnapshot(
      AuthSelectors.company
    );
    return this.companyPartnershipsService
      .list(currentCompany.id, searchValue, null, true)
      .pipe(
        tap((results: PaginationResults<CompanyPartnership>) => {
          setState((state: CargoTendersListFiltersStateModel) => {
            state.partners = results.data.map(
              (partnership: CompanyPartnership) => {
                return partnership.partner;
              }
            );
            return state;
          });
        })
      );
  }
}

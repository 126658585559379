import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { StickyNotesStateModel } from './sticky-notes.model';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { catchError, tap } from 'rxjs/operators';
import { StickyNotesService } from '@app/modules/sticky-notes/services/sticky-notes.service';
import { StickyNote } from '@app/modules/sticky-notes/interfaces/sticky-note';
import {
  AddStickyNote,
  DeleteStickyNote,
  LoadStickyNoteColors,
  LoadStickyNotesCount,
  LoadStickyNotesList,
  UpdateStickyNoteColor,
  UpdateStickyNotePosition,
  UpdateStickyNoteText,
} from './sticky-notes.actions';
import { StickyNoteColor } from '@app/modules/sticky-notes/interfaces/sticky-note-color';
import { of } from 'rxjs';

@Injectable()
@State<StickyNotesStateModel>({
  name: 'sticky_notes',
  defaults: {
    notes: null,
    count: null,
    colors: null,
  },
})
export class StickyNotesState {
  constructor(private stickyNotesService: StickyNotesService) {}

  @Action(LoadStickyNotesCount, { cancelUncompleted: true })
  @ImmutableContext()
  loadStickyNotesCount({ setState }: StateContext<StickyNotesStateModel>) {
    return this.stickyNotesService.count().pipe(
      tap((count: number) => {
        return setState((state: StickyNotesStateModel) => {
          state.count = count;
          return state;
        });
      })
    );
  }

  @Action(LoadStickyNoteColors, { cancelUncompleted: true })
  @ImmutableContext()
  loadStickyNoteColors({
    getState,
    setState,
  }: StateContext<StickyNotesStateModel>) {
    if (getState().colors) {
      return;
    }
    return this.stickyNotesService.getStickyNotesColors().pipe(
      tap((colors: StickyNoteColor[]) => {
        return setState((state: StickyNotesStateModel) => {
          state.colors = colors;
          return state;
        });
      })
    );
  }

  @Action(LoadStickyNotesList, { cancelUncompleted: true })
  @ImmutableContext()
  loadStickyNotesList({ setState }: StateContext<StickyNotesStateModel>) {
    return this.stickyNotesService.list().pipe(
      tap((notes: StickyNote[]) => {
        return setState((state: StickyNotesStateModel) => {
          state.notes = notes;
          return state;
        });
      })
    );
  }

  @Action(AddStickyNote, { cancelUncompleted: true })
  @ImmutableContext()
  addStickyNote({ setState }: StateContext<StickyNotesStateModel>) {
    const mockNoteID = -Date.now();

    setState((state: StickyNotesStateModel) => {
      state.notes.push({
        id: mockNoteID,
        text: '',
        left: 0,
        top: 0,
        color: state.colors[0],
      });
      return state;
    });

    return this.stickyNotesService.create().pipe(
      tap((createdNote: StickyNote) => {
        return setState((state: StickyNotesStateModel) => {
          const note = state.notes.find((currentNote: StickyNote) => {
            return currentNote.id === mockNoteID;
          });
          if (note) {
            note.id = createdNote.id;
          }
          return state;
        });
      }),
      catchError(() => {
        return of(null).pipe(
          tap(() => {
            setState((state: StickyNotesStateModel) => {
              state.notes = state.notes.filter(
                (currentStickyNote: StickyNote) => {
                  return currentStickyNote.id !== mockNoteID;
                }
              );

              return state;
            });
          })
        );
      })
    );
  }

  @Action(DeleteStickyNote, { cancelUncompleted: true })
  @ImmutableContext()
  deleteStickyNote(
    { setState }: StateContext<StickyNotesStateModel>,
    { note }: DeleteStickyNote
  ) {
    if (note.id >= 0) {
      setState((state: StickyNotesStateModel) => {
        state.notes = state.notes.filter((currentStickyNote: StickyNote) => {
          return currentStickyNote.id !== note.id;
        });
        return state;
      });
      return this.stickyNotesService.delete(note.id).pipe(
        catchError(() => {
          return of(null).pipe(
            tap(() => {
              setState((state: StickyNotesStateModel) => {
                state.notes.push(note);
                return state;
              });
            })
          );
        })
      );
    }
  }

  @Action(UpdateStickyNoteText, { cancelUncompleted: true })
  @ImmutableContext()
  updateStickyNoteText(
    { setState }: StateContext<StickyNotesStateModel>,
    { note, newText }: UpdateStickyNoteText
  ) {
    setState((state: StickyNotesStateModel) => {
      const existingStickyNote: StickyNote = state.notes.find(
        (currentStickyNote: StickyNote) => {
          return currentStickyNote.id === note.id;
        }
      );

      if (existingStickyNote) {
        existingStickyNote.text = newText;
      }

      return state;
    });

    return this.stickyNotesService.update(note.id, {
      text: newText,
    });
  }

  @Action(UpdateStickyNotePosition, { cancelUncompleted: true })
  @ImmutableContext()
  updateStickyNotePosition(
    { setState }: StateContext<StickyNotesStateModel>,
    { note, newTop, newLeft }: UpdateStickyNotePosition
  ) {
    setState((state: StickyNotesStateModel) => {
      const existingStickyNote: StickyNote = state.notes.find(
        (currentStickyNote: StickyNote) => {
          return currentStickyNote.id === note.id;
        }
      );

      if (existingStickyNote) {
        existingStickyNote.top = newTop;
        existingStickyNote.left = newLeft;
      }

      return state;
    });

    return this.stickyNotesService
      .update(note.id, {
        top: newTop,
        left: newLeft,
      })
      .pipe(
        catchError(() => {
          return of(null).pipe(
            tap(() => {
              setState((state: StickyNotesStateModel) => {
                const existingStickyNote: StickyNote = state.notes.find(
                  (currentStickyNote: StickyNote) => {
                    return currentStickyNote.id === note.id;
                  }
                );

                if (existingStickyNote) {
                  existingStickyNote.top = note.top;
                  existingStickyNote.left = note.left;
                }

                return state;
              });
            })
          );
        })
      );
  }

  @Action(UpdateStickyNoteColor, { cancelUncompleted: true })
  @ImmutableContext()
  updateStickyNoteColor(
    { setState }: StateContext<StickyNotesStateModel>,
    { note, newColor }: UpdateStickyNoteColor
  ) {
    setState((state: StickyNotesStateModel) => {
      const existingStickyNote: StickyNote = state.notes.find(
        (currentStickyNote: StickyNote) => {
          return currentStickyNote.id === note.id;
        }
      );

      if (existingStickyNote) {
        existingStickyNote.color = newColor;
      }

      return state;
    });

    return this.stickyNotesService.update(note.id, { color: newColor.id }).pipe(
      catchError(() => {
        return of(null).pipe(
          tap(() => {
            setState((state: StickyNotesStateModel) => {
              const existingStickyNote: StickyNote = state.notes.find(
                (currentStickyNote: StickyNote) => {
                  return currentStickyNote.id === note.id;
                }
              );

              if (existingStickyNote) {
                existingStickyNote.color = note.color;
              }

              return state;
            });
          })
        );
      })
    );
  }
}

import { Selector } from '@ngxs/store';
import { CompanyTendersOverallStatisticsState } from './company_tenders_overall_statistics.state';
import { CompanyTendersOverallStatisticsStateModel } from './company_tenders_overall_statistics.model';
import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { CreatedTendersOverallStatistics } from './interface/created-tenders-overall-statistics';
import { ParticipatedTendersOverallStatistics } from './interface/participated-tenders-overall-statistics';

export class CompanyTendersOverallStatisticsSelectors {
  @Selector([CompanyTendersOverallStatisticsState])
  static datePeriod(
    state: CompanyTendersOverallStatisticsStateModel
  ): DatePeriodDayjs {
    return state.datePeriod;
  }

  @Selector([CompanyTendersOverallStatisticsState])
  static companyId(state: CompanyTendersOverallStatisticsStateModel): number {
    return state.companyId;
  }

  @Selector([CompanyTendersOverallStatisticsState])
  static tendersScope(
    state: CompanyTendersOverallStatisticsStateModel
  ): TendersScopes {
    return state.tendersScope;
  }

  @Selector([CompanyTendersOverallStatisticsState])
  static createdStats(
    state: CompanyTendersOverallStatisticsStateModel
  ): CreatedTendersOverallStatistics {
    return state.statistics.created;
  }

  @Selector([CompanyTendersOverallStatisticsState])
  static participatedStats(
    state: CompanyTendersOverallStatisticsStateModel
  ): ParticipatedTendersOverallStatistics {
    return state.statistics.participated;
  }
}

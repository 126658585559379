import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { UsersService } from '@app/modules/users/services/users.service';
import { User } from '@app/modules/users/interfaces/user';
import {
  LoadUsersList,
  LockUser,
  LoadUsersOnline,
  UnlockUser,
  UpdateUsersListFilters,
  UserGoesOnline,
  UserWentOffline,
  MonitorUserConnections,
} from './users.actions';
import { UsersStateModel } from './users-state.model';
import { SseService } from '@app/services/sse.service';
import { SubscriptionAPIResponse } from '@app/modules/mercure/interfaces/subscription-api-response';
import { SubscriptionEvent } from '@app/modules/mercure/interfaces/subscription-event';
import {
  AddUserToFavorites,
  RemoveUserFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<UsersStateModel>({
  name: 'users',
  defaults: {
    online: {
      subscribers: {},
      users: {},
    },
    users: [],
    page: 1,
    maxPage: 1,
    filters: {
      search: null,
      isFavorite: false,
    },
  },
})
export class UsersState {
  constructor(
    private usersService: UsersService,
    private sseService: SseService,
    private store: Store
  ) {}

  @Action(LoadUsersList)
  @ImmutableContext()
  loadUsersList(
    { getState, setState }: StateContext<UsersStateModel>,
    { page }: LoadUsersList
  ) {
    const newPage = page || getState().page;

    return this.usersService.getUsers(newPage, getState().filters).pipe(
      tap((result: PaginationResults<User>) => {
        setState((state: UsersStateModel) => {
          state.users = result.data;
          state.page = newPage;
          state.maxPage = result.maxPage;
          return state;
        });
      })
    );
  }

  @Action(UpdateUsersListFilters)
  @ImmutableContext()
  updateUsersListFilters(
    { setState }: StateContext<UsersStateModel>,
    { filters }: UpdateUsersListFilters
  ) {
    return setState((state: UsersStateModel) => {
      const keys = Object.keys(filters);
      keys.forEach((key: string) => {
        state.filters[key] = filters[key];
      });

      return state;
    });
  }

  @Action(LockUser)
  @ImmutableContext()
  lockUser({ setState }: StateContext<UsersStateModel>, { userId }: LockUser) {
    return this.usersService.lockUser(userId).pipe(
      tap((user: User) => {
        setState((state: UsersStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.users.length; i++) {
            if (state.users[i].id === userId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.users[exists] = {
              ...state.users[exists],
              ...{ status: user.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(UnlockUser)
  @ImmutableContext()
  unlockUser(
    { setState }: StateContext<UsersStateModel>,
    { userId }: UnlockUser
  ) {
    return this.usersService.unlockUser(userId).pipe(
      tap((user: User) => {
        setState((state: UsersStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.users.length; i++) {
            if (state.users[i].id === userId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.users[exists] = {
              ...state.users[exists],
              ...{ status: user.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(LoadUsersOnline)
  @ImmutableContext()
  loadUsersOnline({ setState }: StateContext<UsersStateModel>) {
    return this.sseService.getOnlineUsers().pipe(
      tap((response: SubscriptionAPIResponse<User>) => {
        const onlineUsers: any = {};

        response.subscriptions.forEach(
          (subscription: SubscriptionEvent<User>) => {
            if (
              subscription.active &&
              subscription.topic ===
                'https://cargotenders.com/user/' + subscription.payload.id
            ) {
              onlineUsers[subscription.id] = subscription.payload.id;
            }
          }
        );

        setState((state: UsersStateModel) => {
          state.online.subscribers = onlineUsers;
          for (let subscriptionID in onlineUsers) {
            if (!state.online.users[onlineUsers[subscriptionID]]) {
              state.online.users[onlineUsers[subscriptionID]] = [];
            }
            state.online.users[onlineUsers[subscriptionID]].push(
              subscriptionID
            );
          }
          return state;
        });

        this.store.dispatch(new MonitorUserConnections(response.lastEventID));
      })
    );
  }

  @Action(UserGoesOnline)
  @ImmutableContext()
  userGoesOnline(
    { setState }: StateContext<UsersStateModel>,
    { info }: UserGoesOnline
  ) {
    return setState((state: UsersStateModel) => {
      for (let subscriptionID in info) {
        if (!state.online.users[info[subscriptionID]]) {
          state.online.users[info[subscriptionID]] = [];
        }
        state.online.subscribers[subscriptionID] = info[subscriptionID];
        state.online.users[info[subscriptionID]].push(subscriptionID);
      }
      return state;
    });
  }

  @Action(UserWentOffline)
  @ImmutableContext()
  userWentOffline(
    { setState }: StateContext<UsersStateModel>,
    { subscriptionID }: UserWentOffline
  ) {
    return setState((state: UsersStateModel) => {
      const userId = state.online.subscribers[subscriptionID];
      if (state.online.users[userId]) {
        state.online.users[userId] = state.online.users[userId].filter(
          (existingSubscriptionID: string) => {
            return existingSubscriptionID !== subscriptionID;
          }
        );
        if (state.online.users[userId].length === 0) {
          delete state.online.users[userId];
        }
      }
      delete state.online.subscribers[subscriptionID];
      return state;
    });
  }

  @Action(MonitorUserConnections)
  @ImmutableContext()
  monitorUserConnections(
    {}: StateContext<UsersStateModel>,
    { lastEventID }: MonitorUserConnections
  ) {
    return this.sseService.monitorUserConnections(lastEventID).pipe(
      tap((es: EventSource) => {
        // Receive updates
        es.onmessage = (msg: MessageEvent<string>) => {
          this.sseService.updateRefreshTokenIfNeeded();
          const subscriptionEvent: SubscriptionEvent<User> = JSON.parse(
            msg.data
          );
          if (
            subscriptionEvent.topic ===
            'https://cargotenders.com/user/' + subscriptionEvent.payload.id
          ) {
            if (subscriptionEvent.active) {
              this.store.dispatch(
                new UserGoesOnline({
                  [subscriptionEvent.id]: subscriptionEvent.payload.id,
                })
              );
            } else {
              this.store.dispatch(new UserWentOffline(subscriptionEvent.id));
            }
          }
        };

        // Errors handler
        es.onerror = (error: any) => {
          this.sseService.updateRefreshTokenIfNeeded();
        };
      })
    );
  }

  @Action(AddUserToFavorites)
  @ImmutableContext()
  addUserToFavorites(
    { setState }: StateContext<UsersStateModel>,
    { entityId }: AddUserToFavorites
  ) {
    return setState((state: UsersStateModel) => {
      const user = state.users.find((user: User) => {
        return user.id === entityId;
      });

      if (user) {
        user.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveUserFromFavorites)
  @ImmutableContext()
  removeUserFromFavorites(
    { setState }: StateContext<UsersStateModel>,
    { entityId }: RemoveUserFromFavorites
  ) {
    return setState((state: UsersStateModel) => {
      const user = state.users.find((user: User) => {
        return user.id === entityId;
      });

      if (user) {
        user.isFavorite = false;
      }

      return state;
    });
  }
}

import { Selector } from '@ngxs/store';
import { CargoTendersListCarState } from './cargo-tenders-list_car.state';
import { CargoTendersListCarStateModel } from './cargo-tenders-list_car-state.model';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';

export class CargoTendersListCarSelectors {
  @Selector([CargoTendersListCarState])
  static tenders(state: CargoTendersListCarStateModel): CarCargoTender[] {
    return state.tenders;
  }
}

import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { Selector } from '@ngxs/store';
import { CargoTendersViewStateModel } from './cargo-tenders_view-state.model';
import { CargoTendersViewState } from './cargo-tenders_view.state';

export class CargoTendersViewSelectors {
  @Selector([CargoTendersViewState])
  static cargoTendersTransportationType(
    state: CargoTendersViewStateModel
  ): CargoTendersTransportationTypes {
    return state.cargoTendersTransportationType;
  }
}

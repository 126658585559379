import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { TasksCalendarStateModel } from './tasks-calendar.state-model';
import {
  SetDateFilterValueInTasksCalendarWidget,
  TasksCalendarListState,
} from './list';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  SetViewMonthInTasksCalendarWidget,
  SetViewYearInTasksCalendarWidget,
} from './tasks-calendar.actions';
import * as moment from 'moment';

if (
  localStorage.getItem('auth') &&
  JSON.parse(localStorage.getItem('auth')).accessTokenPayload
) {
  moment.locale(
    JSON.parse(localStorage.getItem('auth')).accessTokenPayload.data.language
  );
}

const now: moment.Moment = moment();

@Injectable()
@State<TasksCalendarStateModel>({
  name: 'widgets__tasks_calendar',
  defaults: {
    currentDate: now,
    selectedDate: now,
    viewMonth: +now.format('M'),
    viewYear: +now.format('Y'),
  },
  children: [TasksCalendarListState],
})
export class TasksCalendarState {
  constructor(private store: Store) {}

  @Action(SetViewMonthInTasksCalendarWidget)
  @ImmutableContext()
  setViewMonthInTasksCalendarWidget(
    { setState }: StateContext<TasksCalendarStateModel>,
    { month }: SetViewMonthInTasksCalendarWidget
  ) {
    return setState((state: TasksCalendarStateModel) => {
      state.viewMonth = month;

      this.store.dispatch(
        new SetDateFilterValueInTasksCalendarWidget(
          state.viewMonth.toString().padStart(2, '0') + '.' + state.viewYear
        )
      );

      return state;
    });
  }

  @Action(SetViewYearInTasksCalendarWidget)
  @ImmutableContext()
  setViewYearInTasksCalendarWidget(
    { setState }: StateContext<TasksCalendarStateModel>,
    { year }: SetViewYearInTasksCalendarWidget
  ) {
    return setState((state: TasksCalendarStateModel) => {
      state.viewYear = year;

      this.store.dispatch(
        new SetDateFilterValueInTasksCalendarWidget(
          state.viewMonth.toString().padStart(2, '0') + '.' + state.viewYear
        )
      );

      return state;
    });
  }
}

import { Selector } from '@ngxs/store';
import { TransportAddEditState } from './transport_add-edit.state';
import { TransportAddEditStateModel } from './transport_add-edit-state.model';
import { Company } from '@app/modules/companies/interfaces/company';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { FieldError } from '@app/interfaces/field-error';
import { TransportAddEditFormData } from '@app/pages/transport/add-edit/interfaces/transport_add-edit.form-data';

export class AddEditTransportSelectors {
  @Selector([TransportAddEditState])
  static companies(state: TransportAddEditStateModel): Company[] {
    return state.companies;
  }

  @Selector([TransportAddEditState])
  static fieldErrors(
    state: TransportAddEditStateModel
  ): FormattedFieldErrors[] {
    const errors = [];

    state.fieldsErrors.forEach((err: FieldError) => {
      const errorMessages = [err.message];
      errors.push({
        field: err.field,
        errors: errorMessages,
      });
    });

    return errors;
  }

  @Selector([TransportAddEditState])
  static formData(state: TransportAddEditStateModel): TransportAddEditFormData {
    return state.formData;
  }
}

import { Selector } from '@ngxs/store';
import { TendersPageFiltersState } from './tenders-page_filters.state';
import { TendersPageFiltersStateModel } from './tenders-page_filters-state.model';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { DatePeriod } from '@app/interfaces/date-period';
import { TendersPageFilters } from '@app/pages/tenders/tenders.page.filters';

export class TendersPageFiltersSelectors {
  @Selector([TendersPageFiltersState])
  static cargoTendersTransportationType(
    state: TendersPageFiltersStateModel
  ): CargoTendersTransportationTypes {
    return state.cargoTendersTransportationType;
  }

  @Selector([TendersPageFiltersState])
  static displayFilters(state: TendersPageFiltersStateModel): boolean {
    return state.displayFilters;
  }

  @Selector([TendersPageFiltersState])
  static createdAtPeriod(state: TendersPageFiltersStateModel): DatePeriod {
    return state.createdAtPeriod;
  }

  @Selector([TendersPageFiltersState])
  static filters(state: TendersPageFiltersStateModel): TendersPageFilters {
    return {
      company: state.company,
      curator: state.curator,
    };
  }
}

import { Injectable } from '@angular/core';
import { PrimaryBlockStateModel } from './primary_block-state.model';
import { PrimaryBlockTabs } from '@app/pages/tabs/components/primary-block-tabs/primary-block-tabs';
import { Action, State, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  PrimaryBlockSwitchTab,
  PrimaryBlockToggleCollapseExpand,
  PrimaryBlockCollapse,
  PrimaryBlockExpand,
  PrimaryBlockSetCargoTendersView,
  PrimaryBlockSetTransportTendersView,
  PrimaryBlockSetWarehousesView,
  PrimaryBlockSetCompaniesView,
  PrimaryBlockSetView,
  PrimaryBlockSetListView,
} from './primary_block.actions';
import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';
import { PrimaryBlockWarehousesViews } from '@app/pages/tabs/components/primary-block-warehouses/primary-block-warehouses-views';
import {
  ChangeCompanySuccess,
  ViewTransportTender,
  ViewWarehouse,
  ViewCarCargoTender,
  ViewSeaCargoTender,
} from '@app/store/tabs/tabs.actions';
import { StateReset } from 'ngxs-reset-plugin';
import { PrimaryBlockCompaniesViews } from '@app/pages/tabs/components/primary-block-companies/primary-block-companies-views';
import { CargoTendersState } from '@app/store/cargo-tenders';
import { TransportTendersState } from '@app/store/transport-tenders';
import { WarehousesState } from '@app/store/warehouses';

@Injectable()
@State<PrimaryBlockStateModel>({
  name: 'primary_block',
  defaults: {
    expanded: true,
    activeTab: PrimaryBlockTabs.CARGO_TENDERS,
    cargoTendersView: PrimaryBlockCargoTendersViews.LIST,
    transportTendersView: PrimaryBlockTransportTendersViews.LIST,
    warehousesView: PrimaryBlockWarehousesViews.LIST,
    companiesView: PrimaryBlockCompaniesViews.LIST,
  },
})
export class PrimaryBlockState {
  constructor(private store: Store) {}

  @Action(PrimaryBlockSwitchTab)
  @ImmutableContext()
  primaryBlockSwitchTab(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { tab }: PrimaryBlockSwitchTab
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = tab;
      return state;
    });
  }

  @Action(PrimaryBlockToggleCollapseExpand)
  @ImmutableContext()
  primaryBlockToggleCollapseExpand({
    getState,
    setState,
  }: StateContext<PrimaryBlockStateModel>) {
    const expanded = getState().expanded;
    return setState((state: PrimaryBlockStateModel) => {
      state.expanded = !expanded;
      return state;
    });
  }

  @Action(PrimaryBlockCollapse)
  @ImmutableContext()
  primaryBlockCollapse({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.expanded = false;
      return state;
    });
  }

  @Action(PrimaryBlockExpand)
  @ImmutableContext()
  primaryBlockExpand({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.expanded = true;
      return state;
    });
  }

  @Action(PrimaryBlockSetListView)
  @ImmutableContext()
  primaryBlockSetListView({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.cargoTendersView = PrimaryBlockCargoTendersViews.LIST;
      state.transportTendersView = PrimaryBlockTransportTendersViews.LIST;
      state.companiesView = PrimaryBlockCompaniesViews.LIST;
      state.warehousesView = PrimaryBlockWarehousesViews.LIST;
      return state;
    });
  }

  @Action(PrimaryBlockSetCargoTendersView)
  @ImmutableContext()
  primaryBlockSetCargoTendersView(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { view }: PrimaryBlockSetCargoTendersView
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.CARGO_TENDERS;
      state.cargoTendersView = view;
      return state;
    });
  }

  @Action(PrimaryBlockSetTransportTendersView)
  @ImmutableContext()
  primaryBlockSetTransportTendersView(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { view }: PrimaryBlockSetTransportTendersView
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.TRANSPORT_TENDERS;
      state.transportTendersView = view;
      return state;
    });
  }

  @Action(PrimaryBlockSetWarehousesView)
  @ImmutableContext()
  primaryBlockSetWarehousesView(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { view }: PrimaryBlockSetWarehousesView
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.WAREHOUSES;
      state.warehousesView = view;
      return state;
    });
  }

  @Action(PrimaryBlockSetCompaniesView)
  @ImmutableContext()
  primaryBlockSetCompaniesView(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { view }: PrimaryBlockSetCompaniesView
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.COMPANIES;
      state.companiesView = view;
      return state;
    });
  }

  @Action(PrimaryBlockSetView)
  @ImmutableContext()
  primaryBlockSetView(
    { setState }: StateContext<PrimaryBlockStateModel>,
    { view }: PrimaryBlockSetView
  ) {
    return setState((state: PrimaryBlockStateModel) => {
      if (
        Object.values(PrimaryBlockCargoTendersViews).includes(
          view as PrimaryBlockCargoTendersViews
        )
      ) {
        state.activeTab = PrimaryBlockTabs.CARGO_TENDERS;
        state.cargoTendersView = view as PrimaryBlockCargoTendersViews;
        return state;
      }
      if (
        Object.values(PrimaryBlockTransportTendersViews).includes(
          view as PrimaryBlockTransportTendersViews
        )
      ) {
        state.activeTab = PrimaryBlockTabs.TRANSPORT_TENDERS;
        state.transportTendersView = view as PrimaryBlockTransportTendersViews;
        return state;
      }

      if (
        Object.values(PrimaryBlockWarehousesViews).includes(
          view as PrimaryBlockWarehousesViews
        )
      ) {
        state.activeTab = PrimaryBlockTabs.WAREHOUSES;
        state.warehousesView = view as PrimaryBlockWarehousesViews;
        return state;
      }

      if (
        Object.values(PrimaryBlockCompaniesViews).includes(
          view as PrimaryBlockCompaniesViews
        )
      ) {
        state.activeTab = PrimaryBlockTabs.COMPANIES;
        state.companiesView = view as PrimaryBlockCompaniesViews;
        return state;
      }

      throw 'Unsupported view provided: ' + view;
    });
  }

  @Action([ViewCarCargoTender, ViewSeaCargoTender])
  @ImmutableContext()
  viewCargoTender({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.CARGO_TENDERS;
      state.cargoTendersView = PrimaryBlockCargoTendersViews.DETAIL;
      return state;
    });
  }

  @Action(ViewTransportTender)
  @ImmutableContext()
  viewTransportTender({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.TRANSPORT_TENDERS;
      state.transportTendersView = PrimaryBlockTransportTendersViews.DETAIL;
      return state;
    });
  }

  @Action(ViewWarehouse)
  @ImmutableContext()
  viewWarehouse({ setState }: StateContext<PrimaryBlockStateModel>) {
    return setState((state: PrimaryBlockStateModel) => {
      state.activeTab = PrimaryBlockTabs.WAREHOUSES;
      state.warehousesView = PrimaryBlockWarehousesViews.DETAIL;
      return state;
    });
  }

  @Action(ChangeCompanySuccess)
  @ImmutableContext()
  changeCompany({}: StateContext<PrimaryBlockStateModel>) {
    this.store.dispatch(
      new StateReset(
        CargoTendersState,
        TransportTendersState,
        WarehousesState
        // todo: reset companies state, when it will be available
      )
    );
  }
}

import { Selector } from '@ngxs/store';
import { AttendanceState } from './attendance.state';
import { AttendanceStateModel } from './attendance.model';
import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { AttendanceSummaryData } from './interface/attendance-summary-data';

export class AttendanceSelectors {
  @Selector([AttendanceState])
  static datePeriod(state: AttendanceStateModel): DatePeriodDayjs {
    return state.datePeriod;
  }

  @Selector([AttendanceState])
  static attendance(state: AttendanceStateModel): AttendanceSummaryData {
    return state.attendance;
  }
}

import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { PrimaryBlockCompaniesViews } from '@app/pages/tabs/components/primary-block-companies/primary-block-companies-views';
import { PrimaryBlockTabs } from '@app/pages/tabs/components/primary-block-tabs/primary-block-tabs';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';
import { PrimaryBlockWarehousesViews } from '@app/pages/tabs/components/primary-block-warehouses/primary-block-warehouses-views';

export class PrimaryBlockStateModel {
  expanded: boolean;
  activeTab: PrimaryBlockTabs;
  cargoTendersView: PrimaryBlockCargoTendersViews;
  transportTendersView: PrimaryBlockTransportTendersViews;
  warehousesView: PrimaryBlockWarehousesViews;
  companiesView: PrimaryBlockCompaniesViews;
}

import { Selector } from '@ngxs/store';
import { NotificationsState } from './notifications.state';
import { NotifificationsStateModel } from './notifications-state.model';
import { UserNotification } from '@app/modules/notifications/interfaces/user-notification';

export class NotificationsSelectors {
  @Selector([NotificationsState])
  static notifications(state: NotifificationsStateModel): UserNotification[] {
    return state.notifications;
  }
}

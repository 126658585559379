import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, map } from 'rxjs';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { AddCompanyCuratorFormData } from '@app/pages/companies/view/tab1/components/add-company-curator/add-company-curator.form-data';
import { EditCompanyCuratorFormData } from '@app/pages/companies/view/tab1/components/edit-company-curator/edit-company-curator.form-data';
import { SearchEntityParams } from '@app/modules/form-elements/components/select-entity/interfaces/search-entity-params';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { IdName } from '@app/interfaces/id-name';

@Injectable({
  providedIn: 'root',
})
export class CompanyCuratorsService {
  constructor(private apiService: ApiService) {}

  list(companyId: number): Observable<CompanyMember[]> {
    return this.apiService.getAll<CompanyMember>(
      '/company/' + companyId + '/curator',
      {
        fields: [
          'id',
          'companyId',
          'markup',
          'relationType.code',
          'relationType.name',
          'userId', // is used for edit? delete ?
          'user.first_name',
          'user.middle_name',
          'user.last_name',
        ].join(','),
        expand: ['user'].join(','),
      }
    );
  }

  getCurators(
    companyId: number,
    page: number = 1,
    searchValue?: string,
    filters: any = {}
  ): Observable<PaginationResults<CompanyMember>> {
    const data: any = {
      fields: [
        'id',
        'companyId',
        'markup',
        'relationType.code',
        'relationType.name',
        'userId', // is used for edit? delete ?
        'user.first_name',
        'user.middle_name',
        'user.last_name',
      ].join(','),
      expand: ['user'].join(','),
      page,
      ...filters,
    };

    if (searchValue) {
      data.search = searchValue;
    }

    return this.apiService.getPaginatedResults<CompanyMember>(
      '/company/' + companyId + '/curator',
      data
    );
  }

  getCuratorsFromAllCompanies(
    page: number = 1,
    searchValue?: string,
    filters: any = {}
  ): Observable<PaginationResults<CompanyMember>> {
    const data: any = {
      fields: [
        'user.id',
        'user.first_name',
        'user.middle_name',
        'user.last_name',
      ].join(','),
      expand: ['user'].join(','),
      page,
      ...filters,
    };

    if (searchValue) {
      data.search = searchValue;
    }

    return this.apiService.getPaginatedResults<CompanyMember>(
      '/company/curators',
      data
    );
  }

  listCompanyCurators(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName>> {
    console.log({ listCompanyCurators: params });
    return this.getCuratorsFromAllCompanies(params.page, params.search, {
      byName: 1,
      sort: 'name',
    }).pipe(
      map((results: PaginationResults<CompanyMember>) => {
        const preparedResults: PaginationResults<IdName> = {
          ...results,
          data: results.data.map((companyMember: CompanyMember) => {
            return {
              id: companyMember.user.id,
              name: [
                companyMember.user.first_name,
                companyMember.user.middle_name,
                companyMember.user.last_name,
              ].join(', '),
            };
          }),
        };

        return preparedResults;
      })
    );
  }

  create(data: AddCompanyCuratorFormData): Observable<CompanyMember> {
    const companyId = data.companyId;
    delete data.companyId;

    return this.apiService.post<CompanyMember>(
      '/company/' + companyId + '/curator',
      data
    );
  }

  update(data: EditCompanyCuratorFormData): Observable<CompanyMember> {
    const companyId = data.companyId;
    const curatorId = data.id;
    delete data.companyId, data.id;

    return this.apiService.put<CompanyMember>(
      '/company/' + companyId + '/curator/' + curatorId,
      data
    );
  }

  delete(companyId: number, userId: number): Observable<unknown> {
    return this.apiService.delete<unknown>(
      '/company/' + companyId + '/curator/' + userId
    );
  }
}

import { CreateCargoTenderFormData } from '@app/pages/cargo-tenders/create/car-tender/interfaces/create-cargo-tender.form-data';
import { CreateSeaCargoTenderFormData } from '@app/pages/cargo-tenders/create/sea-tender/interfaces/create-sea-cargo-tender.form-data';

export class InitializeCreateCargoTenderPage {
  static readonly type = '[Create cargo tender] Initialize';
  constructor() {}
}

export class CreateCarCargoTender {
  static readonly type =
    '[Create cargo tender] Try create car cargo tender (Cargo tender form submitted)';
  constructor(public data: CreateCargoTenderFormData) {}
}

export class CreateOrderCargoTender {
  static readonly type =
    '[Create cargo tender] Try create orger cargo tender (Cargo tender form submitted)';
  constructor(public data: CreateCargoTenderFormData) {}
}

export class ResetInitialCargoTenderData {
  static readonly type = '[Create cargo tender] Reset initial data';
  constructor() {}
}

export class TransportTypesRendered {
  static readonly type = '[Create cargo tender] Transport Types Rendered';
  constructor() {}
}

export class LoadingTypesRendered {
  static readonly type = '[Create cargo tender] Loading Types Rendered';
  constructor() {}
}

export class CargoTypesRendered {
  static readonly type = '[Create cargo tender] Cargo Types Rendered';
  constructor() {}
}

export class AdditionalTransportationRequirementsRendered {
  static readonly type =
    '[Create cargo tender] Additional Transportation Requirements Rendered';
  constructor() {}
}

export class UnitedOptionsRendered {
  static readonly type = '[Create cargo tender] United options rendered';
  constructor() {}
}

export class UnloadedCargoesRendered {
  static readonly type = '[Create cargo tender] Unloaded cargoes rendered';
  constructor(public pointIndex: number) {}
}

export class CreateSeaCargoTender {
  static readonly type =
    '[Create sea cargo tender] Create sea cargo tender (submit the form)';
  constructor(public data: CreateSeaCargoTenderFormData) {}
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { SeaShipment } from '@app/modules/sea-shipments/interfaces/sea-shipment';
import { SeaShipmentCreateFormData } from '@app/modules/sea-shipments/interfaces/sea-shipment-create.from-data';

@Injectable({
  providedIn: 'root',
})
export class SeaShipmentsService {
  constructor(private apiService: ApiService) {}

  list(): Observable<SeaShipment[]> {
    return this.apiService.getAll<SeaShipment>('/widget/sea-shipments');
  }

  create(data: SeaShipmentCreateFormData): Observable<SeaShipment> {
    return this.apiService.create<SeaShipment>('/widget/sea-shipments', data);
  }

  delete(seaShipmentID: number): Observable<boolean> {
    return this.apiService.delete<boolean>(
      '/widget/sea-shipments/' + seaShipmentID
    );
  }
}

import { Selector } from '@ngxs/store';
import { CompanyTendersDistributionState } from './company_tenders_distribution.state';
import { CompanyTendersDistributionStateModel } from './company_tenders_distribution.model';
import { DatePeriodDayjs } from '@app/interfaces/date-period-dayjs';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { CompanyTendersDistributionInfo } from './interface/company-tenders-distribution-info';

export class CompanyTendersDistributionSelectors {
  @Selector([CompanyTendersDistributionState])
  static datePeriod(
    state: CompanyTendersDistributionStateModel
  ): DatePeriodDayjs {
    return state.datePeriod;
  }

  @Selector([CompanyTendersDistributionState])
  static companyId(state: CompanyTendersDistributionStateModel): number {
    return state.companyId;
  }

  @Selector([CompanyTendersDistributionState])
  static tendersScope(
    state: CompanyTendersDistributionStateModel
  ): TendersScopes {
    return state.tendersScope;
  }

  @Selector([CompanyTendersDistributionState])
  static createdDistribution(
    state: CompanyTendersDistributionStateModel
  ): CompanyTendersDistributionInfo[] {
    return state.distribution.created;
  }

  @Selector([CompanyTendersDistributionState])
  static participatedDistribution(
    state: CompanyTendersDistributionStateModel
  ): CompanyTendersDistributionInfo[] {
    return state.distribution.participated;
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersParticipatedCar {
  static readonly type =
    '[Company Tenders Participated Car] Load company tenders ';
}

export class SetCompanyTendersParticipatedCarPage {
  static readonly type =
    '[Company Tenders Participated Car] Set company tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersParticipatedCarDatePeriod {
  static readonly type =
    '[Company Tenders Participated Car] Set company tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersParticipatedCarState {
  static readonly type =
    '[Company Tenders Participated Car] Set company tenders state';
  constructor(public states: TenderStates[]) {}
}

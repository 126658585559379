import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/services/api.service';
import { LoginFormData } from '@app/pages/login/interfaces/login-form-data';
import { RegistrationFormData } from '@app/pages/registration/interfaces/registration-form-data';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccessTokenPayload } from './interfaces/access-token-payload';
import { map } from 'rxjs/operators';
import { NewAccessTokenResponse } from './interfaces/new-access-token-response';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { RegistrationApplicationFormData } from '@app/pages/registration-application/interface/registration-application.form-data';
import { resetPasswordFormData } from '@app/pages/reset-password/interfaces/reset-password.form-data';
import { LogoutData } from './interfaces/logout-data';
import { RegisterUserWithCompanyFormData } from '@app/pages/register-user-with-company/interfaces/register-user-with-company.form-data';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private apiService: ApiService) {}

  login(credentials: LoginFormData) {
    return this.apiService.post('/login', credentials);
  }

  logout(data: LogoutData) {
    return this.apiService.post('/logout', data);
  }

  register(
    registrationFormData: RegistrationFormData
  ): Observable<NewAccessTokenResponse> {
    return this.apiService.post<NewAccessTokenResponse>(
      '/register',
      registrationFormData
    );
  }

  registerUserWithCompany(
    data: RegisterUserWithCompanyFormData
  ): Observable<NewAccessTokenResponse> {
    return this.apiService.post<NewAccessTokenResponse>('/registration', data);
  }

  renewAccessToken(refreshToken: string): Observable<NewAccessTokenResponse> {
    return this.apiService
      .post<ResponseSuccess<NewAccessTokenResponse>>('/refresh-token', {
        refreshToken,
      })
      .pipe(
        map((response: ResponseSuccess<NewAccessTokenResponse>) => {
          return response.data;
        })
      );
  }

  decodeToken(token: string): AccessTokenPayload {
    const jwtHelper = new JwtHelperService();
    return jwtHelper.decodeToken<AccessTokenPayload>(
      token
    ) as AccessTokenPayload;
  }

  submitRegistrationApplication(
    data: RegistrationApplicationFormData
  ): Observable<unknown> {
    return this.apiService.post<unknown>('/registration-application', data);
  }

  forgotPassword(email: string): Observable<unknown> {
    return this.apiService.post<unknown>('/reset-password-request', {
      email,
    });
  }

  resetPassword(
    token: string,
    data: resetPasswordFormData
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/reset-password-confirm/' + token,
      data
    );
  }

  setUserLanguage(language: string): Observable<NewAccessTokenResponse> {
    return this.apiService.put<NewAccessTokenResponse>('/profile/lang', {
      language,
    });
  }
}

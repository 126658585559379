import { UserFinancesTendersWithMarkup } from '@app/pages/profile/user-finances/interfaces/user-finances-tenders-with-markup';
import { UserFinancesTurnover } from '@app/pages/profile/user-finances/interfaces/user-finances-turnover';
import { Selector } from '@ngxs/store';
import { UserFinancesStateModel } from './user_finances-state.model';
import { UserFinancesState } from './user_finances.state';

export class UserFinancesSelectors {
  @Selector([UserFinancesState])
  static tendersWithMarkup(
    state: UserFinancesStateModel
  ): UserFinancesTendersWithMarkup {
    return state.tendersWithMarkup;
  }

  @Selector([UserFinancesState])
  static turnover(state: UserFinancesStateModel): UserFinancesTurnover {
    return state.turnover;
  }
}

import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { FinancesBillsStateModel } from './finances_bills-state.model';
import { FinancesBillsAccountsPayableState } from './accounts-payable';
import { FinancesBillsAccountsReceivableState } from './accounts-receivable';
import { FinancesBillsTabs } from '@app/pages/finances/bills/finances-bills-tabs';
import { SetFinancesBillsActiveTab } from './finances_bills.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';

@Injectable()
@State<FinancesBillsStateModel>({
  name: 'finances__bills',
  defaults: {
    activeTab: FinancesBillsTabs.ACCOUNTS_PAYABLE,
  },
  children: [
    FinancesBillsAccountsPayableState,
    FinancesBillsAccountsReceivableState,
  ],
})
export class FinancesBillsState {
  @Action(SetFinancesBillsActiveTab)
  @ImmutableContext()
  setFinancesBillsActiveTab(
    { setState }: StateContext<FinancesBillsStateModel>,
    { tab }: SetFinancesBillsActiveTab
  ) {
    return setState((state: FinancesBillsStateModel) => {
      state.activeTab = tab;
      return state;
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { UserComment } from '@app/modules/profile/interfaces/user-comment';
import { AddCommentFormData } from '@app/modules/comments/interfaces/add-comment.form-data';
import { ResponseSuccess } from '@app/interfaces/response-success';

@Injectable({
  providedIn: 'root',
})
export class UserCommentsService {
  constructor(private apiService: ApiService) {}

  list(userId: number): Observable<UserComment[]> {
    return this.apiService.getAll<UserComment>('/user/' + userId + '/comment');
  }

  create(data: AddCommentFormData): Observable<UserComment> {
    const userId = data.entityId;
    delete data.entityId;
    return this.apiService.post<UserComment>(
      '/user/' + userId + '/comment',
      data
    );
  }

  delete(comment: UserComment): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>(
        '/user/' + comment.userId + '/comment/' + comment.id
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  SetCarCargoTenders,
  ReadCarCargoTender,
  ReloadCarCargoTender,
} from './cargo-tenders-list_car.actions';
import { CargoTendersListCarStateModel } from './cargo-tenders-list_car-state.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { DeleteBet } from '@app/store/bets/bets.actions';
import { BetsService } from '@app/modules/bets/bets.service';
import { UiService } from '@app/services/ui.service';
import { SetUnreadMessagesCountInTenderChat } from '@app/store/chat';
import { CargoTenderBetSelected } from '@app/store/cargo-tenders/cargo-tenders.actions';
import {
  DeleteCarCargoTender,
  NewBetPlacedInCarCargoTender,
  UpdateCarCargoPointArrivalTime,
} from '@app/store/cargo-tenders/view/car/cargo-tenders-view_car.actions';
import { CargoTendersViewCarSelectors } from '@app/store/cargo-tenders/view';
import { TenderPoint } from '@app/modules/points/interfaces/tender-point';
import {
  AddCarCargoTenderToFavorites,
  RemoveCarCargoTenderFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<CargoTendersListCarStateModel>({
  name: 'cargo_tenders__list__car',
  defaults: {
    tenders: null,
  },
})
export class CargoTendersListCarState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private betsService: BetsService,
    private uiService: UiService,
    private store: Store
  ) {}

  @Action(SetCarCargoTenders)
  @ImmutableContext()
  setCarCargoTenders(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { tenders }: SetCarCargoTenders
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      state.tenders = tenders;
      return state;
    });
  }

  @Action(NewBetPlacedInCarCargoTender)
  @ImmutableContext()
  newBetPlacedInCarCargoTender(
    { getState, setState }: StateContext<CargoTendersListCarStateModel>,
    { tenderId }: NewBetPlacedInCarCargoTender
  ) {
    const tenders = getState().tenders;
    if (!tenders) {
      return;
    }

    let exists = -1;

    for (let i = 0; i < tenders.length; i++) {
      if (tenders[i].id === tenderId) {
        exists = i;
        break;
      }
    }
    if (exists === -1) {
      return;
    }

    return this.cargoTendersCarService.getTenderBetInfo(tenderId).pipe(
      tap((tender: CarCargoTender) => {
        setState((state: CargoTendersListCarStateModel) => {
          state.tenders[exists] = { ...state.tenders[exists], ...tender };
          return state;
        });
      })
    );
  }

  @Action(ReloadCarCargoTender)
  @ImmutableContext()
  reloadCargoTender(
    { getState, setState }: StateContext<CargoTendersListCarStateModel>,
    { tenderId }: ReloadCarCargoTender
  ) {
    const tenders = getState().tenders;
    if (!tenders) {
      return;
    }

    let exists = -1;

    for (let i = 0; i < tenders.length; i++) {
      if (tenders[i].id === tenderId) {
        exists = i;
        break;
      }
    }
    if (exists === -1) {
      return;
    }

    return this.cargoTendersCarService.get(tenderId).pipe(
      tap((tender: CarCargoTender) => {
        setState((state: CargoTendersListCarStateModel) => {
          state.tenders[exists] = { ...state.tenders[exists], ...tender };
          return state;
        });
      })
    );
  }

  @Action(SetUnreadMessagesCountInTenderChat)
  @ImmutableContext()
  setUnreadMessagesCountInTenderChat(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { chatGroupID, unreadMessagesCount }: SetUnreadMessagesCountInTenderChat
  ) {
    setState((state: CargoTendersListCarStateModel) => {
      if (!state.tenders) {
        return state;
      }
      const tender: CarCargoTender = state.tenders.find(
        (tender: CarCargoTender) => {
          return tender.chatId === chatGroupID;
        }
      );

      if (tender) {
        if (unreadMessagesCount < 0) {
          tender.unreadMessageCount -= unreadMessagesCount;
        } else {
          tender.unreadMessageCount = unreadMessagesCount;
        }
      }

      return state;
    });
  }

  @Action(ReadCarCargoTender)
  @ImmutableContext()
  readCargoTender(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { tenderId }: ReadCarCargoTender
  ) {
    return this.cargoTendersCarService.markTenderAsRead(tenderId).pipe(
      tap(() => {
        setState((state: CargoTendersListCarStateModel) => {
          const tenderIndex = state.tenders.findIndex(
            (tender: CarCargoTender) => {
              return tender.id === tenderId;
            }
          );

          if (tenderIndex !== -1) {
            if (state.tenders[tenderIndex].isNew) {
              // state.newTendersCount--; // todo: update in another state
            }
            state.tenders[tenderIndex].isNew = false;
            state.tenders[tenderIndex].isRead = true;
          }

          return state;
        });
      })
    );
  }

  @Action(DeleteBet)
  @ImmutableContext()
  deleteBet(
    {}: StateContext<CargoTendersListCarStateModel>,
    { bet }: DeleteBet
  ) {
    this.uiService.displayLoading();
    return this.betsService.remove(bet).pipe(
      tap(() => {
        this.uiService.dismissLoading();
      })
    );
  }

  @Action(CargoTenderBetSelected)
  @ImmutableContext()
  cargoTenderBetSelected(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { tender }: CargoTenderBetSelected
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      const tenderIndex = state.tenders.findIndex((t: CarCargoTender) => {
        return t.id === tender.id;
      });

      if (tenderIndex !== -1) {
        state.tenders[tenderIndex] = {
          ...state.tenders[tenderIndex],
          ...tender,
        };
      }

      return state;
    });
  }

  @Action(DeleteCarCargoTender)
  @ImmutableContext()
  deleteCarCargoTender(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { tenderId }: DeleteCarCargoTender
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      state.tenders = state.tenders.filter((tender: CarCargoTender) => {
        return tender.id !== tenderId;
      });
      return state;
    });
  }

  @Action(UpdateCarCargoPointArrivalTime)
  @ImmutableContext()
  updateCarCargoPointArrivalTime(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { pointID, arrivalTime }: UpdateCarCargoPointArrivalTime
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      const updatedTender: CarCargoTender = this.store.selectSnapshot(
        CargoTendersViewCarSelectors.tender
      );
      const tender: CarCargoTender = state.tenders.find(
        (currentTender: CarCargoTender) => {
          return currentTender.id === updatedTender.id;
        }
      );

      if (!tender) {
        return state;
      }

      const point: TenderPoint = tender.points.find(
        (existingPoint: TenderPoint) => {
          return existingPoint.id === pointID;
        }
      );

      if (point) {
        point.date.arrivalTime = arrivalTime;
      }

      return state;
    });
  }

  @Action(AddCarCargoTenderToFavorites)
  @ImmutableContext()
  addCarCargoTenderToFavorites(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { entityId }: AddCarCargoTenderToFavorites
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      const tender = state.tenders.find((tender: CarCargoTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveCarCargoTenderFromFavorites)
  @ImmutableContext()
  removeCarCargoTenderFromFavorites(
    { setState }: StateContext<CargoTendersListCarStateModel>,
    { entityId }: RemoveCarCargoTenderFromFavorites
  ) {
    return setState((state: CargoTendersListCarStateModel) => {
      const tender = state.tenders.find((tender: CarCargoTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = false;
      }

      return state;
    });
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { UserFinancesStateModel } from './user_finances-state.model';
import { Injectable } from '@angular/core';
import {
  SetUserSalary,
  LoadUserFinancesStatistics,
  AddUserTurnoverData,
  EditUserTurnoverData,
  DeleteUserTurnoverData,
} from './user_finances.actions';
import { tap } from 'rxjs/operators';
import { UserFinancesService } from '@app/modules/profile/services/user-finances.service';
import { combineLatest } from 'rxjs';
import { UserFinancesTendersWithMarkup } from '@app/pages/profile/user-finances/interfaces/user-finances-tenders-with-markup';
import { UserFinancesTurnover } from '@app/pages/profile/user-finances/interfaces/user-finances-turnover';

@Injectable()
@State<UserFinancesStateModel>({
  name: 'user_finances',
  defaults: {
    tendersWithMarkup: null,
    turnover: null,
  },
})
export class UserFinancesState {
  constructor(private userFinancesService: UserFinancesService) {}

  @Action(SetUserSalary)
  @ImmutableContext()
  setUserSalary(
    {}: StateContext<UserFinancesStateModel>,
    { userId, data }: SetUserSalary
  ) {
    return this.userFinancesService.setUserSalary(userId, data);
  }

  @Action(AddUserTurnoverData)
  @ImmutableContext()
  addUserTurnoverData(
    {}: StateContext<UserFinancesStateModel>,
    { userId, data }: AddUserTurnoverData
  ) {
    return this.userFinancesService.addUserTurnoverData(userId, data);
  }

  @Action(EditUserTurnoverData)
  @ImmutableContext()
  editUserTurnoverData(
    {}: StateContext<UserFinancesStateModel>,
    { turnoverID, data }: EditUserTurnoverData
  ) {
    return this.userFinancesService.editUserTurnoverData(turnoverID, data);
  }

  @Action(DeleteUserTurnoverData)
  @ImmutableContext()
  deleteUserTurnoverData(
    {}: StateContext<UserFinancesStateModel>,
    { turnoverID }: EditUserTurnoverData
  ) {
    return this.userFinancesService.deleteUserTurnoverData(turnoverID);
  }

  @Action(LoadUserFinancesStatistics)
  @ImmutableContext()
  loadUserFinancesStatistics(
    { setState }: StateContext<UserFinancesStateModel>,
    { userId, month, year }: LoadUserFinancesStatistics
  ) {
    return combineLatest([
      this.userFinancesService.getTendersWithMarkup(userId, month, year).pipe(
        tap((tendersWithMarkup: UserFinancesTendersWithMarkup) => {
          setState((state: UserFinancesStateModel) => {
            state.tendersWithMarkup = tendersWithMarkup;
            return state;
          });
        })
      ),

      this.userFinancesService.getTurnover(userId, month, year).pipe(
        tap((turnover: UserFinancesTurnover) => {
          setState((state: UserFinancesStateModel) => {
            state.turnover = turnover;
            return state;
          });
        })
      ),
    ]);
  }
}

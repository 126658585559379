import { DatePeriod } from '@app/interfaces/date-period';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { TendersPageFilters } from '@app/pages/tenders/tenders.page.filters';
import { IonicSelectableComponent } from 'ionic-selectable';

export class SetCargoTendersTransportationTypeOnTendersPage {
  static readonly type =
    '[Tenders page -> Filters] Set cargo tenders transportation type';
  constructor(
    public cargoTendersTransportationType: CargoTendersTransportationTypes
  ) {}
}

export class SetSearchValueOnTendersPage {
  static readonly type = '[Tenders page -> Filters] Set search value';
  constructor(public searchValue: string) {}
}
export class ToggleFiltersOnTendersPage {
  static readonly type = '[Tenders page -> Filters] Toggle filters';
}
export class ApplyFiltersOnTendersPage {
  static readonly type = '[Tenders page -> Filters] Apply filters';
  constructor(public filters: TendersPageFilters) {}
}

export class SetTendersPageFiltersDatePeriod {
  static readonly type = '[Tenders page -> Filters] Set date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SearchCompanyInTendersPageFilters {
  static readonly type = '[Tenders page -> Filters] Search company';
  constructor(
    public component: IonicSelectableComponent,
    public searchValue: string
  ) {}
}

export class SearchCuratorInTendersPageFilters {
  static readonly type = '[Tenders page -> Filters] Search curator';
  constructor(
    public component: IonicSelectableComponent,
    public searchValue: string
  ) {}
}

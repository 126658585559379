import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { SaveChatGroupRequest } from '@app/modules/chat/interfaces/create-chat-group-request';

export class ListMyChats {
  static readonly type =
    '[Chat -> My Chats] Load the list of my chats (the first page only)';
}

export class FetchMyChats {
  static readonly type =
    '[Chat -> My Chats] Fetch the list of my chats (not the first page)';
}

export class TryDeleteChatGroup {
  static readonly type = '[Chat -> My Chats] Try delete chat group';
  constructor(public group: ChatGroup) {}
}

export class DeleteChatGroupFailed {
  static readonly type = '[Chat -> My Chats] Delete chat group failed';
}

export class TryCreateChatGroup {
  static readonly type = '[Chat -> My Chats] Try to create group chat';
  constructor(public groupData: SaveChatGroupRequest) {}
}

export class CreateChatGroupSuccess {
  static readonly type =
    '[Chat -> My Chats] Chat group was successfully created';
  constructor(public chatGroup: ChatGroup) {}
}

export class TryEditChatGroup {
  static readonly type = '[Chat -> My Chats] Try to edit group chat';
  constructor(public groupData: SaveChatGroupRequest) {}
}

export class EditChatGroupSuccess {
  static readonly type = '[Chat -> My Chats] Chat group was successfully saved';
  constructor(public chatGroup: ChatGroup) {}
}

export class SearchMyChats {
  static readonly type = '[Chat -> My Chats] Set my chats search value';
  constructor(public searchValue: string) {}
}

export class PrivateChatAddedToBlackList {
  static readonly type = '[Chat -> My Chats] Private chats added to black list';
  constructor(public chatGroupId: number) {}
}

export class AddChatGroupToMyChats {
  static readonly type = '[Chat -> My Chats] Add chat group to my chats';
  constructor(public chatGroupID: number, public chatGroupName: string) {}
}

import { CompaniesCreateFormData } from '@app/pages/companies/create/interfaces/companies_create.form-data';
import { FieldsErrors } from '@app/interfaces/fields-errors';

export class InitCreateCompanyForm {
  static readonly type = '[Create company] Initialize the form';
  constructor() {}
}

export class TryCreateCompany {
  static readonly type = '[Create company] Try create company';
  constructor(public payload: CompaniesCreateFormData) {}
}
export class CreateCompanyFailed {
  static readonly type =
    '[Create company] Unable to create a company. Wrong data provided?';
  constructor(public errors: FieldsErrors) {}
}
export class CreateCompanySucceess {
  static readonly type =
    '[Create company] The company was successfully created!';
  constructor() {}
}

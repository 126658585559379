import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyComment } from '@app/modules/companies/interfaces/company-comment';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyMemberPosition } from '@app/modules/companies/interfaces/company-member-position';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { Selector } from '@ngxs/store';
import { CompaniesViewStateModel } from './companies_view-state.model';
import { CompaniesViewState } from './companies_view.state';

export class CompaniesViewSelectors {
  @Selector([CompaniesViewState])
  static company(state: CompaniesViewStateModel): Company {
    return state.company;
  }

  @Selector([CompaniesViewState])
  static autoSignature(state: CompaniesViewStateModel): boolean {
    return state.company.autoSignature;
  }

  @Selector([CompaniesViewState])
  static useUserSignature(state: CompaniesViewStateModel): boolean {
    return state.company.userSignature;
  }

  @Selector([CompaniesViewState])
  static stamp(state: CompaniesViewStateModel): string {
    return state.company.stamp;
  }

  @Selector([CompaniesViewState])
  static signature(state: CompaniesViewStateModel): string {
    return state.company.signature;
  }

  @Selector([CompaniesViewState])
  static permissions(state: CompaniesViewStateModel): Permission[] {
    return state.permissions;
  }

  @Selector([CompaniesViewState])
  static companyMembers(state: CompaniesViewStateModel): CompanyMember[] {
    return state.members.result.data;
  }

  @Selector([CompaniesViewState])
  static companyComments(state: CompaniesViewStateModel): CompanyComment[] {
    return state.comments;
  }

  @Selector([CompaniesViewState])
  static documentComments(state: CompaniesViewStateModel): CompanyComment[] {
    return state.documentComments;
  }

  @Selector([CompaniesViewState])
  static companyMembersMaxPage(state: CompaniesViewStateModel): number {
    return state.members.result.maxPage;
  }

  @Selector([CompaniesViewState])
  static companyMembersPage(state: CompaniesViewStateModel): number {
    return state.members.filters.page;
  }

  @Selector([CompaniesViewState])
  static search(state: CompaniesViewStateModel): string {
    return state.members.filters.search;
  }

  @Selector([CompaniesViewState])
  static position(state: CompaniesViewStateModel): number {
    return state.members.filters.position;
  }

  @Selector([CompaniesViewState])
  static positions(state: CompaniesViewStateModel): CompanyMemberPosition[] {
    return state.positions;
  }

  @Selector([CompaniesViewState])
  static partnerships(state: CompaniesViewStateModel): CompanyPartnership[] {
    return state.partnerships;
  }

  @Selector([CompaniesViewState])
  static partnershipsMaxPage(state: CompaniesViewStateModel): number {
    return state.partnershipsMaxPage;
  }

  @Selector([CompaniesViewState])
  static displayCompanyMembersFilters(state: CompaniesViewStateModel): boolean {
    return state.displayCompanyMembersFilters;
  }

  @Selector([CompaniesViewState])
  static changeCompanyMembers(state: CompaniesViewStateModel): CompanyMember[] {
    return state.changeCompanyMembers;
  }
}

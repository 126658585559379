import { SubscriptionInfo } from '@app/modules/users/interfaces/subscription-info';
import { UsersListFilters } from '@app/pages/users/list/interfaces/users-list-filters';

export class MonitorUserConnections {
  static readonly type = '[Users] Monitor user connections';
  constructor(public lastEventID: string) {}
}

export class LoadUsersOnline {
  static readonly type =
    '[Users] Load and set online users infos as online users list';
}

export class UserGoesOnline {
  static readonly type = '[Users] Add a user online info to online users list';
  constructor(public info: SubscriptionInfo) {}
}

export class UserWentOffline {
  static readonly type =
    '[Users] Remove a user online info from online users list';
  constructor(public subscriptionID: string) {}
}

export class LoadUsersList {
  static readonly type = '[Users List Page] Load users list';
  constructor(public page?: number) {}
}

export class LockUser {
  static readonly type = '[Users List Page] Lock a user';
  constructor(public userId: number) {}
}

export class UnlockUser {
  static readonly type = '[Users List Page] Unlock a user';
  constructor(public userId: number) {}
}

export class DeleteUser {
  static readonly type = '[Any Page] Delete user';
  constructor(public userId: number) {}
}

export class UpdateUsersListFilters {
  static readonly type = '[Users List Page] Update users list filters';
  constructor(public filters: UsersListFilters) {}
}

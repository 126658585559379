import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Currency } from '@app/modules/currencies/interfaces/currency';
import { CurrenciesService } from '@app/modules/currencies/services/currencies.service';
import { ListingsStateModel } from './listings-state.model';
import {
  LoadCargoTypes,
  LoadCountries,
  LoadCountrySquares,
  LoadCurrencies,
  LoadCurrencyRates,
  LoadExchangeCargoTypes,
  LoadExistingCompanyPermissions,
  LoadExistingPermissions,
  LoadDeliveryConditions,
  LoadContainerTypes,
  LoadNomenclatures,
  LoadLoadingTypes,
  LoadPackagingTypes,
} from './listings.actions';
import { CargoTypesService } from '@app/modules/cargo-types/services/cargo-types.service';
import { CargoType } from '@app/modules/cargo-types/interfaces/cargo-type';
import { CountriesService } from '@app/modules/countries/countries.service';
import { SquaresService } from '@app/modules/squares/services/squares.service';
import { Country } from '@app/modules/countries/interfaces/country';
import { Square } from '@app/entities/place/square';
import { PermissionsService } from '@app/modules/permissions/services/permissions.service';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { ExchangeCargoTypesService } from '@app/modules/exchange-cargo-types/services/exchange-cargo-types.service';
import { ExchangeCargoType } from '@app/modules/exchange-cargo-types/interfaces/exchange-cargo-type';
import { TransportType } from '@app/modules/transport-types/interfaces/transport-type';
import { TransportTypesService } from '@app/modules/transport-types/services/transport-types.service';
import {
  LoadAdditionalTransportationRequirements,
  LoadRenewalTimes,
  LoadTenderUnitedOptions,
  LoadTransportTypes,
  LoadIncoterms,
} from './listings.actions';
import { AdditionalTransportationRequirementsService } from '@app/modules/additional-transportation-requirements/services/additional-transportation-requirements.service';
import { AdditionalTransportationRequirement } from '@app/modules/additional-transportation-requirements/interfaces/additional-transportation-requirement';
import { RenewalTimesService } from '@app/modules/renewal-times/services/renewal-times.service';
import { RenewalTime } from '@app/modules/renewal-times/interfaces/renewal-time';
import { TenderUnitedOptionsService } from '@app/modules/tender-united-options/services/tender-united-options.service';
import { TenderUnitedOption } from '@app/modules/tender-united-options/interfaces/tender-united-option';
import { IncotermsService } from '@app/modules/incoterms/services/incoterms.service';
import { Incoterm } from '@app/modules/incoterms/interfaces/incoterm';
import { DeliveryConditionsService } from '@app/modules/delivery-conditions/services/delivery-conditions.service';
import { DeliveryCondition } from '@app/modules/delivery-conditions/interfaces/delivery-condition';
import { ContainerTypesService } from '@app/modules/container-types/services/container-types.service';
import { ContainerType } from '@app/modules/container-types/interfaces/container-type';
import { Nomenclature } from '@app/modules/nomenclatures/interfaces/nomenclature';
import { NomenclaturesService } from '@app/modules/nomenclatures/services/nomenclatures.service';
import { LoadingTypesService } from '@app/modules/loading-types/services/loading-types.service';
import { LoadingType } from '@app/modules/loading-types/interfaces/loading-type';
import { PackagingType } from '@app/modules/packaging-types/interfaces/packaging-type';
import { PackagingTypesService } from '@app/modules/packaging-types/services/packaging-types.service';

@Injectable()
@State<ListingsStateModel>({
  name: 'listings',
  defaults: {
    currencies: null,
    nomenclatures: null,
    currencyRates: [],
    cargoTypes: null,
    exchangeCargoTypes: null,
    countries: null,
    countrySquares: [],
    permissions: [],
    companyPermissions: [],
    transportTypes: null,
    additionalTransportationRequirements: null,
    renewalTimes: null,
    tenderUnitedOptions: null,
    incoterms: null,
    deliveryConditions: null,
    containerTypes: null,
    loadingTypes: null,
    packagingTypes: null,
  },
})
export class ListingsState {
  constructor(
    private currenciesService: CurrenciesService,
    private nomenclaturesService: NomenclaturesService,
    private cargoTypesService: CargoTypesService,
    private exchangeCargoTypesService: ExchangeCargoTypesService,
    private countriesService: CountriesService,
    private squaresService: SquaresService,
    private permissionsService: PermissionsService,
    private transportTypesService: TransportTypesService,
    private additionalTransportationRequirementsService: AdditionalTransportationRequirementsService,
    private renewalTimesService: RenewalTimesService,
    private tenderUnitedOptionsService: TenderUnitedOptionsService,
    private incotermsService: IncotermsService,
    private deliveryConditionsService: DeliveryConditionsService,
    private containerTypesService: ContainerTypesService,
    private loadingTypesService: LoadingTypesService,
    private packagingTypesService: PackagingTypesService
  ) {}

  @Action(LoadCurrencies, { cancelUncompleted: true })
  @ImmutableContext()
  loadCurrencies({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().currencies) {
      return;
    }

    return this.currenciesService.getAll().pipe(
      tap((currencies: Currency[]) => {
        setState((state: ListingsStateModel) => {
          state.currencies = currencies;
          return state;
        });
      })
    );
  }

  @Action(LoadNomenclatures, { cancelUncompleted: true })
  @ImmutableContext()
  loadNomenclatures({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().nomenclatures) {
      return;
    }

    return this.nomenclaturesService.getAll().pipe(
      tap((nomenclatures: Nomenclature[]) => {
        setState((state: ListingsStateModel) => {
          state.nomenclatures = nomenclatures;
          return state;
        });
      })
    );
  }

  @Action(LoadCurrencyRates, { cancelUncompleted: true })
  @ImmutableContext()
  loadCurrencyRates({ setState }: StateContext<ListingsStateModel>) {
    return this.currenciesService.getRates().pipe(
      tap((currencyRates: Currency[]) => {
        setState((state: ListingsStateModel) => {
          state.currencyRates = currencyRates;
          return state;
        });
      })
    );
  }

  @Action(LoadCargoTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadCargoTypes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().cargoTypes) {
      return;
    }

    return this.cargoTypesService.getAll().pipe(
      tap((cargoTypes: CargoType[]) => {
        setState((state: ListingsStateModel) => {
          state.cargoTypes = cargoTypes;
          return state;
        });
      })
    );
  }

  @Action(LoadExchangeCargoTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadExcahngeCargoTypes({
    getState,
    setState,
  }: StateContext<ListingsStateModel>) {
    if (getState().exchangeCargoTypes) {
      return;
    }

    return this.exchangeCargoTypesService.getAll().pipe(
      tap((exchangeCargoTypes: ExchangeCargoType[]) => {
        setState((state: ListingsStateModel) => {
          state.exchangeCargoTypes = exchangeCargoTypes;
          return state;
        });
      })
    );
  }

  @Action(LoadCountries, { cancelUncompleted: true })
  @ImmutableContext()
  loadCountries({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().countries) {
      return;
    }
    return this.countriesService.getAll().pipe(
      tap((countries: Country[]) => {
        setState((state: ListingsStateModel) => {
          state.countries = countries;
          return state;
        });
      })
    );
  }

  @Action(LoadCountrySquares, { cancelUncompleted: true })
  @ImmutableContext()
  loadCountrySquares(
    { setState }: StateContext<ListingsStateModel>,
    { countryId }: LoadCountrySquares
  ) {
    return this.squaresService.getAll(countryId).pipe(
      tap((squares: Square[]) => {
        setState((state: ListingsStateModel) => {
          state.countrySquares[countryId] = squares;
          return state;
        });
      })
    );
  }

  @Action(LoadExistingPermissions, { cancelUncompleted: true })
  @ImmutableContext()
  loadExistingPermissions(
    { setState }: StateContext<ListingsStateModel>,
    {}: LoadExistingPermissions
  ) {
    return this.permissionsService.getPermissions().pipe(
      tap((permissions: Permission[]) => {
        setState((state: ListingsStateModel) => {
          state.permissions = permissions;
          return state;
        });
      })
    );
  }

  @Action(LoadExistingCompanyPermissions, { cancelUncompleted: true })
  @ImmutableContext()
  loadExistingCompanyPermissions(
    { setState }: StateContext<ListingsStateModel>,
    {}: LoadExistingCompanyPermissions
  ) {
    return this.permissionsService.getPermissions(true).pipe(
      tap((permissions: Permission[]) => {
        setState((state: ListingsStateModel) => {
          state.companyPermissions = permissions;
          return state;
        });
      })
    );
  }

  @Action(LoadTransportTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadTransportTypes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().transportTypes) {
      return;
    }

    return this.transportTypesService.getAll().pipe(
      tap((transportTypes: TransportType[]) => {
        setState((state: ListingsStateModel) => {
          state.transportTypes = transportTypes;
          return state;
        });
      })
    );
  }

  @Action(LoadAdditionalTransportationRequirements, { cancelUncompleted: true })
  @ImmutableContext()
  additionalTransportationRequirements({
    getState,
    setState,
  }: StateContext<ListingsStateModel>) {
    if (getState().additionalTransportationRequirements) {
      return;
    }

    return this.additionalTransportationRequirementsService.getAll().pipe(
      tap(
        (
          additionalTransportationRequirements: AdditionalTransportationRequirement[]
        ) => {
          setState((state: ListingsStateModel) => {
            state.additionalTransportationRequirements =
              additionalTransportationRequirements;
            return state;
          });
        }
      )
    );
  }

  @Action(LoadRenewalTimes, { cancelUncompleted: true })
  @ImmutableContext()
  renewalTimes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().renewalTimes) {
      return;
    }

    return this.renewalTimesService.getAll().pipe(
      tap((renewalTimes: RenewalTime[]) => {
        setState((state: ListingsStateModel) => {
          state.renewalTimes = renewalTimes;
          return state;
        });
      })
    );
  }

  @Action(LoadTenderUnitedOptions, { cancelUncompleted: true })
  @ImmutableContext()
  tenderUnitedOptions({
    getState,
    setState,
  }: StateContext<ListingsStateModel>) {
    if (getState().tenderUnitedOptions) {
      return;
    }

    return this.tenderUnitedOptionsService.getAll().pipe(
      tap((tenderUnitedOptions: TenderUnitedOption[]) => {
        setState((state: ListingsStateModel) => {
          state.tenderUnitedOptions = tenderUnitedOptions;
          return state;
        });
      })
    );
  }

  @Action(LoadIncoterms, { cancelUncompleted: true })
  @ImmutableContext()
  loadIncoterms({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().incoterms) {
      return;
    }

    return this.incotermsService.list().pipe(
      tap((incoterms: Incoterm[]) => {
        setState((state: ListingsStateModel) => {
          state.incoterms = incoterms;
          return state;
        });
      })
    );
  }

  @Action(LoadDeliveryConditions, { cancelUncompleted: true })
  @ImmutableContext()
  loadDeliveryConditions({
    getState,
    setState,
  }: StateContext<ListingsStateModel>) {
    if (getState().deliveryConditions) {
      return;
    }

    return this.deliveryConditionsService.list().pipe(
      tap((deliveryConditions: DeliveryCondition[]) => {
        setState((state: ListingsStateModel) => {
          state.deliveryConditions = deliveryConditions;
          return state;
        });
      })
    );
  }

  @Action(LoadContainerTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadContainerTypes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().containerTypes) {
      return;
    }

    return this.containerTypesService.list().pipe(
      tap((containerTypes: ContainerType[]) => {
        setState((state: ListingsStateModel) => {
          state.containerTypes = containerTypes;
          return state;
        });
      })
    );
  }

  @Action(LoadLoadingTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadLoadingTypes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().loadingTypes) {
      return;
    }

    return this.loadingTypesService.getAll().pipe(
      tap((loadingTypes: LoadingType[]) => {
        setState((state: ListingsStateModel) => {
          state.loadingTypes = loadingTypes;
          return state;
        });
      })
    );
  }

  @Action(LoadPackagingTypes, { cancelUncompleted: true })
  @ImmutableContext()
  loadPackagingTypes({ getState, setState }: StateContext<ListingsStateModel>) {
    if (getState().packagingTypes) {
      return;
    }

    return this.packagingTypesService.getAll().pipe(
      tap((packagingTypes: PackagingType[]) => {
        setState((state: ListingsStateModel) => {
          state.packagingTypes = packagingTypes;
          return state;
        });
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { User } from '@app/modules/users/interfaces/user';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChatBlackListService {
  constructor(private apiService: ApiService) {}

  // not used
  list(page: number = 1): Observable<PaginationResults<User>> {
    return this.apiService.getPaginatedResults<User>('/chat/user/blacklist', {
      page,
    });
  }

  add(userId: number): Observable<User> {
    return this.apiService.create<User>('/chat/user/blacklist', {
      userId,
    });
  }

  delete(userId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<{ black_list: User[] }>>(
        '/chat/user/blacklist/' + userId
      )
      .pipe(
        map((response: ResponseSuccess<{ black_list: User[] }>) => {
          return response.statusCode === 202;
        })
      );
  }
}

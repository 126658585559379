export class LoadCurrencies {
  static readonly type = '[Listings] Load currencies list';
  constructor() {}
}

export class LoadNomenclatures {
  static readonly type = '[Listings] Load nomenclatures list';
  constructor() {}
}

export class LoadCurrencyRates {
  static readonly type = '[Listings] Load currency rates';
  constructor() {}
}

export class LoadCargoTypes {
  static readonly type = '[Listings] Load cargo types list';
  constructor() {}
}

export class LoadExchangeCargoTypes {
  static readonly type = '[Listings] Load exchange cargo types list';
  constructor() {}
}

export class LoadCountries {
  static readonly type = '[Listings] Load countries list';
  constructor() {}
}

export class LoadCountrySquares {
  static readonly type = '[Listings] Load squares list for provided country id';
  constructor(public countryId: number) {}
}

export class LoadExistingPermissions {
  static readonly type = '[Listings] Load existing permissions';
  constructor() {}
}

export class LoadExistingCompanyPermissions {
  static readonly type = '[Listings] Load existing company permissions';
  constructor() {}
}

export class LoadTransportTypes {
  static readonly type = '[Listings] Load transport types list';
  constructor() {}
}

export class LoadAdditionalTransportationRequirements {
  static readonly type =
    '[Listings] Load additional transportation requirements list';
  constructor() {}
}

export class LoadRenewalTimes {
  static readonly type = '[Listings] Load renewal times list';
  constructor() {}
}

export class LoadTenderUnitedOptions {
  static readonly type = '[Listings] Load tender united options list';
  constructor() {}
}

export class LoadIncoterms {
  static readonly type = '[Listings] Load incoterms list';
  constructor() {}
}

export class LoadDeliveryConditions {
  static readonly type = '[Listings] Load delivery conditions list';
  constructor() {}
}

export class LoadContainerTypes {
  static readonly type = '[Listings] Load container types list';
  constructor() {}
}

export class LoadLoadingTypes {
  static readonly type = '[Listings] Load loading types list';
  constructor() {}
}

export class LoadPackagingTypes {
  static readonly type = '[Listings] Load loading types list';
  constructor() {}
}

export class FetchCargoTenders {
  static readonly type = '[Cargo Tenders list] Fetch cargo tenders';
  constructor(public page?: number) {}
}

export class SetCargoTendersPagination {
  static readonly type = '[Cargo Tenders list] Set cargo tenders pagination';
  constructor(public page: number, public maxPage: number) {}
}

export class SetCargoTendersSearchValue {
  static readonly type = '[Cargo Tenders list] Set cargo tenders search value';
  constructor(public searchValue: string) {}
}

export class LoadNewCargoTendersCount {
  static readonly type = '[Cargo Tenders list] Load new cargo tenders count';
  constructor() {}
}

export class ReadCargoTender {
  static readonly type = '[Cargo Tenders list] Mark cargo tender as read';
  constructor(public tenderId: number) {}
}

export class ReloadCargoTender {
  static readonly type = '[Cargo Tenders list] Reload cargo tender';
  constructor(public tenderId: number) {}
}

export class ToggleCargoTendersListView {
  static readonly type = '[Cargo Tenders list] Toggle cargo tenders list view';
  constructor() {}
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersCreatedSea {
  static readonly type =
    '[Company Tenders Created Sea] Load company sea tenders';
}

export class SetCompanyTendersCreatedSeaPage {
  static readonly type =
    '[Company Tenders Created Sea] Set company sea tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersCreatedSeaDatePeriod {
  static readonly type =
    '[Company Tenders Created Sea] Set company sea tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersCreatedSeaState {
  static readonly type =
    '[Company Tenders Created Sea] Set company sea tenders state';
  constructor(public states: TenderStates[]) {}
}

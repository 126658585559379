import { DeletedMessageData } from '@app/interfaces/deleted-message-data';
import { ChatFeedback } from '@app/modules/chat/interfaces/chat-feedback';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { ChatMessage } from '@app/modules/chat/interfaces/chat-message';
import { MessageFeedback } from '@app/modules/chat/interfaces/meesage-feedback';
import { MessageAttachment } from '@app/modules/chat/interfaces/message-attachment';
import { ReadChatMessages } from '@app/modules/chat/interfaces/read-chat-messages';
import { User } from '@app/modules/users/interfaces/user';

export class SelectChatGroup {
  static readonly type = '[Chat -> Selected chat] Select chat group';
  constructor(public chatGroupId: number) {}
}

export class CloseChatGroup {
  static readonly type = '[Chat -> Selected chat] Close chat group';
  constructor() {}
}

export class ChatAddMessage {
  static readonly type = '[Chat -> Selected chat] Add message';
  constructor(
    public messageContent?: string,
    public attachments?: MessageAttachment[],
    public contact?: User
  ) {}
}

export class RTS_NewChatMessageReceived {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] New chat message received';
  constructor(public message: ChatMessage) {}
}

export class RTS_ChatMessagesWereRead {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] Chat messages were read';
  constructor(public readMessages: ReadChatMessages) {}
}

export class RTS_ChatMessageWasDeleted {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] Chat message was deleted';
  constructor(public deletedMessageData: DeletedMessageData) {}
}

export class RTS_MessageFeedbackAdded {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] Message feedback was added';
  constructor(public messageFeedback: MessageFeedback) {}
}

export class RTS_MessageFeedbackRemoved {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] Message feedback was removed';
  constructor(public messageFeedback: MessageFeedback) {}
}

export class RTS_ChatGroupCreated {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] A chat group has been created';
  constructor(public chatGroup: ChatGroup) {}
}

export class RTS_ChatGroupEdited {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] A chat group has been edited';
  constructor(public chatGroup: ChatGroup) {}
}

export class RTS_ChatGroupRemoved {
  static readonly type =
    '[Chat -> Selected chat] [Real time signal] A chat group has been removed';
  constructor(public chatGroupID: number) {}
}

export class LikeMessage {
  static readonly type = '[Chat -> Selected chat] Add message feedback';
  constructor(public messageID: number, public chatFeedback: ChatFeedback) {}
}

export class LikeMessageSuccess {
  static readonly type =
    '[Chat -> Selected chat] Message feedback has been successfully added';
  constructor(
    public chatID: number,
    public messageID: number,
    public messageFeedbackID: number,
    public messageFeedback: MessageFeedback
  ) {}
}

export class LikeMessageFailed {
  static readonly type =
    '[Chat -> Selected chat] Failed to add message feedback';
  constructor(
    public chatID: number,
    public messageID: number,
    public messageFeedbackID: number
  ) {}
}

export class RemoveMessageFeedback {
  static readonly type = '[Chat -> Selected chat] Remove message feedback';
  constructor(public messageID: number, public messageFeedbackID: number) {}
}

export class RemoveMessageFeedbackSuccess {
  static readonly type =
    '[Chat -> Selected chat] Message feedback has been successfully removed';
  constructor(
    public chatID: number,
    public messageID: number,
    public messageFeedbackID: number
  ) {}
}

export class RemoveMessageFeedbackFailed {
  static readonly type =
    '[Chat -> Selected chat] Failed to remove message feedback';
  constructor(
    public chatID: number,
    public messageID: number,
    public messageFeedbackID: number
  ) {}
}

export class TryDeleteChatMessage {
  static readonly type = '[Chat -> Selected chat] Try to delete chat message';
  constructor(public messageID: number) {}
}

export class AddReplyChatMessage {
  static readonly type = '[Chat -> Selected chat] Add chat message to reply to';
  constructor(public message: ChatMessage) {}
}

export class AddForwardChatMessage {
  static readonly type =
    '[Chat -> Selected chat] Add chat message as forward message';
  constructor(public message: ChatMessage) {}
}

export class RemoveReplyChatMessage {
  static readonly type =
    '[Chat -> Selected chat] Remove chat message to reply to';
}

export class RemoveForwardChatMessage {
  static readonly type =
    '[Chat -> Selected chat] Remove chat message that is being to be forwarded';
}

export class DeleteChatMessageSuccess {
  static readonly type = '[Chat -> Selected chat] Delete chat message success';
  constructor(public chatID: number, public messageID: number) {}
}

export class DeleteChatMessageFailed {
  static readonly type = '[Chat -> Selected chat] Delete chat message failed';
  constructor(public chatID: number, public messageID: number) {}
}

export class FetchChatGroupMessages {
  static readonly type =
    '[Chat -> Selected chat] Fetch the list of selected chat group messages (the initial call)';
  constructor() {}
}

export class ReadChatGroupLoadedMessages {
  static readonly type =
    '[Chat -> Selected chat] Read loaded messages of the selected chat group';
  constructor() {}
}

export class FetchMoreChatGroupMessages {
  static readonly type =
    '[Chat -> Selected chat] Fetch more the list of selected chat group messages (the scroll fetching calls)';
  constructor() {}
}

export class LoadFeedbacksList {
  static readonly type = '[Chat -> Selected chat] Load feedback smiles list';
  constructor() {}
}

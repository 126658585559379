import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { CompanyBanksStateModel } from './company-banks-state.model';
import {
  DeleteCompanyBankSuccess,
  LoadCompanyBanks,
  SaveCompanyBankFailed,
  SaveCompanyBankSuccess,
  TryDeleteCompanyBank,
  TrySaveCompanyBank,
  MarkCompanyBankAsMain,
} from './company-banks.actions';
import { CompanyBank } from '@app/modules/companies/interfaces/company-bank';
import { UiService } from '@app/services/ui.service';
import { of } from 'rxjs';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { ResponseError } from '@app/interfaces/response-error';
import { CompanyBanksService } from '@app/modules/companies/services/company-banks.service';

@Injectable()
@State<CompanyBanksStateModel>({
  name: 'company_banks',
  defaults: {
    banks: null,
    fieldsErrors: null,
  },
})
export class CompanyBanksState {
  constructor(
    private companyBanksService: CompanyBanksService,
    private uiService: UiService,
    private store: Store
  ) {}

  @Action(TrySaveCompanyBank)
  @ImmutableContext()
  trySaveCompanyBank(
    { setState }: StateContext<CompanyBanksStateModel>,
    { data }: TrySaveCompanyBank
  ) {
    this.uiService.displayLoading();

    setState((state: CompanyBanksStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    const methodName = data.id ? 'update' : 'create';
    return this.companyBanksService[methodName](data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveCompanyBankSuccess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new SaveCompanyBankFailed(
              (err.originalError.error as ResponseError).errors
            )
          );

          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(MarkCompanyBankAsMain)
  @ImmutableContext()
  markCompanyBankAsMain(
    {}: StateContext<CompanyBanksStateModel>,
    { bank }: MarkCompanyBankAsMain
  ) {
    this.uiService.displayLoading();

    return this.companyBanksService.markCompanyBankAsMain(bank).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveCompanyBankSuccess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new SaveCompanyBankFailed(
              (err.originalError.error as ResponseError).errors
            )
          );

          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(SaveCompanyBankFailed)
  @ImmutableContext()
  addMemberFailed(
    { setState }: StateContext<CompanyBanksStateModel>,
    { errors }: SaveCompanyBankFailed
  ) {
    setState((state: CompanyBanksStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }

  @Action(LoadCompanyBanks)
  @ImmutableContext()
  loadCompanyBanks(
    { setState }: StateContext<CompanyBanksStateModel>,
    { companyId }: LoadCompanyBanks
  ) {
    return this.companyBanksService.list(companyId).pipe(
      tap((banks: CompanyBank[]) => {
        setState((state: CompanyBanksStateModel) => {
          state.banks = banks;
          return state;
        });
      })
    );
  }

  @Action(TryDeleteCompanyBank)
  @ImmutableContext()
  tryDeleteCompanyBank(
    {}: StateContext<CompanyBanksStateModel>,
    { companyId, bankId }: TryDeleteCompanyBank
  ) {
    return this.companyBanksService.delete(companyId, bankId).pipe(
      tap(() => {
        this.store.dispatch(new DeleteCompanyBankSuccess());
      })
    );
  }
}

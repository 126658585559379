import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { DriversService } from '@app/modules/drivers/services/drivers.service';
import { ProfileDocumentsStateModel } from './profile_documents-state.model';
import {
  LoadDriver,
  UpdateDriverPassportData,
} from './profile_documents.actions';
import { DriverInterface } from '@app/modules/drivers/interfaces/driver.interface';

@Injectable()
@State<ProfileDocumentsStateModel>({
  name: 'profile__documents',
  defaults: {
    driver: null,
  },
})
export class ProfileDocumentsState {
  constructor(private driversService: DriversService) {}

  @Action(LoadDriver)
  @ImmutableContext()
  loadDriver(
    { setState }: StateContext<ProfileDocumentsStateModel>,
    { id }: LoadDriver
  ) {
    return this.driversService.get(id).pipe(
      tap((driver: DriverInterface) => {
        setState((state: ProfileDocumentsStateModel) => {
          state.driver = driver;
          return state;
        });
      })
    );
  }

  @Action(UpdateDriverPassportData)
  @ImmutableContext()
  updateDriverPassportData(
    { setState }: StateContext<ProfileDocumentsStateModel>,
    { id, data }: UpdateDriverPassportData
  ) {
    return this.driversService.updatePassportData(id, data).pipe(
      tap((driver: DriverInterface) => {
        setState((state: ProfileDocumentsStateModel) => {
          state.driver = driver;
          return state;
        });
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { Country } from './interfaces/country';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<Country[]> {
    return this.apiService.getAll<Country>('/country');
  }
}

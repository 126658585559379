import { Selector } from '@ngxs/store';
import { CargoTendersListState } from './cargo-tenders_list.state';
import { CargoTendersListStateModel } from './cargo-tenders_list-state.model';
import { CargoTendersListViews } from '@app/pages/cargo-tenders/list/cargo-tenders-list-views';

export class CargoTendersListSelectors {
  @Selector([CargoTendersListState])
  static newTendersCount(state: CargoTendersListStateModel): number {
    return state.newTendersCount;
  }

  @Selector([CargoTendersListState])
  static page(state: CargoTendersListStateModel): number {
    return state.page;
  }

  @Selector([CargoTendersListState])
  static maxPage(state: CargoTendersListStateModel): number {
    return state.maxPage;
  }

  @Selector([CargoTendersListState])
  static searchValue(state: CargoTendersListStateModel): string {
    return state.searchValue;
  }

  @Selector([CargoTendersListState])
  static view(state: CargoTendersListStateModel): CargoTendersListViews {
    return state.view;
  }
}

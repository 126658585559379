import { UserProfileCompaniesFilters } from '@app/pages/profile/tab4/interfaces/user-profile-companies-filters';

export class SetUserProfileCompaniesFilters {
  static readonly type = '[User Profile -> Companies] Set filters (pagination)';
  constructor(public filters: UserProfileCompaniesFilters) {}
}

export class LoadProfileCompanies {
  static readonly type = '[User Profile -> Companies] Load profile companies';
}

import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { Company } from '@app/modules/companies/interfaces/company';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileCompaniesService {
  constructor(private apiService: ApiService) {}

  getUserCompanies(
    userId: number,
    page: number = 1
  ): Observable<PaginationResults<Company>> {
    return this.apiService.getPaginatedResults<Company>(
      '/company/by-user/' + userId,
      { expand: ['details'].join(','), page }
    );
  }
}

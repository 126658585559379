import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  CreateCargoTender,
  CreateTransportTender,
  ViewTransportTender,
  LoadCompanies,
  ChangeCompany,
  ListTransportTenders,
  ChangeCompanySuccess,
  ViewWarehouse,
  CreateWarehouse,
  ListWarehouses,
} from './tabs.actions';
import { TabsStateModel } from './tabs-state.model';
import { Injectable } from '@angular/core';
import { Company } from '@app/modules/companies/interfaces/company';
import { takeUntil, tap } from 'rxjs/operators';
import { ProfileService } from '@app/modules/profile/services/profile.service';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { UsersService } from '@app/modules/users/services/users.service';
import {
  AuthSelectors,
  LoadTransportTender,
  PrimaryBlockSetCargoTendersView,
  PrimaryBlockSetTransportTendersView,
  PrimaryBlockSetWarehousesView,
  SaveAccessAndRefreshTokens,
} from '@app/store';
import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';
import { LoadWarehouse } from '@app/store/warehouses';
import { PrimaryBlockWarehousesViews } from '@app/pages/tabs/components/primary-block-warehouses/primary-block-warehouses-views';

@Injectable()
@State<TabsStateModel>({
  name: 'tabs',
  defaults: {
    companies: [],
  },
})
export class TabsState {
  constructor(
    private profileService: ProfileService,
    private store: Store,
    private usersService: UsersService
  ) {}

  @Action(ListTransportTenders)
  @ImmutableContext()
  listTransportTenders({}: StateContext<TabsStateModel>) {
    this.store.dispatch(
      new PrimaryBlockSetTransportTendersView(
        PrimaryBlockTransportTendersViews.LIST
      )
    );
  }

  @Action(ListWarehouses)
  @ImmutableContext()
  listWarehouses({}: StateContext<TabsStateModel>) {
    this.store.dispatch(
      new PrimaryBlockSetWarehousesView(PrimaryBlockWarehousesViews.LIST)
    );
  }

  @Action(CreateCargoTender)
  @ImmutableContext()
  createCargoTender({}: StateContext<TabsStateModel>) {
    this.store.dispatch(
      new PrimaryBlockSetCargoTendersView(PrimaryBlockCargoTendersViews.CREATE)
    );
  }

  @Action(CreateTransportTender)
  @ImmutableContext()
  createTransportTender({}: StateContext<TabsStateModel>) {
    this.store.dispatch(
      new PrimaryBlockSetTransportTendersView(
        PrimaryBlockTransportTendersViews.CREATE
      )
    );
  }

  @Action(CreateWarehouse)
  @ImmutableContext()
  createWarehouse({}: StateContext<TabsStateModel>) {
    this.store.dispatch(
      new PrimaryBlockSetWarehousesView(PrimaryBlockWarehousesViews.CREATE)
    );
  }

  @Action(ViewTransportTender)
  @ImmutableContext()
  viewTransportTender(
    {}: StateContext<TabsStateModel>,
    { tenderId, tenderNumber }: ViewTransportTender
  ) {
    this.store.dispatch(new LoadTransportTender(tenderId, tenderNumber));
  }

  @Action(ViewWarehouse)
  @ImmutableContext()
  viewWarehouse({}: StateContext<TabsStateModel>, { id }: ViewWarehouse) {
    this.store.dispatch(new LoadWarehouse(id));
  }

  @Action(LoadCompanies)
  @ImmutableContext()
  loadCompanies(
    { setState }: StateContext<TabsStateModel>,
    { destroy$ }: LoadCompanies
  ) {
    return this.usersService
      .getMyCompanies()
      .pipe(takeUntil(destroy$))
      .pipe(
        tap((companies: Company[]) => {
          setState((state: TabsStateModel) => {
            state.companies = companies;
            return state;
          });
        })
      );
  }

  @Action(ChangeCompany)
  @ImmutableContext()
  changeCompany(
    {}: StateContext<TabsStateModel>,
    { companyId }: ChangeCompany
  ) {
    if (this.store.selectSnapshot(AuthSelectors.isAuthenticated)) {
      const prevComapnyID: number = this.store.selectSnapshot(
        AuthSelectors.company
      )?.id;
      return this.profileService.setCompany(companyId).pipe(
        tap((newAccessTokenResponse: NewAccessTokenResponse) => {
          this.store.dispatch(
            new SaveAccessAndRefreshTokens(newAccessTokenResponse)
          );
          this.store.dispatch(
            new ChangeCompanySuccess(prevComapnyID, companyId)
          );
        })
      );
    }
  }
}

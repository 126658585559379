import { Selector } from '@ngxs/store';
import { ChatContactsStateModel } from './chat_contacts-state.model';
import { ChatContactsState } from './chat_contacts.state';

export class ChatContactsSelectors {
  @Selector([ChatContactsState])
  static chatContacts(state: ChatContactsStateModel) {
    return state.chatContacts;
  }

  @Selector([ChatContactsState])
  static chatContactsSearchValue(state: ChatContactsStateModel): string {
    return state.chatContactsSearchValue;
  }

  @Selector([ChatContactsState])
  static searchContact(state: ChatContactsStateModel) {
    return state.searchContact;
  }

  @Selector([ChatContactsState])
  static searchResult(state: ChatContactsStateModel) {
    return state.searchResult;
  }

  @Selector([ChatContactsState])
  static groupSearchContact(state: ChatContactsStateModel) {
    return state.groupSearchContact;
  }

  @Selector([ChatContactsState])
  static groupSearchResult(state: ChatContactsStateModel) {
    return state.groupSearchResult;
  }
}

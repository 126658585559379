import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { TransportAddEditStateModel } from './transport_add-edit-state.model';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import {
  InitAddEditTransportForm,
  TrySaveTransport,
  SaveTransportSucceess,
  SaveTransportFailed,
  SaveAddTransportFormData,
} from './transport_add-edit.actions';
import { UiService } from '@app/services/ui.service';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { TransportService } from '@app/modules/transport/services/transport.service';
import { UsersService } from '@app/modules/users/services/users.service';
import { Company } from '@app/modules/companies/interfaces/company';

@Injectable()
@State<TransportAddEditStateModel>({
  name: 'transport__add_edit',
  defaults: {
    companies: [],
    fieldsErrors: null,
    formData: null,
  },
})
export class TransportAddEditState {
  constructor(
    private store: Store,
    private transportService: TransportService,
    private uiService: UiService,
    private usersService: UsersService
  ) {}

  @Action(InitAddEditTransportForm)
  @ImmutableContext()
  initAddeditTransportForm({
    setState,
  }: StateContext<TransportAddEditStateModel>) {
    const companies$ = this.usersService.getMyCompanies();

    return companies$.pipe(
      tap((companies: Company[]) => {
        setState((state: TransportAddEditStateModel) => {
          state.companies = companies;
          state.fieldsErrors = null;
          return state;
        });
      })
    );
  }

  @Action(TrySaveTransport)
  @ImmutableContext()
  trySaveTransport(
    { setState }: StateContext<TransportAddEditStateModel>,
    { transport }: TrySaveTransport
  ) {
    setState((state: TransportAddEditStateModel) => {
      state.fieldsErrors = null;
      state.formData = null;
      return state;
    });

    this.uiService.displayLoading();
    return this.transportService.save(transport).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveTransportSucceess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(new SaveTransportFailed(err.originalError.error));
        }
        return of(null);
      })
    );
  }

  @Action(SaveTransportFailed)
  @ImmutableContext()
  saveTransportFailed(
    { setState }: StateContext<TransportAddEditStateModel>,
    { errors }: SaveTransportFailed
  ) {
    setState((state: TransportAddEditStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }

  @Action(SaveAddTransportFormData)
  @ImmutableContext()
  saveAddTransportFormData(
    { setState }: StateContext<TransportAddEditStateModel>,
    { transport }: SaveAddTransportFormData
  ) {
    setState((state: TransportAddEditStateModel) => {
      state.formData = transport;
      return state;
    });
  }
}

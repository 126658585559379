import { CalendarTaskPriorities } from '@app/pages/calendar/calendar-task-priorities';

export enum TasksCalendarDayTasksPriorities {
  NONE = -3,
  COMPLETED = -2,
  UNKNOWN = -1,
  LOW = CalendarTaskPriorities.LOW,
  MEDIUM = CalendarTaskPriorities.MEDIUM,
  HIGH = CalendarTaskPriorities.HIGH,
}

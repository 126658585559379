import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { WeatherStateModel } from './weather.model';
import {
  SelectCity,
  LoadWeatherData,
  LoadUserCities,
  WeatherWidgetAddCity,
  WeatherWidgetRemoveCity,
} from './weather.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { tap } from 'rxjs/operators';
import { WeatherService } from '@app/modules/weather/services/weather.service';
import { WeatherData } from '@app/modules/weather/interfaces/weather-data';
import { UserCity } from '@app/modules/weather/interfaces/user-city';

@Injectable()
@State<WeatherStateModel>({
  name: 'widgets__weather',
  defaults: {
    weather: null,
    userCities: null,
    selectedCity: null,
  },
})
export class WeatherState {
  constructor(private weatherService: WeatherService) {}

  @Action(SelectCity)
  @ImmutableContext()
  selectCity(
    { setState }: StateContext<WeatherStateModel>,
    { city }: SelectCity
  ) {
    setState((state: WeatherStateModel) => {
      state.selectedCity = city;
      return state;
    });
  }

  @Action(LoadWeatherData, { cancelUncompleted: true })
  @ImmutableContext()
  loadWeatherData({ getState, setState }: StateContext<WeatherStateModel>) {
    return this.weatherService.get(getState().selectedCity?.city).pipe(
      tap((weather: WeatherData) => {
        return setState((state: WeatherStateModel) => {
          state.weather = weather;
          return state;
        });
      })
    );
  }

  @Action(LoadUserCities)
  @ImmutableContext()
  loadUserCities({ setState }: StateContext<WeatherStateModel>) {
    return this.weatherService.getUserCities().pipe(
      tap((userCities: UserCity[]) => {
        return setState((state: WeatherStateModel) => {
          state.userCities = userCities;
          return state;
        });
      })
    );
  }

  @Action(WeatherWidgetAddCity)
  @ImmutableContext()
  weatherWidgetAddCity(
    { setState }: StateContext<WeatherStateModel>,
    { city }: WeatherWidgetAddCity
  ) {
    return this.weatherService.addCity(city).pipe(
      tap((userCity: UserCity) => {
        setState((state: WeatherStateModel) => {
          state.userCities.push(userCity);
          return state;
        });
      })
    );
  }

  @Action(WeatherWidgetRemoveCity)
  @ImmutableContext()
  weatherWidgetRemoveCity(
    { setState }: StateContext<WeatherStateModel>,
    { city }: WeatherWidgetRemoveCity
  ) {
    setState((state: WeatherStateModel) => {
      if (state.selectedCity?.id === city.id) {
        state.selectedCity = null;
      }
      state.userCities = state.userCities.filter((userCity: UserCity) => {
        return userCity.id !== city.id;
      });
      return state;
    });

    return this.weatherService.removeCity(city);
  }
}

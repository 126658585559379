import { Injectable } from '@angular/core';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportBet } from '@app/modules/transport-bets/interfaces/transport-bet';
import { ConfirmTransportTenderFormData } from '@app/pages/confirm-transport-tender/interfaces/confirm-transport-tender.form-data';
import { EditTransportTenderFormData } from '@app/pages/transport-tender/view/interfaces/edit-transport-tender.form-data';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { ImportResponse } from '@app/modules/transport-tender/interfaces/import-response';
import { ProgressStatuses } from '@app/modules/transport-tender/interfaces/progress-status';
import { EntityStatuses } from '@app/modules/entity/entity-statuses';
import { TransportTendersListFiltersFormData } from '@app/pages/transport-tender/list/components/filters-form/transport-tenders-list-filters.form-data';
import { DatePeriod } from '@app/interfaces/date-period';
import { CargoTendersListFiltersFormData } from '@app/pages/cargo-tenders/list/components/filters-form/cargo-tenders-list-filters.form-data';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { ResultsWithFilters } from '@app/interfaces/results-with-filters';
import { CompanyTendersTypes } from '@app/pages/companies/view/company-tenders/company-tenders-types';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { CompanyTendersSummary } from '@app/modules/companies/interfaces/company-tenders-summary';

@Injectable({
  providedIn: 'root',
})
export class TransportTenderService {
  constructor(private apiService: ApiService) {}

  getTenders(
    page: number = 1,
    search: string = null,
    filters: TransportTendersListFiltersFormData
  ): Observable<PaginationResults<TransportTender>> {
    const data: any = {
      page,
      fields: [
        'winnerBet.companyId',
        'state.code',
        'number',
        'betsCount',
        'kpi',
        'company',
        'additionalData.route_id',
        'sourcePoint.place.country.code',
        'sourcePoint.place.country.country',
        'sourcePoint.place.type.code',
        'sourcePoint.place.address.short',
        'sourcePoint.place.square.number',
        'status.code',
        'expiresIn',
        'date.readyAt',
        'date.progressStatusUpdatedAt',
        'progressStatus.code',
        'id',
        'transport.transportType.transport',
        'trailer.transportType.transport',
        'transport.tonnage',
        'trailer.tonnage',
        'loading',
        'transport.number',
        'trailer.number',
        'winnerBet.targetPointId',
        'targetPoints.id',
        'targetPoints.place.country.code',
        'targetPoints.place.country.country',
        'targetPoints.place.type.code',
        'targetPoints.place.address.short',
        'targetPoints.place.square.number',
        'targetPoints.targetPointRadius',
        'targetPoints.startPrice',
        'currency.code',
        'targetPoints.bet.max.price.sum',
        'transport.companyId',
        'trailer.companyId',
        'targetPoints.bet.current.cargo.typeId',
        'targetPoints.bet.current.price.sum',
        'targetPoints.bet.current.cargo.name',
        'targetPoints.bet.current.cargo.weight',
        'targetPoints.bet.current.palletsCount',
        'targetPoints.bet.current.cargo.price',
        'targetPoints.bet.current.transportWarmingType',
        'targetPoints.bet.current.temperatureMode',
        'targetPoints.bet.current.date.deliveryDate',
        'targetPoints.bet.current.date.unloadingDate',
        'targetPoints.bet.current.sourcePoint.address',
        'targetPoints.bet.current.sourcePoint.latitude',
        'targetPoints.bet.current.sourcePoint.longitude',
        'targetPoints.bet.current.targetPoint.address',
        'targetPoints.bet.current.targetPoint.latitude',
        'targetPoints.bet.current.targetPoint.longitude',
        'targetPoints.bet.max.price.sum',
        'targetPoints.id',
        'chatId',
        'unreadMessageCount',
        'isNeedAction',
        'isNew',
        'isRead',
        'isFavorite',
        'driver.first_name',
        'driver.middle_name',
        'driver.last_name',
      ].join(','),
      expand: [
        'currency',
        'winnerBet',
        'targetPoints.bet.current',
        'targetPoints.bet.max',
        'transport.transportType',
        'trailer.transportType',
        'driver',
      ].join(','),
      ...filters,
    };

    if (search !== null) {
      data.search = search;
    }

    return this.apiService.getPaginatedResults<TransportTender>(
      '/exchange/tender',
      data
    );
  }

  getTender(id?: number, number?: string): Observable<TransportTender> {
    const endpoint = id
      ? '/exchange/tender/' + id
      : '/exchange/tender/by-number/' + number;
    return this.apiService.get<TransportTender>(endpoint, {
      fields: [
        'isClosedBets',
        'tenderers',
        'loading',
        'id',
        'expiresIn',
        'date.validUntil',
        'pointRadius',
        'targetPoints.place.type.code',
        'targetPoints.place.address.short',
        'targetPoints.place.country.id',
        'targetPoints.place.coords.lat',
        'targetPoints.place.coords.lng',
        'targetPoints.place.square.id',
        'targetPoints.targetPointRadius',
        'targetPoints.startPrice',
        'transport.companyId',
        'transport.tonnage',
        'transport.transportType.transport',
        'transports.transportId',
        'trailer.companyId',
        'trailer.tonnage',
        'trailer.transportType.transport',
        'trailer.transportId',
        'status.code',
        'state.code',
        'progressStatus.code',
        'kpi',
        'date.readyAt',
        'date.validUntil',
        'sourcePoint.place.coords.lat',
        'sourcePoint.place.coords.lng',
        'sourcePoint.place.country.id',
        'sourcePoint.place.country.code',
        'sourcePoint.place.country.country',
        'sourcePoint.place.type.code',
        'sourcePoint.place.address.short',
        'sourcePoint.place.square.id',
        'sourcePoint.place.square.number',
        'winnerBet.targetPointId',
        'targetPoints.bet.max.price.sum',
        'targetPoints.bet.current.cargo.typeId',
        'targetPoints.bet.current.price.sum',
        'targetPoints.bet.current.cargo.weight',
        'targetPoints.bet.current.cargo.packagingTypeId',
        'targetPoints.bet.current.palletsCount',
        'targetPoints.bet.current.cargo.price',
        'targetPoints.bet.current.cargo.name',
        'targetPoints.bet.current.transportWarmingType',
        'targetPoints.bet.current.temperatureMode',
        'targetPoints.bet.current.description',
        'targetPoints.bet.current.date.unloadingDate',
        'targetPoints.bet.current.sourcePoint.address',
        'targetPoints.bet.current.sourcePoint.latitude',
        'targetPoints.bet.current.sourcePoint.longitude',
        'targetPoints.bet.current.targetPoint.address',
        'targetPoints.bet.current.targetPoint.latitude',
        'targetPoints.bet.current.targetPoint.longitude',
        'targetPoints.bet.current.markupPercent',
        'targetPoints.bet.current.carrierPrice.price',
        'targetPoints.id',
        'targetPoints.distance',
        'targetPoints.distance2Gis',
        'targetPoints.pricePerKm2Gis',
        'targetPoints.place.country.code',
        'targetPoints.place.country.country',
        'targetPoints.place.square.number',
        'winner.winnerBetId',
        'targetPoints.activeBets.companyId',
        'targetPoints.activeBets.id',
        'targetPoints.activeBets.tenderId',
        'targetPoints.activeBets.company.details.companyName.short',
        'targetPoints.activeBets.user.id',
        'targetPoints.activeBets.user.avatar',
        'targetPoints.activeBets.user.first_name',
        'targetPoints.activeBets.user.middle_name',
        'targetPoints.activeBets.user.last_name',
        'targetPoints.activeBets.user.isContact',
        'targetPoints.activeBets.user.isBlacklist',
        'targetPoints.activeBets.user.phone',
        'targetPoints.activeBets.user.email',
        'targetPoints.activeBets.date.createdAt',
        'targetPoints.activeBets.price.sum',
        'targetPoints.activeBets.cargo.type.name',
        'targetPoints.activeBets.cargo.name',
        'targetPoints.activeBets.cargo.weight',
        'targetPoints.activeBets.cargo.packagingType.name',
        'targetPoints.activeBets.palletsCount',
        'targetPoints.activeBets.cargo.price',
        'targetPoints.activeBets.transportWarmingType',
        'targetPoints.activeBets.temperatureMode', // null - backend bug
        'targetPoints.activeBets.description',
        'targetPoints.activeBets.date.deliveryDate',
        'targetPoints.activeBets.date.unloadingDate',
        'targetPoints.activeBets.sourcePoint.address',
        'targetPoints.activeBets.targetPoint.address',
        'currency.icon',
        'additionalData',
        // user object should be null, if companies are not partners
        'user.id',
        'user.first_name',
        'user.middle_name',
        'user.last_name',
        'user.avatar',
        'user.isContact',
        'user.isBlacklist',
        'user.phone',
        'unreadMessageCount',
        'winnerBet.companyId',
        'number',
        'driver.id',
        'driver.first_name',
        'driver.middle_name',
        'driver.last_name',
        'loadingType.transport',
        'isVatBet',
        'rootDirectoryId',
      ].join(','),
      expand: [
        'targetPoints.activeBets.company.details',
        'targetPoints.activeBets.user',
        'targetPoints.activeBets.cargo.type',
        'targetPoints.activeBets.cargo.packagingType',
        'targetPoints.bet.max',
        'targetPoints.bet.current',
        'currency',
        'transport.transportType',
        'trailer.transportType',
        'winnerBet',
        'user',
        'driver',
        'loadingType',
      ].join(','),
    });
  }

  setProgressStatus(
    tenderId: number,
    progressStatus: ProgressStatuses
  ): Observable<unknown> {
    return this.apiService.put<Observable<unknown>>(
      '/exchange/tender/change-progress-status',
      {
        tenderId,
        progressStatus,
      }
    );
  }

  loadTransportPoints(
    state: TenderStates = TenderStates.ACTIVE,
    createdAt: DatePeriod = null
  ): Observable<TransportTender[]> {
    const data: any = {
      expand: [
        'sourcePoint',
        'sourcePoint.country',
        'sourcePoint.square',
        'targetPoints',
        'targetPoints.country',
        'targetPoints.bet',
        'targetPoints.square',
        'transport',
        'transport.transportType',
      ].join(','),
    };

    data.state = state;

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }

    data.status = EntityStatuses.ACTIVE;
    data['per-page'] = 100;

    return this.apiService.getAll<TransportTender>('/exchange/tender', data);
  }

  getTenderBetInfo(tender_id: number): Observable<TransportTender> {
    return this.apiService.get<TransportTender>(
      '/exchange/tender/' + tender_id,
      {
        fields: ['betsCount', 'targetPoints'].join(','),
        expand: [
          'targetPoints',
          'targetPoints.country',
          'targetPoints.bet.max',
          'targetPoints.bet.current',
          'targetPoints.bets.cargoType',
          'targetPoints.bets.user',
          'targetPoints.activeBets.cargo.type',
          'targetPoints.activeBets.user',
          'targetPoints.activeBets.company.details',
          'targetPoints.square',
        ].join(','),
      }
    );
  }

  getBetsHistory(
    targetPointId: number,
    page: number
  ): Observable<PaginationResults<TransportBet>> {
    return this.apiService.getPaginatedResults<TransportBet>('/exchange/bet', {
      targetPointId,
      page,
      expand: ['company', 'company.details'].join(','),
    });
  }

  confirmTransportTender(
    tenderId: number,
    data: ConfirmTransportTenderFormData
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/exchange/tender/' + tenderId + '/confirm',
      data
    );
  }

  public import(data: string): Observable<ImportResponse> {
    return this.apiService
      .post<ResponseSuccess<ImportResponse>>('/exchange/tender/import', {
        file: data,
      })
      .pipe(
        map((response: ResponseSuccess<ImportResponse>) => {
          return response.data;
        })
      );
  }

  export(): Observable<unknown> {
    return this.apiService.get<unknown>('/exchange/tender/export');
  }

  updateTender(data: EditTransportTenderFormData): Observable<unknown> {
    const tenderId = data.id;
    delete data.id;
    return this.apiService.put<unknown>('/exchange/tender/' + tenderId, data);
  }

  publish(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/exchange/tender/' + tenderId + '/publish'
    );
  }

  cancel(tenderId: number): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/exchange/tender/' + tenderId + '/cancel'
    );
  }

  prolong(tenderId: number, validUntil: string): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/exchange/tender/' + tenderId + '/prolongation',
      { validUntil }
    );
  }

  publishAll(): Observable<unknown> {
    return this.apiService.put<unknown>('/exchange/tender/publish-all');
  }

  markTenderAsRead(tenderId: number): Observable<unknown> {
    return this.apiService.post<unknown>('/exchange/tender/view', {
      tenderId,
    });
  }

  delete(tenderId: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/exchange/tender/' + tenderId);
  }

  listOnTendersPage(
    page: number = 1,
    filters: CargoTendersListFiltersFormData = {},
    isBusinessPad = false
  ): Observable<
    PaginationResults<TransportTender, FilteredTendersResultsCount>
  > {
    const data: any = {
      page,
      fields: [
        'id',
        'number',
        'company.id',
        'company.details.companyName.short',
        'transport.number',
        'winnerBet.cargo.weight',
        'winnerBet.palletsCount',
        'sourcePoint.place.address.short',
        'sourcePoint.place.country.country',
        'winnerBet.targetPoint.address',
        'currency.icon',
        'winnerBet.targetPoint.startPrice',
        'state.name',
        'company.curators.user.id',
        'company.curators.user.first_name',
        'company.curators.user.middle_name',
        'company.curators.user.last_name',
        'winnerBet.company.curators.user.id',
        'winnerBet.company.curators.user.first_name',
        'winnerBet.company.curators.user.middle_name',
        'winnerBet.company.curators.user.last_name',
        'winnerBet.price.sum',
      ].join(','),
      expand: [
        'company.details',
        'company.curators.user',
        'transport',
        'winnerBet.company.curators.user',
        'winnerBet.targetPoint',
        'currency',
      ].join(','),
      ...filters,
    };

    return this.apiService
      .get<ResultsWithFilters<TransportTender, FilteredTendersResultsCount>>(
        '/exchange/tender' + (isBusinessPad ? '/business-pad' : ''),
        data
      )
      .pipe(map(this.convertResultsWithFiltersToPaginationResults));
  }

  private convertResultsWithFiltersToPaginationResults(
    response: ResultsWithFilters<TransportTender, FilteredTendersResultsCount>
  ): PaginationResults<TransportTender, FilteredTendersResultsCount> {
    return {
      currentPage: response._meta?.currentPage,
      pageCount: response._meta?.pageCount,
      perPage: response._meta?.perPage,
      totalCount: response._meta?.totalCount,
      maxPage: response._meta ? response._meta.pageCount : 1,
      data: response.dataModels,
      filter: response.filter,
    };
  }

  getCompanyTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    const params: any = {};

    if (page > 1) {
      params.page = page;
    }

    if (datePeriod?.from) {
      params.createdAtFrom = datePeriod.from;
    }

    if (datePeriod?.to) {
      params.createdAtTo = datePeriod.to;
    }

    if (state) {
      params.state = state;
    }

    const apiEndpointPathPart =
      companyTendersTypes === CompanyTendersTypes.CREATED
        ? 'for-company'
        : 'for-company-carrier';

    return this.apiService
      .get<
        ResultsWithFilters<
          CompanyTender,
          FilteredTendersResultsCount,
          CompanyTendersSummary
        >
      >('/exchange/tender/' + apiEndpointPathPart + '/' + companyId, params)
      .pipe(
        map(
          (
            response: ResultsWithFilters<
              CompanyTender,
              FilteredTendersResultsCount,
              CompanyTendersSummary
            >
          ) => {
            return {
              currentPage: response._meta?.currentPage,
              pageCount: response._meta?.pageCount,
              perPage: response._meta?.perPage,
              totalCount: response._meta?.totalCount,
              maxPage: response._meta ? response._meta.pageCount : 1,
              data: response.dataModels,
              filter: response.filter,
              summary: response.summary,
            };
          }
        )
      );
  }

  updateDriver(tenderId: number, driverId: number): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/exchange/tender/' + tenderId + '/change-driver',
      { driverId }
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '@app/modules/auth/auth.service';
import { Unauthorized } from '@app/errors/response-errors/unauthorized';
import { AuthState, SaveAccessAndRefreshTokens } from '@app/store';
import { AuthStateModel } from '@app/store/auth/auth-state.model';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(AuthState).pipe(
      mergeMap((state: AuthStateModel) => {
        if (state.token) {
          const jwtHelper = new JwtHelperService();

          if (!jwtHelper.isTokenExpired(state.token)) {
            return of(true);
          }

          return this.authService.renewAccessToken(state.refreshToken).pipe(
            map((response: any) => {
              this.store.dispatch(new SaveAccessAndRefreshTokens(response));
              return true;
            }),
            catchError((error: any) => {
              if (error instanceof Unauthorized) {
                this.store.dispatch(
                  new SaveAccessAndRefreshTokens({
                    token: null,
                    refreshToken: null,
                  })
                );
                return of(false);
              }
              throwError(() => error);
            })
          );
        }
        return of(false);
      })
    );
  }
}

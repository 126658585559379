import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { Selector } from '@ngxs/store';
import { CargoTendersViewSeaStateModel } from './cargo-tenders-view_sea-state.model';
import { CargoTendersViewSeaState } from './cargo-tenders-view_sea.state';

export class CargoTendersViewSeaSelectors {
  @Selector([CargoTendersViewSeaState])
  static tender(state: CargoTendersViewSeaStateModel): SeaCargoTender {
    return state.tender;
  }
}

import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CargoTendersListFiltersFormData } from '@app/pages/cargo-tenders/list/components/filters-form/cargo-tenders-list-filters.form-data';
import { IonicSelectableComponent } from 'ionic-selectable';

export class ShowCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Show (display) filters';
  constructor() {}
}

export class HideCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Hide filters';
  constructor() {}
}

export class SetCargoTendersTransportationType {
  static readonly type =
    '[Cargo tenders list filters] Set cargo tenders transportation type';
  constructor(
    public cargoTendersTransportationType: CargoTendersTransportationTypes
  ) {}
}

export class ResetCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Reset filters';
  constructor() {}
}

export class ApplyCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Apply filters';
  constructor() {}
}

export class SetCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Set filters value';
  constructor(public filters: CargoTendersListFiltersFormData) {}
}

export class SetCargoTendersListFilteredResultsCount {
  static readonly type =
    '[Cargo tenders list filters] Set cargo tenders list filteres results count';
  constructor(public filteredResultsCount: FilteredTendersResultsCount) {}
}

export class SearchCompanyMembersInCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Search company member';
  constructor(
    public component: IonicSelectableComponent,
    public searchValue: string
  ) {}
}

export class SearchPartnerInCargoTendersListFilters {
  static readonly type = '[Cargo tenders list filters] Search partner';
  constructor(
    public component: IonicSelectableComponent,
    public searchValue: string
  ) {}
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { ContainerType } from '@app/modules/container-types/interfaces/container-type';

@Injectable({
  providedIn: 'root',
})
export class ContainerTypesService {
  constructor(private apiService: ApiService) {}

  list(): Observable<ContainerType[]> {
    return this.apiService.getAll<ContainerType>('/container-type');
  }
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  CloseCarCargoTender,
  RefuseCarCargoTender,
  LoadCarCargoTenderTransportAndDriversList,
  CompleteCarCargoTender,
  SetCarCargoTenderPointState,
  CancelCarCargoTender,
  NewBetPlacedInCarCargoTender,
  ToggleCanChangeTransportInCarCargoTender,
  SetCarCargoTenderInProgress,
  DeleteCarCargoTender,
  UpdateCarCargoPointArrivalTime,
  SetCarCargoTenderStateCargoDelivered,
  SetCarCargoTenderContainerNumber,
} from './cargo-tenders-view_car.actions';
import { CargoTendersViewCarStateModel } from './cargo-tenders-view_car-state.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { combineLatest, Observable } from 'rxjs';
import { AuthSelectors } from '@app/store';
import {
  SelectCarCargoTenderBet,
  CargoTenderBetSelected,
} from '@app/store/cargo-tenders/cargo-tenders.actions';
import { User } from '@app/modules/users/interfaces/user';
import { TransportService } from '@app/modules/transport/services/transport.service';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import { Transport } from '@app/modules/transport/interfaces/transport';
import { ViewCarCargoTender } from '@app/store/tabs/tabs.actions';
import { TenderPoint } from '@app/modules/points/interfaces/tender-point';
import { SetWinBet } from '../cargo-tenders_view.actions';

@Injectable()
@State<CargoTendersViewCarStateModel>({
  name: 'cargo_tenders__view__car',
  defaults: {
    tender: null,
    transportList: [],
    drivers: [],
  },
})
export class CargoTendersViewCarState {
  constructor(
    private cargoTendersCarService: CargoTendersCarService,
    private transportService: TransportService,
    private companiesService: CompaniesService,
    private store: Store
  ) {}

  @Action(ViewCarCargoTender)
  @ImmutableContext()
  loadCarCargoTender(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId, tenderNumber }: ViewCarCargoTender
  ) {
    return this.cargoTendersCarService.get(tenderId, tenderNumber).pipe(
      tap((tender: CarCargoTender) => {
        return setState((state: CargoTendersViewCarStateModel) => {
          state.tender = tender;
          return state;
        });
      })
    );
  }

  @Action(CloseCarCargoTender)
  @ImmutableContext()
  closeCarCargoTender(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { id }: CloseCarCargoTender
  ) {
    return this.cargoTendersCarService.closeTender(id);
  }

  @Action(RefuseCarCargoTender)
  @ImmutableContext()
  refuseCarCargoTender(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { id }: RefuseCarCargoTender
  ) {
    return this.cargoTendersCarService.refuseTender(id);
  }

  @Action(CancelCarCargoTender)
  @ImmutableContext()
  cancelCarCargoTender(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { id }: CancelCarCargoTender
  ) {
    return this.cargoTendersCarService.cancelTender(id);
  }

  @Action(CompleteCarCargoTender)
  @ImmutableContext()
  completeCarCargoTender(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { id }: CompleteCarCargoTender
  ) {
    return this.cargoTendersCarService.completeTender(id);
  }

  @Action(SetCarCargoTenderPointState)
  @ImmutableContext()
  setCarCargoTenderPointState(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { pointId, state }: SetCarCargoTenderPointState
  ) {
    return this.cargoTendersCarService.setTenderPointState(pointId, state);
  }

  @Action(NewBetPlacedInCarCargoTender)
  @ImmutableContext()
  newBetPlacedInCarCargoTender(
    { getState, setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: NewBetPlacedInCarCargoTender
  ) {
    if (getState().tender?.id !== tenderId) {
      return;
    }

    return this.cargoTendersCarService.getTenderBetInfo(tenderId).pipe(
      tap((tender: CarCargoTender) => {
        setState((state: CargoTendersViewCarStateModel) => {
          state.tender = { ...state.tender, ...tender };
          return state;
        });
      })
    );
  }

  @Action(LoadCarCargoTenderTransportAndDriversList)
  @ImmutableContext()
  loadCarCargoTenderTransportAndDriversList(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: LoadCarCargoTenderTransportAndDriversList
  ) {
    const transportList$: Observable<Transport[]> =
      this.transportService.getTenderTransportList(tenderId);
    const drivers$: Observable<User[]> = this.companiesService.getDrivers(
      this.store.selectSnapshot(AuthSelectors.company).id
    );

    return combineLatest([transportList$, drivers$]).pipe(
      tap((result: [Transport[], User[]]) => {
        setState((state: CargoTendersViewCarStateModel) => {
          state.transportList = result[0];
          state.drivers = result[1];
          return state;
        });
      })
    );
  }

  @Action(SelectCarCargoTenderBet)
  @ImmutableContext()
  selectCarCargoTenderBet(
    {}: StateContext<CargoTendersViewCarStateModel>,
    { bet }: SelectCarCargoTenderBet
  ) {
    return this.cargoTendersCarService.selectBet(bet).pipe(
      tap((tender: CarCargoTender) => {
        this.store.dispatch(
          new CargoTenderBetSelected({ ...tender, id: bet.tenderId })
        );
      })
    );
  }

  @Action(CargoTenderBetSelected)
  @ImmutableContext()
  cargoTenderBetSelected(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tender }: CargoTenderBetSelected
  ) {
    return setState((state: CargoTendersViewCarStateModel) => {
      state.tender = { ...state.tender, ...tender };
      return state;
    });
  }

  @Action(ToggleCanChangeTransportInCarCargoTender)
  @ImmutableContext()
  toggleCanChangeTransportInCarCargoTender(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: ToggleCanChangeTransportInCarCargoTender
  ) {
    return this.cargoTendersCarService.toggleCanChangeTransport(tenderId).pipe(
      tap(() => {
        return setState((state: CargoTendersViewCarStateModel) => {
          state.tender.canChangeTransport = !state.tender.canChangeTransport;
          return state;
        });
      })
    );
  }

  @Action(SetCarCargoTenderInProgress)
  @ImmutableContext()
  setCarCargoTenderInProgress(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: SetCarCargoTenderInProgress
  ) {
    return this.cargoTendersCarService.setTenderInProgress(tenderId).pipe(
      tap((tender: CarCargoTender) => {
        return setState((state: CargoTendersViewCarStateModel) => {
          state.tender.state = tender.state;

          return state;
        });
      })
    );
  }

  @Action(SetCarCargoTenderStateCargoDelivered)
  @ImmutableContext()
  setCarCargoTenderStateCargoDelivered(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: SetCarCargoTenderStateCargoDelivered
  ) {
    return this.cargoTendersCarService
      .setTenderStateCargoDelivered(tenderId)
      .pipe(
        tap((tender: CarCargoTender) => {
          return setState((state: CargoTendersViewCarStateModel) => {
            state.tender.state = tender.state;
            return state;
          });
        })
      );
  }

  @Action(DeleteCarCargoTender)
  @ImmutableContext()
  deleteCarCargoTender(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId }: DeleteCarCargoTender
  ) {
    return this.cargoTendersCarService.delete(tenderId).pipe(
      tap(() => {
        return setState((state: CargoTendersViewCarStateModel) => {
          state.tender = null;
          return state;
        });
      })
    );
  }

  @Action(UpdateCarCargoPointArrivalTime)
  @ImmutableContext()
  updateCarCargoPointArrivalTime(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { pointID, arrivalTime }: UpdateCarCargoPointArrivalTime
  ) {
    return this.cargoTendersCarService
      .updatePointArrivalTime(pointID, arrivalTime)
      .pipe(
        tap((updatedPoint: TenderPoint) => {
          return setState((state: CargoTendersViewCarStateModel) => {
            const point: TenderPoint = state.tender.points.find(
              (existingPoint: TenderPoint) => {
                return existingPoint.id === updatedPoint.id;
              }
            );

            if (point) {
              point.date = updatedPoint.date;
            }

            return state;
          });
        })
      );
  }

  @Action(SetCarCargoTenderContainerNumber)
  @ImmutableContext()
  SetcarCargoTenderContainerNumber(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { tenderId, containerNumber }: SetCarCargoTenderContainerNumber
  ) {
    return this.cargoTendersCarService
      .setCarCargoTenderContainerNumber(tenderId, containerNumber)
      .pipe(
        tap((tender: CarCargoTender) => {
          return setState((state: CargoTendersViewCarStateModel) => {
            state.tender.containerNumber = tender.containerNumber;
            return state;
          });
        })
      );
  }

  @Action(SetWinBet)
  @ImmutableContext()
  staticetWinBet(
    { setState }: StateContext<CargoTendersViewCarStateModel>,
    { bet }: SetWinBet
  ) {
    return setState((state: CargoTendersViewCarStateModel) => {
      if (state.tender.winBet?.id === bet.id) {
        state.tender.winBet = bet;
      }
      return state;
    });
  }
}

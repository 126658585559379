import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesViewStateModel } from './companies_view-state.model';
import { Injectable } from '@angular/core';
import {
  AddCompanyPartnerFailed,
  AddCompanyPartnerSuccess,
  ConfirmCompanyPartnerFailed,
  ConfirmCompanyPartnerSuccess,
  HideCompanyMembersFilters,
  InitViewCompanyPage,
  LoadCompany,
  LoadCompanyMembers,
  LoadCompanyComments,
  LoadCompanyPartnerships,
  RemoveCompanyPartnerFailed,
  RemoveCompanyPartnerSuccess,
  ToggleCompanyMembersFilters,
  TryAddCompanyPartner,
  TryConfirmCompanyPartner,
  TryRemoveCompanyPartner,
  TrySaveCompanyLimit,
  TrySaveCompanyMarkupSettings,
  UpdateCompanyMembersFilters,
  UpdateCompanyAvatar,
  TryDeleteCompanyContact,
  TryEditCompanyContact,
  TryCreateCompanyContact,
  SaveCompanyContactFailed,
  SaveCompanyContactSuccess,
  CompanyRemoveMember,
  RefreshRequisites,
  SetAutoSignature,
  SetUseUserSignature,
  SaveCompanyType,
  SetCompanyTimeBlocking,
  TryDeleteCompanyLimit,
} from './companies_view.actions';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyContactsService } from '@app/modules/companies/services/company-contacts.service';
import { CompanyContact } from '@app/modules/companies/interfaces/company-contact';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { of } from 'rxjs';
import { CompanyMemberPosition } from '@app/modules/companies/interfaces/company-member-position';
import { CompanyMemberPositionsService } from '@app/modules/companies/services/company-member-positions.service';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyComment } from '@app/modules/companies/interfaces/company-comment';
import { AuthSelectors } from '@app/store/auth';
import { combineLatest } from 'rxjs';
import { User } from '@app/modules/users/interfaces/user';
import { PermissionsService } from '@app/modules/permissions/services/permissions.service';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import { CompanyPartnershipsService } from '@app/modules/companies/services/company-partnerships.service';
import { CompanyCommentsService } from '@app/modules/companies/services/company-comments.service';
import { CompanyMembersService } from '@app/modules/companies/services/company-members.service';
import { CommentTypes } from '@app/modules/comments/comment-types';
import {
  LoadDocumentComments,
  SearchCompanyMember,
  SetCompanyOwner,
  UploadSignature,
  UploadStamp,
} from './companies_view.actions';
import { CompaniesViewCuratorsState } from './curators';
import { CompanyLimitsService } from '@app/modules/companies/services/company-limits.service';
import { CompanyLimit } from '@app/modules/companies/interfaces/company-limit';
import {
  CompaniesViewTendersCreatedCarState,
  CompaniesViewTendersCreatedSeaState,
  CompaniesViewTendersCreatedTransportState,
  CompaniesViewTendersParticipatedCarState,
  CompaniesViewTendersParticipatedSeaState,
  CompaniesViewTendersParticipatedTransportState,
} from './company-tenders';

@Injectable()
@State<CompaniesViewStateModel>({
  name: 'companies__view',
  defaults: {
    company: null,
    displayCompanyMembersFilters: false,
    members: {
      filters: {
        page: 1,
        search: null,
        position: null,
      },
      result: null,
    },
    comments: null,
    documentComments: null,
    positions: [],
    partnerships: null,
    partnershipsMaxPage: null,
    partnershipsSearchValue: null,
    permissions: [],
    changeCompanyMembers: null,
  },
  children: [
    CompaniesViewTendersCreatedCarState,
    CompaniesViewTendersCreatedSeaState,
    CompaniesViewTendersCreatedTransportState,
    CompaniesViewTendersParticipatedCarState,
    CompaniesViewTendersParticipatedSeaState,
    CompaniesViewTendersParticipatedTransportState,
    CompaniesViewCuratorsState,
  ],
})
export class CompaniesViewState {
  constructor(
    private companiesService: CompaniesService,
    private companyPositionsService: CompanyMemberPositionsService,
    private companyContactsService: CompanyContactsService,
    private companyPartnershipsService: CompanyPartnershipsService,
    private permissionsService: PermissionsService,
    private companyCommentsService: CompanyCommentsService,
    private companyMembersService: CompanyMembersService,
    private companyLimitsService: CompanyLimitsService,
    private store: Store
  ) {}

  @Action(InitViewCompanyPage)
  @ImmutableContext()
  initViewCompanyPage(
    { setState }: StateContext<CompaniesViewStateModel>,
    {}: InitViewCompanyPage
  ) {
    return this.companyPositionsService.getAll().pipe(
      tap((positions: CompanyMemberPosition[]) => {
        setState((state: CompaniesViewStateModel) => {
          state.positions = positions;
          return state;
        });
      })
    );
  }

  @Action(LoadCompany)
  @ImmutableContext()
  loadCompaniesList(
    { setState }: StateContext<CompaniesViewStateModel>,
    { companyId }: LoadCompany
  ) {
    const user$ = this.store.selectOnce(AuthSelectors.user);
    return combineLatest([
      user$.pipe(
        mergeMap((user: User) => {
          return this.permissionsService.getUserPermissions(user.id, companyId);
        })
      ),
      this.companiesService.getCompany(companyId),
    ]).pipe(
      tap((result: [Permission[], Company]) => {
        setState((state: CompaniesViewStateModel) => {
          state.permissions = result[0];
          state.company = result[1];
          return state;
        });
      })
    );
  }

  @Action(LoadCompanyMembers)
  @ImmutableContext()
  loadCompanyMembers(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { companyId }: LoadCompanyMembers
  ) {
    return this.companyMembersService
      .getCompanyMembers(companyId, getState().members.filters)
      .pipe(
        tap((result: PaginationResults<CompanyMember>) => {
          const page = getState().members.filters.page ?? 1;
          setState((state: CompaniesViewStateModel) => {
            state.members.result = result;
            return state;
          });
        })
      );
  }

  @Action(LoadCompanyComments)
  @ImmutableContext()
  loadCompanyComments(
    { setState }: StateContext<CompaniesViewStateModel>,
    { companyId }: LoadCompanyComments
  ) {
    return this.companyCommentsService
      .list(companyId, CommentTypes.COMPANY)
      .pipe(
        tap((comments: CompanyComment[]) => {
          setState((state: CompaniesViewStateModel) => {
            state.comments = comments;
            return state;
          });
        })
      );
  }

  @Action(LoadDocumentComments)
  @ImmutableContext()
  loadDocumentComments(
    { setState }: StateContext<CompaniesViewStateModel>,
    { companyId }: LoadDocumentComments
  ) {
    return this.companyCommentsService
      .list(companyId, CommentTypes.COMPANY_DOCUMENT)
      .pipe(
        tap((comments: CompanyComment[]) => {
          setState((state: CompaniesViewStateModel) => {
            state.documentComments = comments;
            return state;
          });
        })
      );
  }

  @Action(TrySaveCompanyLimit)
  @ImmutableContext()
  trySaveCompanyLimit(
    { setState }: StateContext<CompaniesViewStateModel>,
    { data }: TrySaveCompanyLimit
  ) {
    const method = data.id ? 'update' : 'create';
    return this.companyLimitsService[method](data).pipe(
      tap((limit: CompanyLimit) => {
        setState((state: CompaniesViewStateModel) => {
          const companyLimit = state.company.limits.find(
            (someCompanyLimit: CompanyLimit) => {
              return someCompanyLimit.id === limit.id;
            }
          );

          if (companyLimit) {
            companyLimit.financialLimit = limit.financialLimit;
            companyLimit.cargoValueLimit = limit.cargoValueLimit;
          } else {
            state.company.limits.push(limit);
          }

          return state;
        });
      })
    );
  }

  @Action(TryDeleteCompanyLimit)
  @ImmutableContext()
  tryDeleteCompanyLimit(
    { setState }: StateContext<CompaniesViewStateModel>,
    { companyId, companyLimitId }: TryDeleteCompanyLimit
  ) {
    return this.companyLimitsService.delete(companyId, companyLimitId).pipe(
      tap((result: boolean) => {
        if (result) {
          setState((state: CompaniesViewStateModel) => {
            state.company.limits = state.company.limits.filter(
              (someCompanyLimit: CompanyLimit) => {
                return someCompanyLimit.id !== companyLimitId;
              }
            );

            return state;
          });
        }
      })
    );
  }

  @Action(TrySaveCompanyMarkupSettings)
  @ImmutableContext()
  trySaveCompanyMarkupSettings(
    { setState }: StateContext<CompaniesViewStateModel>,
    { data }: TrySaveCompanyMarkupSettings
  ) {
    return this.companiesService.updateCompanyMarkup(data).pipe(
      tap((company: Company) => {
        setState((state: CompaniesViewStateModel) => {
          state.company.vat = company.vat;
          state.company.outsourcing = company.outsourcing;
          state.company.markup = company.markup;
          state.company.insuranceRate = company.insuranceRate;
          state.company.deferredPayment = company.deferredPayment;
          return state;
        });
      })
    );
  }

  @Action(UpdateCompanyAvatar)
  @ImmutableContext()
  updateCompanyAvatar(
    { setState }: StateContext<CompaniesViewStateModel>,
    { avatar }: UpdateCompanyAvatar
  ) {
    return setState((state: CompaniesViewStateModel) => {
      state.company.details.avatar = avatar;
      return state;
    });
  }

  @Action(TryDeleteCompanyContact)
  @ImmutableContext()
  tryDeleteCompanyContact(
    { setState }: StateContext<CompaniesViewStateModel>,
    { contact }: TryDeleteCompanyContact
  ) {
    return this.companyContactsService.delete(contact).pipe(
      tap(() => {
        setState((state: CompaniesViewStateModel) => {
          state.company.contacts = state.company.contacts.filter(
            (existingContact: CompanyContact) => {
              return existingContact.id !== contact.id;
            }
          );
          return state;
        });
      })
    );
  }

  @Action(TryCreateCompanyContact)
  @ImmutableContext()
  tryCreateCompanyContact(
    { setState }: StateContext<CompaniesViewStateModel>,
    { contactData }: TryCreateCompanyContact
  ) {
    return this.companyContactsService.create(contactData).pipe(
      tap((contact: CompanyContact) => {
        this.store.dispatch(new SaveCompanyContactSuccess());
        setState((state: CompaniesViewStateModel) => {
          state.company.contacts.push(contact);
          return state;
        });
      }),
      catchError((err: any) => {
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(new SaveCompanyContactFailed(err));
          return of(null);
        }
      })
    );
  }

  @Action(TryEditCompanyContact)
  @ImmutableContext()
  tryEditCompanyContact(
    { setState }: StateContext<CompaniesViewStateModel>,
    { contactData }: TryEditCompanyContact
  ) {
    return this.companyContactsService.update(contactData).pipe(
      tap((contact: CompanyContact) => {
        this.store.dispatch(new SaveCompanyContactSuccess());
        setState((state: CompaniesViewStateModel) => {
          let i: number = -1;
          state.company.contacts.forEach((c: CompanyContact, index: number) => {
            if (c.id === contact.id) {
              i = index;
            }
          });

          if (i !== -1) {
            state.company.contacts[i] = contact;
          }

          return state;
        });
      }),
      catchError((err: any) => {
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(new SaveCompanyContactFailed(err));
          return of(null);
        }
      })
    );
  }

  @Action(UpdateCompanyMembersFilters)
  @ImmutableContext()
  updateCompanyMembersFilters(
    { setState }: StateContext<CompaniesViewStateModel>,
    { filters }: UpdateCompanyMembersFilters
  ) {
    return setState((state: CompaniesViewStateModel) => {
      const keys = Object.keys(filters);
      keys.forEach((key: string) => {
        state.members.filters[key] = filters[key];
      });

      return state;
    });
  }

  @Action(ToggleCompanyMembersFilters)
  @ImmutableContext()
  toggleCompanyMembersFilters(
    { setState }: StateContext<CompaniesViewStateModel>,
    {}: ToggleCompanyMembersFilters
  ) {
    return setState((state: CompaniesViewStateModel) => {
      state.displayCompanyMembersFilters = !state.displayCompanyMembersFilters;
      return state;
    });
  }

  @Action(HideCompanyMembersFilters)
  @ImmutableContext()
  hideCompanyMembersFilters(
    { setState }: StateContext<CompaniesViewStateModel>,
    {}: HideCompanyMembersFilters
  ) {
    return setState((state: CompaniesViewStateModel) => {
      state.displayCompanyMembersFilters = false;
      return state;
    });
  }

  @Action(LoadCompanyPartnerships)
  @ImmutableContext()
  LoadCompanyPartnerships(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { companyId, search, page }: LoadCompanyPartnerships
  ) {
    if (search !== null) {
      setState((state: CompaniesViewStateModel) => {
        state.partnershipsSearchValue = search;
        return state;
      });
    }

    return this.companyPartnershipsService
      .list(companyId, getState().partnershipsSearchValue, page)
      .pipe(
        tap((results: PaginationResults<CompanyPartnership>) => {
          setState((state: CompaniesViewStateModel) => {
            state.partnerships = results.data;
            state.partnershipsMaxPage = results.maxPage;
            return state;
          });
        })
      );
  }

  @Action(TryAddCompanyPartner)
  @ImmutableContext()
  tryAddCompanyPartner(
    {}: StateContext<CompaniesViewStateModel>,
    { companyId, inn }: TryAddCompanyPartner
  ) {
    return this.companyPartnershipsService
      .sendPartnershipRequest(companyId, inn)
      .pipe(
        tap(() => {
          this.store.dispatch(new AddCompanyPartnerSuccess());
        }),
        catchError((err: any) => {
          this.store.dispatch(new AddCompanyPartnerFailed());
          return err;
        })
      );
  }

  @Action(TryRemoveCompanyPartner)
  @ImmutableContext()
  tryRemoveCompanyPartner(
    {}: StateContext<CompaniesViewStateModel>,
    { companyId, partnerId }: TryRemoveCompanyPartner
  ) {
    return this.companyPartnershipsService
      .cancelPartnership(companyId, partnerId)
      .pipe(
        tap(() => {
          this.store.dispatch(new RemoveCompanyPartnerSuccess());
        }),
        catchError((err: any) => {
          this.store.dispatch(new RemoveCompanyPartnerFailed());
          return err;
        })
      );
  }

  @Action(TryConfirmCompanyPartner)
  @ImmutableContext()
  tryConfirmCompanyPartner(
    {}: StateContext<CompaniesViewStateModel>,
    { companyId, partnerId }: TryConfirmCompanyPartner
  ) {
    return this.companyPartnershipsService
      .confirmPartnership(companyId, partnerId)
      .pipe(
        tap(() => {
          this.store.dispatch(new ConfirmCompanyPartnerSuccess());
        }),
        catchError((err: any) => {
          this.store.dispatch(new ConfirmCompanyPartnerFailed());
          return err;
        })
      );
  }

  @Action(CompanyRemoveMember)
  @ImmutableContext()
  companyRemoveMember(
    {}: StateContext<CompaniesViewStateModel>,
    { member }: CompanyRemoveMember
  ) {
    return this.companyMembersService.removeMember(member.companyId, member.id);
  }

  @Action(RefreshRequisites)
  @ImmutableContext()
  refreshRequisites({ getState }: StateContext<CompaniesViewStateModel>) {
    return this.companiesService.updateCompanyData(getState().company.id);
  }

  @Action(SearchCompanyMember)
  @ImmutableContext()
  searchCompanyMember(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { searchValue }: SearchCompanyMember
  ) {
    return this.companyMembersService
      .getCompanyMembers(getState().company.id, { search: searchValue })
      .pipe(
        tap((results: PaginationResults<CompanyMember>) => {
          setState((state: CompaniesViewStateModel) => {
            state.changeCompanyMembers = results.data;
            return state;
          });
        })
      );
  }

  @Action(SetCompanyOwner)
  @ImmutableContext()
  setCompanyOwner(
    { getState }: StateContext<CompaniesViewStateModel>,
    { member }: SetCompanyOwner
  ) {
    return this.companyMembersService.setOwner(getState().company.id, member);
  }

  @Action(SetAutoSignature)
  @ImmutableContext()
  setAutoSignature(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { autoSignature }: SetAutoSignature
  ) {
    return this.companiesService
      .setAutoSignature(getState().company.id, autoSignature)
      .pipe(
        tap(() => {
          return setState((state: CompaniesViewStateModel) => {
            state.company.autoSignature = !state.company.autoSignature;
            return state;
          });
        })
      );
  }

  @Action(SetUseUserSignature)
  @ImmutableContext()
  setUseUserSignature(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { useUserSignature }: SetUseUserSignature
  ) {
    return this.companiesService
      .setUseUserSignature(getState().company.id, useUserSignature)
      .pipe(
        tap(() => {
          return setState((state: CompaniesViewStateModel) => {
            state.company.userSignature = !state.company.userSignature;
            return state;
          });
        })
      );
  }

  @Action(UploadStamp)
  @ImmutableContext()
  uploadPicture(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { file }: UploadStamp
  ) {
    return this.companiesService.uploadStamp(getState().company.id, file).pipe(
      tap((stamp: string) => {
        return setState((state: CompaniesViewStateModel) => {
          state.company.stamp = stamp;
          return state;
        });
      })
    );
  }

  @Action(UploadSignature)
  @ImmutableContext()
  uploadSignature(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { file }: UploadSignature
  ) {
    return this.companiesService
      .uploadSignature(getState().company.id, file)
      .pipe(
        tap((signature: string) => {
          return setState((state: CompaniesViewStateModel) => {
            state.company.signature = signature;
            return state;
          });
        })
      );
  }

  @Action(SaveCompanyType)
  @ImmutableContext()
  saveCompanyType(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { companyType }: SaveCompanyType
  ) {
    return this.companiesService
      .updateCompanyType(getState().company.id, companyType)
      .pipe(
        tap((company: Company) => {
          return setState((state: CompaniesViewStateModel) => {
            state.company.companyType = company.companyType;
            return state;
          });
        })
      );
  }

  @Action(SetCompanyTimeBlocking)
  @ImmutableContext()
  setCompanyTimeBlocking(
    { getState, setState }: StateContext<CompaniesViewStateModel>,
    { timeBlocking }: SetCompanyTimeBlocking
  ) {
    return this.companiesService
      .setCompanyTimeBlocking(getState().company.id, timeBlocking)
      .pipe(
        tap((company: Company) => {
          return setState((state: CompaniesViewStateModel) => {
            state.company.timeBlocking = company.timeBlocking;
            return state;
          });
        })
      );
  }
}

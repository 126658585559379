import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { MultipleResults } from '@app/interfaces/multiple-results';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@envs/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends DataService {
  protected url = environment.api_url;

  constructor(http: HttpClient) {
    super(http);
  }

  getUrl(): string {
    return this.url;
  }

  get<T>(endpoint: string, obj: object = null): Observable<T> {
    return super.get<ResponseSuccess<T>>(endpoint, obj).pipe(
      map((response: ResponseSuccess<T>) => {
        return response.data;
      })
    );
  }

  put<T>(endpoint: string, obj: object = null): Observable<T> {
    return super.put<ResponseSuccess<T>>(endpoint, obj).pipe(
      map((response: ResponseSuccess<T>) => {
        return response.data;
      })
    );
  }

  getPaginatedResults<T, SummaryType = void>(
    url: string,
    requestParams: object = null
  ): Observable<PaginationResults<T, void, SummaryType>> {
    return super
      .get<ResponseSuccess<MultipleResults<T, SummaryType>>>(url, requestParams)
      .pipe(
        map((response: ResponseSuccess<MultipleResults<T, SummaryType>>) => {
          return {
            currentPage: response.data._meta?.currentPage,
            pageCount: response.data._meta?.pageCount,
            perPage: response.data._meta?.perPage,
            totalCount: response.data._meta?.totalCount,
            maxPage: response.data._meta?.pageCount || 1,
            data: response.data.dataModels,
            summary: response.data.summary,
          };
        })
      );
  }

  getMultiple<T>(
    url: string,
    obj: object = null
  ): Observable<MultipleResults<T>> {
    return super.get<ResponseSuccess<MultipleResults<T>>>(url, obj).pipe(
      map((response: ResponseSuccess<MultipleResults<T>>) => {
        return response.data;
      })
    );
  }

  getAll<T>(url: string, obj: any = null): Observable<T[]> {
    obj = obj || {};
    if (!obj.exact) {
      obj['per-page'] = obj['per-page'] || 0;
    }
    return this.getPaginatedResults<T>(url, obj).pipe(
      map((result: PaginationResults<T>) => {
        return result.data;
      })
    );
  }

  create<T>(
    endpoint: string,
    data = null,
    obj: object = null,
    headers: object = null
  ): Observable<T> {
    return this.post<ResponseSuccess<T>>(endpoint, data, obj, headers).pipe(
      map((response: ResponseSuccess<T>) => {
        return response.data;
      })
    );
  }
}

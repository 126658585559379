import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ChatSelectedChatGroupStateModel } from './chat_selected-chat-group-state.model';
import { Injectable } from '@angular/core';
import {
  AddForwardChatMessage,
  AddReplyChatMessage,
  CloseChatGroup,
  LoadFeedbacksList,
  RemoveForwardChatMessage,
  RemoveReplyChatMessage,
  RTS_ChatGroupRemoved,
  SelectChatGroup,
} from './chat_selected-chat-group.actions';
import { tap } from 'rxjs/operators';
import { ChatFeedbackService } from '@app/modules/chat/services/chat-feedback.service';
import {
  OpenChatGroup,
  OpenExistingChatGroup,
  DeleteChatGroupSuccess,
  RemoveFromCache,
} from '@app/store/chat/chat.actions';
import { PrivateChatAddedToBlackList } from '@app/store/chat/my-chats';
import { ChatFeedback } from '@app/modules/chat/interfaces/chat-feedback';

@Injectable()
@State<ChatSelectedChatGroupStateModel>({
  name: 'chat__selected_chat_group',
  defaults: {
    selectedChatGroupId: null,
    chatFeedbacks: null,
    replyChatMessage: null,
    forwardChatMessage: null,
  },
})
export class ChatSelectedChatGroupState {
  constructor(
    private chatFeedbackService: ChatFeedbackService,
    private store: Store
  ) {}

  @Action([SelectChatGroup, OpenChatGroup, OpenExistingChatGroup])
  @ImmutableContext()
  setSelectedChatGroupId(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { chatGroupId }: OpenChatGroup | SelectChatGroup | OpenExistingChatGroup
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.selectedChatGroupId = chatGroupId;
      return state;
    });
  }

  @Action([CloseChatGroup, AddForwardChatMessage])
  @ImmutableContext()
  closeChatGroup(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    {}: CloseChatGroup
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.selectedChatGroupId = null;
      return state;
    });
  }

  @Action(PrivateChatAddedToBlackList)
  @ImmutableContext()
  privateChatAddedToBlackList(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { chatGroupId }: PrivateChatAddedToBlackList
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      if (state.selectedChatGroupId === chatGroupId) {
        state.selectedChatGroupId = null;
      }
      return state;
    });
  }

  @Action(LoadFeedbacksList)
  @ImmutableContext()
  loadFeedbacksList({
    getState,
    setState,
  }: StateContext<ChatSelectedChatGroupStateModel>) {
    if (getState().chatFeedbacks !== null) {
      return;
    }

    return this.chatFeedbackService.getAll().pipe(
      tap((chatFeedbacks: ChatFeedback[]) => {
        setState((state: ChatSelectedChatGroupStateModel) => {
          state.chatFeedbacks = chatFeedbacks;
          return state;
        });
      })
    );
  }

  @Action(DeleteChatGroupSuccess)
  @ImmutableContext()
  deleteChatGroupSuccess(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { chatGroupID }: DeleteChatGroupSuccess
  ) {
    setState((state: ChatSelectedChatGroupStateModel) => {
      if (state.selectedChatGroupId === chatGroupID) {
        state.selectedChatGroupId = null;
      }
      return state;
    });
  }

  @Action(RTS_ChatGroupRemoved)
  @ImmutableContext()
  rtsChatGroupRemoved(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { chatGroupID }: RTS_ChatGroupRemoved
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      if (state.selectedChatGroupId === chatGroupID) {
        state.selectedChatGroupId = null;
      }

      this.store.dispatch(new RemoveFromCache(chatGroupID));

      return state;
    });
  }

  @Action(AddReplyChatMessage)
  @ImmutableContext()
  addReplyChatMessage(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { message }: AddReplyChatMessage
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.replyChatMessage = message;
      return state;
    });
  }

  @Action(RemoveReplyChatMessage)
  @ImmutableContext()
  emoveReplyChatMessage({
    setState,
  }: StateContext<ChatSelectedChatGroupStateModel>) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.replyChatMessage = null;
      return state;
    });
  }

  @Action(AddForwardChatMessage)
  @ImmutableContext()
  AddForwardChatMessage(
    { setState }: StateContext<ChatSelectedChatGroupStateModel>,
    { message }: AddForwardChatMessage
  ) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.forwardChatMessage = message;
      return state;
    });
  }

  @Action(RemoveForwardChatMessage)
  @ImmutableContext()
  removeForwardChatMessage({
    setState,
  }: StateContext<ChatSelectedChatGroupStateModel>) {
    return setState((state: ChatSelectedChatGroupStateModel) => {
      state.forwardChatMessage = null;
      return state;
    });
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CompanyTendersOverallStatisticsStateModel } from './company_tenders_overall_statistics.model';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { ChangeTendersScopeInCompanyTendersOverallStatistics } from './company_tenders_overall_statistics.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import {
  LoadCompanyTendersOverallStatisticsData,
  ChangeCompanyInCompanyTendersOverallStatistics,
  ChangeDatePeriodInCompanyTendersOverallStatistics,
} from './company_tenders_overall_statistics.actions';
import { tap } from 'rxjs/operators';
import { CompanyTendersOverallStatisticsData } from './interface/company-tenders-overall-statistics-data';
import * as dayjs from 'dayjs';

@Injectable()
@State<CompanyTendersOverallStatisticsStateModel>({
  name: 'widgets__company_tenders_overall_statistics',
  defaults: {
    datePeriod: {
      from: dayjs().startOf('month'),
      to: dayjs().endOf('day'),
    },
    companyId: 0,
    tendersScope: TendersScopes.CREATED,
    statistics: null,
  },
})
export class CompanyTendersOverallStatisticsState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(ChangeTendersScopeInCompanyTendersOverallStatistics)
  @ImmutableContext()
  changeTendersScopeInCompanyTendersOverallStatistics(
    { setState }: StateContext<CompanyTendersOverallStatisticsStateModel>,
    { tendersScope }: ChangeTendersScopeInCompanyTendersOverallStatistics
  ) {
    return setState((state: CompanyTendersOverallStatisticsStateModel) => {
      state.tendersScope = tendersScope;
      return state;
    });
  }

  @Action(ChangeCompanyInCompanyTendersOverallStatistics)
  @ImmutableContext()
  changeCompanyInCompanyTendersOverallStatistics(
    { setState }: StateContext<CompanyTendersOverallStatisticsStateModel>,
    { companyId }: ChangeCompanyInCompanyTendersOverallStatistics
  ) {
    return setState((state: CompanyTendersOverallStatisticsStateModel) => {
      state.companyId = companyId;
      return state;
    });
  }

  @Action(ChangeDatePeriodInCompanyTendersOverallStatistics)
  @ImmutableContext()
  changeDatePeriodInCompanyTendersOverallStatistics(
    { setState }: StateContext<CompanyTendersOverallStatisticsStateModel>,
    { datePeriod }: ChangeDatePeriodInCompanyTendersOverallStatistics
  ) {
    return setState((state: CompanyTendersOverallStatisticsStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }

  @Action(LoadCompanyTendersOverallStatisticsData)
  @ImmutableContext()
  loadCompanyTendersOverallStatisticsData({
    getState,
    setState,
  }: StateContext<CompanyTendersOverallStatisticsStateModel>) {
    const dateFrom = getState().datePeriod.from.isValid()
      ? getState().datePeriod.from.format('DD.MM.YYYY')
      : null;
    const dateTo = getState().datePeriod.to.isValid()
      ? getState().datePeriod.to.format('DD.MM.YYYY')
      : null;
    const companyId = getState().companyId;
    return this.widgetsService
      .getCompanyTendersOverallStatisticsData(dateFrom, dateTo, companyId)
      .pipe(
        tap((statistics: CompanyTendersOverallStatisticsData) => {
          return setState(
            (state: CompanyTendersOverallStatisticsStateModel) => {
              state.statistics = statistics;
              return state;
            }
          );
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { AlertController, LoadingController } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class UiService {
  constructor(
    private loadingController: LoadingController,
    private toastController: ToastController,
    private alertController: AlertController
  ) {}

  presentLoading = 0;

  displayLoading(): void {
    if (this.presentLoading === 0) {
      this.loadingController
        .create()
        .then((ionLoading: HTMLIonLoadingElement) => {
          ionLoading.present();
        });
    }

    this.presentLoading++;
  }

  dismissLoading(): void {
    if (this.presentLoading === 0) {
      return;
    }

    if (this.presentLoading === 1) {
      this.loadingController.dismiss().catch(() => {
        setTimeout(() => {
          this.loadingController.dismiss().catch(() => {});
        }, 1000);
      });
    }

    this.presentLoading--;
  }

  pushMessage(header: string, text: string, duration = 2500) {
    this.createToast(header, text, duration, 'top', null);
  }

  infoMessage(text: string, header?: string, duration = 2500) {
    this.createToast(header, text, duration, 'bottom', 'toast__info');
  }

  successMessage(text, header?: string, duration = 2500) {
    this.createToast(header, text, duration, 'bottom', 'toast__success');
  }

  errorMessage(text, header?: string, duration = 2500) {
    this.createToast(header, text, duration, 'top', 'toast__error');
  }

  displayProvidedErrors(errors: any, noKeys = false): void {
    let errorMessages: string[] = [];
    if (!noKeys) {
      Object.keys(errors).forEach((key) => {
        errorMessages.push(...errors[key]);
      });
    } else {
      errorMessages = errors;
    }

    this.errorMessage(errorMessages.join('\n'));
  }

  displayErrors(e: any, noKeys = false): void {
    const errors = (e.result.error as UnprocessableEntity).originalError.error
      .errors;

    let errorMessages: string[] = [];
    if (!noKeys) {
      Object.keys(errors).forEach((key) => {
        errorMessages.push(...errors[key]);
      });
    } else {
      errorMessages = errors;
    }

    this.errorMessage(errorMessages.join('\n'));
  }

  private async createToast(
    header: string,
    message: string,
    duration = 2500,
    position: 'bottom' | 'top' | 'middle',
    cssClass: string = null
  ) {
    if (!position) {
      position = 'bottom';
    }

    let options: ToastOptions = {
      message,
      duration,
      position,
    };

    if (header) {
      options = {
        ...options,
        header,
      };
    }

    if (cssClass) {
      options = {
        ...options,
        cssClass,
      };
    }

    const toast = await this.toastController.create(options);
    toast.present();
  }

  async alert(
    header: string,
    message: string,
    closeButtonText: string
  ): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: [closeButtonText],
    });
    await alert.present();
  }
}

import { Selector } from '@ngxs/store';
import { RegistrationState } from './registration.state';
import { RegistrationStateModel } from './registration-state.model';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';

export class RegistrationSelectors {
  @Selector([RegistrationState])
  static fieldErrors(state: RegistrationStateModel): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      errors.push({
        field: key,
        errors: state.fieldsErrors[key],
      });
    });

    return errors;
  }
}

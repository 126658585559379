import { Selector } from '@ngxs/store';
import { ChatWriteMessageStateModel } from './chat_write-message-state.model';
import { ChatWriteMessageState } from './chat_write-message.state';

export class ChatWriteMessageSelectors {
  @Selector([ChatWriteMessageState])
  static hasAttachments(state: ChatWriteMessageStateModel) {
    return state.hasAttachments;
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CompanyTendersDistributionStateModel } from './company_tenders_distribution.model';
import {
  ChangeTendersScopeInCompanyTendersDistribution,
  ChangeCompanyInCompanyTendersDistribution,
  ChangeDatePeriodInCompanyTendersDistribution,
} from './company_tenders_distribution.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { TendersScopes } from '@app/modules/widgets/components/select-tenders-scope/tenders-scopes';
import { LoadCompanyTendersDistributionData } from './company_tenders_distribution.actions';
import { CompanyTendersDistributionData } from './interface/company-tenders-distribution-data';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import { tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';

@Injectable()
@State<CompanyTendersDistributionStateModel>({
  name: 'widgets__company_tenders_distribution',
  defaults: {
    datePeriod: {
      from: dayjs().startOf('month'),
      to: dayjs().endOf('day'),
    },
    companyId: 0,
    tendersScope: TendersScopes.CREATED,
    distribution: null,
  },
})
export class CompanyTendersDistributionState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(ChangeTendersScopeInCompanyTendersDistribution)
  @ImmutableContext()
  changeTendersScopeInCompanyTendersDistribution(
    { setState }: StateContext<CompanyTendersDistributionStateModel>,
    { tendersScope }: ChangeTendersScopeInCompanyTendersDistribution
  ) {
    return setState((state: CompanyTendersDistributionStateModel) => {
      state.tendersScope = tendersScope;
      return state;
    });
  }

  @Action(ChangeCompanyInCompanyTendersDistribution)
  @ImmutableContext()
  changeCompanyInCompanyTendersDistribution(
    { setState }: StateContext<CompanyTendersDistributionStateModel>,
    { companyId }: ChangeCompanyInCompanyTendersDistribution
  ) {
    return setState((state: CompanyTendersDistributionStateModel) => {
      state.companyId = companyId;
      return state;
    });
  }

  @Action(ChangeDatePeriodInCompanyTendersDistribution)
  @ImmutableContext()
  changeDatePeriodInCompanyTendersDistribution(
    { setState }: StateContext<CompanyTendersDistributionStateModel>,
    { datePeriod }: ChangeDatePeriodInCompanyTendersDistribution
  ) {
    return setState((state: CompanyTendersDistributionStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }

  @Action(LoadCompanyTendersDistributionData)
  @ImmutableContext()
  loadCompanyTendersDistributionData(
    { getState, setState }: StateContext<CompanyTendersDistributionStateModel>,
    {}: LoadCompanyTendersDistributionData
  ) {
    const dateFrom = getState().datePeriod.from.isValid()
      ? getState().datePeriod.from.format('DD.MM.YYYY')
      : null;
    const dateTo = getState().datePeriod.to.isValid()
      ? getState().datePeriod.to.format('DD.MM.YYYY')
      : null;
    const companyId = getState().companyId;
    return this.widgetsService
      .getCompanyTendersDistributionData(dateFrom, dateTo, companyId)
      .pipe(
        tap((distribution: CompanyTendersDistributionData) => {
          return setState((state: CompanyTendersDistributionStateModel) => {
            state.distribution = distribution;
            return state;
          });
        })
      );
  }
}

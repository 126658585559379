import { Selector } from '@ngxs/store';
import { CompanyStructuresStateModel } from './company-structures-state.model';
import { CompanyStructuresState } from './company-structures.state';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';

export class CompanyStructuresSelectors {
  @Selector([CompanyStructuresState])
  static structures(state: CompanyStructuresStateModel): CompanyStructure[] {
    return state.structures;
  }

  @Selector([CompanyStructuresState])
  static fieldErrors(
    state: CompanyStructuresStateModel
  ): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      const errorMessages = [];
      state.fieldsErrors[key].forEach((errorMessage: string) => {
        errorMessages.push(errorMessage);
      });

      errors.push({
        field: key,
        errors: errorMessages,
      });
    });

    return errors;
  }
}

import { Selector } from '@ngxs/store';
import { CompanyFinancesTransportTendersStatementState } from './company-finances_tenders-statement.state';
import { CompanyFinancesTransportTendersStatementStateModel } from './company-finances_tenders-statement-state.model';
import { TendersStatementRecord } from '@app/modules/company-finances/interfaces/tenders-statement-record';
import { TendersStatementSummary } from '@app/modules/company-finances/interfaces/tenders-statement-summary';
import { DatePeriod } from '@app/interfaces/date-period';

export class CompanyFinancesTransportTendersStatementSelectors {
  @Selector([CompanyFinancesTransportTendersStatementState])
  static records(
    state: CompanyFinancesTransportTendersStatementStateModel
  ): TendersStatementRecord[] {
    return state.records;
  }

  @Selector([CompanyFinancesTransportTendersStatementState])
  static summary(
    state: CompanyFinancesTransportTendersStatementStateModel
  ): TendersStatementSummary {
    return state.summary;
  }

  @Selector([CompanyFinancesTransportTendersStatementState])
  static fileContent(
    state: CompanyFinancesTransportTendersStatementStateModel
  ): string {
    return state.fileContent;
  }

  @Selector([CompanyFinancesTransportTendersStatementState])
  static datePeriod(
    state: CompanyFinancesTransportTendersStatementStateModel
  ): DatePeriod {
    return state.datePeriod;
  }
}

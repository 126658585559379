import { TransportBet } from '@app/modules/transport-bets/interfaces/transport-bet';
import { ProgressStatuses } from '@app/modules/transport-tender/interfaces/progress-status';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { EditTransportTenderFormData } from '@app/pages/transport-tender/view/interfaces/edit-transport-tender.form-data';

export class LoadTransportTender {
  static readonly type =
    '[View Transport Tender Page] Load transport tender data';
  constructor(public id?: number, public number?: string) {}
}

export class SelectTransportBet {
  static readonly type = '[View Transport Tender Page] Select bet';
  constructor(public bet: TransportBet) {}
}

export class RefuseTransporTenderBet {
  static readonly type =
    '[View Transport Tender Page] Refuse transport tender bet';
  constructor(public betId: number, public reason: string) {}
}

export class TransportTenderBetSelected {
  static readonly type =
    '[View Transport Tender Page] Transport tender winner bet was selected';
  constructor(public tender: TransportTender) {}
}

export class SetTransportProgressStatus {
  static readonly type =
    '[View Transport Tender Page] Set transport progress status';
  constructor(
    public tenderId: number,
    public progressStatus: ProgressStatuses
  ) {}
}

export class RepeatTransportTender {
  static readonly type =
    '[View Transport Tender Page] Repeat transport tender (Create transport tender with populated form data)';
  constructor(public tender: TransportTender) {}
}

export class ProlongTransportTender {
  static readonly type =
    '[View Transport Tender Page] Prolong transport tender';
  constructor(public tenderId: number, public validUntil: string) {}
}

export class SaveTransportTender {
  static readonly type = '[View Transport Tender Page] Save transport tender';
  constructor() {}
}

export class EditTransportTender {
  static readonly type = '[View Transport Tender Page] Edit transport tender';
  constructor(public data: EditTransportTenderFormData) {}
}

export class PublishTransportTender {
  static readonly type =
    '[View Transport Tender Page] Publish transport tender';
  constructor(public tenderId: number) {}
}

export class CancelTransportTender {
  static readonly type = '[View Transport Tender Page] Cancel transport tender';
  constructor(public tenderId: number) {}
}

export class DeleteTransportTender {
  static readonly type = '[View Transport Tender] Delete transport tender';
  constructor(public tenderId: number) {}
}

export class UpdateTransportTenderDriver {
  static readonly type =
    '[View Transport Tender] Update transport tender driver';
  constructor(public tenderId: number, public driverId: number) {}
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesListStateModel } from './companies_list-state.model';
import { Injectable } from '@angular/core';
import {
  LoadCompaniesList,
  DeactivateCompany,
  SetCompaniesListSearchValue,
  ActivateCompany,
  SetCompaniesListIsFavoriteFilterValue,
} from './companies_list.actions';
import { tap } from 'rxjs/operators';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import { Company } from '@app/modules/companies/interfaces/company';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { DeleteCompany } from '@app/store/companies/list/companies_list.actions';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { SaveAccessAndRefreshTokens } from '@app/store/auth';
import { CompaniesListFiltersState } from './filters';
import { CompaniesListFiltersStateModel } from './filters/companies_list_filters-state.model';
import { CompaniesListFiltersFormData } from '@app/pages/companies/list/components/filters/companies-list-filters.form-data';
import {
  AddCompanyToFavorites,
  RemoveCompanyFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<CompaniesListStateModel>({
  name: 'companies__list',
  defaults: {
    companies: [],
    page: 1,
    maxPage: 1,
    searchValue: null,
    isFavorite: false,
  },
  children: [CompaniesListFiltersState],
})
export class CompaniesListState {
  constructor(
    private companiesService: CompaniesService,
    private store: Store
  ) {}

  @Action(LoadCompaniesList)
  @ImmutableContext()
  loadCompaniesList(
    { getState, setState }: StateContext<CompaniesListStateModel>,
    { page }: LoadCompaniesList
  ) {
    const newPage = page || getState().page;
    const filtersModel: CompaniesListFiltersStateModel =
      this.store.selectSnapshot(CompaniesListFiltersState);

    let filters: CompaniesListFiltersFormData = {
      types: filtersModel.types,
    };

    if (filtersModel.isAttendanceCompany) {
      filters.isAttendanceCompany = filtersModel.isAttendanceCompany;
      filters.attendanceDatePeriod = filtersModel.attendanceDatePeriod;
    }

    filters = Object.keys(filters)
      .filter((key) => filters[key] !== null)
      .reduce((newObj, key) => {
        newObj[key] = filters[key];
        return newObj;
      }, {});

    return this.companiesService
      .getCompanies(
        newPage,
        getState().searchValue,
        filters,
        getState().isFavorite
      )
      .pipe(
        tap((result: PaginationResults<Company>) => {
          setState((state: CompaniesListStateModel) => {
            state.companies = result.data;
            state.page = newPage;
            state.maxPage = result.maxPage;
            return state;
          });
        })
      );
  }

  @Action(SetCompaniesListSearchValue)
  @ImmutableContext()
  setCompaniesListSearchValue(
    { setState }: StateContext<CompaniesListStateModel>,
    { searchValue }: SetCompaniesListSearchValue
  ) {
    return setState((state: CompaniesListStateModel) => {
      state.searchValue = searchValue;
      return state;
    });
  }

  @Action(SetCompaniesListIsFavoriteFilterValue)
  @ImmutableContext()
  setCompaniesListIsFavoriteFilterValue(
    { setState }: StateContext<CompaniesListStateModel>,
    { isFavorite }: SetCompaniesListIsFavoriteFilterValue
  ) {
    return setState((state: CompaniesListStateModel) => {
      state.isFavorite = isFavorite;
      return state;
    });
  }

  @Action(DeleteCompany)
  @ImmutableContext()
  deleteCompany(
    {}: StateContext<CompaniesListStateModel>,
    { companyId }: DeleteCompany
  ) {
    return this.companiesService.deleteCompany(companyId).pipe(
      tap((response: NewAccessTokenResponse) => {
        if (response) {
          this.store.dispatch(new SaveAccessAndRefreshTokens(response));
        }
      })
    );
  }

  @Action(DeactivateCompany)
  @ImmutableContext()
  deactivateCompany(
    { setState }: StateContext<CompaniesListStateModel>,
    { companyId }: DeactivateCompany
  ) {
    return this.companiesService.deactivateCompany(companyId).pipe(
      tap((company: Company) => {
        setState((state: CompaniesListStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.companies.length; i++) {
            if (state.companies[i].id === companyId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.companies[exists] = {
              ...state.companies[exists],
              ...{ status: company.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(ActivateCompany)
  @ImmutableContext()
  activateCompany(
    { setState }: StateContext<CompaniesListStateModel>,
    { companyId }: ActivateCompany
  ) {
    return this.companiesService.activateCompany(companyId).pipe(
      tap((company: Company) => {
        setState((state: CompaniesListStateModel) => {
          let exists = -1;
          for (let i = 0; i < state.companies.length; i++) {
            if (state.companies[i].id === companyId) {
              exists = i;
              break;
            }
          }

          if (exists !== -1) {
            state.companies[exists] = {
              ...state.companies[exists],
              ...{ status: company.status },
            };
          }
          return state;
        });
      })
    );
  }

  @Action(AddCompanyToFavorites)
  @ImmutableContext()
  addCompanyToFavorites(
    { setState }: StateContext<CompaniesListStateModel>,
    { entityId }: AddCompanyToFavorites
  ) {
    return setState((state: CompaniesListStateModel) => {
      const company = state.companies.find((company: Company) => {
        return company.id === entityId;
      });

      if (company) {
        company.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveCompanyFromFavorites)
  @ImmutableContext()
  removeCompanyFromFavorites(
    { setState }: StateContext<CompaniesListStateModel>,
    { entityId }: RemoveCompanyFromFavorites
  ) {
    return setState((state: CompaniesListStateModel) => {
      const company = state.companies.find((company: Company) => {
        return company.id === entityId;
      });

      if (company) {
        company.isFavorite = false;
      }

      return state;
    });
  }
}

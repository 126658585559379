import { BillEntity } from '@app/modules/bills/interfaces/bill-entity';

export class UpdateBillData {
  static readonly type = '[Company finances -> Bills] Update bill data';
  constructor(public billEntity: BillEntity) {}
}

export class DeactivateBill {
  static readonly type = '[Company finances -> Bills] Deactivate bill';
  constructor(public billID: number) {}
}

import { CalendarView } from 'angular-calendar';

export class SetCalendarViewDate {
  static readonly type = '[Calendar] Set view date in the calendar';
  constructor(public date: Date) {}
}

export class SetCalendarView {
  static readonly type = '[Calendar] Set calendar view (month/week/day)';
  constructor(public view: CalendarView) {}
}

export class SetCalendarActiveDayIsOpen {
  static readonly type =
    '[Calendar] Set "activate date is open" parameter value';
  constructor(public activeDayIsOpen: boolean) {}
}

export class DeleteTaskInCalendar {
  static readonly type = '[Calendar] Delete task in the calendar';
  constructor(public taskId: number) {}
}

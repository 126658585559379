import { Selector } from '@ngxs/store';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportTendersViewState } from './transport-tenders_view.state';
import { TransportTendersViewStateModel } from './transport-tenders_view-state.model';

export class TransportTendersViewSelectors {
  @Selector([TransportTendersViewState])
  static tender(state: TransportTendersViewStateModel): TransportTender {
    return state.tender;
  }
}

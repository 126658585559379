import { Selector } from '@ngxs/store';
import { CargoTendersListSeaState } from './cargo-tenders-list_sea.state';
import { CargoTendersListSeaStateModel } from './cargo-tenders-list_sea-state.model';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';

export class CargoTendersListSeaSelectors {
  @Selector([CargoTendersListSeaState])
  static tenders(state: CargoTendersListSeaStateModel): SeaCargoTender[] {
    return state.tenders;
  }
}

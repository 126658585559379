import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CompaniesViewTendersStateModel } from '../../companies_view_tenders-state.model';
import { CompaniesService } from '@app/modules/companies/services/companies.service';
import {
  LoadCompanyTendersParticipatedCar,
  SetCompanyTendersParticipatedCarDatePeriod,
  SetCompanyTendersParticipatedCarPage,
  SetCompanyTendersParticipatedCarState,
} from './companies_view_tenders-participated-car.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesViewSelectors } from '@app/store/companies';
import { filter, mergeMap, Subject, takeUntil, tap } from 'rxjs';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CompanyTendersSummary } from '@app/modules/companies/interfaces/company-tenders-summary';
import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyTendersTypes } from '@app/pages/companies/view/company-tenders/company-tenders-types';
@Injectable()
@State<CompaniesViewTendersStateModel>({
  name: 'companies__view__tenders_participated_car',
  defaults: {
    datePeriod: {
      from: null,
      to: null,
    },
    tenders: null,
    markups: null,
    page: 1,
    maxPage: null,
    state: null,
    filteredResultsCount: null,
  },
})
export class CompaniesViewTendersParticipatedCarState {
  constructor(
    private companiesService: CompaniesService,
    private store: Store
  ) {}

  @Action(LoadCompanyTendersParticipatedCar, { cancelUncompleted: true })
  @ImmutableContext()
  loadCompanyTendersParticipatedCar({
    setState,
    getState,
  }: StateContext<CompaniesViewTendersStateModel>) {
    const page = getState().page;
    const datePeriod = getState().datePeriod;
    const state = getState().state;
    const gotCompany$ = new Subject();
    return this.store.select(CompaniesViewSelectors.company).pipe(
      takeUntil(gotCompany$),
      filter((company: Company) => !!company),
      mergeMap((company: Company) => {
        gotCompany$.next(true);
        gotCompany$.complete();
        return this.companiesService
          .getCompanyCarCargoTenders(
            company.id,
            CompanyTendersTypes.PARTICIPATED,
            page,
            datePeriod,
            state
          )
          .pipe(
            tap(
              (
                paginationResults: PaginationResults<
                  CompanyTender,
                  FilteredTendersResultsCount,
                  CompanyTendersSummary
                >
              ) => {
                setState((state: CompaniesViewTendersStateModel) => {
                  state.tenders = paginationResults.data;
                  if (paginationResults.summary?.markup) {
                    state.markups = paginationResults.summary.markup;
                  } else {
                    state.markups = null;
                  }
                  state.page = paginationResults.currentPage;
                  state.maxPage = paginationResults.maxPage;
                  state.filteredResultsCount = paginationResults.filter;
                  return state;
                });
              }
            )
          );
      })
    );
  }

  @Action(SetCompanyTendersParticipatedCarPage)
  @ImmutableContext()
  setCompanyTendersParticipatedCarPage(
    { setState }: StateContext<CompaniesViewTendersStateModel>,
    { page }: SetCompanyTendersParticipatedCarPage
  ) {
    return setState((state: CompaniesViewTendersStateModel) => {
      state.page = page;
      return state;
    });
  }

  @Action(SetCompanyTendersParticipatedCarDatePeriod)
  @ImmutableContext()
  setCompanyTendersParticipatedCarDatePeriod(
    { setState }: StateContext<CompaniesViewTendersStateModel>,
    { datePeriod }: SetCompanyTendersParticipatedCarDatePeriod
  ) {
    return setState((state: CompaniesViewTendersStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }

  @Action(SetCompanyTendersParticipatedCarState)
  @ImmutableContext()
  setCompanyTendersParticipatedCarState(
    { setState }: StateContext<CompaniesViewTendersStateModel>,
    { states }: SetCompanyTendersParticipatedCarState
  ) {
    return setState((state: CompaniesViewTendersStateModel) => {
      state.state = states;
      return state;
    });
  }
}

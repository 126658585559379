import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LayoutSelectors } from '@app/store/layout/layout.selectors';

@Injectable()
export class IsWhitelabelGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(LayoutSelectors.isWhitelabel);
  }
}

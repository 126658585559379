import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TenderUnitedOption } from '@app/modules/tender-united-options/interfaces/tender-united-option';

@Injectable({
  providedIn: 'root',
})
export class TenderUnitedOptionsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<TenderUnitedOption[]> {
    return this.apiService
      .getAll<TenderUnitedOption>('/tender-united-option')
      .pipe(
        map((tenderUnitedOptions: TenderUnitedOption[]) => {
          return tenderUnitedOptions.reverse();
        })
      );
  }
}

import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class SetSeaCargoTenders {
  static readonly type = '[Sea Cargo Tenders List] Set sea cargo tenders list';
  constructor(public tenders?: SeaCargoTender[]) {}
}

export class LoadNewSeaCargoTendersCount {
  static readonly type =
    '[Sea Cargo Tenders List] Load new sea cargo tenders count';
  constructor() {}
}

export class ReadSeaCargoTender {
  static readonly type =
    '[Sea Cargo Tenders List] Mark sea cargo tender as read';
  constructor(public tenderId: number) {}
}

export class ReloadSeaCargoTender {
  static readonly type = '[Sea Cargo Tenders List] Reload sea cargo tender';
  constructor(public tenderId: number) {}
}

export class HighlightSeaCargoTenderOnMap {
  static readonly type =
    '[Cargo Tenders list] Highlight sea cargo tender on map';
  constructor(public tenderId: number, public tenderState: TenderStates) {}
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportBetsService } from '@app/modules/transport-bets/services/transport-bets.service';
import { NewTransportBetPlaced } from '@app/store/bets';
import { TransportTendersViewStateModel } from './transport-tenders_view-state.model';
import {
  CancelTransportTender,
  DeleteTransportTender,
  EditTransportTender,
  LoadTransportTender,
  PublishTransportTender,
  SelectTransportBet,
  SetTransportProgressStatus,
  TransportTenderBetSelected,
  UpdateTransportTenderDriver,
} from './transport-tenders_view.actions';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';
import {
  RefuseTransporTenderBet,
  ProlongTransportTender,
} from './transport-tenders_view.actions';
import { SetTransportTenderValidUntil } from '@app/store/transport-tenders/transport-tenders.actions';

@Injectable()
@State<TransportTendersViewStateModel>({
  name: 'transport_tenders__view',
  defaults: {
    tender: null,
  },
})
export class TransportTendersViewState {
  constructor(
    private transportTenderService: TransportTenderService,
    private transportBetsService: TransportBetsService,
    private store: Store
  ) {}

  @Action(LoadTransportTender)
  @ImmutableContext()
  loadTransportTender(
    { setState }: StateContext<TransportTendersViewStateModel>,
    { id, number }: LoadTransportTender
  ) {
    return this.transportTenderService.getTender(id, number).pipe(
      tap((tender: TransportTender) => {
        setState((state: TransportTendersViewStateModel) => {
          state.tender = tender;
          return state;
        });
      })
    );
  }

  @Action(SelectTransportBet)
  @ImmutableContext()
  selectTransportBet(
    {}: StateContext<TransportTendersViewStateModel>,
    { bet }: SelectTransportBet
  ) {
    return this.transportBetsService.selectBet(bet).pipe(
      tap((tender: TransportTender) => {
        tender.id = bet.tenderId;
        tender.winnerBet = bet;
        this.store.dispatch(new TransportTenderBetSelected(tender));
      })
    );
  }

  @Action(RefuseTransporTenderBet)
  @ImmutableContext()
  refuseTransporTenderBet(
    {}: StateContext<TransportTendersViewStateModel>,
    { betId, reason }: RefuseTransporTenderBet
  ) {
    return this.transportBetsService.refuseBet(betId, reason);
  }

  @Action(TransportTenderBetSelected)
  @ImmutableContext()
  transportTenderBetSelected(
    { setState }: StateContext<TransportTendersViewStateModel>,
    { tender }: TransportTenderBetSelected
  ) {
    return setState((state: TransportTendersViewStateModel) => {
      state.tender = { ...state.tender, ...tender };
      return state;
    });
  }

  @Action(SetTransportProgressStatus)
  @ImmutableContext()
  setTransportProgressStatus(
    {}: StateContext<TransportTendersViewStateModel>,
    { tenderId, progressStatus }: SetTransportProgressStatus
  ) {
    return this.transportTenderService.setProgressStatus(
      tenderId,
      progressStatus
    );
  }

  @Action(NewTransportBetPlaced)
  @ImmutableContext()
  newTransportBetPlaced(
    { getState, setState }: StateContext<TransportTendersViewStateModel>,
    { tender_id }: NewTransportBetPlaced
  ) {
    if (getState().tender?.id !== tender_id) {
      return;
    }

    return this.transportTenderService.getTenderBetInfo(tender_id).pipe(
      tap((tender: TransportTender) => {
        setState((state: TransportTendersViewStateModel) => {
          state.tender = { ...state.tender, ...tender };
          return state;
        });
      })
    );
  }

  @Action(EditTransportTender)
  @ImmutableContext()
  editTransportTender(
    {}: StateContext<TransportTendersViewStateModel>,
    { data }: EditTransportTender
  ) {
    return this.transportTenderService.updateTender(data);
  }

  @Action(PublishTransportTender)
  @ImmutableContext()
  publishTransportTender(
    {}: StateContext<TransportTendersViewStateModel>,
    { tenderId }: PublishTransportTender
  ) {
    return this.transportTenderService.publish(tenderId);
  }

  @Action(CancelTransportTender)
  @ImmutableContext()
  cancelTransportTender(
    {}: StateContext<TransportTendersViewStateModel>,
    { tenderId }: CancelTransportTender
  ) {
    return this.transportTenderService.cancel(tenderId);
  }

  @Action(ProlongTransportTender)
  @ImmutableContext()
  prolongTransportTender(
    {}: StateContext<TransportTendersViewStateModel>,
    { tenderId, validUntil }: ProlongTransportTender
  ) {
    return this.transportTenderService.prolong(tenderId, validUntil);
  }

  @Action(SetTransportTenderValidUntil)
  @ImmutableContext()
  setTransportTenderValidUntil(
    { setState }: StateContext<TransportTendersViewStateModel>,
    { data }: SetTransportTenderValidUntil
  ) {
    return setState((state: TransportTendersViewStateModel) => {
      if (state.tender?.id === data.tenderId) {
        state.tender.date.validUntil = data.validUntil;
      }
      return state;
    });
  }

  @Action(DeleteTransportTender)
  @ImmutableContext()
  deleteTransportTender(
    { setState }: StateContext<TransportTendersViewStateModel>,
    { tenderId }: DeleteTransportTender
  ) {
    return this.transportTenderService.delete(tenderId).pipe(
      tap(() => {
        return setState((state: TransportTendersViewStateModel) => {
          state.tender = null;
          return state;
        });
      })
    );
  }

  @Action(UpdateTransportTenderDriver)
  @ImmutableContext()
  updateTransportTenderDriver(
    {}: StateContext<TransportTendersViewStateModel>,
    { tenderId, driverId }: UpdateTransportTenderDriver
  ) {
    return this.transportTenderService.updateDriver(tenderId, driverId);
  }
}

import { Injectable } from '@angular/core';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { State, Action, StateContext } from '@ngxs/store';
import {
  LoadTendersReport,
  DownloadTendersReport,
  SetDatePeriodInCompanyTendersReport,
} from './company-finances_tenders-report.actions';
import { CompanyFinancesTendersReportStateModel } from './company-finances_tenders-report-state.model';
import { CompanyFinancesService } from '@app/modules/company-finances/services/company-finances.service';
import { tap } from 'rxjs';
import { TendersReportFileData } from '@app/modules/company-finances/interfaces/tenders-report-file-data';
import { TendersReportRecord } from '@app/modules/company-finances/interfaces/tenders-report-record';

@Injectable()
@State<CompanyFinancesTendersReportStateModel>({
  name: 'company_finances__tenders_report',
  defaults: {
    records: null,
    fileContent: null,
    datePeriod: null,
  },
})
export class CompanyFinancesTendersReportState {
  constructor(private companyFinancesService: CompanyFinancesService) {}

  @Action(LoadTendersReport)
  @ImmutableContext()
  loadTendersReport({
    getState,
    setState,
  }: StateContext<CompanyFinancesTendersReportStateModel>) {
    const from = getState().datePeriod?.from || null;
    const to = getState().datePeriod?.to || null;
    return this.companyFinancesService.getTendersReport(from, to).pipe(
      tap((records: TendersReportRecord[]) => {
        return setState((state: CompanyFinancesTendersReportStateModel) => {
          state.records = records;
          return state;
        });
      })
    );
  }

  @Action(DownloadTendersReport)
  @ImmutableContext()
  downloadTendersReport({
    getState,
    setState,
  }: StateContext<CompanyFinancesTendersReportStateModel>) {
    const from = getState().datePeriod?.from || null;
    const to = getState().datePeriod?.to || null;
    return this.companyFinancesService.downloadTendersReport(from, to).pipe(
      tap((fileData: TendersReportFileData) => {
        return setState((state: CompanyFinancesTendersReportStateModel) => {
          state.fileContent = fileData.file;
          return state;
        });
      })
    );
  }

  @Action(SetDatePeriodInCompanyTendersReport)
  @ImmutableContext()
  setDatePeriodInCompanyTendersReport(
    { setState }: StateContext<CompanyFinancesTendersReportStateModel>,
    { datePeriod }: SetDatePeriodInCompanyTendersReport
  ) {
    return setState((state: CompanyFinancesTendersReportStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }
}

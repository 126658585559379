import { Injectable } from '@angular/core';
import { SaveCompanyStructureFormData } from '@app/pages/companies/view/tab1/components/add-edit-company-structure/save-company-structure-form-data';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { CompanyStructure } from '@app/modules/companies/interfaces/company-structure';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { CompanyStructureTypes } from '@app/modules/companies/company-structure-types';
import { DatePeriod } from '@app/interfaces/date-period';

@Injectable({
  providedIn: 'root',
})
export class CompanyStructuresService {
  constructor(private apiService: ApiService) {}

  // companyStructureList
  list(companyId: number): Observable<CompanyStructure[]> {
    return this.apiService.getAll<CompanyStructure>(
      '/company/' + companyId + '/structure'
    );
  }

  // not set
  listAll(createdAt: DatePeriod): Observable<CompanyStructure[]> {
    const data: any = {
      fields: [
        'address',
        'type.code',
        'type.name',
        'companyId',
        'latitude',
        'longitude',
        'company.details.companyName.short',
      ].join(','),
      expand: ['company.details'].join(','),
    };

    if (createdAt?.from) {
      data.createdAtFrom = createdAt.from;
    }

    if (createdAt?.to) {
      data.createdAtTo = createdAt.to;
    }
    return this.apiService.getAll<CompanyStructure>('/company/structure', data);
  }

  // companyStructureCreate
  create(data: SaveCompanyStructureFormData): Observable<CompanyStructure> {
    const companyId = data.companyId;
    delete data.companyId;
    return this.apiService.post<CompanyStructure>(
      '/company/' + companyId + '/structure',
      data
    );
  }

  // companyStructureView
  get(companyId: number, structureId: number): Observable<CompanyStructure> {
    return this.apiService.get<CompanyStructure>(
      '/company/' + companyId + '/structure/' + structureId
    );
  }

  // companyStructureUpdate
  update(data: SaveCompanyStructureFormData): Observable<CompanyStructure> {
    const companyId = data.companyId;
    delete data.companyId;
    const structureId = data.id;
    delete data.id;
    return this.apiService.put<CompanyStructure>(
      '/company/' + companyId + '/structure/' + structureId,
      data
    );
  }

  // companyStructureDelete
  delete(companyId: number, structureId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<boolean>>(
        '/company/' + companyId + '/structure/' + structureId
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }

  structureIcon(structureType: CompanyStructureTypes, size = 32) {
    const iconImages = {
      [CompanyStructureTypes.OFFICE]:
        'assets/icons/company-structures/office.png',
      [CompanyStructureTypes.MANUFACTURE]:
        'assets/icons/company-structures/manufacture.png',
      [CompanyStructureTypes.WAREHOUSE]:
        'assets/icons/company-structures/warehouse.png',
      [CompanyStructureTypes.TEMPORARY_WAREHOUSE]:
        'assets/icons/company-structures/temporary_warehouse.png',
      [CompanyStructureTypes.MANUFACTURE_WAREHOUSE]:
        'assets/icons/company-structures/manufacture_warehouse.png',
      [CompanyStructureTypes.TRANSPORT_BASE]:
        'assets/icons/company-structures/transport_base.png',
    };

    return {
      url: iconImages[structureType],
      scaledSize: {
        width: size,
        height: size,
      },
    };
  }
}

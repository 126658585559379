import { Selector } from '@ngxs/store';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';
import { TransportTendersListState } from './transport-tenders_list.state';
import { TransportTendersListStateModel } from './transport-tenders_list-state.model';

export class TransportTendersListSelectors {
  @Selector([TransportTendersListState])
  static tenders(state: TransportTendersListStateModel): TransportTender[] {
    return state.tenders;
  }

  @Selector([TransportTendersListState])
  static searchValue(state: TransportTendersListStateModel): string {
    return state.searchValue;
  }

  @Selector([TransportTendersListState])
  static page(state: TransportTendersListStateModel): number {
    return state.page;
  }

  @Selector([TransportTendersListState])
  static maxPage(state: TransportTendersListStateModel): number {
    return state.maxPage;
  }
}

import { Company } from '@app/modules/companies/interfaces/company';
import { CompanyMembersFilters } from '@app/modules/companies/interfaces/company-members-filters';
import { CompanyMemberPosition } from '@app/modules/companies/interfaces/company-member-position';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { CompanyComment } from '@app/modules/companies/interfaces/company-comment';
import { Permission } from '@app/modules/permissions/interfaces/permission';

export class CompaniesViewStateModel {
  company: Company;
  members: {
    filters: CompanyMembersFilters;
    result: PaginationResults<CompanyMember>;
  };
  comments: CompanyComment[];
  documentComments: CompanyComment[];
  positions: CompanyMemberPosition[];
  displayCompanyMembersFilters: boolean;
  partnerships: CompanyPartnership[];
  partnershipsMaxPage: number;
  partnershipsSearchValue: string;
  permissions: Permission[];
  changeCompanyMembers: CompanyMember[];
}

import { TransportTendersListFiltersFormData } from '@app/pages/transport-tender/list/components/filters-form/transport-tenders-list-filters.form-data';

export class ShowTransportTendersListFilters {
  static readonly type =
    '[Transport tenders list filters] Show (display) filters';
  constructor() {}
}

export class HideTransportTendersListFilters {
  static readonly type = '[Transport tenders list filters] Hide filters';
  constructor() {}
}

export class ResetTransportTendersListFilters {
  static readonly type = '[Transport tenders list filters] Reset filters';
  constructor() {}
}

export class ApplyTransportTendersListFilters {
  static readonly type = '[Transport tenders list filters] Apply filters';
  constructor(public filters: TransportTendersListFiltersFormData) {}
}

export class SetTransportTendersListFilters {
  static readonly type = '[Transport tenders list filters] Set filters value';
  constructor(public filters: TransportTendersListFiltersFormData) {}
}

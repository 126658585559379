import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { FinancesBillsState } from './bills';
import { FinancesStateModel } from './finances-state.model';

@Injectable()
@State<FinancesStateModel>({
  name: 'finances',
  children: [FinancesBillsState],
})
export class FinancesState {}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { AddToFavoritesFormData } from '@app/modules/favorites/interfaces/add-to-favorites.form-data';
import { Favorite } from '@app/modules/favorites/interfaces/favorite';
import { RemoveFromFavoritesFormData } from '@app/modules/favorites/interfaces/remove-from-favorites.form-data';

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  constructor(private apiService: ApiService) {}

  add(data: AddToFavoritesFormData): Observable<Favorite> {
    return this.apiService.create<Favorite>('/favorite', data);
  }

  remove(data: RemoveFromFavoritesFormData): Observable<boolean> {
    return this.apiService.delete(
      '/favorite/' + data.entityType + '/' + data.entityId
    );
  }
}

import { Action, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AttendanceStateModel } from './attendance.model';
import {
  ChangeDatePeriodInAttendanceWidget,
  LoadDataForAttendanceWidget,
} from './attendance.actions';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { WidgetsService } from '@app/modules/widgets/services/widgets.service';
import { tap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { AttendanceSummaryData } from './interface/attendance-summary-data';

@Injectable()
@State<AttendanceStateModel>({
  name: 'widgets__attendance',
  defaults: {
    datePeriod: {
      from: dayjs(),
      to: dayjs(),
    },
    attendance: null,
  },
})
export class AttendanceState {
  constructor(private widgetsService: WidgetsService) {}

  @Action(ChangeDatePeriodInAttendanceWidget)
  @ImmutableContext()
  changeDatePeriodInAttendanceWidget(
    { setState }: StateContext<AttendanceStateModel>,
    { datePeriod }: ChangeDatePeriodInAttendanceWidget
  ) {
    return setState((state: AttendanceStateModel) => {
      state.datePeriod = datePeriod;
      return state;
    });
  }

  @Action(LoadDataForAttendanceWidget)
  @ImmutableContext()
  loadDataForAttendanceWidget(
    { getState, setState }: StateContext<AttendanceStateModel>,
    {}: LoadDataForAttendanceWidget
  ) {
    const dateFrom = getState().datePeriod.from.isValid()
      ? getState().datePeriod.from.format('DD.MM.YYYY')
      : null;
    const dateTo = getState().datePeriod.to.isValid()
      ? getState().datePeriod.to.format('DD.MM.YYYY')
      : null;
    return this.widgetsService.getAttendanceSummaryData(dateFrom, dateTo).pipe(
      tap((attendance: AttendanceSummaryData) => {
        return setState((state: AttendanceStateModel) => {
          state.attendance = attendance;
          return state;
        });
      })
    );
  }
}

import { PrimaryBlockTabs } from '@app/pages/tabs/components/primary-block-tabs/primary-block-tabs';
import { Selector } from '@ngxs/store';
import { PrimaryBlockState } from './primary_block.state';
import { PrimaryBlockStateModel } from './primary_block-state.model';
import { PrimaryBlockCargoTendersViews } from '@app/pages/tabs/components/primary-block-cargo-tenders/primary-block-cargo-tenders-views';
import { PrimaryBlockTransportTendersViews } from '@app/pages/tabs/components/primary-block-transport-tenders/primary-block-transport-tenders-views';
import { PrimaryBlockWarehousesViews } from '@app/pages/tabs/components/primary-block-warehouses/primary-block-warehouses-views';
import { PrimaryBlockViews } from '@app/pages/tabs/components/primary-block/primary-block-views';
import { PrimaryBlockCompaniesViews } from '@app/pages/tabs/components/primary-block-companies/primary-block-companies-views';

export class PrimaryBlockSelectors {
  @Selector([PrimaryBlockState])
  static activeTab(state: PrimaryBlockStateModel): PrimaryBlockTabs {
    return state.activeTab;
  }

  @Selector([PrimaryBlockState])
  static expanded(state: PrimaryBlockStateModel): boolean {
    return state.expanded;
  }

  @Selector([PrimaryBlockState])
  static cargoTendersView(
    state: PrimaryBlockStateModel
  ): PrimaryBlockCargoTendersViews {
    return state.cargoTendersView;
  }

  @Selector([PrimaryBlockState])
  static transportTendersView(
    state: PrimaryBlockStateModel
  ): PrimaryBlockTransportTendersViews {
    return state.transportTendersView;
  }

  @Selector([PrimaryBlockState])
  static warehousesView(
    state: PrimaryBlockStateModel
  ): PrimaryBlockWarehousesViews {
    return state.warehousesView;
  }

  @Selector([PrimaryBlockState])
  static companiesView(
    state: PrimaryBlockStateModel
  ): PrimaryBlockCompaniesViews {
    return state.companiesView;
  }

  @Selector([PrimaryBlockState])
  static activeView(state: PrimaryBlockStateModel): PrimaryBlockViews {
    if (state.activeTab === PrimaryBlockTabs.CARGO_TENDERS) {
      return state.cargoTendersView;
    }
    if (state.activeTab === PrimaryBlockTabs.TRANSPORT_TENDERS) {
      return state.transportTendersView;
    }
    if (state.activeTab === PrimaryBlockTabs.WAREHOUSES) {
      return state.warehousesView;
    }
    if (state.activeTab === PrimaryBlockTabs.COMPANIES) {
      return state.companiesView;
    }
  }

  @Selector([PrimaryBlockState])
  static isListView(state: PrimaryBlockStateModel): boolean {
    return (
      (state.activeTab === PrimaryBlockTabs.CARGO_TENDERS &&
        state.cargoTendersView === PrimaryBlockCargoTendersViews.LIST) ||
      (state.activeTab === PrimaryBlockTabs.TRANSPORT_TENDERS &&
        state.transportTendersView ===
          PrimaryBlockTransportTendersViews.LIST) ||
      (state.activeTab === PrimaryBlockTabs.WAREHOUSES &&
        state.warehousesView === PrimaryBlockWarehousesViews.LIST) ||
      (state.activeTab === PrimaryBlockTabs.COMPANIES &&
        state.companiesView === PrimaryBlockCompaniesViews.LIST)
    );
  }

  @Selector([PrimaryBlockState])
  static isDetailView(state: PrimaryBlockStateModel): boolean {
    return (
      (state.activeTab === PrimaryBlockTabs.CARGO_TENDERS &&
        state.cargoTendersView === PrimaryBlockCargoTendersViews.DETAIL) ||
      (state.activeTab === PrimaryBlockTabs.TRANSPORT_TENDERS &&
        state.transportTendersView ===
          PrimaryBlockTransportTendersViews.DETAIL) ||
      (state.activeTab === PrimaryBlockTabs.WAREHOUSES &&
        state.warehousesView === PrimaryBlockWarehousesViews.DETAIL)
    );
  }
}

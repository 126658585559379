import { TenderStates } from '@app/modules/tenders/tender-states';
import { ImportItemsListResponse } from '@app/modules/transport-tender/interfaces/import-items-list-response';
import { ConfirmTransportTenderFormData } from '@app/pages/confirm-transport-tender/interfaces/confirm-transport-tender.form-data';

export class FetchTransportTenders {
  static readonly type = '[Transport tenders list] Fetch tansport tenders';
  constructor(public page?: number) {}
}

export class SetTransportTendersSearchValue {
  static readonly type =
    '[Transport tenders list] Set transport tenders search value';
  constructor(public searchValue: string) {}
}

export class ConfirmTransportTender {
  static readonly type = '[Transport tenders] Confirm transport tenders';
  constructor(
    public tenderId: number,
    public data: ConfirmTransportTenderFormData
  ) {}
}

export class ImportTransportTenders {
  static readonly type = '[Transport tenders] Import tansport tenders';
  constructor(public data: string) {}
}

export class ImportTransportTendersErrors {
  static readonly type = '[Transport tenders] Import tansport tenders errors';
  constructor(public errors: ImportItemsListResponse) {}
}

export class ExportTransportTenders {
  static readonly type = '[Transport tenders] Export transport tenders';
  constructor() {}
}

export class HighlightTransportTenderOnMap {
  static readonly type =
    '[Transport tenders] Highlight Transport Tender On Map';
  constructor(public tenderId: number, public tenderState: TenderStates) {}
}

export class PublishAllTransportTenders {
  static readonly type = '[Transport tenders] Publish all transport tenders';
  constructor() {}
}

export class ReadTransportTender {
  static readonly type = '[Transport tenders] Mark tender as read';
  constructor(public tenderId: number) {}
}

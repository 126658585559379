import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { ApiTokenStateModel } from './api_token-state.model';
import { Injectable } from '@angular/core';
import {
  GenerateApiToken,
  LoadApiToken,
  RevokeApiToken,
} from './api_token.actions';
import { tap } from 'rxjs/operators';
import { ApiToken } from '@app/modules/api-token/interfaces/api-token';
import { ApiTokenService } from '@app/modules/api-token/services/api-token.service';
import { ChangeCompanySuccess } from '@app/store/tabs/tabs.actions';
import { StateReset } from 'ngxs-reset-plugin';

@Injectable()
@State<ApiTokenStateModel>({
  name: 'api_token',
  defaults: {
    apiToken: null,
  },
})
export class ApiTokenState {
  constructor(private apiTokenService: ApiTokenService, private store: Store) {}

  @Action(LoadApiToken, { cancelUncompleted: true })
  @ImmutableContext()
  loadApiToken({ getState, setState }: StateContext<ApiTokenStateModel>) {
    if (getState().apiToken) {
      return;
    }

    return this.apiTokenService.get().pipe(
      tap((apiToken: ApiToken) => {
        setState((state: ApiTokenStateModel) => {
          state.apiToken = apiToken;
          return state;
        });
      })
    );
  }

  @Action(GenerateApiToken)
  @ImmutableContext()
  generateApiToken(
    { setState }: StateContext<ApiTokenStateModel>,
    { ip }: GenerateApiToken
  ) {
    return this.apiTokenService.create(ip).pipe(
      tap((apiToken: ApiToken) => {
        setState((state: ApiTokenStateModel) => {
          state.apiToken = apiToken;
          return state;
        });
      })
    );
  }

  @Action(RevokeApiToken)
  @ImmutableContext()
  revokeApiToken({ setState }: StateContext<ApiTokenStateModel>) {
    return this.apiTokenService.delete().pipe(
      tap((deleted: boolean) => {
        if (deleted) {
          setState((state: ApiTokenStateModel) => {
            state.apiToken = null;
            return state;
          });
        }
      })
    );
  }

  @Action(ChangeCompanySuccess)
  @ImmutableContext()
  changeCompany({}: StateContext<ApiTokenStateModel>) {
    this.store.dispatch(new StateReset(ApiTokenState));
  }
}

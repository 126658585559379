import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { CompanyPopularRoutesStateModel } from './company-popular-routes-state.model';
import {
  DeleteCompanyPopularRouteSuccess,
  LoadCompanyPopularRoutes,
  SaveCompanyPopularRouteFailed,
  SaveCompanyPopularRouteSuccess,
  TryDeleteCompanyPopularRoute,
  TrySaveCompanyPopularRoute,
} from './company-popular-routes.actions';
import { UiService } from '@app/services/ui.service';
import { of } from 'rxjs';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { ResponseError } from '@app/interfaces/response-error';
import { CompanyPopularRoutesService } from '@app/modules/companies/services/company-popular-routes.service';
import { CompanyPopularRoute } from '@app/modules/companies/interfaces/company-popular-route';

@Injectable()
@State<CompanyPopularRoutesStateModel>({
  name: 'company_popular_routes',
  defaults: {
    routes: null,
    fieldsErrors: null,
  },
})
export class CompanyPopularRoutesState {
  constructor(
    private companyPopularRoutesService: CompanyPopularRoutesService,
    private uiService: UiService,
    private store: Store
  ) {}

  @Action(TrySaveCompanyPopularRoute)
  @ImmutableContext()
  trySaveCompanyPopularRoute(
    { setState }: StateContext<CompanyPopularRoutesStateModel>,
    { data }: TrySaveCompanyPopularRoute
  ) {
    this.uiService.displayLoading();

    setState((state: CompanyPopularRoutesStateModel) => {
      state.fieldsErrors = null;
      return state;
    });

    const methodName = data.id ? 'update' : 'create';
    return this.companyPopularRoutesService[methodName](data).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveCompanyPopularRouteSuccess());
      }),
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new SaveCompanyPopularRouteFailed(
              (err.originalError.error as ResponseError).errors
            )
          );

          return of(null);
        }
        return of(err);
      })
    );
  }

  @Action(SaveCompanyPopularRouteFailed)
  @ImmutableContext()
  saveCompanyPopularRouteFailed(
    { setState }: StateContext<CompanyPopularRoutesStateModel>,
    { errors }: SaveCompanyPopularRouteFailed
  ) {
    return setState((state: CompanyPopularRoutesStateModel) => {
      state.fieldsErrors = errors;
      return state;
    });
  }

  @Action(LoadCompanyPopularRoutes)
  @ImmutableContext()
  loadCompanyPopularRoutes(
    { setState }: StateContext<CompanyPopularRoutesStateModel>,
    { companyId }: LoadCompanyPopularRoutes
  ) {
    return this.companyPopularRoutesService.list(companyId).pipe(
      tap((routes: CompanyPopularRoute[]) => {
        setState((state: CompanyPopularRoutesStateModel) => {
          state.routes = routes;
          return state;
        });
      })
    );
  }

  @Action(TryDeleteCompanyPopularRoute)
  @ImmutableContext()
  tryDeleteCompanyPopularRoute(
    {}: StateContext<CompanyPopularRoutesStateModel>,
    { companyId, routeId }: TryDeleteCompanyPopularRoute
  ) {
    return this.companyPopularRoutesService.delete(companyId, routeId).pipe(
      tap(() => {
        this.store.dispatch(new DeleteCompanyPopularRouteSuccess());
      })
    );
  }
}

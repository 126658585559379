import { Selector } from '@ngxs/store';
import { CompanyFinancesTendersReportState } from './company-finances_tenders-report.state';
import { CompanyFinancesTendersReportStateModel } from './company-finances_tenders-report-state.model';
import { TendersReportRecord } from '@app/modules/company-finances/interfaces/tenders-report-record';
import { DatePeriod } from '@app/interfaces/date-period';

export class CompanyFinancesTendersReportSelectors {
  @Selector([CompanyFinancesTendersReportState])
  static records(
    state: CompanyFinancesTendersReportStateModel
  ): TendersReportRecord[] {
    return state.records;
  }

  @Selector([CompanyFinancesTendersReportState])
  static fileContent(state: CompanyFinancesTendersReportStateModel): string {
    return state.fileContent;
  }

  @Selector([CompanyFinancesTendersReportState])
  static datePeriod(state: CompanyFinancesTendersReportStateModel): DatePeriod {
    return state.datePeriod;
  }
}

import { TenderStates } from '@app/modules/tenders/tender-states';
import { Selector } from '@ngxs/store';
import { CargoTendersListFiltersState } from './cargo-tenders_list_filters.state';
import { CargoTendersListFiltersStateModel } from './cargo-tenders_list_filters-state.model';
import { CargoTendersTransportationTypes } from '@app/modules/tenders/cargo-tenders-transportation-types';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CompanyPartnership } from '@app/modules/companies/interfaces/company-partnership';
import { User } from '@app/modules/users/interfaces/user';

export class CargoTendersListFiltersSelectors {
  @Selector([CargoTendersListFiltersState])
  static isFavorite(state: CargoTendersListFiltersStateModel): boolean {
    return state.isFavorite;
  }

  @Selector([CargoTendersListFiltersState])
  static cargoTendersTransportationType(
    state: CargoTendersListFiltersStateModel
  ): CargoTendersTransportationTypes {
    return state.cargoTendersTransportationType;
  }

  @Selector([CargoTendersListFiltersState])
  static displayFilters(state: CargoTendersListFiltersStateModel): boolean {
    return state.displayFilters;
  }

  @Selector([CargoTendersListFiltersState])
  static states(state: CargoTendersListFiltersStateModel): TenderStates[] {
    return state.states;
  }

  @Selector([CargoTendersListFiltersState])
  static createdAtFrom(state: CargoTendersListFiltersStateModel): string {
    return state.createdAtFrom;
  }

  @Selector([CargoTendersListFiltersState])
  static createdAtTo(state: CargoTendersListFiltersStateModel): string {
    return state.createdAtTo;
  }

  @Selector([CargoTendersListFiltersState])
  static isMyTenders(state: CargoTendersListFiltersStateModel): boolean {
    return state.isMyTenders;
  }

  @Selector([CargoTendersListFiltersState])
  static isInternational(state: CargoTendersListFiltersStateModel): boolean {
    return state.isInternational;
  }

  @Selector([CargoTendersListFiltersState])
  static userId(state: CargoTendersListFiltersStateModel): number {
    return state.userId;
  }

  @Selector([CargoTendersListFiltersState])
  static companyMembers(state: CargoTendersListFiltersStateModel): User[] {
    return state.companyMembers;
  }

  @Selector([CargoTendersListFiltersState])
  static partnerId(state: CargoTendersListFiltersStateModel): number {
    return state.partnerId;
  }

  @Selector([CargoTendersListFiltersState])
  static partners(
    state: CargoTendersListFiltersStateModel
  ): CompanyPartnership[] {
    return state.partners;
  }

  @Selector([CargoTendersListFiltersState])
  static countryFrom(state: CargoTendersListFiltersStateModel): number {
    return state.countryFrom;
  }

  @Selector([CargoTendersListFiltersState])
  static countryTo(state: CargoTendersListFiltersStateModel): number {
    return state.countryTo;
  }

  @Selector([CargoTendersListFiltersState])
  static filteredResultsCount(
    state: CargoTendersListFiltersStateModel
  ): FilteredTendersResultsCount {
    return state.filteredResultsCount;
  }
}

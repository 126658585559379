import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Injectable } from '@angular/core';
import {
  CloseIcebergPopup,
  CloseIcebergVideoPopup,
  DisplayFragment,
  DisplayNextFragment,
  EnableIntervalRotation,
  OpenIcebergPopup,
  OpenIcebergVideoPopup,
  SetIsMouseOverIceberg,
} from './main-page.actions';
import { MainPageStateModel } from './main-page-state.model';

import { fragments } from '@app/pages/main-page/components/iceberg-fragments/fragments';
import { Fragment } from '@app/pages/main-page/components/iceberg-fragments/interfaces/fragment';

@Injectable()
@State<MainPageStateModel>({
  name: 'main_page',
  defaults: {
    isMouseOverIceberg: false,
    hoveredFragmentName: null,
    activeFragmentName: null,
    intervalRotationEnabled: true,
    popupData: null,
    videoUrl: null,
  },
})
export class MainPageState {
  @Action(DisplayFragment)
  @ImmutableContext()
  displayFragment(
    { setState }: StateContext<MainPageStateModel>,
    { fragmentName }: DisplayFragment
  ) {
    return setState((state: MainPageStateModel) => {
      state.hoveredFragmentName = fragmentName;
      const fragment: Fragment = fragments.find((fragment: Fragment) => {
        return fragment.name === fragmentName;
      });

      if (fragment?.popupData) {
        state.activeFragmentName = fragmentName;
      }

      state.intervalRotationEnabled = false;
      state.isMouseOverIceberg = true;
      return state;
    });
  }

  @Action(EnableIntervalRotation)
  @ImmutableContext()
  enableIntervalRotation({ setState }: StateContext<MainPageStateModel>) {
    return setState((state: MainPageStateModel) => {
      state.intervalRotationEnabled = true;
      return state;
    });
  }

  @Action(DisplayNextFragment)
  @ImmutableContext()
  displayNextFragment({ setState }: StateContext<MainPageStateModel>) {
    return setState((state: MainPageStateModel) => {
      state.hoveredFragmentName = state.activeFragmentName =
        this.getNextActivatableFragment(state.activeFragmentName);
      return state;
    });
  }

  getNextActivatableFragment(fragment: number | null): number {
    const availableFragments: number[] = fragments
      .filter((fragment: Fragment) => {
        return !!fragment.popupData;
      })
      .map((fragment: Fragment) => {
        return fragment.name;
      });

    const currentIndex = availableFragments.indexOf(fragment);
    return availableFragments[currentIndex + 1] || availableFragments[0];
  }

  @Action(SetIsMouseOverIceberg)
  @ImmutableContext()
  setIsMouseOverIceberg(
    { setState }: StateContext<MainPageStateModel>,
    { isMouseOverIceberg }: SetIsMouseOverIceberg
  ) {
    return setState((state: MainPageStateModel) => {
      state.intervalRotationEnabled = true;
      state.isMouseOverIceberg = isMouseOverIceberg;
      return state;
    });
  }

  @Action(OpenIcebergPopup)
  @ImmutableContext()
  openIcebergPopup(
    { setState }: StateContext<MainPageStateModel>,
    { data }: OpenIcebergPopup
  ) {
    return setState((state: MainPageStateModel) => {
      state.popupData = data;
      return state;
    });
  }

  @Action(CloseIcebergPopup)
  @ImmutableContext()
  closeIcebergPopup({ setState }: StateContext<MainPageStateModel>) {
    return setState((state: MainPageStateModel) => {
      state.popupData = null;
      return state;
    });
  }

  @Action(OpenIcebergVideoPopup)
  @ImmutableContext()
  openIcebergVideoPopup(
    { setState }: StateContext<MainPageStateModel>,
    { videoUrl }: OpenIcebergVideoPopup
  ) {
    return setState((state: MainPageStateModel) => {
      state.videoUrl = videoUrl;
      return state;
    });
  }

  @Action(CloseIcebergVideoPopup)
  @ImmutableContext()
  closeIcebergVideoPopup({ setState }: StateContext<MainPageStateModel>) {
    return setState((state: MainPageStateModel) => {
      state.videoUrl = null;
      return state;
    });
  }
}

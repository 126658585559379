import { Injectable } from '@angular/core';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { ApiService } from '@app/services/api.service';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GroupChatTypes } from '@app/modules/chat/group-chat-types';
import { ChatGroup } from '@app/modules/chat/interfaces/chat-group';
import { SaveChatGroupRequest } from '@app/modules/chat/interfaces/create-chat-group-request';
import { MultipleResults } from '@app/interfaces/multiple-results';
import { NotFound } from '@app/errors/response-errors/not-found';
import { User } from '@app/modules/users/interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class ChatGroupsService {
  private requiredFields: string[] = [
    'id',
    'userId',
    'name',
    'avatar',
    'type.code',
    'unreadMessageCount',
    'date.updatedAt',
    'member.id',
    'member.isContact',
    'member.isBlacklist',
    'lastMessage.id',
    'lastMessage.message',
    'lastMessage.date.createdAt',
    'lastMessage.isRead',
    'lastMessage.user.id',
  ];

  constructor(private apiService: ApiService) {}

  getUncreatedID(id: number, chatType: GroupChatTypes): number {
    return +`-${id}.${chatType}`;
  }

  extractEntityIDfromUncreatedChatID(uncreatedChatID: number): number {
    return Math.floor(Math.abs(uncreatedChatID));
  }

  getPrivateChat(id: number): Observable<ChatGroup> {
    return this.apiService
      .get<ChatGroup>('/chat/by-member/' + id, {
        fields: [...this.requiredFields].join(','),
        expand: ['lastMessage'].join(','),
      })
      .pipe(
        catchError((error: any) => {
          if (error instanceof NotFound) {
            return of(null);
          }

          return throwError(() => error);
        })
      );
  }

  /**
   * Create private chat with a user
   * If a private chat with the user already exists, then the existing chat will be returned
   *
   * @param userID User ID with whom the private chat should be created
   * @returns
   */
  createPrivateChat(userID: number): Observable<ChatGroup> {
    return this.apiService.create<ChatGroup>('/chat', {
      type: GroupChatTypes.PRIVATE,
      memberIds: [userID],
    });
  }

  createGroupChat(groupData: SaveChatGroupRequest): Observable<ChatGroup> {
    return this.apiService
      .post<ResponseSuccess<ChatGroup>>('/chat', {
        type: GroupChatTypes.GROUP,
        name: groupData.name,
        memberIds: groupData.memberIds,
        avatar: groupData.avatar,
      })
      .pipe(
        map((response: ResponseSuccess<ChatGroup>) => {
          return response.data;
        })
      );
  }

  createTransportTenderChat(tenderId: number): Observable<ChatGroup> {
    return this.apiService
      .post<ResponseSuccess<ChatGroup>>('/exchange/chat', {
        tenderId,
      })
      .pipe(
        map((response: ResponseSuccess<ChatGroup>) => {
          return response.data;
        })
      );
  }

  updateGroupChat(groupData: SaveChatGroupRequest): Observable<ChatGroup> {
    return this.apiService.put<ChatGroup>('/chat/' + groupData.id, {
      name: groupData.name,
      avatar: groupData.avatar,
      memberIds: groupData.memberIds,
    });
  }

  deleteGroupChat(groupId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<ChatGroup>>('/chat/' + groupId)
      .pipe(
        map((response: ResponseSuccess<ChatGroup>) => {
          return response.statusCode === 202;
        })
      );
  }

  getMyChats(
    count: number = null,
    search: string = null
  ): Observable<MultipleResults<ChatGroup>> {
    return this.list(count, search, {
      myChats: 1,
    });
  }

  getTendersChats(
    count: number = null,
    search: string = null
  ): Observable<MultipleResults<ChatGroup>> {
    return this.list(count, search, {
      type: GroupChatTypes.TENDER,
    });
  }

  private list(
    count: number = null,
    search: string = null,
    params: any = {}
  ): Observable<MultipleResults<ChatGroup>> {
    params = {
      ...params,
      fields: [...this.requiredFields].join(','),
      expand: ['lastMessage'].join(','),
    };

    if (count > 0) {
      params.count = count;
    }

    if (search) {
      params.name = search;
    }

    return this.apiService.getMultiple<ChatGroup>('/chat', params);
  }

  getSpecialChatGroup(): Observable<ChatGroup> {
    return this.apiService.get<ChatGroup>('/chat/special', {
      fields: [...this.requiredFields].join(','),
      expand: ['lastMessage'].join(','),
    });
  }

  createSpecialChatGroup(): Observable<ChatGroup> {
    return this.apiService
      .post<ResponseSuccess<ChatGroup>>('/chat/special')
      .pipe(
        map((response: ResponseSuccess<ChatGroup>) => {
          return response.data;
        })
      );
  }

  getChatGroup(id: number): Observable<ChatGroup> {
    return this.apiService.get<ChatGroup>('/chat/' + id, {
      fields: [...this.requiredFields].join(','),
      expand: ['lastMessage'].join(','),
    });
  }

  // @todo: change endpoint/parameters, when backend will support such query
  getChatGroupInMyChats(id: number): Observable<ChatGroup> {
    return this.apiService.get<ChatGroup>('/chat/' + id, {
      fields: [...this.requiredFields].join(','),
      expand: ['lastMessage'].join(','),
    });
  }

  getChatGroupMembers(id: number): Observable<User[]> {
    return this.apiService
      .get<ChatGroup>('/chat/' + id, {
        fields: [
          'allMembers.id',
          'allMembers.avatar',
          'allMembers.first_name',
          'allMembers.middle_name',
          'allMembers.last_name',
        ].join(','),
        expand: ['allMembers'].join(','),
      })
      .pipe(
        map((chatGroup: ChatGroup) => {
          return chatGroup.allMembers;
        })
      );
  }

  markAsRead(chatGroupID: number): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/chat/' + chatGroupID + '/messages/read'
    );
  }
}

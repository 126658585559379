import { Selector } from '@ngxs/store';
import { TendersPageCarState } from './tenders-page_car.state';
import { TendersPageCarStateModel } from './tenders-page_car-state.model';
import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';

export class TendersPageCarSelectors {
  @Selector([TendersPageCarState])
  static tenders(state: TendersPageCarStateModel): CarCargoTender[] {
    return state.tenders;
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class LoadCompanyTendersCreatedCar {
  static readonly type = '[Company Tenders Created Car] Load company tenders ';
}

export class SetCompanyTendersCreatedCarPage {
  static readonly type =
    '[Company Tenders Created Car] Set company tenders page';
  constructor(public page: number) {}
}

export class SetCompanyTendersCreatedCarDatePeriod {
  static readonly type =
    '[Company Tenders Created Car] Set company tenders date period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetCompanyTendersCreatedCarState {
  static readonly type =
    '[Company Tenders Created Car] Set company tenders state';
  constructor(public states: TenderStates[]) {}
}

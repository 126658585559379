import { CompanyBank } from '@app/modules/companies/interfaces/company-bank';
import { Selector } from '@ngxs/store';
import { CompanyBanksStateModel } from './company-banks-state.model';
import { CompanyBanksState } from './company-banks.state';
import { FormattedFieldErrors } from '@app/interfaces/formatted-field-errors';

export class CompanyBanksSelectors {
  @Selector([CompanyBanksState])
  static banks(state: CompanyBanksStateModel): CompanyBank[] {
    return state.banks;
  }

  @Selector([CompanyBanksState])
  static fieldErrors(state: CompanyBanksStateModel): FormattedFieldErrors[] {
    const errors = [];

    Object.keys(state.fieldsErrors).forEach((key: string) => {
      const errorMessages = [];
      state.fieldsErrors[key].forEach((errorMessage: string) => {
        errorMessages.push(errorMessage);
      });

      errors.push({
        field: key,
        errors: errorMessages,
      });
    });

    return errors;
  }
}

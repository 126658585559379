import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataService {
  protected url: string;

  constructor(private http: HttpClient) {}

  get<T>(endpoint: string, obj: object = null): Observable<T> {
    return this.http.get<T>(
      this.getEndpoint(endpoint),
      this.getHttpParams(obj)
    );
  }

  post<T>(
    endpoint: string,
    data = null,
    obj: object = null,
    headers: object = null
  ): Observable<T> {
    return this.http.post<T>(
      this.getEndpoint(endpoint),
      data,
      this.getHttpParams(obj, headers)
    );
  }

  upload<T>(
    endpoint: string,
    data = null,
    obj: object = null,
    headers: object = null
  ): Observable<HttpEvent<T>> {
    return this.http.post<T>(this.getEndpoint(endpoint), data, {
      reportProgress: true,
      observe: 'events',
      ...this.getHttpParams(obj, headers),
    });
  }

  put<T>(endpoint: string, data = null, obj: object = null): Observable<T> {
    return this.http.put<T>(
      this.getEndpoint(endpoint),
      data,
      this.getHttpParams(obj)
    );
  }

  delete<T>(endpoint: string, obj: object = null): Observable<T> {
    return this.http.delete<T>(
      this.getEndpoint(endpoint),
      this.getHttpParams(obj)
    );
  }

  protected getHttpParams(
    obj: object,
    additionalHeaders?: object
  ): { headers: HttpHeaders; params?: any } {
    const requestOptions: any = {};
    const defaultHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const headers = {
      ...defaultHeaders,
      ...additionalHeaders,
    };

    if (headers['Content-Type'] === 'default') {
      delete headers['Content-Type'];
    }

    requestOptions.headers = new HttpHeaders(headers);

    if (obj !== null) {
      requestOptions.params = this.objectToHttpParams(obj);
    }

    return requestOptions;
  }

  protected objectToHttpParams(obj: object): HttpParams {
    let params = new HttpParams();
    for (const key of Object.keys(obj)) {
      if (obj[key] !== null) {
        if (typeof obj[key] === 'object') {
          if (obj[key]) {
          }
          obj[key].forEach((arrayValue: string) => {
            params = params.append(key + '[]', arrayValue);
          });
        } else {
          params = params.append(key, obj[key] as unknown as string);
        }
      }
    }

    return params;
  }

  private getEndpoint(url: string) {
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }

    return this.url + url;
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdditionalTransportationRequirement } from '@app/modules/additional-transportation-requirements/interfaces/additional-transportation-requirement';

@Injectable({
  providedIn: 'root',
})
export class AdditionalTransportationRequirementsService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<AdditionalTransportationRequirement[]> {
    return this.apiService
      .getAll<AdditionalTransportationRequirement>(
        '/additional-transportation-requirement'
      )
      .pipe(
        map(
          (
            additionalTransportationRequirements: AdditionalTransportationRequirement[]
          ) => {
            return additionalTransportationRequirements.reverse();
          }
        )
      );
  }
}

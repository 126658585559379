import { Selector } from '@ngxs/store';
import { TendersPageTransportState } from './tenders-page_transport.state';
import { TendersPageTransportStateModel } from './tenders-page_transport-state.model';
import { TransportTender } from '@app/modules/transport-tender/interfaces/transport-tender';

export class TendersPageTransportSelectors {
  @Selector([TendersPageTransportState])
  static tenders(state: TendersPageTransportStateModel): TransportTender[] {
    return state.tenders;
  }
}

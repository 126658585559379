/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@envs/environment';

import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import packageInfo from '../package.json';
import { RewriteFrames } from '@sentry/integrations';

if (environment.sentry_dsn) {
  Sentry.init({
    dsn: environment.sentry_dsn,
    environment: environment.name,
    release: 'my-app-name@' + packageInfo.version,
    integrations: [
      new RewriteFrames(),
      new BrowserTracing({
        tracingOrigins: [
          environment.origin,
          environment.api_url,
          environment.mercure_url,
        ],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

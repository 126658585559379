import { RegistrationFormData } from '@app/pages/registration/interfaces/registration-form-data';
import { ResponseError } from '@app/interfaces/response-error';

export class InitRegistrationPage {
  static readonly type = '[Registration Page] Initialize';
  constructor() {}
}

export class TryRegister {
  static readonly type = '[Registration Page] Try register';
  constructor(public payload: RegistrationFormData) {}
}
export class RegistrationFailed {
  static readonly type =
    '[Registration Page] Registration failed, wrong data provided?';
  constructor(public responseError: ResponseError) {}
}
export class RegistrationSucceess {
  static readonly type =
    '[Registration Page] Registration succeeded, redirect to dashboard...';
  constructor() {}
}

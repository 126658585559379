import { CalendarTaskStates } from '@app/pages/calendar/calendar-task-states';
import { CalendarTaskTypes } from '@app/pages/calendar/calendar-task-types';
import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';
import { Selector } from '@ngxs/store';
import { TasksCalendarListState } from './tasks-calendar_list.state';
import { TasksCalendarListStateModel } from './tasks-calendar_list.state-model';

export class TasksCalendarListSelectors {
  @Selector([TasksCalendarListState])
  static filters(state: TasksCalendarListStateModel): CalendarListFilters {
    return state.filters;
  }

  @Selector([TasksCalendarListState])
  static type(state: TasksCalendarListStateModel): CalendarTaskTypes {
    return state.filters.type;
  }

  @Selector([TasksCalendarListState])
  static state(state: TasksCalendarListStateModel): CalendarTaskStates {
    return state.filters.state;
  }

  @Selector([TasksCalendarListState])
  static displayFilters(state: TasksCalendarListStateModel): boolean {
    return state.displayFilters;
  }
}

import { CarCargoTender } from '@app/modules/tenders/interfaces/car-cargo-tender';
import { TenderStates } from '@app/modules/tenders/tender-states';

export class SetCarCargoTenders {
  static readonly type = '[Car Cargo Tenders List] Set car cargo tenders list';
  constructor(public tenders?: CarCargoTender[]) {}
}

export class SetCarCargoTendersPagination {
  static readonly type =
    '[Car Cargo Tenders List] Set cargo tenders pagination';
  constructor(public page: number, public maxPage: number) {}
}

export class SetCarCargoTendersSearchValue {
  static readonly type =
    '[Car Cargo Tenders List] Set cargo tenders search value';
  constructor(public searchValue: string) {}
}

export class LoadNewCarCargoTendersCount {
  static readonly type =
    '[Car Cargo Tenders List] Load new cargo tenders count';
  constructor() {}
}

export class ReadCarCargoTender {
  static readonly type = '[Car Cargo Tenders List] Mark cargo tender as read';
  constructor(public tenderId: number) {}
}

export class ReloadCarCargoTender {
  static readonly type = '[Car Cargo Tenders List] Reload cargo tender';
  constructor(public tenderId: number) {}
}

export class HighlightCarCargoTenderOnMap {
  static readonly type =
    '[Car Cargo Tenders List] Highlight cargo tender on map';
  constructor(public tenderId: number, public tenderState: TenderStates) {}
}

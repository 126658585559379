import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, catchError, of } from 'rxjs';
import { Company } from '@app/modules/companies/interfaces/company';
import { PaginationResults } from '@app/interfaces/pagination-results';
import { CompaniesCreateFormData } from '@app/pages/companies/create/interfaces/companies_create.form-data';
import { CompanyTypes } from '@app/modules/companies/company-types';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { map, mergeMap } from 'rxjs/operators';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { User } from '@app/modules/users/interfaces/user';
import { CodeName } from '@app/interfaces/code-name';
import { SaveCompanyMarkupFormData } from '@app/pages/companies/view/tab4/components/set-company-markup/save-company-markup-form-data';
import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { FileService } from '@app/services/file.service';
import { UiService } from '@app/services/ui.service';
import { CompaniesListFiltersFormData } from '@app/pages/companies/list/components/filters/companies-list-filters.form-data';
import { CargoTendersCarService } from '@app/modules/tenders/services/cargo-tenders-car.service';
import { CompanyTender } from '@app/modules/companies/interfaces/company-tender';
import { FilteredTendersResultsCount } from '@app/modules/tenders/filtered-tenders-results-count';
import { CompanyTendersSummary } from '@app/modules/companies/interfaces/company-tenders-summary';
import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStates } from '@app/modules/tenders/tender-states';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { IdName } from '@app/interfaces/id-name';
import { SearchEntityParams } from '@app/modules/form-elements/components/select-entity/interfaces/search-entity-params';
import { CompanyTendersTypes } from '@app/pages/companies/view/company-tenders/company-tenders-types';
import { TransportTenderService } from '@app/modules/transport-tender/services/transport-tender.service';

marker('MODULES.COMPANIES.SERVICES.COMPANIES.Грузовладелец');
marker('MODULES.COMPANIES.SERVICES.COMPANIES.Грузоперевозчик');
marker('MODULES.COMPANIES.SERVICES.COMPANIES.Экспедитор');
marker('MODULES.COMPANIES.SERVICES.COMPANIES.Завод');

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  constructor(
    private apiService: ApiService,
    private fileService: FileService,
    private uiService: UiService,
    private cargoTendersCarService: CargoTendersCarService,
    private cargoTendersSeaService: CargoTendersSeaService,
    private transportTenderService: TransportTenderService
  ) {}

  getCompany(companyId: number): Observable<Company> {
    return this.apiService.get<Company>('/company/' + companyId, {
      expand: [
        'contacts',
        'country',
        'details',
        'details.emails',
        'details.founders',
        'details.phones',
        'owner',
        'limits',
      ].join(','),
    });
  }

  getCompanies(
    page: number = 1,
    searchValue?: string,
    filters: CompaniesListFiltersFormData = {},
    isFavorite: boolean = false
  ): Observable<PaginationResults<Company>> {
    const data: any = {
      fields: [
        'id',
        'companyType.code',
        'companyType.name',
        'details.companyName.short',
        'details.tin',
        'details.address',
        'date.lastLogin',
        'date.createdAt',
        'status.code',
        'status.name',
        'isFavorite',
        'tenderCount',
      ].join(','),
      expand: ['details'].join(','),
      page,
      ...this.prepareFilters(filters),
    };

    if (searchValue) {
      data.search = searchValue;
    }

    if (isFavorite) {
      data.isFavorite = 1;
    }

    return this.apiService.getPaginatedResults<Company>('/company', data);
  }

  listCompanies(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName>> {
    return this.getCompanies(params.page, params.search, {
      byName: 1,
      sort: 'name',
    }).pipe(
      map((results: PaginationResults<Company>) => {
        const preparedResults: PaginationResults<IdName> = {
          ...results,
          data: results.data.map((company: Company) => {
            return {
              id: company.id,
              name: company.details.companyName.short,
              tin: company.details.tin,
              address: company.details.address,
            };
          }),
        };

        return preparedResults;
      })
    );
  }

  protected prepareFilters(filters: CompaniesListFiltersFormData): any {
    let result: any = filters;

    if (result.isAttendanceCompany) {
      if (result.attendanceDatePeriod?.from) {
        result.attendanceFrom = result.attendanceDatePeriod.from
          .split('-')
          .reverse()
          .join('.');
      }
      if (result.attendanceDatePeriod?.to) {
        result.attendanceTo = result.attendanceDatePeriod.to
          .split('-')
          .reverse()
          .join('.');
      }
    }

    if (filters.attendanceDatePeriod) {
      delete filters.attendanceDatePeriod;
    }

    return filters;
  }

  searchCompanies(searchValue?: string): Observable<Company[]> {
    const data: any = {
      fields: ['id', 'details.companyName.short'].join(','),
      expand: ['details'].join(','),
      search: searchValue,
    };

    return this.apiService.getPaginatedResults<Company>('/company', data).pipe(
      map((result: PaginationResults<Company>) => {
        return result.data;
      })
    );
  }

  createCompany(data: CompaniesCreateFormData): Observable<unknown> {
    return this.apiService.post<unknown>('/company', data);
  }

  deleteCompany(id: number): Observable<NewAccessTokenResponse> {
    return this.apiService
      .delete<ResponseSuccess<NewAccessTokenResponse>>('/company/' + id)
      .pipe(
        map((response: ResponseSuccess<NewAccessTokenResponse>) => {
          return response.data;
        })
      );
  }

  getCompanyTypes(): CodeName<CompanyTypes>[] {
    return [
      {
        code: CompanyTypes.SHIPPER,
        name: 'MODULES.COMPANIES.SERVICES.COMPANIES.Грузовладелец',
      },
      {
        code: CompanyTypes.CARRIER,
        name: 'MODULES.COMPANIES.SERVICES.COMPANIES.Грузоперевозчик',
      },
      {
        code: CompanyTypes.FORWARDER,
        name: 'MODULES.COMPANIES.SERVICES.COMPANIES.Экспедитор',
      },
      {
        code: CompanyTypes.FACTORY,
        name: 'MODULES.COMPANIES.SERVICES.COMPANIES.Завод',
      },
    ];
  }

  updateCompanyMarkup(data: SaveCompanyMarkupFormData): Observable<Company> {
    const companyId = data.id;
    delete data.id;
    return this.apiService.put<Company>(
      '/company/' + companyId + '/markup',
      data
    );
  }

  updateCompanyData(companyId: number): Observable<any> {
    return this.apiService.put('/company/' + companyId + '/details');
  }

  getDrivers(companyId: number): Observable<User[]> {
    return this.apiService
      .getAll<CompanyMember>('/company/' + companyId + '/staff/drivers', {
        fields: [
          'user.id',
          'user.first_name',
          'user.middle_name',
          'user.last_name',
        ].join(','),
        expand: ['user'].join(','),
      })
      .pipe(
        map((members: CompanyMember[]) => {
          return members.map((member: CompanyMember) => {
            return member.user;
          });
        })
      );
  }

  listDrivers(
    params: SearchEntityParams
  ): Observable<PaginationResults<IdName>> {
    const companyId = params.companyId;
    delete params.companyId;
    return this.apiService
      .getPaginatedResults<CompanyMember>(
        '/company/' + companyId + '/staff/drivers',
        {
          fields: [
            'user.id',
            'user.first_name',
            'user.middle_name',
            'user.last_name',
          ].join(','),
          expand: ['user'].join(','),
          ...params,
        }
      )
      .pipe(
        map((results: PaginationResults<CompanyMember>) => {
          const preparedResults: PaginationResults<IdName> = {
            ...results,
            data: results.data.map((member: CompanyMember) => {
              return {
                id: member.user.id,
                name:
                  member.user.first_name +
                  ' ' +
                  member.user.middle_name +
                  ' ' +
                  member.user.last_name,
              };
            }),
          };

          return preparedResults;
        })
      );
  }

  activateCompany(companyId: number): Observable<Company> {
    return this.apiService.put<Company>('/company/' + companyId + '/activate');
  }

  deactivateCompany(companyId: number): Observable<Company> {
    return this.apiService.put<Company>(
      '/company/' + companyId + '/deactivate'
    );
  }

  getShippers(): Observable<Company[]> {
    return this.apiService.getAll<Company>('/company/shipper', {
      fields: [
        'id, details.tin, details.companyName.short',
        'details.address',
      ].join(','),
      expand: ['details'].join(','),
    });
  }

  addShipper(tin: number): Observable<Company> {
    return this.apiService
      .post<ResponseSuccess<Company>>('/company/shipper', { tin })
      .pipe(
        map((response: ResponseSuccess<Company>) => {
          return response.data;
        })
      );
  }

  getConsignees(): Observable<Company[]> {
    return this.apiService.getAll<Company>('/company/consignee', {
      fields: [
        'id, details.tin, details.companyName.short',
        'details.address',
      ].join(','),
      expand: ['details'].join(','),
    });
  }

  addConsignee(tin: number): Observable<Company> {
    return this.apiService
      .post<ResponseSuccess<Company>>('/company/consignee', { tin })
      .pipe(
        map((response: ResponseSuccess<Company>) => {
          return response.data;
        })
      );
  }

  setAutoSignature(
    companyId: number,
    autoSignature: boolean
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/company/' + companyId + '/auto-signature',
      { autoSignature }
    );
  }

  setUseUserSignature(
    companyId: number,
    useUserSignature: boolean
  ): Observable<unknown> {
    return this.apiService.put<unknown>(
      '/company/' + companyId + '/user-signature',
      { useUserSignature }
    );
  }

  uploadStamp(companyId: number, file: File): Observable<string> {
    return this.fileService.fileAsBase64(file).pipe(
      mergeMap((fileAsBase64: string) => {
        return this.apiService
          .put<Company>('/company/' + companyId + '/stamp', {
            image: fileAsBase64,
          })
          .pipe(
            catchError((err: any) => {
              this.uiService.displayProvidedErrors(
                err.originalError.error.errors,
                true
              );
              return of(null);
            }),
            map((company: Company) => {
              return company.stamp;
            })
          );
      })
    );
  }

  uploadSignature(companyId: number, file: File): Observable<string> {
    return this.fileService.fileAsBase64(file).pipe(
      mergeMap((fileAsBase64: string) => {
        return this.apiService
          .put<Company>('/company/' + companyId + '/signature', {
            image: fileAsBase64,
          })
          .pipe(
            catchError((err: any) => {
              this.uiService.displayProvidedErrors(
                err.originalError.error.errors,
                true
              );
              return of(null);
            }),
            map((company: Company) => {
              return company.signature;
            })
          );
      })
    );
  }

  updateCompanyType(
    companyId: number,
    companyType: CompanyTypes
  ): Observable<Company> {
    return this.apiService.put<Company>(
      '/company/' + companyId + '/change-type',
      { companyType }
    );
  }

  setCompanyTimeBlocking(
    companyId: number,
    timeBlocking?: string
  ): Observable<Company> {
    return this.apiService.put<Company>(
      '/company/' + companyId + '/time-blocking',
      { timeBlocking }
    );
  }

  getCompanyCarCargoTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    return this.cargoTendersCarService.getCompanyTenders(
      companyId,
      companyTendersTypes,
      page,
      datePeriod,
      state
    );
  }

  getCompanySeaCargoTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    return this.cargoTendersSeaService.getCompanyTenders(
      companyId,
      companyTendersTypes,
      page,
      datePeriod,
      state
    );
  }

  getCompanyTransportTenders(
    companyId: number,
    companyTendersTypes: CompanyTendersTypes,
    page: number = 1,
    datePeriod: DatePeriod = null,
    state: TenderStates[] = null
  ): Observable<
    PaginationResults<
      CompanyTender,
      FilteredTendersResultsCount,
      CompanyTendersSummary
    >
  > {
    return this.transportTenderService.getCompanyTenders(
      companyId,
      companyTendersTypes,
      page,
      datePeriod,
      state
    );
  }

  checkCurrencyLimit(
    companyId: number,
    currencyId: number
  ): Observable<boolean> {
    return this.apiService
      .post<ResponseSuccess<{ validLimit: boolean }>>(
        '/company/' + companyId + '/check-limits',
        { currencyId }
      )
      .pipe(
        map((result: ResponseSuccess<{ validLimit: boolean }>) => {
          return result.data.validLimit;
        })
      );
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { map, Observable } from 'rxjs';
import { ChatFeedback } from '@app/modules/chat/interfaces/chat-feedback';
import { MessageFeedback } from '@app/modules/chat/interfaces/meesage-feedback';
import { ResponseSuccess } from '@app/interfaces/response-success';

@Injectable({
  providedIn: 'root',
})
export class ChatFeedbackService {
  constructor(private apiService: ApiService) {}

  getAll(): Observable<ChatFeedback[]> {
    return this.apiService.getAll<ChatFeedback>('/chat/message/feedback', {
      fields: ['id', 'smiley'].join(','),
    });
  }

  likeMessage(
    chatID: number,
    messageID: number,
    feedbackID: number
  ): Observable<MessageFeedback> {
    return this.apiService.put(
      '/chat/' + chatID + '/message/' + messageID + '/feedback',
      {
        feedbackId: feedbackID,
      }
    );
  }

  removeMessageFeedback(
    chatID: number,
    messageID: number,
    feedbackID: number
  ): Observable<boolean> {
    return this.apiService
      .delete(
        '/chat/' + chatID + '/message/' + messageID + '/feedback/' + feedbackID
      )
      .pipe(
        map((response: ResponseSuccess<boolean>) => {
          return response.data;
        })
      );
  }
}

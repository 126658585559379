import { Transport } from '@app/modules/transport/interfaces/transport';
import { Selector } from '@ngxs/store';
import { TransportListStateModel } from './transport_list-state.model';
import { TransportListState } from './transport_list.state';

export class TransportListSelectors {
  @Selector([TransportListState])
  static searchValue(state: TransportListStateModel): string {
    return state.searchValue;
  }

  @Selector([TransportListState])
  static isFavorite(state: TransportListStateModel): boolean {
    return state.isFavorite;
  }

  @Selector([TransportListState])
  static transport(state: TransportListStateModel): Transport[] {
    return state.transport;
  }

  @Selector([TransportListState])
  static page(state: TransportListStateModel): number {
    return state.page;
  }

  @Selector([TransportListState])
  static maxPage(state: TransportListStateModel): number {
    return state.maxPage;
  }
}

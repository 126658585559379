import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { UserNotification } from '@app/modules/notifications/interfaces/user-notification';
import { NotificationTypes } from '@app/modules/notifications/notification-types';
import { UserNotificationStates } from '@app/modules/notifications/user-notification-states';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apiService: ApiService) {}

  list(): Observable<UserNotification[]> {
    return this.apiService.getAll<UserNotification>('/notifications');
  }

  update(
    notificationId: number,
    type: NotificationTypes,
    state: UserNotificationStates
  ): Observable<UserNotification[]> {
    return this.apiService.put<UserNotification[]>('/notifications', [
      {
        notificationId,
        [type]: state,
      },
    ]);
  }
}

import { createSelector } from '@ngxs/store';
import { StatisticsStateModel } from './statistics-state.model';

export class StatisticsSelectors {
  static statistics(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.statistics.data;
    });
  }

  static summary(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.statistics.summary;
    });
  }

  static filters(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.filters;
    });
  }

  static page(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.filters.page;
    });
  }

  static maxPage(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.statistics.maxPage;
    });
  }

  static searchValue(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return state.filters.search;
    });
  }

  static datePeriod(stateClass) {
    return createSelector([stateClass], (state: StatisticsStateModel) => {
      return {
        from: state.filters.doneAtFrom,
        to: state.filters.doneAtTo,
      };
    });
  }
}

import { Injectable } from '@angular/core';
import { User } from '@app/modules/users/interfaces/user';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseSuccess } from '@app/interfaces/response-success';

@Injectable({
  providedIn: 'root',
})
export class ChatContactsService {
  constructor(private apiService: ApiService) {}

  contactFields = [
    'id',
    'avatar',
    'first_name',
    'middle_name',
    'last_name',
    'login_at',
    'isContact',
    'isBlacklist',
  ];

  searchContact(searchQuery: string): Observable<User> {
    return this.apiService
      .getAll<User>('/user', {
        fields: [...this.contactFields].join(','),
        search: searchQuery,
        exact: 1,
      })
      .pipe(
        map((results: User[]) => {
          return results[0];
        })
      );
  }

  listAll(): Observable<User[]> {
    return this.apiService.getAll<User>('/chat/user/contact', {
      fields: [...this.contactFields].join(','),
    });
  }

  add(userId: number): Observable<User> {
    return this.apiService.create<User>('/chat/user/contact', {
      userId,
    });
  }

  delete(userId: number): Observable<boolean> {
    return this.apiService
      .delete<ResponseSuccess<{ contacts: User[] }>>(
        '/chat/user/contact/' + userId
      )
      .pipe(
        map((response: ResponseSuccess<{ contacts: User[] }>) => {
          return response.statusCode === 202;
        })
      );
  }
}

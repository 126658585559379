import { CalendarAddEditFormData } from '@app/pages/calendar/components/calendar-add-edit-task/calendar-add-edit.form-data';

export class CreateTaskInCalendar {
  static readonly type = '[Calendar] Create new task in the calendar';
  constructor(public data: CalendarAddEditFormData) {}
}

export class UpdateTaskInCalendar {
  static readonly type = '[Calendar] Update a task in the calendar';
  constructor(public id: number, public data: CalendarAddEditFormData) {}
}

import { Selector } from '@ngxs/store';
import { FinancesBillsState } from './finances_bills.state';
import { FinancesBillsStateModel } from './finances_bills-state.model';
import { FinancesBillsTabs } from '@app/pages/finances/bills/finances-bills-tabs';

export class FinancesBillsSelectors {
  @Selector([FinancesBillsState])
  static activeTab(state: FinancesBillsStateModel): FinancesBillsTabs {
    return state.activeTab;
  }
}

import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  CloseSeaCargoTender,
  RefuseSeaCargoTender,
  CompleteSeaCargoTender,
  SetSeaCargoTenderPointState,
  CancelSeaCargoTender,
  NewBetPlacedInSeaCargoTender,
  ToggleCanChangeTransportInSeaCargoTender,
  SetSeaCargoTenderInProgress,
  DeleteSeaCargoTender,
  UpdateSeaCargoPointArrivalDate,
  SetSeaCargoTenderStateCargoDelivered,
  SetSeaCargoTenderBookingNumber,
  SetSeaCargoTenderContainerNumber,
} from './cargo-tenders-view_sea.actions';
import { CargoTendersViewSeaStateModel } from './cargo-tenders-view_sea-state.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SelectSeaCargoTenderBet } from '@app/store/cargo-tenders/cargo-tenders.actions';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import { SeaCargoTenderBetSelected } from '@app/store/cargo-tenders/cargo-tenders.actions';
import { ViewSeaCargoTender } from '@app/store/tabs';
import { SeaCargoTenderLoadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-load-place';
import { SeaCargoTenderUnloadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-unload-place';
import { SetWinBet } from '../cargo-tenders_view.actions';

@Injectable()
@State<CargoTendersViewSeaStateModel>({
  name: 'cargo_tenders__view__sea',
  defaults: {
    tender: null,
  },
})
export class CargoTendersViewSeaState {
  constructor(
    private cargoTendersSeaService: CargoTendersSeaService,
    private store: Store
  ) {}

  @Action(ViewSeaCargoTender)
  @ImmutableContext()
  loadSeaCargoTender(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId, tenderNumber }: ViewSeaCargoTender
  ) {
    return this.cargoTendersSeaService.get(tenderId, tenderNumber).pipe(
      tap((tender: SeaCargoTender) => {
        setState((state: CargoTendersViewSeaStateModel) => {
          state.tender = tender;
          return state;
        });
      })
    );
  }

  @Action(CloseSeaCargoTender)
  @ImmutableContext()
  closeSeaCargoTender(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { id }: CloseSeaCargoTender
  ) {
    return this.cargoTendersSeaService.closeTender(id);
  }

  @Action(RefuseSeaCargoTender)
  @ImmutableContext()
  refuseSeaCargoTender(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { id }: RefuseSeaCargoTender
  ) {
    return this.cargoTendersSeaService.refuseTender(id);
  }

  @Action(CancelSeaCargoTender)
  @ImmutableContext()
  cancelSeaCargoTender(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { id }: CancelSeaCargoTender
  ) {
    return this.cargoTendersSeaService.cancelTender(id);
  }

  @Action(CompleteSeaCargoTender)
  @ImmutableContext()
  completeSeaCargoTender(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { id }: CompleteSeaCargoTender
  ) {
    return this.cargoTendersSeaService.completeTender(id);
  }

  @Action(SetSeaCargoTenderPointState)
  @ImmutableContext()
  setSeaCargoTenderPointState(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { pointId, state }: SetSeaCargoTenderPointState
  ) {
    return this.cargoTendersSeaService.setTenderPointState(pointId, state);
  }

  @Action(NewBetPlacedInSeaCargoTender)
  @ImmutableContext()
  newBetPlacedInSeaCargoTender(
    { getState, setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId }: NewBetPlacedInSeaCargoTender
  ) {
    if (getState().tender?.id !== tenderId) {
      return;
    }

    return this.cargoTendersSeaService.getTenderBetInfo(tenderId).pipe(
      tap((tender: SeaCargoTender) => {
        setState((state: CargoTendersViewSeaStateModel) => {
          state.tender = { ...state.tender, ...tender };
          return state;
        });
      })
    );
  }

  @Action(SelectSeaCargoTenderBet)
  @ImmutableContext()
  selectSeaCargoTenderBet(
    {}: StateContext<CargoTendersViewSeaStateModel>,
    { bet }: SelectSeaCargoTenderBet
  ) {
    return this.cargoTendersSeaService.selectBet(bet).pipe(
      tap((tender: SeaCargoTender) => {
        this.store.dispatch(
          new SeaCargoTenderBetSelected({ ...tender, id: bet.tenderId })
        );
      })
    );
  }

  @Action(SeaCargoTenderBetSelected)
  @ImmutableContext()
  cargoTenderBetSelected(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tender }: SeaCargoTenderBetSelected
  ) {
    return setState((state: CargoTendersViewSeaStateModel) => {
      state.tender = { ...state.tender, ...tender };
      return state;
    });
  }

  @Action(ToggleCanChangeTransportInSeaCargoTender)
  @ImmutableContext()
  toggleCanChangeTransportInSeaCargoTender(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId }: ToggleCanChangeTransportInSeaCargoTender
  ) {
    return this.cargoTendersSeaService.toggleCanChangeTransport(tenderId).pipe(
      tap(() => {
        return setState((state: CargoTendersViewSeaStateModel) => {
          state.tender.canChangeTransport = !state.tender.canChangeTransport;
          return state;
        });
      })
    );
  }

  @Action(SetSeaCargoTenderInProgress)
  @ImmutableContext()
  setSeaCargoTenderInProgress(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId }: SetSeaCargoTenderInProgress
  ) {
    return this.cargoTendersSeaService.setTenderInProgress(tenderId).pipe(
      tap((tender: SeaCargoTender) => {
        return setState((state: CargoTendersViewSeaStateModel) => {
          state.tender.state = tender.state;

          return state;
        });
      })
    );
  }

  @Action(SetSeaCargoTenderStateCargoDelivered)
  @ImmutableContext()
  setSeaCargoTenderStateCargoDelivered(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId }: SetSeaCargoTenderStateCargoDelivered
  ) {
    return this.cargoTendersSeaService
      .setTenderStateCargoDelivered(tenderId)
      .pipe(
        tap((tender: SeaCargoTender) => {
          return setState((state: CargoTendersViewSeaStateModel) => {
            state.tender.state = tender.state;
            return state;
          });
        })
      );
  }

  @Action(DeleteSeaCargoTender)
  @ImmutableContext()
  deleteSeaCargoTender(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId }: DeleteSeaCargoTender
  ) {
    return this.cargoTendersSeaService.delete(tenderId).pipe(
      tap(() => {
        return setState((state: CargoTendersViewSeaStateModel) => {
          state.tender = null;
          return state;
        });
      })
    );
  }

  @Action(UpdateSeaCargoPointArrivalDate)
  @ImmutableContext()
  updateSeaCargoPointArrivalDate(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { pointID, arrivalDate }: UpdateSeaCargoPointArrivalDate
  ) {
    return this.cargoTendersSeaService
      .updatePointArrivalDate(pointID, arrivalDate)
      .pipe(
        tap(
          (
            updatedPoint: SeaCargoTenderLoadPlace | SeaCargoTenderUnloadPlace
          ) => {
            return setState((state: CargoTendersViewSeaStateModel) => {
              let point: SeaCargoTenderLoadPlace | SeaCargoTenderUnloadPlace;
              switch (updatedPoint.id) {
                case state.tender.loadPlace.id:
                  point = state.tender.loadPlace;
                  break;

                case state.tender.unloadPlace.id:
                  point = state.tender.unloadPlace;
                  break;
              }

              if (point) {
                point.arrivalDate = updatedPoint.arrivalDate;
              }

              return state;
            });
          }
        )
      );
  }

  @Action(SetSeaCargoTenderBookingNumber)
  @ImmutableContext()
  setSeaCargoTenderBookingNumber(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    {
      tenderId,
      bookingNumber,
      additionalBookingNumbers,
    }: SetSeaCargoTenderBookingNumber
  ) {
    return this.cargoTendersSeaService
      .setSeaCargoTenderBookingNumber(
        tenderId,
        bookingNumber,
        additionalBookingNumbers
      )
      .pipe(
        tap((tender: SeaCargoTender) => {
          return setState((state: CargoTendersViewSeaStateModel) => {
            state.tender.bookingNumber = tender.bookingNumber;
            state.tender.additionalBookingNumber =
              tender.additionalBookingNumber;
            return state;
          });
        })
      );
  }

  @Action(SetSeaCargoTenderContainerNumber)
  @ImmutableContext()
  setSeaCargoTenderContainerNumber(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { tenderId, containerNumber }: SetSeaCargoTenderContainerNumber
  ) {
    return this.cargoTendersSeaService
      .setSeaCargoTenderContainerNumber(tenderId, containerNumber)
      .pipe(
        tap((tender: SeaCargoTender) => {
          return setState((state: CargoTendersViewSeaStateModel) => {
            state.tender.containerNumber = tender.containerNumber;
            return state;
          });
        })
      );
  }

  @Action(SetWinBet)
  @ImmutableContext()
  staticetWinBet(
    { setState }: StateContext<CargoTendersViewSeaStateModel>,
    { bet }: SetWinBet
  ) {
    return setState((state: CargoTendersViewSeaStateModel) => {
      if (state.tender.winBet?.id === bet.id) {
        state.tender.winBet = bet;
      }
      return state;
    });
  }
}

import { DatePeriod } from '@app/interfaces/date-period';
import { TenderStatementTypes } from '@app/pages/company-finances/tender-statement-types';

export class LoadTransportTendersStatement {
  static readonly type =
    '[Company finances -> Transport Tenders statement] Load transport tenders statement data';
  constructor() {}
}

export class DownloadTransportTendersStatement {
  static readonly type =
    '[Company finances -> Transport Tenders statement] Download transport tenders statement';
  constructor() {}
}

export class SetDatePeriodInCompanyTransportTendersStatement {
  static readonly type =
    '[Company finances -> Transport Tenders statement] Set Date Period';
  constructor(public datePeriod: DatePeriod) {}
}

export class SetTypeInCompanyTransportTendersStatement {
  static readonly type =
    '[Company finances -> Transport Tenders statement] Set Type';
  constructor(public type: TenderStatementTypes) {}
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { SetSeaTendersOnTendersPage } from './tenders-page_sea.actions';
import { TendersPageSeaStateModel } from './tenders-page_sea-state.model';
import { Injectable } from '@angular/core';

@Injectable()
@State<TendersPageSeaStateModel>({
  name: 'tenders_page__sea',
  defaults: {
    tenders: null,
  },
})
export class TendersPageSeaState {
  @Action(SetSeaTendersOnTendersPage)
  @ImmutableContext()
  setSeaTendersOnTendersPage(
    { setState }: StateContext<TendersPageSeaStateModel>,
    { tenders }: SetSeaTendersOnTendersPage
  ) {
    return setState((state: TendersPageSeaStateModel) => {
      state.tenders = tenders;
      return state;
    });
  }
}

import { WarehousePhoto } from '@app/modules/warehouses/interfaces/warehouse-photo';
import { CreateWarehouseFormData } from '@app/pages/warehouses/create/interfaces/create-warehouse.form-data';

export class TryCreateWarehouse {
  static readonly type =
    '[Create Warehouse Page] Try create warehouse (Warehouse form submitted)';
  constructor(public data: CreateWarehouseFormData) {}
}

export class TryUpdateWarehouse {
  static readonly type =
    '[Create Warehouse Page] Try update warehouse (Warehouse form submitted)';
  constructor(public data: CreateWarehouseFormData) {}
}

export class ResetWarehouse {
  static readonly type = '[Create Warehouse Page] Reset initial warehouse data';
  constructor() {}
}

export class DeleteWarehousePhoto {
  static readonly type = '[Create Warehouse Page] Delete warehouse photo';
  constructor(public warehouseId: number, public photoId: number) {}
}

export class UploadWarehousePhotos {
  static readonly type = '[Create Warehouse Page] Upload warehouse photos';
  constructor(public warehouseId: number, public photos: string[]) {}
}

export class SetWarehousePhotos {
  static readonly type = '[Create Warehouse Page] Set warehouse photos';
  constructor(public photos: WarehousePhoto[]) {}
}

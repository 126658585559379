export const environment = {
  origin: 'https://cargotenders.com',
  name: 'prod',
  production: true,
  is_local: false,
  plugins: [],
  api_domain: 'backend-prod.cargotenders.com',
  api_url: 'https://backend-prod.cargotenders.com/api/v1',
  mercure_url: 'https://ws-prod.cargotenders.com',
  sentry_dsn: null,
  enableLogger: true,
  gis_map_api_key: '00b7d46c-70a6-41cd-a351-4d8fae1431c0',
};

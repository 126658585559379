import { State, Action, StateContext, Store, Actions } from '@ngxs/store';
import { tap, catchError, map, mergeMap } from 'rxjs/operators';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { AuthStateModel } from './auth-state.model';
import { from, Observable, of } from 'rxjs';
import {
  TryLogout,
  LogoutSuccess,
  SaveAccessAndRefreshTokens,
  InitLoginPage,
  TryLogin,
  LoginFailed,
  LoginSucceess,
  ReloadCurrentCompanyPermissions,
  SubmitRegistrationApplication,
  ForgotPassword,
  ResetPassword,
  SetUserLangauge,
} from './auth.actions';

import { Injectable } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';
import {
  TryRegister,
  RegistrationFailed,
  RegistrationSucceess,
} from '@app/store/registration/registration.actions';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { ResponseSuccess } from '@app/interfaces/response-success';
import { AuthService } from '@app/modules/auth/auth.service';
import { NewAccessTokenResponse } from '@app/modules/auth/interfaces/new-access-token-response';
import { StateResetAll } from 'ngxs-reset-plugin';
import { PermissionsService } from '@app/modules/permissions/services/permissions.service';
import { Permission } from '@app/modules/permissions/interfaces/permission';
import {
  ChangeCompanySuccess,
  InitDashboard,
} from '@app/store/tabs/tabs.actions';
import { LogoutData } from '@app/modules/auth/interfaces/logout-data';
import { Platform } from '@ionic/angular';
import { Device, DeviceId } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import {
  RegisterUserWithCompanyFailed,
  RegisterUserWithCompanySucceess,
  TryRegisterUserWithCompany,
} from '../register-user-with-company';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    refreshToken: null,
    accessTokenPayload: null,
    wrongCredentials: false,
    userPermissions: [],
    companyPermissions: [],
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private store: Store,
    private uiService: UiService,
    private permissionsService: PermissionsService,
    private platform: Platform
  ) {}

  @Action(TryLogin)
  @ImmutableContext()
  login({}: StateContext<AuthStateModel>, { payload }: TryLogin) {
    this.uiService.displayLoading();
    return this.authService.login(payload).pipe(
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(new LoginFailed());
        }
        return of(null);
      }),
      tap((response: ResponseSuccess<NewAccessTokenResponse>) => {
        this.uiService.dismissLoading();
        if (response) {
          this.store.dispatch(new SaveAccessAndRefreshTokens(response.data));
          this.store.dispatch(new LoginSucceess());
        }
      })
    );
  }

  @Action(TryRegister)
  @ImmutableContext()
  tryRegister({}: StateContext<AuthStateModel>, { payload }: TryRegister) {
    this.uiService.displayLoading();
    return this.authService.register(payload).pipe(
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(new RegistrationFailed(err.originalError.error));
        }
        return of(null);
      }),
      tap((response: NewAccessTokenResponse) => {
        this.uiService.dismissLoading();
        if (response) {
          this.store.dispatch(new RegistrationSucceess());
        }
      })
    );
  }

  @Action(TryRegisterUserWithCompany)
  @ImmutableContext()
  tryRegisterUserWithCompany(
    {}: StateContext<AuthStateModel>,
    { payload }: TryRegisterUserWithCompany
  ) {
    this.uiService.displayLoading();
    return this.authService.registerUserWithCompany(payload).pipe(
      catchError((err) => {
        this.uiService.dismissLoading();
        if (err instanceof UnprocessableEntity) {
          this.store.dispatch(
            new RegisterUserWithCompanyFailed(err.originalError.error)
          );
        }
        return of(null);
      }),
      tap((response: NewAccessTokenResponse) => {
        this.uiService.dismissLoading();
        if (response) {
          this.store.dispatch(new RegisterUserWithCompanySucceess());
        }
      })
    );
  }

  @Action(SaveAccessAndRefreshTokens)
  @ImmutableContext()
  private saveAccessAndRefreshTokens(
    { setState }: StateContext<AuthStateModel>,
    { payload }: SaveAccessAndRefreshTokens
  ) {
    return setState((state: AuthStateModel) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.accessTokenPayload = this.authService.decodeToken(payload.token);

      return state;
    });
  }

  @Action(TryLogout)
  @ImmutableContext()
  logout({ getState, setState }: StateContext<AuthStateModel>) {
    const data: LogoutData = {
      refreshToken: getState().refreshToken,
    };

    let deviceId$: Observable<string>;

    if (this.platform.is('hybrid')) {
      deviceId$ = from(Device.getId()).pipe(
        mergeMap((info: DeviceId) => {
          return from(PushNotifications.removeAllListeners()).pipe(
            map(() => {
              return info.identifier;
            })
          );
        })
      );
    } else {
      deviceId$ = of(null);
    }

    return deviceId$.pipe(
      mergeMap((deviceId: string) => {
        if (deviceId) {
          data.deviceId = deviceId;
        }
        return this.authService.logout(data).pipe(
          tap(() => {
            this.store.dispatch(new LogoutSuccess());
          })
        );
      })
    );
  }

  @Action(LogoutSuccess)
  @ImmutableContext()
  logoutSuccess({ setState }: StateContext<AuthStateModel>) {
    this.store.dispatch(new StateResetAll());
    return setState((state: AuthStateModel) => {
      state.token = null;
      state.refreshToken = null;
      state.accessTokenPayload = null;

      return state;
    });
  }

  @Action(InitLoginPage)
  @ImmutableContext()
  initHomepage({ setState }: StateContext<AuthStateModel>) {
    setState((state: AuthStateModel) => {
      state.wrongCredentials = false;
      return state;
    });
  }

  @Action(LoginFailed)
  @ImmutableContext()
  authFailed({ setState }: StateContext<AuthStateModel>) {
    setState((state: AuthStateModel) => {
      state.wrongCredentials = true;
      return state;
    });
  }

  // todo: remake?
  @Action(LoginSucceess)
  @ImmutableContext()
  authSucceeded({ setState }: StateContext<AuthStateModel>) {
    setState((state: AuthStateModel) => {
      state.wrongCredentials = false;
      return state;
    });
  }

  @Action(LoginSucceess)
  @Action(ChangeCompanySuccess)
  @Action(InitDashboard)
  @Action(ReloadCurrentCompanyPermissions)
  @ImmutableContext()
  loadUserCompanyPermissions({
    getState,
    setState,
  }: StateContext<AuthStateModel>) {
    return this.permissionsService
      .getUserPermissions(
        getState().accessTokenPayload.data.id,
        getState().accessTokenPayload.data.company?.id
      )
      .pipe(
        tap((permissions: Permission[]) => {
          return setState((state: AuthStateModel) => {
            state.companyPermissions = permissions;
            return state;
          });
        })
      );
  }

  @Action(LoginSucceess)
  @Action(InitDashboard)
  @Action(ReloadCurrentCompanyPermissions)
  @ImmutableContext()
  loadUserPermissions({ getState, setState }: StateContext<AuthStateModel>) {
    return this.permissionsService
      .getUserPermissions(getState().accessTokenPayload.data.id, 0)
      .pipe(
        tap((permissions: Permission[]) => {
          return setState((state: AuthStateModel) => {
            state.userPermissions = permissions;
            return state;
          });
        })
      );
  }

  @Action(SubmitRegistrationApplication)
  @ImmutableContext()
  submitRegistrationApplication(
    {}: StateContext<AuthStateModel>,
    { data }: SubmitRegistrationApplication
  ) {
    return this.authService.submitRegistrationApplication(data);
  }

  @Action(ForgotPassword)
  @ImmutableContext()
  forgotPassword({}: StateContext<AuthStateModel>, { email }: ForgotPassword) {
    return this.authService.forgotPassword(email);
  }

  @Action(ResetPassword)
  @ImmutableContext()
  resetPassword(
    {}: StateContext<AuthStateModel>,
    { token, data }: ResetPassword
  ) {
    return this.authService.resetPassword(token, data);
  }

  @Action(SetUserLangauge)
  @ImmutableContext()
  setUserLangauge(
    { setState }: StateContext<AuthStateModel>,
    { lang }: SetUserLangauge
  ) {
    return this.authService.setUserLanguage(lang).pipe(
      tap((payload: NewAccessTokenResponse) => {
        return setState((state: AuthStateModel) => {
          state.token = payload.token;
          state.refreshToken = payload.refreshToken;
          state.accessTokenPayload = this.authService.decodeToken(
            payload.token
          );
          return state;
        });
      })
    );
  }
}

import { CompanyMember } from '@app/modules/companies/interfaces/company-member';
import { RelationTypes } from '@app/modules/companies/relation-types';
import { Selector } from '@ngxs/store';
import { CompaniesViewCuratorsStateModel } from './companies_view_curators-state.model';
import { CompaniesViewCuratorsState } from './companies_view_curators.state';

export class CompaniesViewCuratorsSelectors {
  @Selector([CompaniesViewCuratorsState])
  static curators(state: CompaniesViewCuratorsStateModel): CompanyMember[] {
    return state.curators;
  }

  @Selector([CompaniesViewCuratorsState])
  static logistsCount(state: CompaniesViewCuratorsStateModel): number {
    return state.curators.filter((member: CompanyMember) => {
      return member.relationType.code === RelationTypes.LOGIST;
    }).length;
  }

  @Selector([CompaniesViewCuratorsState])
  static sellersCount(state: CompaniesViewCuratorsStateModel): number {
    return state.curators.filter((member: CompanyMember) => {
      return member.relationType.code === RelationTypes.SELLER;
    }).length;
  }

  @Selector([CompaniesViewCuratorsState])
  static curatorsCount(state: CompaniesViewCuratorsStateModel): number {
    return state.curators.filter((member: CompanyMember) => {
      return member.relationType.code === RelationTypes.CURATOR;
    }).length;
  }
}

import { Fragment } from './interfaces/fragment';

const assetsDir = 'assets/img/widgets/';

export const fragments: Fragment[] = [
  {
    name: 1,
  },
  {
    name: 2,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Формирование документов',
      description:
        'PAGES.MAIN_PAGE.FRAGMENTS.Формирование документов (описание)',
      imageSrc: assetsDir + 'iceberg-widget-documents.png',
    },
  },
  {
    name: 3,
  },
  {
    name: 4,
  },
  {
    name: 5,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Курсы валют',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Курсы валют (описание)',
      imageSrc: assetsDir + 'iceberg-widget-currency.png',
    },
  },
  {
    name: 6,
  },
  {
    name: 7,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Календарь',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Календарь (описание)',
      imageSrc: assetsDir + 'iceberg-widget-calendar.png',
    },
  },
  {
    name: 8,
  },
  {
    name: 9,
  },
  {
    name: 10,
  },
  {
    name: 11,
  },
  {
    name: 12,
  },
  {
    name: 13,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Карта',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Карта (описание)',
      imageSrc: assetsDir + 'iceberg-widget-map.png',
    },
  },
  {
    name: 14,
  },
  {
    name: 15,
  },
  {
    name: 16,
  },
  {
    name: 17,
  },
  {
    name: 18,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Тендеры',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Тендеры (описание)',
      imageSrc: assetsDir + 'iceberg-widget-tenders.png',
    },
  },
  {
    name: 19,
  },
  {
    name: 20,
  },
  {
    name: 21,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Чаты',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Чаты (описание)',
      imageSrc: assetsDir + 'iceberg-widget-chat.png',
    },
  },
  {
    name: 22,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Погода',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Погода (описание)',
      imageSrc: assetsDir + 'iceberg-widget-weather.png',
    },
  },
  {
    name: 23,
  },
  {
    name: 24,
  },
  {
    name: 25,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Расписание морских партнеров',
      description:
        'PAGES.MAIN_PAGE.FRAGMENTS.Расписание морских партнеров (описание)',
      imageSrc: assetsDir + 'iceberg-widget-ship-routes.png',
    },
  },
  {
    name: 26,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Заметки',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Заметки (описание)',
      imageSrc: assetsDir + 'iceberg-widget-notes.png',
    },
  },
  {
    name: 27,
  },
  {
    name: 28,
  },
  {
    name: 29,
  },
  {
    name: 30,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Статистика по тендерам',
      description:
        'PAGES.MAIN_PAGE.FRAGMENTS.Статистика по тендерам (описание)',
      imageSrc: assetsDir + 'iceberg-widget-tender-stats.png',
    },
  },
  {
    name: 31,
  },
  {
    name: 32,
    popupData: {
      title: 'PAGES.MAIN_PAGE.FRAGMENTS.Калькулятор',
      description: 'PAGES.MAIN_PAGE.FRAGMENTS.Калькулятор (описание)',
      imageSrc: assetsDir + 'iceberg-widget-calc.png',
    },
  },
];

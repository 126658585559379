import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { LayoutSelectors } from '@app/store/layout/layout.selectors';

@Injectable()
export class IsNotWhitelabelGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.store.selectOnce(LayoutSelectors.isWhitelabel).pipe(
      map((isWhitelabel: boolean) => {
        return !isWhitelabel;
      })
    );
  }
}

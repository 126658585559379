import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable, takeUntil, tap } from 'rxjs';
import { CalendarAddEditFormData } from '@app/pages/calendar/components/calendar-add-edit-task/calendar-add-edit.form-data';
import { CalendarListFilters } from '@app/pages/calendar/interfaces/calendar-list-filters';
import { CalendarTask } from '@app/pages/calendar/interfaces/calendar-task';
import {
  ActionCompletion,
  Actions,
  ofActionCompleted,
  Store,
} from '@ngxs/store';
import {
  CreateTaskInCalendar,
  DeleteTaskInCalendar,
  ListTasksInCalendar,
  ListTasksInTasksCalendarWidget,
  UpdateTaskInCalendar,
} from '@app/store';
import { Action } from '@ngxs-labs/emitter/lib/core/internal/internals';

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(
    private apiService: ApiService,
    private actions$: Actions,
    private store: Store
  ) {}

  create(data: CalendarAddEditFormData): Observable<CalendarTask> {
    return this.apiService.create<CalendarTask>('/calendar-task', data);
  }

  update(id: number, data: CalendarAddEditFormData): Observable<CalendarTask> {
    return this.apiService.put<CalendarTask>('/calendar-task/' + id, data);
  }

  list(
    filters: CalendarListFilters,
    fields?: string[]
  ): Observable<CalendarTask[]> {
    const params: any = {
      ...filters,
    };

    if (fields) {
      params.fields = fields.join(',');
    }

    return this.apiService.get<CalendarTask[]>('/calendar-task', params);
  }

  delete(id: number): Observable<boolean> {
    return this.apiService.delete<boolean>('/calendar-task/' + id);
  }

  get(id: number): Observable<CalendarTask> {
    return this.apiService.get<CalendarTask>('/calendar-task/' + id, {
      fields: [
        'id',
        'priority',
        'title',
        'task',
        'date.startAt',
        'date.endAt',
        'state.code',
        'createdBy.id',
        'createdBy.first_name',
        'createdBy.middle_name',
        'createdBy.last_name',
        'createdTo.id',
        'createdTo.first_name',
        'createdTo.middle_name',
        'createdTo.last_name',
      ].join(','),
      expand: ['createdBy', 'createdTo'].join(','),
    });
  }

  complete(id: number): Observable<CalendarTask> {
    return this.apiService.put<CalendarTask>('/calendar-task/' + id + '/done');
  }

  executeOnUpdate(
    until$: Observable<boolean>,
    excuteAction: Action<ListTasksInTasksCalendarWidget | ListTasksInCalendar>
  ) {
    this.actions$
      .pipe(
        takeUntil(until$),
        ofActionCompleted(
          CreateTaskInCalendar,
          UpdateTaskInCalendar,
          DeleteTaskInCalendar
        ),
        tap(
          (
            actionCompletion: ActionCompletion<
              CreateTaskInCalendar | UpdateTaskInCalendar | DeleteTaskInCalendar
            >
          ) => {
            if (actionCompletion.result.successful) {
              this.store.dispatch(new excuteAction());
            }
          }
        )
      )
      .subscribe();
  }
}

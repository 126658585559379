import { State, Action, StateContext, Store } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  SetSeaCargoTenders,
  ReloadSeaCargoTender,
} from './cargo-tenders-list_sea.actions';
import { CargoTendersListSeaStateModel } from './cargo-tenders-list_sea-state.model';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { SeaCargoTender } from '@app/modules/tenders/interfaces/sea-cargo-tender';
import { CargoTendersSeaService } from '@app/modules/tenders/services/cargo-tenders-sea.service';
import {
  CargoTendersViewSeaSelectors,
  DeleteSeaCargoTender,
  NewBetPlacedInSeaCargoTender,
  UpdateSeaCargoPointArrivalDate,
} from '@app/store/cargo-tenders/view';
import { SeaCargoTenderLoadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-load-place';
import { SeaCargoTenderUnloadPlace } from '@app/modules/tenders/interfaces/sea-cargo-tender-unload-place';
import {
  AddSeaCargoTenderToFavorites,
  RemoveSeaCargoTenderFromFavorites,
} from '@app/store/favorites';

@Injectable()
@State<CargoTendersListSeaStateModel>({
  name: 'cargo_tenders__list__sea',
  defaults: {
    tenders: null,
  },
})
export class CargoTendersListSeaState {
  constructor(
    private cargoTendersSeaService: CargoTendersSeaService,
    private store: Store
  ) {}

  @Action(SetSeaCargoTenders)
  @ImmutableContext()
  setSeaCargoTenders(
    { setState }: StateContext<CargoTendersListSeaStateModel>,
    { tenders }: SetSeaCargoTenders
  ) {
    return setState((state: CargoTendersListSeaStateModel) => {
      state.tenders = tenders;
      return state;
    });
  }

  @Action(ReloadSeaCargoTender)
  @ImmutableContext()
  reloadCargoTender(
    { getState, setState }: StateContext<CargoTendersListSeaStateModel>,
    { tenderId }: ReloadSeaCargoTender
  ) {
    const tenders = getState().tenders;
    if (!tenders) {
      return;
    }

    let exists = -1;

    for (let i = 0; i < tenders.length; i++) {
      if (tenders[i].id === tenderId) {
        exists = i;
        break;
      }
    }
    if (exists === -1) {
      return;
    }

    return this.cargoTendersSeaService.get(tenderId).pipe(
      tap((tender: SeaCargoTender) => {
        setState((state: CargoTendersListSeaStateModel) => {
          state.tenders[exists] = { ...state.tenders[exists], ...tender };
          return state;
        });
      })
    );
  }

  @Action(NewBetPlacedInSeaCargoTender)
  @ImmutableContext()
  newBetPlacedInSeaCargoTender(
    { getState, setState }: StateContext<CargoTendersListSeaStateModel>,
    { tenderId }: NewBetPlacedInSeaCargoTender
  ) {
    const tenders = getState().tenders;
    if (!tenders) {
      return;
    }

    let exists = -1;

    for (let i = 0; i < tenders.length; i++) {
      if (tenders[i].id === tenderId) {
        exists = i;
        break;
      }
    }
    if (exists === -1) {
      return;
    }

    return this.cargoTendersSeaService.getTenderBetInfo(tenderId).pipe(
      tap((tender: SeaCargoTender) => {
        setState((state: CargoTendersListSeaStateModel) => {
          state.tenders[exists] = { ...state.tenders[exists], ...tender };
          return state;
        });
      })
    );
  }

  @Action(DeleteSeaCargoTender)
  @ImmutableContext()
  deleteSeaCargoTender(
    { setState }: StateContext<CargoTendersListSeaStateModel>,
    { tenderId }: DeleteSeaCargoTender
  ) {
    return setState((state: CargoTendersListSeaStateModel) => {
      state.tenders = state.tenders.filter((tender: SeaCargoTender) => {
        return tender.id !== tenderId;
      });
      return state;
    });
  }

  @Action(UpdateSeaCargoPointArrivalDate)
  @ImmutableContext()
  updateSeaCargoPointArrivalDate(
    { setState }: StateContext<CargoTendersListSeaStateModel>,
    { pointID, arrivalDate }: UpdateSeaCargoPointArrivalDate
  ) {
    return setState((state: CargoTendersListSeaStateModel) => {
      const updatedTender: SeaCargoTender = this.store.selectSnapshot(
        CargoTendersViewSeaSelectors.tender
      );
      const tender: SeaCargoTender = state.tenders.find(
        (currentTender: SeaCargoTender) => {
          return currentTender.id === updatedTender.id;
        }
      );

      if (!tender) {
        return state;
      }

      let point: SeaCargoTenderLoadPlace | SeaCargoTenderUnloadPlace;
      switch (pointID) {
        case tender.loadPlace.id:
          point = tender.loadPlace;
          break;

        case tender.unloadPlace.id:
          point = tender.unloadPlace;
          break;
      }

      if (point) {
        point.arrivalDate = arrivalDate;
      }

      return state;
    });
  }

  @Action(AddSeaCargoTenderToFavorites)
  @ImmutableContext()
  AddseaCargoTenderToFavorites(
    { setState }: StateContext<CargoTendersListSeaStateModel>,
    { entityId }: AddSeaCargoTenderToFavorites
  ) {
    return setState((state: CargoTendersListSeaStateModel) => {
      const tender = state.tenders.find((tender: SeaCargoTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = true;
      }

      return state;
    });
  }

  @Action(RemoveSeaCargoTenderFromFavorites)
  @ImmutableContext()
  removeSeaCargoTenderFromFavorites(
    { setState }: StateContext<CargoTendersListSeaStateModel>,
    { entityId }: RemoveSeaCargoTenderFromFavorites
  ) {
    return setState((state: CargoTendersListSeaStateModel) => {
      const tender = state.tenders.find((tender: SeaCargoTender) => {
        return tender.id === entityId;
      });

      if (tender) {
        tender.isFavorite = false;
      }

      return state;
    });
  }
}

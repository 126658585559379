import { Action, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BetsStateModel } from './bets-state.model';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import {
  AddBetFailed,
  AddBetSuccess,
  SaveSeaTenderMarkupFailed,
  SaveSeaTenderMarkupSuccess,
  TryAddBet,
  TrySaveSeaTenderMarkup,
  TryUpdateBet,
  UpdateBetFailed,
  UpdateBetSuccess,
  UpdateConfirmedBet,
} from './bets.actions';
import { UiService } from '@app/services/ui.service';
import { BetsService } from '@app/modules/bets/bets.service';
import { catchError, of, tap } from 'rxjs';
import { UnprocessableEntity } from '@app/errors/response-errors/unprocessable-entity';
import { HttpErrorResponse } from '@angular/common/http';
import { SetWinBet } from '../cargo-tenders/view/cargo-tenders_view.actions';
import { Bet } from '@app/modules/bets/interfaces/bet';

@Injectable()
@State<BetsStateModel>({
  name: 'bets',
  defaults: {},
})
export class BetsState {
  constructor(
    private uiService: UiService,
    private betsService: BetsService,
    private store: Store
  ) {}

  @Action(TryAddBet)
  @ImmutableContext()
  addBet(
    {}: StateContext<BetsStateModel>,
    {
      cargoTenderTransportationType,
      tenderId,
      price,
      deliveryDate,
      minPrice,
      comment,
      duration,
      markup,
      markupPercent,
      clientSum,
      carrierVat,
      clientVat,
    }: TryAddBet
  ) {
    this.uiService.displayLoading();
    return this.betsService
      .add(
        cargoTenderTransportationType,
        tenderId,
        price,
        deliveryDate,
        minPrice,
        comment,
        duration,
        markup,
        markupPercent,
        clientSum,
        carrierVat,
        clientVat
      )
      .pipe(
        tap(() => {
          this.uiService.dismissLoading();
          this.store.dispatch(new AddBetSuccess());
        }),
        catchError((error: any) => {
          if (error instanceof UnprocessableEntity) {
            const errorMessages = (error.originalError as HttpErrorResponse)
              .error.errors;
            this.store.dispatch(new AddBetFailed(errorMessages));
            this.uiService.dismissLoading();
            return of(null);
          }
        })
      );
  }

  @Action(TryUpdateBet)
  @ImmutableContext()
  updateBet(
    {}: StateContext<BetsStateModel>,
    { cargoTenderTransportationType, tenderId, price }: TryUpdateBet
  ) {
    this.uiService.displayLoading();
    return this.betsService
      .update(cargoTenderTransportationType, tenderId, price)
      .pipe(
        tap(() => {
          this.uiService.dismissLoading();
          this.store.dispatch(new UpdateBetSuccess());
        }),
        catchError((error: any) => {
          if (error instanceof UnprocessableEntity) {
            const errorMessages = (error.originalError as HttpErrorResponse)
              .error.errors;
            this.store.dispatch(new UpdateBetFailed(errorMessages));
            this.uiService.dismissLoading();
            return of(null);
          }
        })
      );
  }

  @Action(UpdateConfirmedBet)
  @ImmutableContext()
  updateConfirmedBet(
    {}: StateContext<BetsStateModel>,
    { betId, data }: UpdateConfirmedBet
  ) {
    return this.betsService.updateConfirmedBet(betId, data).pipe(
      tap((bet: Bet) => {
        this.store.dispatch(new SetWinBet(bet));
      })
    );
  }

  @Action(TrySaveSeaTenderMarkup)
  @ImmutableContext()
  trySaveSeaTenderMarkup(
    {}: StateContext<BetsStateModel>,
    { betId, markup }: TrySaveSeaTenderMarkup
  ) {
    this.uiService.displayLoading();
    return this.betsService.changeMarkup(betId, markup).pipe(
      tap(() => {
        this.uiService.dismissLoading();
        this.store.dispatch(new SaveSeaTenderMarkupSuccess());
      }),
      catchError((error: any) => {
        if (error instanceof UnprocessableEntity) {
          const errorMessages = (error.originalError as HttpErrorResponse).error
            .errors;
          this.store.dispatch(new SaveSeaTenderMarkupFailed(errorMessages));
          this.uiService.dismissLoading();
          return of(null);
        }
      })
    );
  }
}

import { State, Action, StateContext } from '@ngxs/store';
import { ImmutableContext } from '@ngxs-labs/immer-adapter';
import { CompaniesListFiltersStateModel } from './companies_list_filters-state.model';
import { Injectable } from '@angular/core';
import { SetCompaniesListFilters } from './companies_list_filters.actions';

@Injectable()
@State<CompaniesListFiltersStateModel>({
  name: 'companies__list__filters',
  defaults: {
    types: [],
    isAttendanceCompany: false,
    attendanceDatePeriod: null,
  },
})
export class CompaniesListFiltersState {
  @Action(SetCompaniesListFilters)
  @ImmutableContext()
  setCompaniesListFilters(
    { setState }: StateContext<CompaniesListFiltersStateModel>,
    { filters }: SetCompaniesListFilters
  ) {
    return setState((state: CompaniesListFiltersStateModel) => {
      if (filters.types !== undefined) {
        state.types = filters.types;
      }

      if (filters.isAttendanceCompany !== undefined) {
        state.isAttendanceCompany = filters.isAttendanceCompany;
      }

      if (filters.attendanceDatePeriod !== undefined) {
        state.attendanceDatePeriod = filters.attendanceDatePeriod;
      }

      return state;
    });
  }
}

import { Injectable } from '@angular/core';
import { ApiService } from '@app/services/api.service';
import { Observable } from 'rxjs';
import { Square } from '@app/entities/place/square';

@Injectable({
  providedIn: 'root',
})
export class SquaresService {
  constructor(private apiService: ApiService) {}

  getAll(countryId: number): Observable<Square[]> {
    return this.apiService.getAll<Square>('/square', {
      countryId,
    });
  }
}
